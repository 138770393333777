
 
//API Method Constants
//for backdoor login
export const METHOD_TOKEN = 'ssoapp/backenduser/';
export const METHOD_USERINFO = 'users/me/';

//for sso login
export const METHOD_SSO_REDIRECT_URL = 'ssoapp/signin/';
export const METHOD_SSO_VERIFY_TOKEN = 'ssoapp/authenticate/';
export const METHOD_OKTA_SSO_VERIFY_TOKEN = 'ssoapp/authenticate-okta/';
export const METHOD_SSO_LOGOUT = 'ssoapp/signout/';
export const METHOD_OKTA_SSO_LOGOUT= 'ssoapp/signout-okta';
//language for sso
export const LANGUAGE_BAD_SSO_URL = 'Invalid Sso url.';
export const LANGUAGE_GENERAL_SSO_ERROR = 'Some error occured.';

//Action Constants
export const LOGIN_INFO = 'LOGIN_INFO';