import React, { Component } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import roles_list_data from "resources/json/roles.json";
import FilterableMultiSelect from "components/shared/FilterableMultiSelect";
import { ErrorMessage } from "components/shared/ErrorMessage";
import _ from "lodash";
import userService from "services/admin/userService";
import Spinner from "components/shared/Spinner";
import { MSG_UPDATE_SUCCESS, MSG_ADD_SUCCESS, TEXT_MAX_LENGTH, TEXT_MIN_LENGTH, EMAIl_MIN_LENGTH } from "constants/admin/users";
import { ROLE_SLUG_PROD_ADMIN, ROLE_SLUG_SYS_ADMIN, ROLE_SLUG_PUBLIC_USER } from "constants/common";
import Breadcrumb from "components/shared/Breadcrumb";
import { handleChange, handleTouched,formFailure,trimFields } from "utils/masterHelpers";
const serviceObj = new userService();
class UserForm extends Component {
  roles_list = JSON.parse(JSON.stringify(roles_list_data));

  constructor(props) {
    super(props);
    this.handleChange=handleChange.bind(this);
    this.handleTouched=handleTouched.bind(this);
    this.formFailure=formFailure.bind(this);
    this.trimFields=trimFields.bind(this);
    this.products_list=[];
    this.state = {
      isEditForm: false,
      userId: 0,
      formReady: false,
      isSubmitting: false,
      refreshRolesToken: new Date(),
      refreshProductToken: new Date(),
      errors: {},
      touched: {},
      form: {
        email: "",
        full_name: "",
        hdsusername:"",
        user_type:"",
        phone:"",
        address:"",
        country:"",
        company_name: "",
        roles: [],
        products: [],
      },
      breadcrumbs:[{ title: 'Manage Users', url: '/admin/users' },
      { title: 'User' }
    ]
    };
  }

  componentDidMount = async () => {
    this.products_list = await serviceObj.getAllFlatProducts();
    const { userId } = this.props.match.params;
    if (userId > 0) {
      //repopulate the form
      this.setState({
        userId,
        isEditForm: true,
      });
      const userInfo = await serviceObj.getDetail(userId);
      const form = this.state.form;
      form.email = userInfo?.email || '';
      form.full_name = userInfo?.full_name || '';
      form.hdsusername = userInfo?.hdsusername || '';
      form.user_type = userInfo?.user_type || '';
      form.phone = userInfo?.phone || '';
      form.address = userInfo?.address || '';
      form.country = userInfo?.country || '';
      form.company_name = userInfo?.company_name || '';
      form.roles = userInfo?.roles || [];
      form.products = userInfo?.products || [];
      if (userInfo?.roles.length) {
        this.roles_list.map((role) => {
          if (userInfo.roles.includes(role.id)) {
            role.isselected = true;
          }
          return true;
        });
      }
      if (userInfo?.products.length) {
        this.products_list.map((product) => {
          if (userInfo.products.includes(product.id)) {
            product.isselected = true;
          }
          return true;
        });
      }
      this.setState(
        {
          form,
        },
        () => {
          this.setState({
            formReady: true,
          });
        }
      ); //populate in form
    } else {
      //do any preload any api call if needed in caseof add form then make the form ready to load
      this.setState({
        formReady: true,
      });
    }
  }; 

  submitForm = async (e) => {
    e.preventDefault();
    this.trimFields(this.state.form);
    this.setState({
      isSubmitting: true,
    });
    const errors = await this.validateForm();
    if (_.isEmpty(errors)) {
      //proceed or apply additional validation
      const errCount = await this.applyRoleProductLogic();
      if (errCount === 0) {
        //now procced to save user
        const form = this.state.form;
        let response;
        try {
          if (this.state.userId > 0 && this.state.isEditForm) {
            response = await serviceObj.update(form, this.state.userId);
          } else {
            response = await serviceObj.create(form);
          }
          if (response) {
            toast.success(response.message || (this.state.userId > 0) ? MSG_UPDATE_SUCCESS : MSG_ADD_SUCCESS);
            this.props.history.push("/admin/users");
          }
        } catch (err) {
           //failure case
          this.formFailure();
          if(err){
            const errors = this.state.errors;
            for (const [field, msg] of Object.entries(err)) {
              errors[field]=msg[0];
            }
            await this.setState({
              errors,
            });
          }
        }
      } else {
        //failure case
        this.formFailure();
      }
    } else {
      //failure case
      this.formFailure();
    }
  };  
  applyRoleProductLogic = async () => {
    const form = this.state.form;
    //if sysadmin is checked the fill all the products by default
    let errorCount = 0;
    const sysAdminRole = this.roles_list
      .filter((role) => role.slug === ROLE_SLUG_SYS_ADMIN)
      .map((r) => r.id);

    if (!_.isEmpty(_.intersection(sysAdminRole, this.state.form.roles))) {
      form["products"] = this.products_list.map((p) => {
        p.isselected = true;
        return p.id;
      });
      await this.setState({
        form,
        refreshProductToken: new Date(),
      });
    }

    //if there is only public user then no products can be select
    const publicUserRole = this.roles_list
      .filter((role) => role.slug === ROLE_SLUG_PUBLIC_USER)
      .map((r) => r.id);
    if (
      this.state.form.roles.length === 1 &&
      publicUserRole[0] === this.state.form.roles[0]
    ) {
      this.products_list.map((p) => {
        p.isselected = false;
        return p.id;
      });
      form["products"] = [];
      await this.setState({
        form,
        refreshProductToken: new Date(), //this is updated to lost the selcted state in filtercontrol
      });
    }

    //if there is any how product admin slected then atleast 1 product is mandatory
    const productAdminRole = this.roles_list
      .filter((role) => role.slug === ROLE_SLUG_PROD_ADMIN)
      .map((r) => r.id);
    if (!_.isEmpty(_.intersection(productAdminRole, this.state.form.roles))) {
      const selectedProducts = this.state.form.products;
      if (selectedProducts.length === 0) {
        const errors = this.state.errors;
        errors["products"] = "select atleast 1 product.";
        errorCount++;
        await this.setState(
          {
            errors,
          },
          () => {
            this.handleTouched("products"); //force set products touched
          }
        );
      } else {
        const errors = this.state.errors;
        errors["products"] = "";
        await this.setState({
          errors,
        });
      }
    }

    return errorCount;
  };
  handleMultiSelect = (data) => {
    const form = this.state.form;
    form[data.field] = data.value;
    this.setState(
      {
        form,
      },
      async () => {
        //mark this input as touched
        this.handleTouched(data.field);

        //trigger the validation
        await this.validateForm();

        this.applyRoleProductLogic(); //after basic validations trgigger further businesslogic
      }
    );
  };
  validateForm = async () => {
    const errors = {};
    const schema = yup.object().shape({
      email: yup.string().required().email().min(EMAIl_MIN_LENGTH).max(TEXT_MAX_LENGTH),
      full_name: yup.string().required().min(TEXT_MIN_LENGTH).max(TEXT_MAX_LENGTH),
      hdsusername: yup.string(),
      user_type: yup.string(),
      phone:yup.string(),
      address:yup.string(),
      country: yup.string(),
      company_name: yup.string(),
      roles: yup.array().required(),
    });
    // check validity
    await schema
      .validate(this.state.form, { abortEarly: false })
      .catch(function (err) {
        err.inner.map((error) =>
          !errors[error.path] ? (errors[error.path] = error.message) : null
        );
      });

    this.setState({
      errors,
    });
    return errors; //return basicerrors
  };

  render() {
    const {
      isSubmitting,
      form,
      touched,
      errors,
      refreshRolesToken,
      refreshProductToken,
      isEditForm,
      formReady,
      breadcrumbs
    } = this.state;
    return (
      <div className="form-wrap mt-3">
        {isSubmitting ? <Spinner /> : null}
        {formReady ? (
          <div className="form-group">
            <Breadcrumb data={breadcrumbs} isEdit={isEditForm} />
            <form onSubmit={this.submitForm}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter email"
                  onChange={this.handleChange}
                  value={form.email}
                  className={`form-control ${
                    touched.email && errors.email ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="email"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="full_name">Full Name</label>
                <input
                  type="text"
                  name="full_name"
                  placeholder="Enter Full Name"
                  onChange={this.handleChange}
                  value={form.full_name}
                  className={`form-control ${
                    touched.full_name && errors.full_name ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="full_name"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="hdsusername">Hds Username</label>
                <input
                  type="text"
                  name="hdsusername"
                  readOnly={isEditForm}
                  placeholder={!isEditForm?"Enter Hds Username":null}
                  onChange={this.handleChange}
                  value={form.hdsusername}
                  className={`form-control ${
                    touched.hdsusername && errors.hdsusername ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="hdsusername"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="user_type">UserType</label>
                <input
                  type="text"
                  name="user_type"
                  readOnly={isEditForm}
                  placeholder={!isEditForm?"Enter Usertype":null}
                  onChange={this.handleChange}
                  value={form.user_type}
                  className={`form-control ${
                    touched.user_type && errors.user_type ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="user_type"
                  className="text-danger"
                />
              </div>              
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  name="phone"
                  placeholder="Enter Phone"
                  onChange={this.handleChange}
                  value={form.phone}
                  className={`form-control ${
                    touched.phone && errors.phone ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="phone"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <textarea
                  name="address"
                  placeholder="Enter Address"
                  onChange={this.handleChange}
                  value={form.address}
                  className={`form-control ${
                    touched.address && errors.address ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="address"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  name="country"
                  placeholder="Enter Country"
                  onChange={this.handleChange}
                  value={form.country}
                  className={`form-control ${
                    touched.country && errors.country ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="country"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="company_name">Company Name</label>
                <input
                  type="text"
                  name="company_name"
                  placeholder="Enter Company Name"
                  onChange={this.handleChange}
                  value={form.company_name}
                  className={`form-control ${
                    touched.company_name && errors.company_name
                      ? "is-invalid"
                      : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="company_name"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <div className="float-left mb-3 col-md-2">
                  <label>Roles</label>
                  <FilterableMultiSelect
                    controlFor={'userForm'}
                    controlName={"roles"}
                    resetOptions={refreshRolesToken}
                    options={this.roles_list}
                    onChangeValue={this.handleMultiSelect}
                  />
                  <ErrorMessage
                    tag="div"
                    touched={touched}
                    errors={errors}
                    name="roles"
                    className="text-danger"
                  />
                </div>
                <div className="float-left mb-3 col-md-3">
                  <label>Products</label>
                  <FilterableMultiSelect
                    controlFor={'userForm'}
                    controlName={"products"}
                    resetOptions={refreshProductToken}
                    options={this.products_list}
                    onChangeValue={this.handleMultiSelect}
                  />
                  <ErrorMessage
                    tag="div"
                    touched={touched}
                    errors={errors}
                    name="products"
                    className="text-danger"
                  />
                </div>
                <div className="float-left mb-3 col-md-5">
                  <label>Guidelines*</label>
                  <ul className="guidelines">
                    <li>
                      If System Admin Selected then all products will be
                      selected, you won't be able to unselect any product
                    </li>
                    <li>
                      If Product Admin is selected then atleast 1 product need
                      to select mandatory
                    </li>
                    <li>
                      If Lab user is selected then product selection is optional
                    </li>
                    <li>
                      If only Public User is selected, then any product can't be
                      select
                    </li>
                  </ul>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-secondary btn-block"
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? "Please wait..."
                  : isEditForm
                    ? "Update User"
                    : "Add User"}
              </button>
            </form>
          </div>
        ) : (
            <Spinner />
          )}
      </div>
    );
  }
}

export default UserForm;
