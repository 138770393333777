import {
  LOADING,
  ALL_CATEGORY_PRODUCTS,
} from "constants/common";
import {
  STORAGE_FILTERS,
  STORAGE_PRODUCT_DATA,
  LOADING_FILTERS,
  DOWNLOAD_LIMIT,
  FILTER_KEYWORDS,
  FILTER_ALL_KEYWORDS,
  TOTAL_RECORD_COUNT,
  LEGACY_PRODUCT_MENU
} from "constants/productMatrix";

const initialState = {
  filterKeywords: [],
  allKeywords:[],
  downloadLimit: 0,
  products: [],
  matriceFilters: [],
  loadingCategoryProducts: false,
  loadingFilters: false,
  matriceProducts: [],
  totalRecordCount:0,
  legacyProductMenu:[]
};

const setProductTags=(data,state)=>{ 
  const existIndex=state.filterKeywords.findIndex(row=>row?.product_id===data?.product_id); 
  if(existIndex>-1){ 
    state.filterKeywords[existIndex]=data;
  }else{
    state.filterKeywords.push(data);
  }
  return state.filterKeywords;
}

const productSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LOADING_FILTERS:
      return {
        ...state,
        loadingFilters: action.payload,
      };
    case ALL_CATEGORY_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case DOWNLOAD_LIMIT:
      return {
        ...state,
        downloadLimit: action.payload,
      };
    case STORAGE_FILTERS:
      return {
        ...state,
        matriceFilters: action.payload,
      };
    case STORAGE_PRODUCT_DATA:
      return {
        ...state,
        matriceProducts: action.payload,
      };
    case FILTER_KEYWORDS:
      return {
        ...state,
        filterKeywords: setProductTags(action.payload,state),
      };
    case FILTER_ALL_KEYWORDS:
        return {
          ...state,
          allKeywords: action.payload,
        };
    case TOTAL_RECORD_COUNT:
      return {
        ...state,
        totalRecordCount:action.payload
      }
    case LEGACY_PRODUCT_MENU:
      return {
        ...state,
        legacyProductMenu:action.payload
      }
    default:
      return state;
  }
};
export default productSearchReducer;
