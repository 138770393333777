// it is used for sds ansible Solution 
const FILTERS = {
    "ansible-sdsb": { 
        hitachi_storage_ansible_module: {
            title: 'Hitachi Storage Ansible Module',
            iconClass: 'network-wired'
        },
       
        ansible: {
            title: 'Ansible',
            icon: 'server.png'
            
        },
        python: {
            title: 'Python',
            icon: 'server.png'
        },
        gateway_os: {
            title: 'Gateway OS',
            iconClass: 'project-diagram'
        },
        uai_gateway: {
            title: 'UAI Gateway',
            icon: 'server.png'
            
        },
        sds_software_version: {
            title : "Software Version",
            icon: 'switch.png'
        }
    }
}
export default FILTERS;