const FILTERS = {
    "ops-center-administrator": {
        administrator_version: {
            title: 'Administrator Version',
            icon: 'server.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        },
        docker: {
            title: 'Container Engines',
            iconClass: 'project-diagram'
        },
        java_dev_kit: {
            title: 'JDK',
            iconClass: 'project-diagram'
        },
        hypervisor: {
            title: 'Hypervisor',
            icon: 'array.png'
        },
        browser: {
            title: 'Browser',
            icon: 'multipath.png'
        },
        array_customer_version: {
            title: 'Storage Array',
            icon: 'array_if.png'
        },
        nas_module: {
            title: 'NAS Module',
            icon: 'firmware.png'
        },
        switch: {
            title: 'Switch',
            icon: 'switch.png'
        }
    }
}
export default FILTERS;