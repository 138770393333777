import { networkService } from "services/networkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,  
  METHOD_DROPDOWN,
  METHOD_ARRAYCUSTOMER_LIST,
  METHOD_ARRAYCUSTOMER_CREATE,
  METHOD_ARRAYCUSTOMER_UPDATE,
  METHOD_ARRAYCUSTOMER_DELETE,
  METHOD_ARRAYCUSTOMER_DETAIL,
  METHOD_ARRAYIF_LIST,
  METHOD_ARRAYIF_CREATE,
  METHOD_ARRAYIF_UPDATE,
  METHOD_ARRAYIF_DELETE,
  METHOD_ARRAYIF_DETAIL,
  METHOD_ARRAYCUSTOMERVERSIONMAPPING_LIST,
  METHOD_ARRAYCUSTOMERVERSIONMAPPING_CREATE,
  METHOD_ARRAYCUSTOMERVERSIONMAPPING_UPDATE,
  METHOD_ARRAYCUSTOMERVERSIONMAPPING_DELETE,
  METHOD_ARRAYCUSTOMERVERSIONMAPPING_DETAIL,
  METHOD_ARRAYCUSTOMER_DROP
} from "constants/admin/masters/array";
class arrayService {
  //array methods
  getAll = (data) => {
    return networkService
      .post(METHOD_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (id) => {
    const updatedMethodName = METHOD_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, id) => {
    const updatedMethodName = METHOD_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (id, is_delete = false) => {
    const updatedMethodName = METHOD_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };  
  getAllDropdown= () => {
    return networkService
      .get(METHOD_DROPDOWN)
      .then((response) => response.data);
  };

  //array customer methods
  getAllCustomer = (data) => {
    return networkService
      .post(METHOD_ARRAYCUSTOMER_LIST, data)
      .then((response) => response.data);
  };
  getCustomerDetail = (id) => {
    const updatedMethodName = METHOD_ARRAYCUSTOMER_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createCustomer = (data) => {
    return networkService
      .post(METHOD_ARRAYCUSTOMER_CREATE, data)
      .then((response) => response.data);
  };
  updateCustomer = (data, id) => {
    const updatedMethodName = METHOD_ARRAYCUSTOMER_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteCustomer = (id, is_delete = false) => {
    const updatedMethodName = METHOD_ARRAYCUSTOMER_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  getAllCustomerDropdown= () => {
    return networkService
      .get(METHOD_ARRAYCUSTOMER_DROP)
      .then((response) => response.data);
  };


  //array if methods
  getAllArrayIf = (data) => {
    return networkService
      .post(METHOD_ARRAYIF_LIST, data)
      .then((response) => response.data);
  };
  getArrayIfDetail = (id) => {
    const updatedMethodName = METHOD_ARRAYIF_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createArrayIf = (data) => {
    return networkService
      .post(METHOD_ARRAYIF_CREATE, data)
      .then((response) => response.data);
  };
  updateArrayIf = (data, id) => {
    const updatedMethodName = METHOD_ARRAYIF_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteArrayIf = (id, is_delete = false) => {
    const updatedMethodName = METHOD_ARRAYIF_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  

  //array customer version mapping methods
  getAllArrayCustomerVersionMapping = (data) => {
    return networkService
      .post(METHOD_ARRAYCUSTOMERVERSIONMAPPING_LIST, data)
      .then((response) => response.data);
  };
  getArrayCustomerVersionMappingDetail = (id) => {
    const updatedMethodName = METHOD_ARRAYCUSTOMERVERSIONMAPPING_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createArrayCustomerVersionMapping = (data) => {
    return networkService
      .post(METHOD_ARRAYCUSTOMERVERSIONMAPPING_CREATE, data)
      .then((response) => response.data);
  };
  updateArrayCustomerVersionMapping = (data, id) => {
    const updatedMethodName = METHOD_ARRAYCUSTOMERVERSIONMAPPING_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteArrayCustomerVersionMapping = (id, is_delete = false) => {
    const updatedMethodName = METHOD_ARRAYCUSTOMERVERSIONMAPPING_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default arrayService;
