import React from "react";
import _ from "lodash";
import { captureMatrixPageView, modalMatricePagingFilters } from "utils/utils";
import productMatrixService from "services/productMatrixService";
import history from "../history";
import {
  encodeUri,
  parseUrlQuery,
  buildUrlQuery,
  modalMatriceFilters,
} from "utils/utils";
import {
  KEYWORD_SEARCH_FIELD,
  KEYWORD_SEARCH_CATEGORY,
  ERROR_FORM_EMPTY,
  MODULE_UCP_PLATFORM,
} from "constants/productMatrix";
import { toast } from "react-toastify";
import {
  ERROR_MESSAGE_MIN_FILTERS,
  OS_FIELD_NAME,
  VCF_VERSION_FIELD_NAME,
} from "constants/common";
import CustomStringRender from "components/shared/CustomStringRender";
import { store } from "index";

const productMatrixServiceObj = new productMatrixService();

export async function handleChangeMenu(e) {
  if (e.slug) {
    if (e?.legacy) {
      this.props.history.push(encodeUri(`/assets/${e.slug}`));
    } else if (e?.landing) {
      this.props.history.push(encodeUri(`/landing-page/${e.slug}`));
    } else {
      this.props.history.push(encodeUri(`/products/${e.slug}`));
    }
  }
}

export async function matriceDataHandler(query) {
  this.setState({
    loadingTable: true,
    selectedRows: [], //reset the selected rows
  });
  return !_.isEmpty(this.props.uriParams)
    ? new Promise((resolve, reject) => {
        let sortData = [{ field: this.state.columns[0].field, dir: "asc" }]; //default sorting
        if (!_.isEmpty(query.orderBy)) {
          sortData = [
            {
              field: query.orderBy.field,
              dir: query.orderDirection,
            },
          ];
        }
        const finalParams = {
          filter: modalMatricePagingFilters(this.props.uriParams),
          take: query.pageSize,
          skip: query.pageSize * query.page,
          sort: sortData,
        };
        this.setState(
          { reqParams: finalParams, defaultPageSize: query.pageSize },
          () => {
            this.props.changeResultParams(finalParams);
            productMatrixServiceObj
              .getPaginatedProducts(finalParams, this.props.productId)
              .then((response) => {
                this.setState({
                  loadingTable: false,
                });
                this.props.setTotalRecords(response.total);
                resolve({
                  data: response.items,
                  page: query.page,
                  totalCount: response.total,
                });
              })
              .catch((e) => {
                this.setState({
                  loadingTable: false,
                });
              });
          }
        );
      })
    : new Promise((resolve, reject) => {
        this.setState({
          loadingTable: false,
        });
        this.props.setTotalRecords(0);
        resolve({ totalCount: 0, data: [], page: 0 });
      });
}
export function rowStyleHandler(rowData) {
  return {
    color: this.state.selectedRows.find((r) => r.id === rowData.id)?.expanded
      ? "#FFFFFF"
      : "#000000",
    backgroundColor: this.state.selectedRows.find((r) => r.id === rowData.id)
      ?.expanded
      ? "#051C2C"
      : "#F4F3F3",
  };
}
export function checkRowExpandable(rowData, l2_fields) {
  return l2_fields.filter((field) => rowData[field] !== "");
}
export function rowClickHandler(event, rowData, togglePanel) {
  const found = this.state.selectedRows.find((r) => r.id === rowData.id);
  const valid_l2_fields = checkRowExpandable(rowData, this.state.l2_fields);
  if (valid_l2_fields.length) {
    if (found?.id > 0) {
      //means already clicked once
      found.expanded = !found.expanded;
      found.expandWidgetOpenState = !found.expandWidgetOpenState;
      const updateState = [...this.state.selectedRows];
      this.setState({ selectedRows: updateState });
    } else {
      const newRecord = {
        id: rowData.id,
        expanded: true,
        expandWidgetOpenState: true,
      };
      const updateState = [...this.state.selectedRows, newRecord];
      this.setState({ selectedRows: updateState });
    }
    togglePanel();
  }
}

export function rowClickHandlerDatapath(event, rowData, togglePanel) {
  const found = this.state.selectedRows.find((r) => r.id === rowData.id);
  if (found?.id > 0) {
    //means already clicked once
    found.expanded = !found.expanded;
    found.expandWidgetOpenState = !found.expandWidgetOpenState;
    const updateState = [...this.state.selectedRows];
    this.setState({ selectedRows: updateState });
  } else {
    const newRecord = {
      id: rowData.id,
      expanded: true,
      expandWidgetOpenState: true,
    };
    const updateState = [...this.state.selectedRows, newRecord];
    this.setState({ selectedRows: updateState });
  }
  togglePanel();
}
export function getWidgetExpandState(id) {
  const rowState = this.state.selectedRows.find((row) => row.id === id);
  return rowState?.expandWidgetOpenState || false;
}

export function scrollToResult() {
  this.props.resultElemRef.current.scrollIntoView({ behavior: "smooth" });
}

//added new common methods in matrice helper
export function handleTotalRecords(count) {
  this.setState({
    totalFilteredRecords: count,
  });
  this.props.totalRecords(count); //set the value in redux, so that parent component can easily utilize that
}

export function getUriParams() {
  const qs = history.location.search;
  return qs !== "" ? parseUrlQuery(qs) : {};
}

export async function initializeMatrix() {
  const uriParams = this.getUriParams();
  await this.setState({
    uriParams,
    refreshDataToken: Date.now(),
    searchFormState: [],
  });
  if (!_.isEmpty(uriParams)) {
    const defaultFormfromUrl = [...uriParams];
    await this.setState({
      searchFormState: defaultFormfromUrl,
    });
  }
  this.props.getMatriceFilters(
    modalMatriceFilters(uriParams),
    this.props.productId
  );
  //Add in Google Analytics
  captureMatrixPageView();
  //
}

export async function handleResultParams(reqParams) {
  this.setState({
    requestedPagingParams: reqParams,
  });
}

export async function download(downloadType) {
  const { totalFilteredRecords, requestedPagingParams } = this.state;
  this.setState({
    downloading: true,
  });
  const paginParameters = totalFilteredRecords > 0 ? requestedPagingParams : {};
  if (!_.isEmpty(paginParameters)) {
    paginParameters.take = totalFilteredRecords;
  }
  await productMatrixServiceObj.downloadFile(
    downloadType,
    paginParameters,
    this.props.productId,
    totalFilteredRecords
  );
  this.setState({
    downloading: false,
  });
}
export async function resetHandler() {
  const resetFilters = []; //reset all filters, //reset the pdf and csv dreq param state
  this.setState({
    searchFormState: resetFilters,
    requestedPagingParams: {},
    refreshToken: Date.now(),
    refreshDataToken: Date.now(),
    uriParams: {},
    clickedControls: [],
  });
  this.props.getMatriceFilters(
    modalMatriceFilters(resetFilters),
    this.props.productId
  );
  this.props.resetGlobalSearch(); //to reset global search in parent componenet
  history.push({
    path: encodeUri(`/products/${this.props.productSlug}`),
  });
}
export async function checkElemination() {
  const formState = this.state.searchFormState;
  const matriceFilters = this.props.matriceFilters;
  const updatedFormState = formState.map((row) => {
    if (row.type === "int") {
      const fieldName = row.field;
      const values = row.value;
      const filterValues = matriceFilters[fieldName]
        ? matriceFilters[fieldName].map((option) => option.id)
        : [];
      const intersection = _.intersection(values, filterValues);
      row.value = intersection;
    }
    return row;
  });
  await this.setState({
    searchFormState: updatedFormState,
  });
}
export async function searchHandler() {
  //await this.checkElemination();//05 august 2021-commented to make the filter flow correct
  let update_selectedstate = [];
  this.state.searchFormState.map((obj) => {
    if (obj?.dynamic === true) {
      Object.keys(obj.value).map((key) => {
        const alreadyExistIndex = this.state.searchFormState.findIndex(
          (obj) => obj.field === key
        );
        if (alreadyExistIndex === -1) {
          update_selectedstate.push({
            field: key,
            type: "int",
            value: obj.value[key],
            operator: "eq",
          });
        } else {
          const alreadyExistIndexInU = update_selectedstate.findIndex(
            (obj) => obj.field === key
          );
          if (alreadyExistIndexInU === -1) {
            update_selectedstate.push({
              field: key,
              type: "int",
              value: obj.value[key],
              operator: "eq",
            });
          } else {
            update_selectedstate[alreadyExistIndexInU].value = _.union(
              update_selectedstate[alreadyExistIndexInU].value,
              obj.value[key]
            );
          }
        }

        return update_selectedstate;
      });
    } else {
      update_selectedstate.push({ ...obj });
    }
    return update_selectedstate;
  });
  const finalUriParams = buildUrlQuery(update_selectedstate);
  history.push({
    path: encodeUri(`/products/${this.props.productSlug}`),
    search: finalUriParams,
  });
  //update the uri param in state
  const uriParams = this.getUriParams();
  this.setState({
    uriParams: uriParams,
    refreshDataToken: Date.now(),
  });
}
export async function submitHandler(minFilterCount = 0) {
  if (!_.isEmpty(this.state.searchFormState)) {
    await this.checkElemination();
    const allFilters = this.state.searchFormState.filter(
      (row) =>
        row.field !== KEYWORD_SEARCH_CATEGORY &&
        row.field !== KEYWORD_SEARCH_FIELD
    );
    if (minFilterCount > allFilters.length) {
      toast.error(ERROR_MESSAGE_MIN_FILTERS.replace("{COUNT}", minFilterCount));
      return false;
    } else {
      const finalUriParams = buildUrlQuery(this.state.searchFormState);
      history.push({
        search: finalUriParams,
      });
      //update the uri param in state
      const uriParams = this.getUriParams();
      this.setState({
        uriParams: uriParams,
        refreshDataToken: Date.now(),
      });
    }
  } else {
    toast.error(ERROR_FORM_EMPTY);
  }
}
export async function handleChangeValue(e) {
  const alreadyExistIndex = this.state.searchFormState.findIndex(
    (obj) => obj?.field === e.field
  );
  let formState = [...this.state.searchFormState];
  let selectedControls = _.union(this.state.clickedControls, [e.field]);
  let selectedfield = e.field;
  if (alreadyExistIndex === -1) {
    formState.push(e);
  }
  if (
    e.field === "ucp_platform" &&
    MODULE_UCP_PLATFORM.includes(this.props.productId)
  ) {
    if (e.value[0] !== formState[0]?.value[0]) {
      let obj = formState[0];
      let newObj = { ...obj, value: [e.value[0]] };
      formState = [newObj];
    }
  }
  // baremetal boot option selection logic
  else if (
    e.field === "boot_options" &&
    this.props.productId === 74 &&
    e.value[0] !== undefined
  ) {
    if (e.value[0] !== formState[0]?.value[0]) {
      let obj = formState.filter((x) => x.field === "boot_options")[0];
      let newObj = { ...obj, value: [e.value[0]] };
      formState = [newObj];
    } else {
    }
  } else {
    formState[alreadyExistIndex] = e;
    if (
      (Array.isArray(e.value) && e.value.length === 0) ||
      (!Array.isArray(e.value) && e.value === "")
    ) {
      formState.splice(alreadyExistIndex, 1);
      selectedControls = selectedControls.filter((item) => item !== e.field); //remove the field
      selectedfield = selectedControls[selectedControls.length - 1]; //get last index from array
    } else {
      if (
        selectedControls.length > 0 &&
        selectedfield !== selectedControls[selectedControls.length - 1]
      ) {
        const splicedItem = selectedControls.splice(
          selectedControls.findIndex((item) => item === e.field),
          1
        );
        selectedControls = [...selectedControls, ...splicedItem];
      }
    }
  }
  if (MODULE_UCP_PLATFORM.includes(this.props.productId)) {
    let fieldIndex;
    if (selectedControls.includes("ucp_platform")) {
      let ucp_platformSelectedField = formState.find(
        (s1) => s1?.field === "ucp_platform"
      );
      if (ucp_platformSelectedField) {
        switch (ucp_platformSelectedField?.value[0]) {
          case 1:
            fieldIndex = formState?.findIndex((s1) => s1?.field === "drive");
            if (fieldIndex > 0) {
              formState = formState.filter((s1) => s1?.field !== "drive");
            }
            fieldIndex = formState?.findIndex(
              (s1) => s1?.field === "vmware_vcf_version"
            );

            if (fieldIndex > 0) {
              formState = formState.filter(
                (s1) => s1?.field !== "vmware_vcf_version"
              );
            }

            break;
          case 2:
            fieldIndex = formState?.findIndex(
              (s1) => s1?.field === "vmware_vcf_version"
            );
            if (fieldIndex > 0) {
              formState = formState.filter(
                (s1) => s1?.field !== "vmware_vcf_version"
              );
            }
            break;
          case 3:
            fieldIndex = formState?.findIndex(
              (s1) => s1?.field === "operating_system"
            );
            if (fieldIndex > 0) {
              formState = formState.filter(
                (s1) => s1?.field !== "operating_system"
              );
            }
            break;
          default:
            break;
        }
      }
    }
  }

  await this.setState({
    searchFormState: formState,
    clickedControls: selectedControls,
  });
  if (e.type === "int") {
    this.props
      .getMatriceFilters(
        modalMatriceFilters(formState, selectedfield),
        this.props.productId
      )
      .then((res) => {
        if (res === true) {
          this.searchHandler();
        }
      });
  }
}
export async function setkeyword(keyword, category) {
  await this.handleChangeValue({
    field: KEYWORD_SEARCH_FIELD,
    type: "string",
    value: keyword,
    operator: "eq",
  });
  await this.handleChangeValue({
    field: KEYWORD_SEARCH_CATEGORY,
    type: "string",
    value: category,
    operator: "eq",
  });

  this.searchHandler();
}

export function transformDescription(data) {
  //either flat array or key val pair object
  return _.isArray(data)
    ? data.map((row) => [row?.name, row?.description])
    : Object.keys(data).map((column) => [column, data[column]]);
}

//Asterisk handeling
export const asteriskExceptions = {
  "interop-matrix": [
    "some filter key",
    "some L1 result key",
    "some L2 Column Label",
  ], //put all possible keys matrix wise,For L2 Use Label Names
};
export function checkExcept(controlFor, controlName = "_") {
  if (asteriskExceptions[controlFor] !== undefined) {
    if (asteriskExceptions[controlFor]?.includes(controlName)) {
      return true;
    }
  }
  return false;
}
export function customizeCellRendering(cols) {
  const controlFor = window.location.pathname.replace("/products/", "");
  return cols.map((col) => {
    if (col.render === undefined) {
      //only attach render when its not defined
      col.render = (rowData) => (
        <CustomStringRender
          controlFor={controlFor}
          controlName={col.field}
          WrapperTag="div"
          data={rowData[col.field]}
        />
      );
    }
    return col;
  });
}
export function replaceAll(string, search, replace) {
  return string?.split(search).join(replace);
}
export function highlightStringAsterisk(data, controlName = "_") {
  const controlFor = window.location.pathname.replace("/products/", "");
  return checkExcept(controlFor, controlName)
    ? data
    : data !== null
    ? replaceAll(data, "*", '<span class="asterisk-style">*</span>')
    : data;
}

export function handleSingleSelection(e) {
  this.setState({ touched: this.state.touched + 1 });

  if (this.state.selectedOptions.length > 0) {
    const selectedOpts = this.state.selectedOptions;
    const removalIndex = this.state.selectedOptions.indexOf(
      parseInt(e.target.value)
    );
    selectedOpts.splice(removalIndex, 1);
    this.setState({ selectedOptions: [...selectedOpts] });
  }
  if (e.target.checked) {
    this.setState({
      selectedOptions: [
        ...this.state.selectedOptions,
        parseInt(e.target.value),
      ],
    });
  } else {
    const selectedOpts = this.state.selectedOptions;
    const removalIndex = this.state.selectedOptions.indexOf(
      parseInt(e.target.value)
    );
    selectedOpts.splice(removalIndex, 1);
    this.setState({ selectedOptions: [...selectedOpts] });
  }
}

export function insertPropertyInObjectSpecificPosition(
  obj,
  key,
  value,
  position
) {
  var result = {};
  var counter = 0;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (counter === position) {
        result[key] = value;
      }
      result[prop] = obj[prop];
      counter++;
    }
  }
  if (!(key in result)) {
    result[key] = value;
  }
  return result;
}

function handleColumnForFilter({
  rawDataArray,
  stateDataArray,
  fieldToRemove,
}) {
  const defaultColumns = [...rawDataArray];
  const targetItemIndex = defaultColumns?.findIndex(
    (el) =>
      el.field?.toLowerCase()?.trim() === fieldToRemove.toLowerCase()?.trim()
  );
  const isTargetItemPresent = stateDataArray.find(
    (el) =>
      el.field?.toLowerCase()?.trim() === fieldToRemove?.toLowerCase()?.trim()
  );
  if (targetItemIndex !== -1 && isTargetItemPresent) {
    defaultColumns.splice(targetItemIndex, 1);
    return defaultColumns;
  }
  return stateDataArray;
}

function handleColumnForUcpCiHc(arrayObj) {
  const { rawDataArray, stateDataArray } = arrayObj;
  return handleColumnForFilter({
    rawDataArray: rawDataArray,
    stateDataArray: stateDataArray,
    fieldToRemove: "vmware_vcf_version",
  });
}

function handleColumnForUcpRs(arrayObj) {
  const { rawDataArray, stateDataArray } = arrayObj;
  return handleColumnForFilter({
    rawDataArray: rawDataArray,
    stateDataArray: stateDataArray,
    fieldToRemove: "operating_system_name",
  });
}

export function handleColumnVisibility(arrayObj) {
  const { rawDataArray, stateDataArray } = arrayObj;
  const selectedFilter = store
    .getState()
    ?.productSearchReducer?.matriceFilters?.ucp_platform?.find(
      (p1) => p1.isselected
    )
    ?.name?.toLowerCase()
    ?.trim();
  let updatedColumn;
  switch (selectedFilter) {
    case "ucp ci":
      updatedColumn = handleColumnForUcpCiHc({ rawDataArray, stateDataArray });
      break;
    case "ucp hc":
      updatedColumn = handleColumnForUcpCiHc({ rawDataArray, stateDataArray });
      break;
    case "ucp rs":
      updatedColumn = handleColumnForUcpRs({ rawDataArray, stateDataArray });
      break;
    default:
      updatedColumn = handleColumnForUcpCiHc({ rawDataArray, stateDataArray });
      break;
  }
  return updatedColumn;
}

function handleFieldForL2({ rawDataArray, stateDataArray, fieldToRemove }) {
  const defaultFields = [...rawDataArray];
  const targetItemIndex = defaultFields?.findIndex(
    (el) =>
      el.columnTitle?.toLowerCase()?.trim() ===
      fieldToRemove.toLowerCase()?.trim()
  );
  const isTargetItemPresent = stateDataArray.find(
    (el) =>
      el.columnTitle?.toLowerCase()?.trim() ===
      fieldToRemove?.toLowerCase()?.trim()
  );
  if (targetItemIndex !== -1 && isTargetItemPresent) {
    defaultFields.splice(targetItemIndex, 1);
    return defaultFields;
  }
  return stateDataArray;
}

function handleL2FieldForUcpCiHc(arrayObj) {
  const { rawDataArray, stateDataArray } = arrayObj;
  return handleFieldForL2({
    rawDataArray: rawDataArray,
    stateDataArray: stateDataArray,
    fieldToRemove: OS_FIELD_NAME,
  });
}

function handleL2FieldForUcpRs(arrayObj) {
  const { rawDataArray, stateDataArray } = arrayObj;
  return handleFieldForL2({
    rawDataArray: rawDataArray,
    stateDataArray: stateDataArray,
    fieldToRemove: VCF_VERSION_FIELD_NAME,
  });
}

export function handleL2FieldVisibility(arrayObj) {
  const { rawDataArray, stateDataArray } = arrayObj;
  const selectedFilter = store
    .getState()
    ?.productSearchReducer?.matriceFilters?.ucp_platform?.find(
      (p1) => p1.isselected
    )
    ?.name?.toLowerCase()
    ?.trim();
  let updatedFields;
  switch (selectedFilter) {
    case "ucp ci":
      updatedFields = handleL2FieldForUcpCiHc({ rawDataArray, stateDataArray });
      break;
    case "ucp hc":
      updatedFields = handleL2FieldForUcpCiHc({ rawDataArray, stateDataArray });
      break;
    case "ucp rs":
      updatedFields = handleL2FieldForUcpRs({ rawDataArray, stateDataArray });
      break;
    default:
      updatedFields = handleL2FieldForUcpCiHc({ rawDataArray, stateDataArray });
      break;
  }
  return updatedFields;
}

export function openVmwareVcfLink({ url, tableElRef }) {
  // adding onclick listener to open link on a new tab
  const vmwareVcfLinkEl = tableElRef?.current?.querySelector("#vmwareVcfLink");
  if (vmwareVcfLinkEl) {
    vmwareVcfLinkEl.onclick = (e) => {
      e.preventDefault(); // preventing from scrolling to top
      window.open(url, "_blank");
    };
  }
}

export function getFormattedList(value) {
  const valueArr = value?.split(",");
  if (value && valueArr.length) {
    const listTemplate =
      "<ul>" +
      valueArr.map((value) => `<li>${value.trim()}</li>`)?.join("") +
      "</ul>";
    return listTemplate;
  }
  return value;
}
export const getUCPStr = (x, y, z) => {
  let arr = [x, y, z];
  let filteredArr = arr.filter((item) => item !== "");
  return filteredArr.join();
};
export const displayBootOptions = (bootoptionsArr) => {
  let filteredBootOptions = bootoptionsArr
    .filter((option) => {
      if (option.value === "Y") {
        return option.name;
      }
    })
    .map((item) => item.name)
    .join(", ");

  return filteredBootOptions;
};
