import { networkService } from "services/networkService";
import {
  METHOD_HDLMTAG_LIST,
  METHOD_HDLMTAG_CREATE,
  METHOD_HDLMTAG_UPDATE,
  METHOD_HDLMTAG_DELETE,
  METHOD_HDLMTAG_DETAIL
} from "constants/admin/masters/hdlm";
class SophiaService {

  //HDLMTag methods
  getAllHDLMTag = (data) => {
    return networkService
      .post(METHOD_HDLMTAG_LIST, data)
      .then((response) => response.data);
  };
  getHDLMTagDetail = (id) => {
    const updatedMethodName = METHOD_HDLMTAG_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHDLMTag = (data) => {
    return networkService
      .post(METHOD_HDLMTAG_CREATE, data)
      .then((response) => response.data);
  };
  updateHDLMTag = (data, id) => {
    const updatedMethodName = METHOD_HDLMTAG_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHDLMTag = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HDLMTAG_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };   
}
export default SophiaService;