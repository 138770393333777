import React, { useState, useEffect } from "react";
import Header from "components/shared/Header";
import HeaderBrandSite from "components/shared/HeaderBrandSite";
import FooterBrandSite from "components/shared/FooterBrandSite";
import commonService from "services/commonService";
const commonServiceObj = new commonService();
function AppLayout(props) {
  const [bannerdata, showBanner] = useState([]);

  useEffect(() => {
    // call api and get data
    getDowntimeBannerData();
  }, []);
// call api and get data
  const getDowntimeBannerData = () => {
    commonServiceObj
      .getDowntimeBanner()
      .then((response) => {
        showBanner(response);
      })
      .catch((error) => console.log("error", error));
  };
// show banner if start date is less than current date and end date is greater than current date
  const shouldDisplayBanner = () => {
    if (!bannerdata) {
      return false;
    } else {
      const currentDate = new Date();
      const bannerStartDate =
        bannerdata && bannerdata.filter((x) => x.key === "BannerStartDate")[0];
      const bannerEndDate =
        bannerdata && bannerdata.filter((x) => x.key === "BannerEndDate")[0];
      const startDateValue = bannerStartDate && bannerStartDate.value;
      const endDateValue = bannerEndDate && bannerEndDate.value;
      const startDate = new Date(startDateValue);
      const endDate = new Date(endDateValue);
      return currentDate >= startDate && currentDate <= endDate;
    }
  };
  const getBannerMessage = () => {
    let message =
      bannerdata && bannerdata.filter((x) => x.key === "BannerMessage")[0];
    return message.value;
  };

  return (
    <div className="d-flex flex-column">
      <HeaderBrandSite />
      <div id="og-app" className="content container-block">
        <Header />
        {shouldDisplayBanner() && (
          <div className="alert-warning pcg-downtime-notice" role="alert">
            {getBannerMessage()}
          </div>
        )}

        <div id="page-content" className="container-block">
          {props.children}
         
        </div>
      </div>
      <FooterBrandSite />
    </div>
  );
}
export default AppLayout;
