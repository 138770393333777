import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//vmware solution type
export const METHOD_VMWARE_SOLUTION_TYPE_LIST = 'vmwaresolutiontype/pageable/';
export const METHOD_VMWARE_SOLUTION_TYPE_CREATE = 'vmwaresolutiontype/';
export const METHOD_VMWARE_SOLUTION_TYPE_UPDATE = 'vmwaresolutiontype/{ID}/';
export const METHOD_VMWARE_SOLUTION_TYPE_DELETE = 'vmwaresolutiontype/{ID}/';
export const METHOD_VMWARE_SOLUTION_TYPE_DETAIL = 'vmwaresolutiontype/{ID}/';

//UCP Release Version
export const METHOD_UCP_RELEASE_VERSION_LIST = 'ucpreleaseversion/pageable/';
export const METHOD_UCP_RELEASE_VERSION_CREATE = 'ucpreleaseversion/';
export const METHOD_UCP_RELEASE_VERSION_UPDATE = 'ucpreleaseversion/{ID}/';
export const METHOD_UCP_RELEASE_VERSION_DELETE = 'ucpreleaseversion/{ID}/';
export const METHOD_UCP_RELEASE_VERSION_DETAIL = 'ucpreleaseversion/{ID}/';

//VMware VCF Version
export const METHOD_VMWARE_VCF_VERSION_LIST = 'vmwarevcfversion/pageable/';
export const METHOD_VMWARE_VCF_VERSION_CREATE = 'vmwarevcfversion/';
export const METHOD_VMWARE_VCF_VERSION_UPDATE = 'vmwarevcfversion/{ID}/';
export const METHOD_VMWARE_VCF_VERSION_DELETE = 'vmwarevcfversion/{ID}/';
export const METHOD_VMWARE_VCF_VERSION_DETAIL = 'vmwarevcfversion/{ID}/';

//MANAGEMENT SOFTWARE VERSION
export const METHOD_MANAGEMENT_SOFTWARE_VERSION_LIST = 'managementsoftwareversion/pageable/';
export const METHOD_MANAGEMENT_SOFTWARE_VERSION_CREATE = 'managementsoftwareversion/';
export const METHOD_MANAGEMENT_SOFTWARE_VERSION_UPDATE = 'managementsoftwareversion/{ID}/';
export const METHOD_MANAGEMENT_SOFTWARE_VERSION_DELETE = 'managementsoftwareversion/{ID}/';
export const METHOD_MANAGEMENT_SOFTWARE_VERSION_DETAIL = 'managementsoftwareversion/{ID}/';



//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;