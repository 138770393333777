import React, { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import DataGrid from "components/shared/DataGrid";
import analyzerService from "services/admin/masters/analyzerService";
import {
  MSG_WARNING_DELETE_MAPPING,
  MSG_DELETE_RECORD,
  MSG_WARNING_UNDO_DELETE_MAPPING,
  MSG_UNDO_RECORD,
  MSG_ADDITIONAL_UNDO,
  MSG_ADDITIONAL,
} from "constants/admin/masters/analyzer";
import AnalyzerMappingForm from "./Form";
import Breadcrumb from "components/shared/Breadcrumb";
import ConfirmBox from "components/shared/ConfirmBox";
import { toggleChangehandler, getCurrentSlug } from "utils/masterHelpers";
import masterFields from "./masterFields";
import {
  customizeCellRendering,
  highlightStringAsterisk,
} from "utils/matriceHelpers";
import DOMPurify from "dompurify";
const serviceObj = new analyzerService();
class Manage extends Component {
  constructor(props) {
    super(props);
    this.toggleChangehandler = toggleChangehandler.bind(this);
    this.getCurrentSlug = getCurrentSlug.bind(this);
    const masterId = parseInt(props.match.params.Id);
    const masterInfo = masterFields.find((r) => r.masterId === masterId);
    this.state = {
      masterId: masterId,
      editId: 0,
      refreshToken: Date.now(),
      analyzer_versions: [],
      master_drop_list: [],
      editable_ability_list: [],
      existing_mapping_list: [],
      os_exceptions: [],
      masterName: masterInfo?.masterName,
      analyzerMappingColumns: customizeCellRendering(masterInfo?.columns),
      masterInfo: masterInfo,
      filtering: false,
      paging: false,
      breadcrumbs: [
        { title: "Analyzer Masters", url: "/admin/master/analyzer/" },
        { title: "Manage Mapping" },
      ],
      actions: [
        (row) => this.decideAction(row, "edit"),
        (row) => this.decideAction(row, "undo"),
        (row) => this.decideAction(row, "delete"),
      ],
      showDeleteConfirmBox: false,
      deleteId: 0,
      deleteConfirmMessage: "",
      additionalConfirmMessage: "",
      deleteActionPostSuccessMsg: "",
    };
  }
  blankIcon = () => ({
    //black space icon
    icon: " ",
    tooltip: "",
  });
  decideAction = (rowData, actionType) => {
    const dataPathName = rowData[this.state.analyzerMappingColumns[0].field];

    if (actionType === "undo") {
      return rowData.is_deleted === true
        ? {
            icon: actionType,
            tooltip: "Undo Deleted Record",
            onClick: async (event, rowData) => {
              const Info = await serviceObj.getEditDetailForReactivate(
                this.state.masterId,
                rowData.id
              );
              let additionalConfirmMessage = "";
              if (Info.analyzer_versions.length > 1) {
                additionalConfirmMessage = MSG_ADDITIONAL_UNDO.replace(
                  "ITEM_NAME",
                  dataPathName
                ).replace(
                  "ANALYZER_NAMES",
                  Info.analyzer_versions
                    .filter((r) => r.id !== Info.record_details.analyzer_id)
                    .map((r) => r.name)
                    .join(", ")
                );
              }
              this.setState({
                showDeleteConfirmBox: true,
                deleteId: rowData.id,
                deleteConfirmMessage: MSG_WARNING_UNDO_DELETE_MAPPING.replace(
                  "ITEM_NAME",
                  dataPathName
                ),
                additionalConfirmMessage: additionalConfirmMessage,
                deleteActionPostSuccessMsg: MSG_UNDO_RECORD,
              });
            },
          }
        : null;
    } else if (actionType === "edit") {
      let editAction;
      if (rowData.is_deleted === false) {
        const editOptionConf = {
          icon: actionType,
          tooltip: "Edit Record",
          onClick: (event, rowData) => {
            this.setState({
              editId: rowData.id,
            });
          },
        };
        if (this.state.masterInfo?.editAllowedAllEligibleRows === true) {
          editAction = editOptionConf;
        } else {
          //in this case we need to show 1 edit icon for whole group
          const info = this.state?.editable_ability_list?.find(
            (r) => r.id === rowData.id
          );
          if (info?.editable) {
            editAction = editOptionConf;
          } else {
            editAction = this.blankIcon();
          }
        }
      } else {
        editAction = this.blankIcon();
      }

      return editAction;
    } else if (actionType === "delete") {
      return rowData.is_deleted === false
        ? {
            icon: actionType,
            tooltip: "Delete Record",
            onClick: async (event, rowData) => {
              const Info = await serviceObj.getEditDetail(
                this.state.masterId,
                rowData.id
              );
              let additionalConfirmMessage = "";
              if (Info.analyzer_versions.length > 1) {
                additionalConfirmMessage = MSG_ADDITIONAL.replace(
                  "ITEM_NAME",
                  dataPathName
                ).replace(
                  "ANALYZER_NAMES",
                  Info.analyzer_versions
                    .filter((r) => r.id !== Info.record_details.analyzer_id)
                    .map((r) => r.name)
                    .join(", ")
                );
              }
              this.setState({
                showDeleteConfirmBox: true,
                deleteId: rowData.id,
                deleteConfirmMessage: MSG_WARNING_DELETE_MAPPING.replace(
                  "ITEM_NAME",
                  dataPathName
                ),
                additionalConfirmMessage: additionalConfirmMessage,
                deleteActionPostSuccessMsg: MSG_DELETE_RECORD,
              });
            },
          }
        : null;
    }
  };
  componentDidMount = async () => {
    await this.getData();
  };
  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.refreshToken !== prevState.refreshToken) {
      await this.getData();
    }
  };
  deleteConfirmHandler = async (e) => {
    if (e.confirmState) {
      let deleteType = 0;
      if (e.additionalCheck) {
        deleteType = 1;
      }
      serviceObj
        .deleteAnalyzerMapping(
          this.state.masterId,
          this.state.deleteId,
          deleteType
        )
        .then((response) => {
          toast.success(
            response.message || this.state.deleteActionPostSuccessMsg
          );
          this.handleRefresh();
        });
    } else {
      //cancel case
      this.setState({
        showDeleteConfirmBox: false,
        deleteId: 0,
        deleteConfirmMessage: "",
        additionalConfirmMessage: "",
        deleteActionPostSuccessMsg: "",
      });
    }
  };
  handleRefresh = async (e) => {
    this.setState({
      editId: 0,
      refreshToken: Date.now(),
      showDeleteConfirmBox: false,
      deleteId: 0,
      deleteConfirmMessage: "",
      additionalConfirmMessage: "",
      deleteActionPostSuccessMsg: "",
    });
  };
  transformData = (data) =>
    data.map((row) => ({
      value: row.id,
      plainText: row.name,
      label: (
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(highlightStringAsterisk(row.name)),
          }}
        />
      ),
      isDisabled: row?.disabled || false,
    }));
  addEditStatus = (items, uniqueIdentifierColumn) => {
    const uniqueSeries = [];
    items.map((item) => {
      const found = uniqueSeries.find(
        (id) => id === item[uniqueIdentifierColumn]
      );
      if (found) {
        item.editable = false;
      } else {
        if (item.is_deleted === false) {
          uniqueSeries.push(item[uniqueIdentifierColumn]);
          item.editable = true;
        } else {
          item.editable = false;
        }
      }
      return item;
    });
    return items;
  };
  addgroupColor = (items, uniqueIdentifierColumn) => {
    const uniqueSeries = [];
    let lastColor;
    const color1 = "#FFFFFF";
    const color2 = "#F4F3F3";
    items.map((item) => {
      const found = uniqueSeries.find(
        (id) => id === item[uniqueIdentifierColumn]
      );
      if (found) {
        item.backgroundColor = lastColor;
      } else {
        uniqueSeries.push(item[uniqueIdentifierColumn]);
        if (lastColor === undefined) {
          lastColor = color1;
        } else {
          lastColor = lastColor === color1 ? color2 : color1;
        }
        item.backgroundColor = lastColor;
      }
      return item;
    });
    return items;
  };
  getData = async () => {
    serviceObj
      .getAllInitialData(this.state.masterId)
      .then(async (response) => {
        const master_drop_list = this.transformData(response.master_drop_list);
        const os_exceptions = response?.os_exceptions
          ? this.transformData(response?.os_exceptions)
          : [];
        await this.setState({
          analyzer_versions: response.analyzer_versions,
          master_drop_list,
          editable_ability_list: this.addEditStatus(
            response.existing_mapping_list,
            this.state.analyzerMappingColumns[0].field
          ),
          existing_mapping_list: this.addgroupColor(
            response.existing_mapping_list,
            this.state.analyzerMappingColumns[0].field
          ),
          os_exceptions,
        });
      })
      .catch((e) => {});
  };
  rowStyle = (rowData) => {
    return {
      backgroundColor: rowData.backgroundColor,
    };
  };

  render() {
    const {
      breadcrumbs,
      masterName,
      masterId,
      editId,
      analyzerMappingColumns,
      existing_mapping_list,
      actions,
      filtering,
      paging,
      refreshToken,
      analyzer_versions,
      master_drop_list,
      os_exceptions,
      showDeleteConfirmBox,
      deleteConfirmMessage,
      additionalConfirmMessage,
    } = this.state;
    return (
      <div className="form-wrap mt-3">
        <Breadcrumb data={breadcrumbs} disableEditTitle={true} />
        {showDeleteConfirmBox ? (
          <ConfirmBox
            actionHandler={this.deleteConfirmHandler}
            additionalCheckText={additionalConfirmMessage}
          >
            {deleteConfirmMessage}
          </ConfirmBox>
        ) : null}
        <div className="form-group d-flex">
          <div className="col-sm-4">
            {analyzer_versions.length ? (
              <AnalyzerMappingForm
                editId={editId}
                masterId={masterId}
                analyzer_versions_list={analyzer_versions}
                master_drop_list={master_drop_list}
                os_exceptions={os_exceptions}
                triggerParentHandler={this.handleRefresh}
              />
            ) : null}
          </div>
          <div className="col-sm-8">
            <Form.Group as={Row}>
              <Col>
                <DataGrid
                  showToolBar={true}
                  tableTitle={`Analyzer - ${masterName} Mapping`}
                  columns={analyzerMappingColumns}
                  dataHandler={existing_mapping_list}
                  actions={actions}
                  filtering={filtering}
                  paging={paging}
                  refreshToken={refreshToken}
                  maxBodyHeight={"500px"}
                  headerStyle={{ backgroundColor: "#e9ecef" }}
                  rowStyle={this.rowStyle}
                ></DataGrid>
              </Col>
            </Form.Group>
          </div>
        </div>
      </div>
    );
  }
}
export default Manage;
