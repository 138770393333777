import { networkService } from "services/networkService";
import {
  METHOD_OPS_CENTER_VERSION_LIST,
  METHOD_OPS_CENTER_VERSION_CREATE,
  METHOD_OPS_CENTER_VERSION_UPDATE,
  METHOD_OPS_CENTER_VERSION_DELETE,
  METHOD_OPS_CENTER_VERSION_DETAIL,  
  METHOD_PROTECTOR_VERSION_LIST,
  METHOD_PROTECTOR_VERSION_CREATE,
  METHOD_PROTECTOR_VERSION_UPDATE,
  METHOD_PROTECTOR_VERSION_DELETE,
  METHOD_PROTECTOR_VERSION_DETAIL,  
  METHOD_ADOPTABLE_TECHNOLOGIES_LIST,
  METHOD_ADOPTABLE_TECHNOLOGIES_CREATE,
  METHOD_ADOPTABLE_TECHNOLOGIES_UPDATE,
  METHOD_ADOPTABLE_TECHNOLOGIES_DELETE,
  METHOD_ADOPTABLE_TECHNOLOGIES_DETAIL,
} from "constants/admin/masters/protector";
class ProtectorService {

  //Ops Center Version methods
  getAllOpsCenterVersion = (data) => {
    return networkService
      .post(METHOD_OPS_CENTER_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getOpsCenterVersionDetail = (id) => {
    const updatedMethodName = METHOD_OPS_CENTER_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createOpsCenterVersion = (data) => {
    return networkService
      .post(METHOD_OPS_CENTER_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateOpsCenterVersion = (data, id) => {
    const updatedMethodName = METHOD_OPS_CENTER_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteOpsCenterVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_OPS_CENTER_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };   
  //Protector version methods
  getAllProtectorVersion = (data) => {
    return networkService
      .post(METHOD_PROTECTOR_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getProtectorVersionDetail = (id) => {
    const updatedMethodName = METHOD_PROTECTOR_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createProtectorVersion = (data) => {
    return networkService
      .post(METHOD_PROTECTOR_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateProtectorVersion = (data, id) => {
    const updatedMethodName = METHOD_PROTECTOR_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteProtectorVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_PROTECTOR_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
    //Adoptable Technologies methods
    getAllAdoptableTechnologies = (data) => {
      return networkService
        .post(METHOD_ADOPTABLE_TECHNOLOGIES_LIST, data)
        .then((response) => response.data);
    };
    getAdoptableTechnologiesDetail = (id) => {
      const updatedMethodName = METHOD_ADOPTABLE_TECHNOLOGIES_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createAdoptableTechnologies = (data) => {
      return networkService
        .post(METHOD_ADOPTABLE_TECHNOLOGIES_CREATE, data)
        .then((response) => response.data);
    };
    updateAdoptableTechnologies = (data, id) => {
      const updatedMethodName = METHOD_ADOPTABLE_TECHNOLOGIES_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteAdoptableTechnologies = (id, is_delete = false) => {
      const updatedMethodName = METHOD_ADOPTABLE_TECHNOLOGIES_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName, { data: { is_delete } })
        .then((response) => response.data);
    };
}
export default ProtectorService;