import {
  MSG_DELETE_WARNING,
  MSG_UNDO_DELETE_WARNING,
  MSG_RECORD_CREATE,
  MSG_RECORD_UPDATE,
  MSG_RECORD_DELETE,
  MSG_RECORD_UNDO,
  MIN_TEXT_LENGTH,
  MAX_TEXT_LENGTH,
} from "constants/common";

// hrpc version
export const METHOD_HRPC_VERSION_LIST = "hrpcversion/pageable/";
export const METHOD_HRPC_VERSION_CREATE = "hrpcversion/";
export const METHOD_HRPC_VERSION_UPDATE = "hrpcversion/{ID}/";
export const METHOD_HRPC_VERSION_DELETE = "hrpcversion/{ID}/";
export const METHOD_HRPC_VERSION_DETAIL = "hrpcversion/{ID}/";

// Container Orchestrator

export const METHOD_CONTAINER_ORCHESTRATOR_LIST =
  "containerorchestrator/pageable/";
export const METHOD_CONTAINER_ORCHESTRATOR_CREATE = "containerorchestrator/";
export const METHOD_CONTAINER_ORCHESTRATOR_UPDATE =
  "containerorchestrator/{ID}/";
export const METHOD_CONTAINER_ORCHESTRATOR_DELETE =
  "containerorchestrator/{ID}";
export const METHOD_CONTAINER_ORCHESTRATOR_DETAIL =
  "containerorchestrator/{ID}";

//  Orchestrator Version

export const METHOD_ORCHESTRATOR_VERSION_LIST = "orchestratorversion/pageable/";
export const METHOD_ORCHESTRATOR_VERSION_CREATE = "orchestratorversion/";
export const METHOD_ORCHESTRATOR_VERSION_UPDATE = "orchestratorversion/{ID}/";
export const METHOD_ORCHESTRATOR_VERSION_DELETE = "orchestratorversion/{ID}/";
export const METHOD_ORCHESTRATOR_VERSION_DETAIL = "orchestratorversion/{ID}/";

//text box length
export const TEXT_MIN_LENGTH = MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH = MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;
