import {networkService} from "./networkService";
import {
    METHOD_GET_ALL_PRODUCTS,
    METHOD_ADMIN_SETINGS,
    METHOD_ALL_RELATED_MASTERS,
    METHOD_ENCRYPT_PASSWORD,
    METHOD_LEGACY_LINKS,
    APP_SITEAVAILABILITY_URL,
    METHOD_ALL_EOSL_MASTER_PRODUCTS,
    METHOD_SHOW_DOWNTIME_BANNER
  } from "constants/common";
  import {
    METHOD_DOWNLOAD_LIMIT
  } from "constants/productMatrix";
  import {
    METHOD_USERINFO,
    METHOD_TOKEN,
    METHOD_SSO_REDIRECT_URL,
    METHOD_SSO_LOGOUT,
    METHOD_OKTA_SSO_VERIFY_TOKEN,
    METHOD_OKTA_SSO_LOGOUT
  } from "constants/login";
  
class commonService{
 
    getProductSearchInitials = () => {
      return Promise.all([
        //this.getAllProducts(),
        this.getDownloadLimit()
      ]);
    }

    getAllProducts = () => {
        return networkService.get(METHOD_GET_ALL_PRODUCTS).then((response)=>response.data);
    };
    getSsoRedirectUrl = (payload) =>{    
      return networkService.post(METHOD_SSO_REDIRECT_URL, payload).then((response)=>response.data);
    }
    getVerifySsoToken = (payload) =>{      
      return networkService.post(METHOD_OKTA_SSO_VERIFY_TOKEN, payload).then((response)=>response.data);
    }
    getSsoLogoutUrl = () =>{      
      return networkService.get(METHOD_SSO_LOGOUT).then((response)=>response.data);      
    }
   getOktaSsoLogoutUrl = () =>{      
     return networkService.get(METHOD_OKTA_SSO_LOGOUT).then((response)=>response.data);      
    }
    doBackdoorLogin = (payload) =>{
      return networkService.post(METHOD_TOKEN, payload).then((response)=>response.data);
    }
    getUserInfo = () =>{
      return networkService.get(METHOD_USERINFO).then((response)=>response.data);
    }

    getDownloadLimit = () => {
      return networkService.get(METHOD_DOWNLOAD_LIMIT).then((response)=>response.data);
    };

    getAdminSettings = () => {
      return networkService.get(METHOD_ADMIN_SETINGS).then((response)=>response.data);
    };
    saveAdminSettings = (payload) => {
      return networkService.patch(METHOD_ADMIN_SETINGS,payload).then((response)=>response.data);
    };
    getAllRelatedMasters = () =>{      
      return networkService.get(METHOD_ALL_RELATED_MASTERS).then((response)=>response.data);
    }
    encryptPassword = (payload) =>{   
      return networkService.post(METHOD_ENCRYPT_PASSWORD,payload).then((response)=>response.data);
    }
    getLegacyLinks = (productId) =>{ 
      const CONSTRUCTED_METHOD = METHOD_LEGACY_LINKS.replace(
        "{productId}",
        productId
      ); 
      return networkService.get(CONSTRUCTED_METHOD).then((response)=>response.data);
    }
    checkSitesAvailablityForSupportHeader = (qsParams) =>{ 
      const url=APP_SITEAVAILABILITY_URL+qsParams; 
      return networkService.get(url).then((response)=>response.data);
    }
    getAllEoslMasterProducts = () =>{      
      return networkService.get(METHOD_ALL_EOSL_MASTER_PRODUCTS).then((response)=>response.data);
    }
  // show downtime banner in the front page of the application
  getDowntimeBanner = ()=>{
    return networkService.get(METHOD_SHOW_DOWNTIME_BANNER).then((response)=>response.data);
  }

}
export default commonService;
