const FILTERS = {
    gad: {
        os: {
            title: 'OS',
            icon: 'os.png'
        },
        os_kernel: {
            title: 'OS Kernel',
            icon: 'os_kernel.png'
        },
        multi_path: {
            title: 'Multipath',
            icon: 'multipath.png'
        },
        cluster: {
            title: 'Cluster',
            iconClass: 'project-diagram'
        },
        array: {
            title: 'Array',
            icon: 'array.png'
        },
        array_if: {
            title: 'Array Interface',
            icon: 'array_if.png'
        },
        alua: {
            title: 'ALUA/Non-ALUA',
            iconClass: 'network-wired'
        },
        switch: {
            title: 'Switch (Remote Path)',
            icon: 'switch.png'
        },
        switchfirmware: {
            title: 'Switch Firmware',
            icon: 'firmware.png'
        }
    }
}
export default FILTERS;