import React, { Component } from "react";
import "resources/css/mega-menu.css";
import _, { isEmpty } from "lodash";
import { API_URL } from "constants/common";
import authService from "services/authService";
import { withRouter } from "react-router-dom";
import { LABEL_ALL_PRODUCT_CATEGORY } from "constants/productMatrix";
import { connect } from "react-redux";
import { getLegacyProductMenu } from "actions/productSearchActions";
import { store } from "index";
import {linkedFromSolutiontoProduct}  from "actions/commonActions";
class MegaMenu extends Component {
  constructor(props) {
    super(props);
    this.defaultLabel = "Select Option";
    this.state = {
      megaMenus: props?.data,
      plugin: props?.legacyProduct ? true : false,
      menuLabel: props?.defaultLabel || this.defaultLabel,
      alwaysOpenStates: props?.alwaysOpenStates || [this.defaultLabel],
      dropdownOpenState: props?.defaultOpenState === true ? true : false,
      dropdownOpenStateCss: {
        opacity: 1,
        // overflow: "visible",
        visibility: "visible",
        zIndex: "900",
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        megaMenus: this.props.data,
      });
    }
    // checking the selected tab name and displaying button name
    if (
      this.props.defaultLabel === LABEL_ALL_PRODUCT_CATEGORY &&
      this.props.defaultLabel !== prevProps.defaultLabel
    ) {
      this.setState({
        megaMenus: this.props.data,
        plugin: false,
      });
    }
    if (this.props.defaultLabel !== prevProps.defaultLabel) {
      this.setState({
        menuLabel: this.props.defaultLabel,
        dropdownOpenState: this.props?.defaultOpenState === true ? true : false,
      });
    }
  }
  componentDidMount() {
    document
      .getElementById("root")
      .addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      megaMenus: !isEmpty(this.props.legacyProductMenu)
        ? this.props?.legacyProductMenu.nodes
        : this.props.data,
      plugin:
        !isEmpty(this.props.legacyProductMenu) ||
        this.props?.legacyProduct ||
        this.props.selectedProduct
          ? true
          : false,
    });
  }

  componentWillUnmount() {
    document
      .getElementById("root")
      .removeEventListener("mousedown", this.handleClickOutside);
    if (!this.props?.legacyProduct) this.props.getLegacyProductMenu([]);
  }

 

  closeMegaMenu = (e) => {
    this.setState({
      dropdownOpenState: false,
    });
  };
  openMegaMenu = (e) => {
    this.setState({
      dropdownOpenState: true,
    });
  };

  dropDownToggle = (e) => {
    e.stopPropagation();
    this.setState({
      dropdownOpenState: !this.state.dropdownOpenState,
    });
  };
  getUri = (link) => {
    return link.replace("{API_URL}", API_URL);
  };

  handleClick = (data) => {
    // call action if data.isRedirectedToProductTab is true
    if (data.isRedirectedToProductTab) {
      this.props.linkedFromSolutiontoProduct(true)
    }
    if (data?.link === true) {
     
      window.open(this.getUri(data.slug), "_blank"); //to open new page
    } else {
      this.setState({
        menuLabel: data.parent || data.mega_menu_name,
      });
      if (!data?.plugin) this.props.onChangeMenu(data);
    }
    this.closeMegaMenu();
    if (data?.link && data?.link !== true) {
      window.open(data.link, "_blank"); //to open new page
    }
  };

  handlePluginClick = (data) => {
    this.setState({ plugin: true });
    this.setState({
      menuLabel: data.parent ? `${data.parent} > ${data.mega_menu_name}` : data.mega_menu_name,
      megaMenus: data.nodes,
    });
  };

  handleBackClick = () => {
    this.setState({ plugin: false });
    this.props.getLegacyProductMenu([]);
    this.props.handleLegacyFlag && this.props.handleLegacyFlag();
    if (this.props?.legacyProduct) {
      this.setState({
        menuLabel: this.props.defaultLabel,
        megaMenus:  this.renderCategories(this.state.megaMenus),
      });
    } else
      this.setState({
        menuLabel: this.props.defaultLabel,
        megaMenus: this.props?.selectedProduct ? this.props.matriceProducts : this.props.data,
      });
  };

  handleHomeClick = (e) => {
    e.stopPropagation();
    // this.props.history.push("/");
    this.props.getLegacyProductMenu([]);
  };

  renderChilds = (childs) => {
    const userData = authService.userData;
    return (
      <ul>
        {childs.map((col, index) =>
          col.plugin ? (
            <li
              title={col?.disabled ? "Coming Soon" : col.mega_menu_name}
              key={index}
              onClick={
                !col?.disabled && col.slug
                  ? (e) => this.handlePluginClick(col)
                  : null
              }
              className={`${
                !col?.disabled && col.slug
                  ? "mega-menu-link"
                  : "mega-menu-disabled-link"
              } ${
                col.allowedUserTypes &&
                !col.allowedUserTypes.includes(userData?.user_type)
                  ? "d-none"
                  : "d-block"
              } rotate`}
            >
              {col.slug ? (
                col.mega_menu_name
              ) : (
                <div
                  className={`child-heading ${
                    col.allowedUserTypes &&
                    !col.allowedUserTypes.includes(userData?.user_type)
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  {col.mega_menu_name}
                </div>
              )}
              {/* {!_.isEmpty(col.nodes) ? this.renderChilds(col.nodes) : null} */}
            </li>
          ) : (
            <li
              title={col?.disabled ? "Coming Soon" : col.mega_menu_name}
              key={index}
              onClick={
                !col?.disabled && col.slug ? (e) => this.handleClick(col) : null
              }
              className={`${
                !col?.disabled && col.slug
                  ? "mega-menu-link"
                  : "mega-menu-disabled-link"
              } ${
                col.allowedUserTypes &&
                !col.allowedUserTypes.includes(userData?.user_type)
                  ? "d-none"
                  : "d-block"
              }`}
            >
              {col.slug ? (
                col.mega_menu_name
              ) : (
                <div
                  className={`child-heading ${
                    col.allowedUserTypes &&
                    !col.allowedUserTypes.includes(userData?.user_type)
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  {col.mega_menu_name}
                </div>
              )}
              {!_.isEmpty(col.nodes) ? this.renderChilds(col.nodes) : null}
            </li>
          )
        )}
      </ul>
    );
  };

  // render mega menu based on type (Products, Solutions) and position(left, center, right)
  renderCategories = (data) => {
    const userData = authService.userData;
    const selectedMegamenuOption = store.getState().commonReducer.megaMenuOption;
    let menuData = data.filter((item) => item.category === selectedMegamenuOption);
    let leftColumnData = menuData && menuData.filter((item) => item.alignment === "left");
    let rightColumnData = menuData && menuData.filter((item) => item.alignment === "right");
    let centerColumnData = menuData && menuData.filter(
      (item) => item.alignment === "center"
    );

    return (
      <>
        <div className="type left">
          {this.getDesiredUI(leftColumnData, userData)}
        </div>
        <div className="type center">
          {this.getDesiredUI(centerColumnData, userData)}
        </div>
        <div className="type right">
          {this.getDesiredUI(rightColumnData, userData)}
        </div>
      </>
    );
  };

  getDesiredUI = (data, userData) => {
    return data.map((row, index) => (
      <div key={index} className="nav-column">
        {row.slug !== null ? (
          <h3
            onClick={(e) => this.handleClick(row)}
            className="mega-menu-link heading"
          >
            {row.mega_menu_name} 
          </h3>
        ) : (
          <h3
            className={`heading ${
              row.allowedUserTypes &&
              !row.allowedUserTypes.includes(userData?.user_type)
                ? "d-none"
                : "d-block"
            }`}
          >
            {row.mega_menu_name}
          </h3>
        )}
        {!_.isEmpty(row.nodes) ? this.renderChilds(row.nodes) : ""}
      </div>
    ));
  };
 

  showLabel = () =>
    this.state.menuLabel.split(">").map((menu, index) =>
      this.state.menuLabel.split(">").length === index + 1 ? (
        <React.Fragment key={index}>{menu}</React.Fragment>
      ) : (
        <React.Fragment key={index}>
          {menu} <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </React.Fragment>
      )
    );

  render() {
    const { defaultLabel } = this.props;
    const { dropdownOpenStateCss ,dropdownOpenState} = this.state;
    return (
     
      <div
        className="mega-menu ml-3"
      >
        {/* mega menu button */}
        <button
          className="btn btn-branding"
          type="button"
          data-toggle="dropdown"
          onClick={this.dropDownToggle}
        >
          <span className="mega-menu-label" title={"Toggle Menu"}>
          {this.state.menuLabel ? this.showLabel() : defaultLabel}
          <span className="caret"></span>
          <img src={"/assets/images/select-up-arrow-rl-mob.png"} alt={dropdownOpenState?"Show menu" :"Hide menu"} className={dropdownOpenState ?"rotate-icon ml-1":"ml-1" }/>
        </span>
        </button>
        <div
          className="mega-menu-data"
          style={this.state.dropdownOpenState ? dropdownOpenStateCss : {}}
        >
          {this.state.plugin && (
            <div>
              <i
                style={{
                  fontSize: "20px",
                  margin: "10px 0px 0px 10px",
                  cursor: "pointer",
                }}
                className="fa fa-arrow-circle-left"
                aria-hidden="true"
                onClick={this.handleBackClick}
              ></i>
            </div>
          )}
          {this.state.megaMenus && this.state.megaMenus
            ? this.renderCategories(this.state.megaMenus)
            : null}
        </div>
      </div>
    
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.productSearchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLegacyProductMenu: (payload) => dispatch(getLegacyProductMenu(payload)),
  linkedFromSolutiontoProduct: (data) => dispatch(linkedFromSolutiontoProduct(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MegaMenu)
);
