import { networkService } from "services/networkService";
import {
  METHOD_PRODUCT_LIST,
  METHOD_PRODUCT_CREATE,
  METHOD_PRODUCT_UPDATE,
  METHOD_PRODUCT_DELETE,
  METHOD_PRODUCT_DETAIL,
  METHOD_PRODUCT_INTERMIX_LIST,
  METHOD_PRODUCT_INTERMIX_CREATE,
  METHOD_PRODUCT_INTERMIX_UPDATE,
  METHOD_PRODUCT_INTERMIX_DELETE,
  METHOD_PRODUCT_INTERMIX_DETAIL
} from "constants/admin/masters/replication";
class replicationService {
  //replication product
  getAllProducts = (data) => {
    return networkService
      .post(METHOD_PRODUCT_LIST, data)
      .then((response) => response.data);
  };
  getProductDetail = (id) => {
    const updatedMethodName = METHOD_PRODUCT_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createProduct = (data) => {
    return networkService
      .post(METHOD_PRODUCT_CREATE, data)
      .then((response) => response.data);
  };
  updateProduct = (data, id) => {
    const updatedMethodName = METHOD_PRODUCT_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteProduct = (id,is_delete=false) => {
    const updatedMethodName = METHOD_PRODUCT_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };

  //replication product intermix  
  getAllProductIntermix = (data) => {
    return networkService
      .post(METHOD_PRODUCT_INTERMIX_LIST, data)
      .then((response) => response.data);
  };
  getProductIntermixDetail = (id) => {
    const updatedMethodName = METHOD_PRODUCT_INTERMIX_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createProductIntermix = (data) => {
    return networkService
      .post(METHOD_PRODUCT_INTERMIX_CREATE, data)
      .then((response) => response.data);
  };
  updateProductIntermix = (data, id) => {
    const updatedMethodName = METHOD_PRODUCT_INTERMIX_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteProductIntermix = (id,is_delete=false) => {
    const updatedMethodName = METHOD_PRODUCT_INTERMIX_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };
}
export default replicationService;
