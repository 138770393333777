const FILTERS = {
    "ops-api-cm": {
        cm_rest_api_version: {
            title: 'CM REST API Version',
            icon: 'server.png'
        },
        
        hypervisor: {
            title: 'Hypervisor',
            icon: 'array.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        },
        jdk: {
            title: 'JDK',
            iconClass: 'project-diagram'
        },
        array_customer_version: {
            title: 'Storage Array',
            icon: 'array.png'
        }
    }
}
export default FILTERS;