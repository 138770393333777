import React from "react";

// function is used to format long string which has more than 100 chars.

function formatString(inputString) {
  if (inputString.length <= 100) {
    return inputString;
  } else {
    const firstLine = inputString.substring(0, 100);
    const lastSpaceIndex = firstLine.lastIndexOf(" ");
    const firstLineWithoutLastWord = firstLine.substring(0, lastSpaceIndex);
    const lastWord = firstLine.substring(lastSpaceIndex + 1);
    const remainingCharacters = lastWord + inputString.substring(100);

    return (
      <>
        {firstLineWithoutLastWord}
        <br />
        {/* recursively calling the function */}
        {formatString(remainingCharacters)}
      </>
    );
  }
}
const SimpleDataTable = (props) => {
  return (
    <div className="table-responsive-sm">
      {props?.title && <h6>{props?.title}</h6>}
      <div style={props?.style || null}>
        <table className="table table-sm simpleDataTable">
          {props?.header?.length > 0 ? (
            <thead>
              <tr>
                {props.header.map((title, i) => (
                  <th key={i}>{title}</th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {props?.data?.length > 0 &&
              props?.data?.map((row, i) => (
                <tr key={i}>
                  {row.map((value, i) => (
                    <td key={i}>{formatString(value)}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SimpleDataTable;
