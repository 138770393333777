const FILTERS = {
    vro: {
        vro_release: {
            title: 'vRO Release',
            icon: 'server.png'
        },
        vro_plugin: {
            title: 'vRO Plugin',
            icon: 'os.png'
        },
        plugin_version: {
            title: 'Plugin Version',
            icon: 'os_kernel.png'
        }
    }
}
export default FILTERS;