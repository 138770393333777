import { networkService } from "services/networkService";
import {
  METHOD_SCPRODUCT_LIST,
  METHOD_SCPRODUCT_CREATE,
  METHOD_SCPRODUCT_UPDATE,
  METHOD_SCPRODUCT_DELETE,
  METHOD_SCPRODUCT_DETAIL,  
  METHOD_PARTNER_LIST,
  METHOD_PARTNER_CREATE,
  METHOD_PARTNER_UPDATE,
  METHOD_PARTNER_DELETE,
  METHOD_PARTNER_DETAIL,   
  METHOD_CERTIFICATION_LIST,
  METHOD_CERTIFICATION_CREATE,
  METHOD_CERTIFICATION_UPDATE,
  METHOD_CERTIFICATION_DELETE,
  METHOD_CERTIFICATION_DETAIL,
  METHOD_MULTIPATHSOFTWARE_LIST,
  METHOD_MULTIPATHSOFTWARE_CREATE,
  METHOD_MULTIPATHSOFTWARE_UPDATE,
  METHOD_MULTIPATHSOFTWARE_DELETE,
  METHOD_MULTIPATHSOFTWARE_DETAIL
} from "constants/admin/masters/storageCertification";
class storageCertificationService {

  //ScProduct methods
  getAllScProduct = (data) => {
   return networkService
     .post(METHOD_SCPRODUCT_LIST, data)
     .then((response) => response.data);
 };
 getScProductDetail = (id) => {
   const updatedMethodName = METHOD_SCPRODUCT_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createScProduct = (data) => {
    return networkService
      .post(METHOD_SCPRODUCT_CREATE, data)
      .then((response) => response.data);
  };
  updateScProduct = (data, id) => {
    const updatedMethodName = METHOD_SCPRODUCT_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteScProduct = (id, is_delete = false) => {
    const updatedMethodName = METHOD_SCPRODUCT_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  //Partner methods
  getAllPartner = (data) => {
   return networkService
     .post(METHOD_PARTNER_LIST, data)
     .then((response) => response.data);
 };
 getPartnerDetail = (id) => {
   const updatedMethodName = METHOD_PARTNER_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createPartner = (data) => {
    return networkService
      .post(METHOD_PARTNER_CREATE, data)
      .then((response) => response.data);
  };
  updatePartner = (data, id) => {
    const updatedMethodName = METHOD_PARTNER_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deletePartner = (id, is_delete = false) => {
    const updatedMethodName = METHOD_PARTNER_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  //Certification methods
  getAllCertification = (data) => {
   return networkService
     .post(METHOD_CERTIFICATION_LIST, data)
     .then((response) => response.data);
 };
 getCertificationDetail = (id) => {
   const updatedMethodName = METHOD_CERTIFICATION_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createCertification = (data) => {
    return networkService
      .post(METHOD_CERTIFICATION_CREATE, data)
      .then((response) => response.data);
  };
  updateCertification = (data, id) => {
    const updatedMethodName = METHOD_CERTIFICATION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteCertification = (id, is_delete = false) => {
    const updatedMethodName = METHOD_CERTIFICATION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  
  //MultiPathSoftware methods
  getAllMultiPathSoftware = (data) => {
   return networkService
     .post(METHOD_MULTIPATHSOFTWARE_LIST, data)
     .then((response) => response.data);
 };
 getMultiPathSoftwareDetail = (id) => {
   const updatedMethodName = METHOD_MULTIPATHSOFTWARE_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createMultiPathSoftware = (data) => {
    return networkService
      .post(METHOD_MULTIPATHSOFTWARE_CREATE, data)
      .then((response) => response.data);
  };
  updateMultiPathSoftware = (data, id) => {
    const updatedMethodName = METHOD_MULTIPATHSOFTWARE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteMultiPathSoftware = (id, is_delete = false) => {
    const updatedMethodName = METHOD_MULTIPATHSOFTWARE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default storageCertificationService;