const FILTERS = {
  sdsb: {
    vssb_model: {
      title: "Model",
      icon: "server.png",
    },
    hvssb_software_version: {
      title: "Software Version",
      icon: "os.png",
    },
    backend_interface: {
      title: "Backend Interface",
      icon: "os_kernel.png",
    },
    backend_switch_firmware: {
      title: "Backend Switch Firmware",
      icon: "firmware.png",
    },
    backend_switch: {
      title: "Backend Switch",
      icon: "switch.png",
    },
    frontend_interface: {
      title: "Frontend Interface",
      icon: "os_kernel.png",
    },
    frontend_switch: {
      title: "Frontend Switch",
      icon: "switch.png",
    },
    frontend_switch_firmware: {
      title: "Frontend Switch Firmware",
      icon: "firmware.png",
    },
    hvssb_hypervisor: {
      title: "Hypervisor",
      icon: "hba.png",
    },
    server: {
      title: "Server",
      icon: "server.png",
    },
    os: {
      title: "Client OS",
      icon: "os.png",
    },
    os_kernel: {
      title: "Kernel",
      icon: "os_kernel.png",
    },
    hba: {
      title: "HBA",
      icon: "hba.png",
    },
    hba_driver: {
      title: "HBA Driver",
      icon: "hba_driver.png",
    },
    switch: {
      title: "Switch",
      icon: "switch.png",
    },
    switch_firmware: {
      title: "Switch Firmware",
      icon: "firmware.png",
    },
  },
};
export default FILTERS;
