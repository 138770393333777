import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//isv methods
export const METHOD_ISV_NAME_LIST = 'isv/pageable/';
export const METHOD_ISV_NAME_CREATE = 'isv/';
export const METHOD_ISV_NAME_UPDATE = 'isv/{ID}/';
export const METHOD_ISV_NAME_DELETE = 'isv/{ID}/';
export const METHOD_ISV_NAME_DETAIL = 'isv/{ID}/';
export const METHOD_ISV_NAME_DROP = 'isv/drop/';


//isv application name methods
export const METHOD_ISV_APPLICATION_NAME_LIST = 'isvapplicationname/pageable/';
export const METHOD_ISV_APPLICATION_NAME_CREATE = 'isvapplicationname/';
export const METHOD_ISV_APPLICATION_NAME_UPDATE = 'isvapplicationname/{ID}/';
export const METHOD_ISV_APPLICATION_NAME_DELETE = 'isvapplicationname/{ID}/';
export const METHOD_ISV_APPLICATION_NAME_DETAIL = 'isvapplicationname/{ID}/';
export const METHOD_ISV_APPLICATION_NAME_DROP = 'isvapplicationname/drop/';


//isv application version methods
export const METHOD_ISV_APPLICATION_VERSION_LIST = 'isvapplicationversion/pageable/';
export const METHOD_ISV_APPLICATION_VERSION_CREATE = 'isvapplicationversion/';
export const METHOD_ISV_APPLICATION_VERSION_UPDATE = 'isvapplicationversion/{ID}/';
export const METHOD_ISV_APPLICATION_VERSION_DELETE = 'isvapplicationversion/{ID}/';
export const METHOD_ISV_APPLICATION_VERSION_DETAIL = 'isvapplicationversion/{ID}/';
export const METHOD_ISV_APPLICATION_VERSION_DROP = 'isvapplicationversion/drop/';

//HCP Interface methods
export const METHOD_HCP_INTERFACE_LIST = 'hcpinterface/pageable/';
export const METHOD_HCP_INTERFACE_CREATE = 'hcpinterface/';
export const METHOD_HCP_INTERFACE_UPDATE = 'hcpinterface/{ID}/';
export const METHOD_HCP_INTERFACE_DELETE = 'hcpinterface/{ID}/';
export const METHOD_HCP_INTERFACE_DETAIL = 'hcpinterface/{ID}/';
export const METHOD_HCP_INTERFACE_DROP = 'hcpinterface/drop/';


//HCP for Cloud Scale version methods
export const METHOD_HCP_CLOUD_SCALE_VERSION_LIST = 'hcpcloudscaleversion/pageable/';
export const METHOD_HCP_CLOUD_SCALE_VERSION_CREATE = 'hcpcloudscaleversion/';
export const METHOD_HCP_CLOUD_SCALE_VERSION_UPDATE = 'hcpcloudscaleversion/{ID}/';
export const METHOD_HCP_CLOUD_SCALE_VERSION_DELETE = 'hcpcloudscaleversion/{ID}/';
export const METHOD_HCP_CLOUD_SCALE_VERSION_DETAIL = 'hcpcloudscaleversion/{ID}/';
export const METHOD_HCP_CLOUD_SCALE_VERSION_DROP = 'hcpcloudscaleversion/drop/';


//HCP Anywhere version methods
export const METHOD_HCP_ANYWHERE_VERSION_LIST = 'hcpanywhereversion/pageable/';
export const METHOD_HCP_ANYWHERE_VERSION_CREATE = 'hcpanywhereversion/';
export const METHOD_HCP_ANYWHERE_VERSION_UPDATE = 'hcpanywhereversion/{ID}/';
export const METHOD_HCP_ANYWHERE_VERSION_DELETE = 'hcpanywhereversion/{ID}/';
export const METHOD_HCP_ANYWHERE_VERSION_DETAIL = 'hcpanywhereversion/{ID}/';
export const METHOD_HCP_ANYWHERE_VERSION_DROP = 'hcpanywhereversion/drop/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;