import qs from 'qs';
import _ from 'lodash';
import { KEYWORD_SEARCH_FIELD, KEYWORD_SEARCH_CATEGORY } from 'constants/productMatrix';
import { DATE_FORMAT, GA_TAG_ID } from 'constants/common';
import moment from "moment";

export const encodeUri = (uri) => encodeURI(uri);
export const decodeUri = (uri) => decodeURI(uri);
export const buildQueryParams = (obj) => qs.stringify(obj, { encode: false });
export const parseQueryParams = (q) => qs.parse(q.slice(1));
export const jsonStringify = (obj) => JSON.stringify(obj);
export const parseJson = (json) => JSON.parse(decodeUri(json));
export const slugify = (keyword) => {
	//allow A-Z,a-z,0-9 only then Replace multi spaces with a single space then Replace space with a '-' symbol
	return keyword.replace(/[^A-Za-z0-9 ]/g, '').replace(/\s{2,}/g, ' ').replace(/\s/g, "-");
}
export const deSlugify = (keyword) => (!_.isEmpty(keyword)) ? keyword.replace(/-/g, " ") : "";
export const buildUrlQuery = (dataArr) => {
	const query = {};
	dataArr.map((obj) => {
		const val = _.isArray(obj.value) ? obj.value.join(',') : encodeURIComponent(decodeURIComponent(obj.value));
		if (!_.isEmpty(val)) {
			query[obj.field] = val;
		}
		return true;
	});
	return buildQueryParams(query);
}
export const parseUrlQuery = (q) => {
	q = parseQueryParams(q);
	const searchedData = [];
	const ignorableFields = ['status', 't', 'layout'];//ignorable fileds in qs
	let index = 0;
	Object.keys(q).map((FieldName) => {
		if (!ignorableFields.includes(FieldName)) {
			if (FieldName === KEYWORD_SEARCH_FIELD || FieldName === KEYWORD_SEARCH_CATEGORY) {
				searchedData[index] = {
					field: FieldName,
					type: "string",
					value: q[FieldName].trim(),
					operator: "eq",
				};
				index++;
			} else {
				if (FieldName.replace(/\s+/g, '').length > 0) {
					searchedData[index] = {
						field: FieldName,
						type: "int",
						value: q[FieldName].trim().split(',').map((id) => parseInt(id)),
						operator: "eq",
					};
					index++;
				}
			}
		}
		return true;
	})
	return searchedData;
}
export const modalMatricePagingFilters = (q) => {
	const filterData = [];
	let index = 0;
	q.map((row) => {
		if (row.field === KEYWORD_SEARCH_FIELD || row.field === KEYWORD_SEARCH_CATEGORY) {
			filterData[index] = row;
			index++;
		} else {
			if (row.field.replace(/\s+/g, '').length > 0) {
				filterData[index] = {
					field: row.field,
					type: row.type,
					value: row.value.join(","),
					operator: row.operator,
				};
				index++;
			}
		}
		return true;
	})
	return filterData;
}
export const modalMatriceFilters = (dataArr, FieldName = '') => {
	if (!_.isEmpty(dataArr)) {
		const query = {};
		if (FieldName !== '' && FieldName !== '_f') {
			query['selectedfield'] = FieldName;
		}
		const ignorableFields=['_f','keywordsearch'];
		dataArr.filter(obj=>!ignorableFields.includes(obj?.field)).map(obj=>query[obj?.field]=obj?.value);	
		return query;
	} else {
		return {};
	}

}
export const transformMenuDropdownData = (data = []) => {
	if (!_.isEmpty(data)) {
		const newData = [];
		data.map(row =>
			newData.push({
				title: row.menu_name,
				route: row.menu_url,
				hasSubmenu: (row?.nodes?.length > 0) ? true : false,
				childrens: transformMenuDropdownData(row?.nodes)
			})
		)
		return newData;
	} else {
		return data;
	}

}
export const baseUrl = () => {
	if (!window.location.origin) {
		return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
	} else {
		return window.location.origin;
	}
}
export const captureMatrixPageView = async () => {
	window.gtag('event', 'matrix_unique_page_view', {
		matrix_name: window.document.title.split("::")[1],
		matrix_path: window.location.pathname
	})
}
export const captureGAPageView = async (data = {}) => {
	window.gtag('event', 'page_view', {
		page_title: (data?.page_title || window.document.title),
		page_location: (data?.page_location || window.location.href),
		page_path: (data?.page_path || window.location.pathname)
	})
}
export const captureGADimension = async userData => {
	window.gtag('config', GA_TAG_ID, {
		'custom_map': { 'dimension1': 'flow' }
	});
	window.gtag('event', 'user_flow_dimension', { 'flow': (userData?.id > 0) ? 'Session' : 'Public' });
}

export const encodeBase64 = string => window.btoa(string);
export const decodeBase64 = string => window.atob(string);
export const isValidURL = string => {
	try {
		new URL(string);
	} catch (_) {
		return false;
	}
	return true;
}
export const formatDate = (dateString) => {
	if(dateString){
		const stillUtc = moment.utc(dateString).toDate();
    	return moment(stillUtc).local().format(DATE_FORMAT);
	}else{
		return dateString;
	}
};
export const convertToFormat = (dateString,format) => {
	if(dateString){
		return moment(dateString).local().format(format);
	}else{
		return dateString;
	}
};
export const  paginateArray = (array, page_size, page_number) => {
	//page number should be start from 0
	return array.slice(page_number * page_size, (page_number+1) * page_size);
}