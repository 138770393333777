import React, { Component } from "react";
import {connect} from "react-redux";
//import {toast} from 'react-toastify'; 
import { Formik, Form, Field, ErrorMessage } from "formik";
import {doLogin} from "actions/loginActions";
import Spinner from "components/shared/Spinner"; 
import authService from "services/authService";
import { Helmet } from "react-helmet";
import { APP_NAME } from "constants/common";
class Login extends Component {

  state={
    serverErrors:[]
  }
 
  submitForm=(formData, actions) => {
    this.props.doLogin(formData).then((res)=>{ 
      if(res===true){
        this.props.history.push('/admin/dashboard');        
      }else{
        this.setState({
          serverErrors:res
        })
        actions.setSubmitting(false);//allow form to re-enter the credentials and submit again
      }
    })
     
  }
  componentDidMount(){
    const userData= authService.userData;
    if(userData.id){
      this.props.history.push('/admin/dashboard');//push to home page if user is already loggedin
    }
  }

  validateForm=(values)=>{    
    const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      let errors = {};
      if (values.email === "") {
        errors.email = "Email is required";
      } else if (!emailTest.test(values.email)) {
        errors.email = "Invalid email address format";
      }
      if (values.password === "") {
        errors.password = "Password is required";
      } else if (values.password.length < 3) {
        errors.password = "Password must be minimum 3 characters ";
      }
      return errors;
  }
  
  render() { 
    const {loading} = this.props;
    const {serverErrors}=this.state;
    return (
      <div className="container">
         {loading ? <Spinner /> : ""}
         <Helmet>
              <title>{APP_NAME} :: Login</title>
          </Helmet>
        <div className="row mb-5">
          <div className="col-lg-12 text-center">
            <h1 className="mt-5">Login</h1>
          </div>
        </div>
        <div className="row">
        <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={this.validateForm}
              onSubmit={this.submitForm}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      className={`form-control ${
                        (touched.email && (errors?.email || serverErrors?.email)) ? "is-invalid" : ""
                      }`}
                    />
                    {(serverErrors?.email) ? (
                              <div className="text-danger">
                                {serverErrors?.email}
                              </div>
                            ) : null} 
                    <ErrorMessage
                      component="div"
                      name="email"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      className={`form-control ${
                        (touched.password && (errors?.password || serverErrors?.password))? "is-invalid" : ""
                      }`}
                    />
                    {(serverErrors?.password) ? (
                              <div className="text-danger">
                                {serverErrors?.password}
                              </div>
                            ) : null} 
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Login"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  ...state.loginReducer,
});

const mapDispatchToProps = (dispatch) => ({
  doLogin: (formData) => dispatch(doLogin(formData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
