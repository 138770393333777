import React, { Component } from "react";
import { toast } from "react-toastify";
import legacyProductService from "services/admin/masters/legacyProductService";
import { MSG_UPDATE_RECORD, MSG_VALIDATION_LINKS_FORM } from "constants/admin/masters/legacyProducts";
import * as yup from "yup";
import _ from "lodash";
const serviceObj = new legacyProductService();
class LinksForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props?.data?.id || 0,
            formData: this.props?.data?.links || [],
        }
    }

    validatePayload = async (payload) => {
        const errors = {};
        const schema = yup.object().shape({
            id: yup.number().required().positive('please select a product.'),
            links: yup.array().of(
                yup.object().shape({
                    title: yup.string().required(),
                    link: yup.string().required().url(),
                })
            )
        });
        // check validity
        await schema
            .validate(payload, { abortEarly: false })
            .catch(function (err) {
                err.inner.map((error) =>
                    !errors[error.path] ? (errors[error.path] = error.message) : null
                );
            });
        return errors; //return basicerrors
    };

    saveAll = async () => {
        const { id, formData } = this.state;
        const payload = { id, links: formData };
        const errors = await this.validatePayload(payload);
        if (_.isEmpty(errors)) {
            try {
                const response = await serviceObj.saveAll(id, formData);
                if (response) {
                    toast.success(response.message || MSG_UPDATE_RECORD);
                    this.props.refreshHandler();                    
                }
            } catch (err) {
                //failure case
                console.log('Error in save all links', err)
            }
        } else {
            toast.error(MSG_VALIDATION_LINKS_FORM)
        }
    }

    addMore = () => {
        const blankRow = { id: 0, title: '', link: '', is_deleted: false };
        this.setState({
            formData: [
                ...this.state.formData, blankRow
            ]
        })
    }
    removeField = (rowIndex) => {

        const list = [...this.state.formData];
        list.splice(rowIndex, 1);
        this.setState({
            formData: list
        })

    }
    handleChange = (rowIndex, event) => {
        const list = this.state.formData;
        list[rowIndex][event.target.name] = event.target.value;
        this.setState({
            formData: list
        })
    }

    render() {
        const { formData } = this.state; 
        return (!this.props?.data?.is_deleted)?(
            <div className="form-wrap ml-5 mr-5  mt-5 mb-2">
                <div className="row mt-1 mb-3">
                    <div className="col col-md-4">
                    <b>Title</b>
                    </div>
                    <div className="col col-md-6">
                    <b>Link</b>
                    </div>
                    <div className="col col-md-2">
                        <button onClick={this.addMore} className="btn btn-secondary">+ Add</button>
                        <button onClick={this.saveAll} className="ml-1 btn btn-branding">Save All</button>
                    </div>
                </div>
                {
                    formData && formData.map((item, index) => (
                        <div key={index} className="row mt-1 mb-3">
                            <div className="col col-md-4">
                                <div className="form-group">                                    
                                    <input type="text" className="form-control" name="title" value={item.title} onChange={(e) => this.handleChange(index, e)} placeholder="Link Title" />
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="form-group">
                                    
                                    <input type="text" className="form-control" name="link" value={item.link} onChange={(e) => this.handleChange(index, e)} placeholder="Link Url" />
                                </div>
                            </div>
                            <div className="col col-md-2 mt-1">
                                <button onClick={() => this.removeField(index)} className="btn btn-sm btn-danger">X</button>
                            </div>
                        </div>
                    ))
                }
            </div>
        ):null;
    }
}

export default LinksForm;
