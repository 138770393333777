import { networkService } from "services/networkService";
import {
  METHOD_VEEAM_PLUGIN_VERSION_LIST,
  METHOD_VEEAM_PLUGIN_VERSION_CREATE,
  METHOD_VEEAM_PLUGIN_VERSION_UPDATE,
  METHOD_VEEAM_PLUGIN_VERSION_DELETE,
  METHOD_VEEAM_PLUGIN_VERSION_DETAIL,
  METHOD_SERVER_TYPE_LIST,
  METHOD_SERVER_TYPE_CREATE,
  METHOD_SERVER_TYPE_UPDATE,
  METHOD_SERVER_TYPE_DELETE,
  METHOD_SERVER_TYPE_DETAIL,
  METHOD_SERVER_CONFIGURATION_LIST,
  METHOD_SERVER_CONFIGURATION_CREATE,
  METHOD_SERVER_CONFIGURATION_UPDATE,
  METHOD_SERVER_CONFIGURATION_DELETE,
  METHOD_SERVER_CONFIGURATION_DETAIL,
  METHOD_SERVER_PURPOSE_LIST,
  METHOD_SERVER_PURPOSE_CREATE,
  METHOD_SERVER_PURPOSE_UPDATE,
  METHOD_SERVER_PURPOSE_DELETE,
  METHOD_SERVER_PURPOSE_DETAIL,
} from "constants/admin/masters/veeam";

class VeeamService {
  //VEEAM PLUGIN VERSION METHODS
  getAllVeemPluginVersion = (data) => {
    return networkService
      .post(METHOD_VEEAM_PLUGIN_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getVeemPluginVersionDetail = (id) => {
    const updatedMethodName = METHOD_VEEAM_PLUGIN_VERSION_DETAIL.replace(
      "{ID}",
      id
    );
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createVeemPluginVersion = (data) => {
    return networkService
      .post(METHOD_VEEAM_PLUGIN_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateVeemPluginVersion = (data, id) => {
    const updatedMethodName = METHOD_VEEAM_PLUGIN_VERSION_UPDATE.replace(
      "{ID}",
      id
    );
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVeemPluginVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_VEEAM_PLUGIN_VERSION_DELETE.replace(
      "{ID}",
      id
    );
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //SERVER TYPE METHODS

  getAllServerType = (data) => {
    return networkService
      .post(METHOD_SERVER_TYPE_LIST, data)
      .then((response) => response.data);
  };
  getServerTypeDetail = (id) => {
    const updatedMethodName = METHOD_SERVER_TYPE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createServerType = (data) => {
    return networkService
      .post(METHOD_SERVER_TYPE_CREATE, data)
      .then((response) => response.data);
  };
  updateServerType = (data, id) => {
    const updatedMethodName = METHOD_SERVER_TYPE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteServerType = (id, is_delete = false) => {
    const updatedMethodName = METHOD_SERVER_TYPE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //SERVER CONFIGURATION METHODS
  getAllServerConfiguration = (data) => {
    return networkService
      .post(METHOD_SERVER_CONFIGURATION_LIST, data)
      .then((response) => response.data);
  };
  getServerConfigurationDetail = (id) => {
    const updatedMethodName = METHOD_SERVER_CONFIGURATION_DETAIL.replace(
      "{ID}",
      id
    );
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createServerConfiguration = (data) => {
    return networkService
      .post(METHOD_SERVER_CONFIGURATION_CREATE, data)
      .then((response) => response.data);
  };
  updateServerConfiguration = (data, id) => {
    const updatedMethodName = METHOD_SERVER_CONFIGURATION_UPDATE.replace(
      "{ID}",
      id
    );
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteServerConfiguration = (id, is_delete = false) => {
    const updatedMethodName = METHOD_SERVER_CONFIGURATION_DELETE.replace(
      "{ID}",
      id
    );
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  //SERVER PURPOSE METHODS
  getAllServerPurpose = (data) => {
    return networkService
      .post(METHOD_SERVER_PURPOSE_LIST, data)
      .then((response) => response.data);
  };
  getServerPurposeDetail = (id) => {
    const updatedMethodName = METHOD_SERVER_PURPOSE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createServerPurpose = (data) => {
    return networkService
      .post(METHOD_SERVER_PURPOSE_CREATE, data)
      .then((response) => response.data);
  };
  updateServerPurpose = (data, id) => {
    const updatedMethodName = METHOD_SERVER_PURPOSE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteServerPurpose = (id, is_delete = false) => {
    const updatedMethodName = METHOD_SERVER_PURPOSE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default VeeamService;
