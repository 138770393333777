const FILTERS = {
    vcenter: {
        os: {
            title: 'vSphere Release',
            icon: 'server.png'
        },
        clientplugin_version: {
            title: 'Client Plug-In Version',
            icon: 'os.png'
        },
        array_customer_version: {
            title: 'Array',
            icon: 'switch.png'
        },
        protocol: {
            title: 'Protocol',
            icon: 'os_kernel.png'
        }
    }
}
export default FILTERS;