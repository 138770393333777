import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import DataGrid from "components/shared/DataGrid";
import _ from "lodash";
import storageCertificationService from "services/admin/masters/storageCertificationService";
import {
  MSG_WARNING_DELETE,
  MSG_DELETE_RECORD,
  MSG_WARNING_UNDO_DELETE,
  MSG_UNDO_RECORD,
} from "constants/admin/masters/storageCertification";
import Spinner from "components/shared/Spinner";
import Toggle from "components/shared/Toggle";
import MasterRelation from "components/shared/MasterRelation";
import {
  handleRefresh,
  toggleChangehandlerNew,
  getCurrentSlug,
} from "utils/masterHelpers";
import JobProgressStatus from "containers/Admin/masters/job-progress/JobProgressStatus";
import EoslMapping from "containers/Admin/masters/eosl-mapping/regular-masters/EoslMapping";
const serviceObj = new storageCertificationService();
class List extends Component {
  constructor(props) {
    super(props);
    this.toggleChangehandler = toggleChangehandlerNew.bind(this);
    this.handleRefresh = handleRefresh.bind(this);
    this.getCurrentSlug = getCurrentSlug.bind(this);
    this.state = {
      loadingTable: false,
      refreshToken: Date.now(),
      //datatable states
      filtering: true,
      columns: [{ title: "Client Platform", field: "name" }],
      actions: [
        (row) => this.decideAction(row, "undo"),
        (row) => this.decideAction(row, "edit"),
        (row) => this.decideAction(row, "delete"),
      ],
      pageSize: 20,
      recordsMainFilter: {
        field: "is_deleted",
        type: "int",
        value: 0,
        operator: "eq",
      },
    };
  }

  decideAction = (rowData, actionType) => {
    if (actionType === "undo") {
      return rowData.is_deleted === 1
        ? {
            icon: actionType,
            tooltip: "Undo Deleted Record",
            onClick: (event, rowData) => {
              if (
                window.confirm(
                  MSG_WARNING_UNDO_DELETE.replace("ITEM_NAME", rowData.name)
                )
              ) {
                serviceObj.deleteScProduct(rowData.id).then((response) => {
                  toast.success(response.message || MSG_UNDO_RECORD);
                  this.handleRefresh();
                });
              }
            },
          }
        : null;
    } else if (actionType === "edit") {
      return rowData.is_deleted === 0
        ? {
            icon: actionType,
            tooltip: "Edit Record",
            onClick: (event, rowData) => {
              this.props.history.push(
                "/admin/master/" + this.getCurrentSlug() + "/edit/" + rowData.id
              );
            },
          }
        : null;
    } else if (actionType === "delete") {
      return rowData.is_deleted === 0
        ? {
            icon: actionType,
            tooltip: "Delete Record",
            onClick: (event, rowData) => {
              if (
                window.confirm(
                  MSG_WARNING_DELETE.replace("ITEM_NAME", rowData.name)
                )
              ) {
                serviceObj.deleteScProduct(rowData.id).then((response) => {
                  toast.success(response.message || MSG_DELETE_RECORD);
                  this.handleRefresh();
                });
              }
            },
          }
        : null;
    }
  };

  handlePagingData = (query) => {
    this.setState({
      loadingTable: true,
    });
    return new Promise((resolve, reject) => {
      let sortData = [{ field: "id", dir: "desc" }]; //default sorting
      if (!_.isEmpty(query.orderBy)) {
        sortData = [
          {
            field: query.orderBy.field,
            dir: query.orderDirection,
          },
        ];
      }
      this.setState({ pageSize: query.pageSize }, () => {
        let filters = [];
        if (!_.isEmpty(query.filters)) {
          query.filters.map((filter) =>
            filters.push({
              field: filter.column.field,
              type: "string",
              value: filter.value,
              operator: "eq",
            })
          );
        }
        const reqParams = {
          take: query.pageSize,
          skip: query.pageSize * query.page,
          sort: sortData,
          filter: filters,
        };
        if (!_.isEmpty(this.state.recordsMainFilter)) {
          filters.push(this.state.recordsMainFilter);
        }
        serviceObj
          .getAllScProduct(reqParams)
          .then((response) => {
            this.setState({
              loadingTable: false,
            });
            resolve({
              data: response.items,
              page: query.page,
              totalCount: response.total,
            });
          })
          .catch((e) => {
            this.setState({
              loadingTable: false,
            });
          });
      });
    });
  };
  render() {
    const { loadingTable } = this.state;
    return (
      <>
        {loadingTable ? <Spinner /> : null}
        <div className="form-wrap mt-3">
          <div className="form-group">
            <Form.Group as={Row}>
              <Col sm={6}>
                <JobProgressStatus />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={6}>
                <Toggle
                  variant="secondary"
                  triggerHandler={this.toggleChangehandler}
                  default={"0"}
                  options={[
                    { name: "Active", value: "0" },
                    { name: "Deleted", value: "1" },
                    { name: "All", value: "" },
                  ]}
                />
                <MasterRelation master={this.getCurrentSlug()} />
              </Col>
              <Col className="text-right" sm={6}>
                <EoslMapping
                  slug={this.getCurrentSlug()}
                  masterDetailId={59}
                  masterTitle="Platform"
                  masterKey="scproduct"
                />
                <Link
                  className="btn btn-secondary"
                  to={"/admin/master/" + this.getCurrentSlug() + "/add"}
                >
                  Add Client Platform
                </Link>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={12}>
                <DataGrid
                  actions={this.state.actions}
                  columns={this.state.columns}
                  dataHandler={this.handlePagingData}
                  pageSize={this.state.pageSize}
                  filtering={this.state.filtering}
                  refreshToken={this.state.refreshToken}
                ></DataGrid>
              </Col>
            </Form.Group>
          </div>
        </div>
      </>
    );
  }
}
export default List;
