import React, { Component } from "react";
import {setDisclaimerAccept} from "actions/commonActions"; 
import { connect } from "react-redux"; 
import { Modal } from "react-bootstrap";
import { DISCLAIMER_ACCEPTANCE, DISCLAIMER_TEXT, DISCLAIMER_TITLE } from "constants/common";
class Disclaimer extends Component {
  render(){
    
  const disclaimerSession=localStorage.getItem(DISCLAIMER_ACCEPTANCE);
    return (!disclaimerSession)?(
      <Modal
        show={true}
        onHide={() => {}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-center disclaimer-header">
          <Modal.Title className="w-100" >
            {DISCLAIMER_TITLE}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <p className="mt-3">{DISCLAIMER_TEXT}</p>
          <p className="text-center mt-3"> <button className="btn btn-branding" onClick={this.props.setDisclaimerAccept}>I Accept</button></p>
        </Modal.Body>
      </Modal>
    ):null;
  }
}
const mapStateToProps = (state) => ({
  ...state.commonReducer,
});

const mapDispatchToProps = (dispatch) => ({
    setDisclaimerAccept: () => dispatch(setDisclaimerAccept()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Disclaimer);

  