import { networkService } from "services/networkService";
import {
  METHOD_LIST,
  METHOD_DOWNLOAD_ZIP,
} from "constants/admin/downloadDatapath";
import _ from "lodash";

class DownloadaDatapathService {
  getAll = (data) => {
    return networkService
      .post(METHOD_LIST, data)
      .then((response) => response.data);
  };

  downloadFile = (requestedParams, zipFileName) => {
    return new Promise((resolve, reject) => {
      if (!_.isEmpty(requestedParams)) {
        const metaData = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let fileName = zipFileName;
        metaData["responseType"] = "arraybuffer";

        networkService
          .post(METHOD_DOWNLOAD_ZIP, requestedParams, metaData)
          .then(
            (response) => {
              if (window.navigator && window.navigator.msSaveBlob) {
                //for ie and edge browsers
                window.navigator.msSaveBlob(
                  new Blob([response.data]),
                  fileName
                );
              } else {
                //for regular browsers
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                let a = document.createElement("a");
                a.href = url;
                a.setAttribute("download", fileName);
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }
              resolve();
            },
            (err) => {
              resolve(); //resolve it from exception also as global extension handler will show the error and resolving this promise will hide the spinner
            }
          );
      }
    });
  };
}
export default DownloadaDatapathService;
