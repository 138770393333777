import React from "react";
import { NAVBAR_LOGO } from "constants/common";
const FooterBrandSite = (props) => {
    const dateObj = new Date();
    return (
        <section className="brandSiteWrapper"> 
        
            <div className="footer aem-GridColumn aem-GridColumn--default--12">
                <div className="footer">
                    <div className="footer-gray">
                        <div className="footer-container content-container container">
                            <div className="nav-list-container">
                                <div id="footer-col-1">
                                    <ul className="nav-list col-sm-3 col-md-3">

                                        <li>EXPLORE </li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/Support_Information/Support_Connect/Support_Connect_Overview" target="_blank">Support Website Overview<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.hitachivantara.com/en-us/products.html" target="_blank">Hitachi Products<span className="glyphicon glyphicon-new-window animateIcon "></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/Support_Information/More_Info/How_to_Access_Pentaho_and_Lumada_Downloads_and_Other_Resources" target="_blank">Pentaho & Lumada Resources<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.hitachivantara.com/en-us/services/customer-support.html" target="_blank">Customer Support Services<span className="glyphicon glyphicon-new-window animateIcon "></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://support.hitachivantara.com/en/learning/training.html" target="_blank">Training<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://support.hitachivantara.com/en/learning/certifications.html" target="_blank">Certifications<span></span></a></li>
                                    </ul>
                                </div>

                                <div id="footer-col-2">
                                    <ul className="nav-list col-sm-3 col-md-3">

                                        <li>INFORMATION </li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/Documents" target="_blank">Product Documentation<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/Support_Information/More_Info/Product_Life-Cycle_Matrix" target="_blank">Product Life-Cycle Matrix<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://compatibility.hitachivantara.com/" target="_blank">Product Compatibility<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.hitachivantara.com/en-us/pdf/specifications/hitachi-support-service-descriptions-and-deliverables.pdf" target="_blank">Customer Support Terms<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/Support_Information/More_Info/Global_Services_Areas" target="_blank">Global Services Service Areas<span></span></a></li>
                                    </ul>
                                </div>

                                <div id="footer-col-3">
                                    <ul className="nav-list col-sm-3 col-md-3">

                                        <li>SUPPORT </li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/Support_Information/Data_Collection" target="_blank">Data Collection<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/Support_Information/Support_Questions" target="_blank">Support Questions<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/Support_Information/Hitachi_Vantara_Security_Advisories" target="_blank">Security Advisories<span></span><span className="glyphicon glyphicon-lock" /></a></li>
                                        <li><a rel="noopener noreferrer" href="https://knowledge.hitachivantara.com/knowledge" target="_blank">Knowledge<span></span><span className="glyphicon glyphicon-lock" /></a></li>
                                    </ul>
                                </div>

                                <div id="footer-col-4">
                                    <ul className="nav-list col-sm-3 col-md-3">

                                        <li>GET IN TOUCH </li>
                                        <li><a rel="noopener noreferrer" href="https://www.hitachivantara.com/en-us/company/careers.html" target="_blank">Careers<span className="glyphicon glyphicon-new-window animateIcon "></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://support.hitachivantara.com/en/contact-support.html" target="_blank">Contact Support<span></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://partnerportal.hitachivantara.com/s/contact-us" target="_blank">Partner Contact<span className="glyphicon glyphicon-new-window animateIcon "></span></a></li>
                                        <li><a rel="noopener noreferrer" href="https://www.hitachivantara.com/partnerlocator/en_us/partnerlocator.html" target="_blank">Partner Locator<span className="glyphicon glyphicon-new-window animateIcon "></span></a></li>
                                    </ul>
                                </div>
                            </div>
                           <div className="footer-info">This site is best viewed with latest version of Chrome, Firefox and Microsoft Edge.</div>
                            <a rel="noopener noreferrer" href="https://www.hitachivantara.com/en-us/home.html" target="_blank" className="footer-logo">

                                <span className="hv-txt-footer-logo">{NAVBAR_LOGO}</span>

                            </a>
                        </div>
                    </div>
                    <div id="copyright-footer" className="footer-white">
                        <div className="footer-container content-container container">
                            <p className="links">
                                <a rel="noopener noreferrer" href="https://support.hitachivantara.com/en/terms-of-use.html" target="_blank">Terms of Use</a>
                                <span>|</span>
                                <a rel="noopener noreferrer" href="https://www.hitachivantara.com/en-us/company/legal.privacy.html" target="_blank">Privacy Policy</a>
                                <span>|</span>
                                <a rel="noopener noreferrer" href="https://www.hitachivantara.com/en-us/company/legal.html" target="_blank">Legal</a>
                                <span>|</span>
                                <a rel="noopener noreferrer" href="https://support.hitachivantara.com/en/sitemap.html" target="_blank">Sitemap</a>
                                <span>|</span>
                            </p>
                            <p className="copyright">&copy; {NAVBAR_LOGO} Corporation {dateObj.getFullYear()}. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default FooterBrandSite;