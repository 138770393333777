import React, { Component, Suspense, createRef } from "react";

import Spinner from "components/shared/Spinner";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getUserWiseProducts,
  resetFilters,
} from "actions/productSearchActions";
import {
  LABEL_ALL_PRODUCT_CATEGORY,
  LABEL_ALL_SOLUTION_CATEGORY,
} from "constants/productMatrix";
import MegaMenu from "components/shared/ManageDatapathMegaMenu";
import TabWrapper from "containers/Pages/TabWrapper/TabWrapper";
import { Matrices } from "./DatapathExportMatrices";
const elemRef = createRef();

class DatapathExport extends Component {
  constructor(props) {
    super(props);
    if (_.isEmpty(props.matriceProducts)) {
      props.getUserWiseProducts();
    }
    this.state = {
      defaultMegaMenuLabel: "All Products Category",
      downloadAction: 0,
      selectedProduct: [],
    };
  }
  renderRelevantComponent = (selectedProduct) => {
    let RelevantComponent = null;
    for (const matrice of Matrices) {
      if (matrice.Slugs.includes(selectedProduct.slug)) {
        RelevantComponent = matrice.Component;
        break;
      }
    }
    let productID = null;
    if(selectedProduct.category === "Products"){
      productID = selectedProduct.id;
    } else if(selectedProduct.category === "Solutions" && selectedProduct.solution_id){
      productID = selectedProduct.solution_id;
      
    } else {
      productID = selectedProduct.id;
    }
    if (RelevantComponent !== null) {
      return (
        <RelevantComponent
          resultElemRef={elemRef}
          downloadAction={this.state.downloadAction}
          productId={productID}
          productSlug={selectedProduct.slug}
        />
      );
    } else {
      return (
        <div className="row col-lg-12 alert alert-warning">{"Coming soon"}</div>
      );
    }
  };
  componentDidMount = async () => {
    this.setDefaultMegaMenuLabel();
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState?.selectedProduct?.id !== this.state?.selectedProduct?.id) {
      //reset the previous filters from redux
      this.props.resetFilters();
    }
    if (prevProps.megaMenuOption !== this.props.megaMenuOption) {
      this.setDefaultMegaMenuLabel();
    }
  }

  // to set the default mega menu label of button based from redux state
  setDefaultMegaMenuLabel = () => {
    const { megaMenuOption } = this.props;
    if (!megaMenuOption || megaMenuOption === "Products") {
      this.setState({ defaultMegaMenuLabel: LABEL_ALL_PRODUCT_CATEGORY });
    } else if (megaMenuOption === "Solutions") {
      this.setState({ defaultMegaMenuLabel: LABEL_ALL_SOLUTION_CATEGORY });
    }
  };

  handleChangeMenu = (selectedProduct) => {
    this.setState({ selectedProduct, downloadAction: 0 });
  };
  handleChange = (e) => {
   
    this.setState({ [e.target.name]: e.target.value });
  };

  scrollToResult = () => {
    elemRef.current.scrollIntoView({ behavior: "smooth" });
  };
// check the logic and test it
  findProducts = (selectedProduct, matriceProducts) => {
    if(matriceProducts) {

    if (selectedProduct?.parent) {
      let foundObj;
      JSON.stringify(matriceProducts, (_, nestedValue) => {
        if (
          nestedValue &&
          nestedValue["parent"] === (selectedProduct?.parent).split(" > ")[0]
        ) {
          foundObj = nestedValue;
        }
        return nestedValue;
      });
      if(foundObj){
      return foundObj.nodes;
      }else if(!foundObj){
        return matriceProducts;
      }
    } else {
      return matriceProducts;
    }
  }
  };

  render() {
    const { loading, loadingFilters, matriceProducts } = this.props;
    const { selectedProduct, downloadAction, defaultMegaMenuLabel } =
      this.state;
    return (
      <>
        {loading ? <Spinner /> : null}
        {loadingFilters ? <Spinner /> : null}
        <TabWrapper>
          <div className="form-wrap">
            <div className="row mega-row p-1 mt-3">
              <div>
                <div className="form-group">
                  <MegaMenu
                    defaultOpenState={
                      selectedProduct?.mega_menu_name !== undefined
                        ? false
                        : true
                    }
                    alwaysOpenStates={[defaultMegaMenuLabel]}
                    data={this.findProducts(selectedProduct, matriceProducts)}
                    flagHomeIcon={false}
                    onChangeMenu={this.handleChangeMenu}
                    selectedProduct={selectedProduct?.parent}
                    defaultLabel={
                      selectedProduct?.parent ||
                      selectedProduct?.mega_menu_name ||
                      defaultMegaMenuLabel
                    }
                  />
                </div>
              </div>
              {selectedProduct?.id > 0 ? (
                <div className="datapath-action-dropdown">
                  <div className="input-group col-sm-5 mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Download Action</span>
                    </div>
                    <select
                      name="downloadAction"
                      value={downloadAction}
                      onChange={this.handleChange}
                      className="form-control"
                      placeholder="Choose Action"
                    >
                      <option value="0">Update/Delete</option>
                      <option value="1">Re-Activate</option>
                    </select>
                  </div>
                </div>
              ) : null}
              {selectedProduct?.id > 0 && this.props.totalRecordCount ? (
                <div className="pl-2 mb-3">
                  <button
                    className="btn btn-link branding-link"
                    onClick={this.scrollToResult}
                  >
                    {this.props.totalRecordCount} Records Found{" "}
                    <i className="fa fa-angle-double-down"></i>
                  </button>
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <Suspense fallback={<Spinner />}>
                {!_.isEmpty(selectedProduct)
                  ? this.renderRelevantComponent(selectedProduct)
                  : null}
              </Suspense>
            </div>
          </div>
        </TabWrapper>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  // getting matriceProducts from productSearchReducer
  ...state.productSearchReducer,
  ...state.commonReducer,
});

const mapDispatchToProps = (dispatch) => ({
  resetFilters: () => dispatch(resetFilters()),
  getUserWiseProducts: () => dispatch(getUserWiseProducts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatapathExport);
