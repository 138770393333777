const FILTERS = {
    openstack: {
        releases: {
            title: 'Release Name',
            icon: 'os.png'
        },
        vendor_distribution: {
            title: 'Vendor Distribution',
            icon: 'multipath.png'
        },
        os_version: {
            title: 'OS Version',
            icon: 'os_kernel.png'
        },
        hbsd_version: {
            title: 'HBSD Version',
            iconClass: 'project-diagram'
        },
        array: {
            title: 'Array',
            icon: 'array.png'
        },
        array_if: {
            title: 'Array Interface',
            icon: 'array_if.png'
        }
    }
}
export default FILTERS;