import { networkService } from "./networkService";
import { METHOD_PRODUCTS, METHOD_UPLOAD_FILE, METHOD_UPLOAD_HISTORY, UPLOAD_PROGRESS, METHOD_DOWNLOAD_TEMPlATE } from "constants/admin/bulkUpload";

class uploadService {

  updateProgress = (progress) => (dispatch) => {
    dispatch({
      type: UPLOAD_PROGRESS,
      payload: progress,
    });
  }

  uploadFile = (productId,payload, dispatch) => {
    const updatedProductIdMethodUrl = METHOD_UPLOAD_FILE.replace("{productId}", productId);
    return networkService.post(updatedProductIdMethodUrl, payload, {
      onUploadProgress: (ProgressEvent) => {
        const progress = (ProgressEvent.loaded / ProgressEvent.total) * 100;
        dispatch(this.updateProgress(progress));
      },
    })
      .then((response) => response.data)
      .catch(error => false);
  };

  getUploadHistory = (payload) => {
    return networkService.post(METHOD_UPLOAD_HISTORY, payload)
      .then((response) => response.data)
      .catch(error => []);
  };
  getUserWiseProducts = () => {
    return networkService.get(METHOD_PRODUCTS)
      .then((response) => response.data);
  };
  downloadFile = (url, filename) => {
    return new Promise((resolve, reject) => {
      networkService.get(url).then((response) => {
        if (window.navigator && window.navigator.msSaveBlob) {
          //for ie and edge browsers
          window.navigator.msSaveBlob(new Blob([response.data]), filename);
        } else {
          //for regular browsers
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", filename);
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        resolve();
      }).catch((err) => {
        resolve();
      });
    });
  };

  downloadTemplate = (productId,payload) => {
    return new Promise((resolve, reject) => {
      const filename = `template-${productId}.csv`;
      const url = METHOD_DOWNLOAD_TEMPlATE.replace("{productId}", productId);
      networkService.post(url,payload).then((response) => {
        if (window.navigator && window.navigator.msSaveBlob) {
          //for ie and edge browsers
          window.navigator.msSaveBlob(new Blob([response.data]), filename);
        } else {
          //for regular browsers
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", filename);
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        resolve();
      }).catch((err) => {
        //console.log('err',err)
        resolve();
      });
    });
  };



}
export default uploadService;
