import React, { Component } from "react";
import { handleChangeMenu } from "utils/matriceHelpers";
import products from "resources/json/static-menus.json";
import MegaMenu from "components/shared/MegaMenu";
import { APP_NAME } from "constants/common";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LABEL_ALL_PRODUCT_CATEGORY } from "constants/productMatrix";
class LandingProducts extends Component {
  constructor(props) {
    super(props);
    this.handleChangeMenu = handleChangeMenu.bind(this);
    this.state = {
      nodes: [],
      loading: false,
      productTitle: "",
    };
  }

  componentDidMount() {
    this.getProductDetail();
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.productSlug !== prevProps.match.params.productSlug
    ) {
      this.getProductDetail();
    }
  }

  findSelectedMenu = (products, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(products, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };

  getProductDetail = async () => {
    const {
      match: {
        params: { productSlug },
      },
    } = this.props;
    const selectedProduct = this.findSelectedMenu(
      products,
      "slug",
      productSlug
    );
    this.setState({
      loading: false,
      nodes: selectedProduct.child,
      productTitle: selectedProduct.name,
    });
  };

  render() {
    const { nodes, loading, productTitle } = this.state;
    return !loading ? (
      <div className="form-wrap">
        <div className="row mega-row p-1 mt-3">
          <div className="form-group">
            <MegaMenu
              data={products}
              onChangeMenu={this.handleChangeMenu}
              flagHomeIcon={true}
              defaultLabel={productTitle || LABEL_ALL_PRODUCT_CATEGORY}
            />
            {productTitle ? (
              <Helmet>
                <title>
                  {APP_NAME} :: {productTitle}
                </title>
              </Helmet>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h3 className="mt-1">
              {" "}
              <i className="fa fa-book"></i>
              {productTitle}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            {nodes &&
              nodes.map((row, index) => (
                <p key={index}>
                  <span
                    onClick={() => this.handleChangeMenu(row)}
                    className="branding-red"
                  >
                    {" "}
                    <FontAwesomeIcon
                      size="lg"
                      icon={["far", "file-alt"]}
                    />{" "}
                    {row.name}
                  </span>{" "}
                  {/*<br />{row.link_modified_date}*/}
                </p>
              ))}
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default LandingProducts;
