const FILTERS = {
    "veeam": {
        veeam_plugin_version:{
            title: 'Veeam Plugin Version',
            icon: 'switch.png'
        },
        server_type:{
            title:'Server Type',
            icon: 'server.png'
        },
        os:{
            title:'OS',
            icon: 'os.png'
        },
       server_configuration: {
            title:'Server Configuration',
            icon: 'server.png'
        },
        server_purpose:{
            title:'Server Purpose',
            icon: 'server.png'
        },
        array_customer_version:{
            title:'Array',
            icon: 'switch.png'
        }
     }
}
export default FILTERS;