import axios from "axios";
import {
  API_URL,
  LOGGED_IN_USER_INFO,
  ERROR_MESSAGE_UNKNOWN,
  ERROR_MESSAGE_401,
  OKTA_TOKEN_INFO,
} from "constants/common";
import { METHOD_UPLOAD_FILE } from "constants/admin/bulkUpload";
import { METHOD_DONWLOAD_KEYWORDS } from "constants/productMatrix";
import authService from "./authService";
import { toast } from "react-toastify";
import { spinnerService } from "services/spinnerService";
import _ from "lodash";
import { oktaAuth } from "App";
export const networkService = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const networkServiceMock = axios.create({
  baseURL: "http://localhost:8080/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

//request interceptor//
const requestHandler = (request) => {
  spinnerService.show("globalSpinner"); //show global spinner
  if (request.url === METHOD_UPLOAD_FILE) {
    request.headers["Content-Type"] = "multipart/form-data";
  }
  const userInfo = sessionStorage.getItem(LOGGED_IN_USER_INFO)
    ? JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO))
    : {};
  if (userInfo?.access_token) {
    const skipTokenMethods = [METHOD_DONWLOAD_KEYWORDS];
    if (!skipTokenMethods.includes(request.url)) {
      request.headers["Authorization"] =
        "Bearer " + (userInfo.access_token || "");
    }
  }
  return request;
};
networkService.interceptors.request.use(requestHandler);
networkServiceMock.interceptors.request.use(requestHandler);
//response interceptor//
const errorHandler = (error) => {
  //hide global spinner
  spinnerService.hide("globalSpinner");
  //if 401 then logout//
  if (
    error?.response?.status === 401 ||
    _.isEmpty(localStorage.getItem(OKTA_TOKEN_INFO))
  ) {
    toast.error(ERROR_MESSAGE_401, {
      onClose: async () => {
        // await store.dispatch(logout());
        await oktaAuth.signOut();
        await authService.logout();
      },
      toastId: 401,
    });
  } else if (error?.response?.status === 403) {
    toast.error(error?.response?.data?.detail, { toastId: 403 });
    authService.redirect("/403");
  } else if (error?.response?.status === 404) {
    toast.error(ERROR_MESSAGE_UNKNOWN, { toastId: 404 });
  } else if (error?.response?.data?.detail) {
    toast.error(error?.response?.data?.detail);
  } else if (error?.response?.data?.message) {
    toast.error(error?.response?.data?.message);
  }

  if (error.request.responseType === "arraybuffer") {
    //in case of zip api fails then read the data from array buffer
    const errorData = JSON.parse(
      Buffer.from(error.response.data).toString("utf8")
    );
    toast.error(errorData?.message);
    return Promise.reject(errorData);
  } else {
    //for rest all json or blob responses
    return Promise.reject({ ...error?.response?.data });
  }
};

const successHandler = (response) => {
  spinnerService.hide("globalSpinner"); //hide global spinner
  return response;
};
networkService.interceptors.response.use(successHandler, errorHandler);
networkServiceMock.interceptors.response.use(successHandler, errorHandler);
