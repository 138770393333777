import { networkService } from "services/networkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,  
  METHOD_DROPDOWN,
  METHOD_SAVE_ALL
} from "constants/admin/masters/legacyProducts";
class legacyProductService {
  //array methods
  getAll = (data) => {
    return networkService
      .post(METHOD_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (id) => {
    const updatedMethodName = METHOD_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, id) => {
    const updatedMethodName = METHOD_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (id, is_delete = false) => {
    const updatedMethodName = METHOD_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };  
  getAllDropdown = (id=0) => {
    let updatedMethodName=METHOD_DROPDOWN;
    if(id>0){      
      updatedMethodName += id+'/';
    }
    
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  saveAll = (id,payload) => {
    const updatedMethodName = METHOD_SAVE_ALL.replace("{ID}", id);
    return networkService
      .post(updatedMethodName,payload)
      .then((response) => response.data);
  }

}
export default legacyProductService;
