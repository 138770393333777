import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH_200, MAX_TEXT_LENGTH } from "constants/common";

//vROPS Release
export const METHOD_VROPS_RELEASE_LIST = 'vropsrelease/pageable/';
export const METHOD_VROPS_RELEASE_CREATE = 'vropsrelease/';
export const METHOD_VROPS_RELEASE_UPDATE = 'vropsrelease/{ID}/';
export const METHOD_VROPS_RELEASE_DELETE = 'vropsrelease/{ID}/';
export const METHOD_VROPS_RELEASE_DETAIL = 'vropsrelease/{ID}/';

//Management pack
export const METHOD_MANAGEMENT_PACK_LIST = 'managementpack/pageable/';
export const METHOD_MANAGEMENT_PACK_CREATE = 'managementpack/';
export const METHOD_MANAGEMENT_PACK_UPDATE = 'managementpack/{ID}/';
export const METHOD_MANAGEMENT_PACK_DELETE = 'managementpack/{ID}/';
export const METHOD_MANAGEMENT_PACK_DETAIL = 'managementpack/{ID}/';

//Management pack version
export const METHOD_MANAGEMENT_PACK_VERSION_LIST = 'managementpackversion/pageable/';
export const METHOD_MANAGEMENT_PACK_VERSION_CREATE = 'managementpackversion/';
export const METHOD_MANAGEMENT_PACK_VERSION_UPDATE = 'managementpackversion/{ID}/';
export const METHOD_MANAGEMENT_PACK_VERSION_DELETE = 'managementpackversion/{ID}/';
export const METHOD_MANAGEMENT_PACK_VERSION_DETAIL = 'managementpackversion/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;
export const TEXT_MAX_LENGTH_200=MAX_TEXT_LENGTH_200;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;