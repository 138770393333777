const FILTERS = {
    analyzer: {
        analyzer: {
            title: 'Analyzer Version',
            icon: 'server.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        },
        virtualization_software: {
            title: 'Virtualization Software',
            icon: 'os_kernel.png'
        },
        host: {
            title: 'Host',
            icon: 'array.png'
        },
        fc_switch: {
            title: 'Switch',
            icon: 'switch.png'
        },
        hypervisor: {
            title: 'Hypervisor',
            icon: 'hba.png'
        },
        storage_array: {
            title: 'Storage Array',
            icon: 'array_if.png'
        },
        hnas: {
            title: 'Hitachi NAS',
            icon: 'firmware.png'
        },
        tpa: {
            title: '3rd Party Array',
            icon: 'hba_driver.png'
        },
        jdk: {
            title: 'Analyzer JDK',
            iconClass: 'project-diagram'
        },
        detail_view_jdk: {
            title: 'Detail View JDK',
            iconClass: 'network-wired'
        }
    }
}
export default FILTERS;