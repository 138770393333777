import { networkService } from "services/networkService";
import {
  METHOD_HSPP_VERSION_LIST,
  METHOD_HSPP_VERSION_CREATE,
  METHOD_HSPP_VERSION_UPDATE,
  METHOD_HSPP_VERSION_DELETE,
  METHOD_HSPP_VERSION_DETAIL,
} from "constants/admin/masters/hspp";

class HsppService {
  // Hspp version
  getAllHSPPVersion = (data) => {
    return networkService
      .post(METHOD_HSPP_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getHSPPVersionDetail = (id) => {
    const updatedMethodName = METHOD_HSPP_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHSPPVersion = (data) => {
    return networkService
      .post(METHOD_HSPP_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateHSPPVersion = (data, id) => {
    const updatedMethodName = METHOD_HSPP_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHSPPVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HSPP_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}

export default HsppService;
