import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-date-picker";
import {
  MSG_DELETE_RECORD,
  MSG_ROLLBACK_RECORD,
  MSG_UPDATE_RECORD,
  MSG_WARNING_ROLLBACK,
} from "constants/admin/masters/eosl";
import eoslService from "services/admin/masters/eoslService";
import EoslForm from "./EoslForm";
import Toggle from "components/shared/Toggle";
import { toast } from "react-toastify";
import { handleRefresh } from "utils/masterHelpers";
import { connect } from "react-redux";
import { convertToFormat, paginateArray } from "utils/utils";
import MaterialTable from "material-table";
import { PROD_ENVS } from "constants/common";
import { getEoslMasterProducts } from "actions/commonActions";
import _ from "lodash";
const serviceObj = new eoslService();
class EoslMapping extends Component {
  constructor(props) {
    super(props);
    this.handleRefresh = handleRefresh.bind(this);

    this.todayDT = new Date();
    this.tomorrowDT = new Date(this.todayDT);
    this.tomorrowDT.setDate(this.tomorrowDT.getDate() + 1);

    this.state = {
      refreshToken: Date.now(),
      currentSearchText: "",
      showModal: false,
      toggleState: "0",
      columns: [
        { title: "Name", field: "master_name", editable: "never" },
        {
          title: "Master EOSL Date",
          field: "master_eosl_date",
          render: (rowData) =>
            convertToFormat(rowData?.master_eosl_date, "MM/DD/YYYY"),
          editComponent: (props) => (
            <DatePicker
              name="master_eosl_date"
              onChange={(e) => props.onChange(e)}
              value={new Date(props.value)}
              className="form-control"
              format={"MM/dd/y"}
              clearIcon={null}
            />
          ),
        },
        {
          title: "Datapath EOSL Date",
          field: "datapath_eosl_date",
          render: (rowData) =>
            convertToFormat(rowData?.datapath_eosl_date, "MM/DD/YYYY"),
          editComponent: (props) => (
            <DatePicker
              name="datapath_eosl_date"
              onChange={(e) => props.onChange(e)}
              value={new Date(props.value)}
              minDate={
                PROD_ENVS.includes(
                  process.env.REACT_APP_CUSTOM_NODE_ENV
                )
                  ? this.tomorrowDT
                  : null
              }
              className="form-control"
              format={"MM/dd/y"}
              clearIcon={null}
            />
          ),
        },
        { title: "Product Name", field: "product_name", editable: "never" }
      ],
      actions: [(row) => this.decideAction(row, "undo")],
    };
  }

  componentDidMount(){
    if(_.isEmpty(this.props?.eoslMasterProducts)){
      this.props.getEoslMasterProducts();
    }
  }

  decideAction = (rowData, actionType) => {
    if (actionType === "undo") {
      return rowData.eosl_status === 2
        ? {
            icon: actionType,
            tooltip: "Rollback Record",
            onClick: (event, rowData) => {
              if (
                window.confirm(
                  MSG_WARNING_ROLLBACK.replace("ITEM_NAME", rowData.master_name)
                )
              ) {
                serviceObj.rollback(rowData.id).then((response) => {
                  toast.success(response.message || MSG_ROLLBACK_RECORD);
                  this.handleRefresh();
                });
              }
            },
          }
        : null;
    }
  };

  handleModalClose = (e) => {
    this.setState({
      showModal: false,
      currentSearchText: "",
      toggleState: "0",
    });
  };
  handleModalOpen = (e) => {
    this.setState({
      showModal: true,
      currentSearchText: "",
      toggleState: "0",
    });
  };
  toggleChangehandler = (toggleState) => {
    this.setState({
      toggleState,
      refreshToken: Date.now(),
    });
  };
  checkIsQuotedString = (str) => {
    if (
      (str[0] === "'" && str[str.length - 1] === "'") ||
      (str[0] === '"' && str[str.length - 1] === '"')
    ) {
      return true;
    }
    return false;
  };
  onMasterSelect = async (data) => {
    await this.setState({
      currentSearchText: data?.label ? `"${data?.label}"` : "",
      toggleState: "0",
      refreshToken: Date.now(),
    });
  };
  filterData = (allData, searchTerm, exactSearch = false) => {
    searchTerm = searchTerm.toLowerCase();
    const columns = this.getColumns().map((c) => c.field);
    if (exactSearch) {
      //exact match
      return allData.filter((row, i) => {
        let match = false;
        for (let column of columns) {
          if (row[column]) {
            if (column.indexOf("date") > -1) {
              //kind of date column
              if (
                convertToFormat(row[column], "MM/DD/YYYY").toString() ===
                searchTerm
              ) {
                match = true;
                break;
              }
            } else if (row[column].toString().toLowerCase() === searchTerm) {
              match = true;
              break;
            }
          }
        }
        return match;
      });
    } else {
      //like match
      return allData.filter((row) => {
        let match = false;
        for (let column of columns) {
          if (row[column]) {
            if (column.indexOf("date") > -1) {
              //kind of date column
              if (
                convertToFormat(row[column], "MM/DD/YYYY")
                  .toString()
                  .indexOf(searchTerm) > -1
              ) {
                match = true;
                break;
              }
            } else if (
              row[column].toString().toLowerCase().indexOf(searchTerm) > -1
            ) {
              match = true;
              break;
            }
          }
        }
        return match;
      });
    }
  };
  handleData = async (query) => {
    return new Promise(async (resolve, reject) => {
      await this.setState({
        currentSearchText: query.search,
      });

      serviceObj
        .getAllEosl({
          masterdetail_id: this.props.masterDetailId,
          ...this.props?.additionalParams,
        })
        .then(async (response) => {
          let allData =
            this.state.toggleState === ""
              ? response
              : response.filter(
                  (r) => r.eosl_status === parseInt(this.state.toggleState)
                );
          if (query.search) {
            if (this.checkIsQuotedString(query.search)) {
              //match exact
              const searchTerm = query.search.slice(1, query.search.length - 1);
              allData = this.filterData(allData, searchTerm, true);
            } else {
              //match like
              allData = this.filterData(allData, query.search);
            }
          }
          resolve({
            totalCount: allData.length,
            page: query.page,
            data: paginateArray(allData, 10, query.page),
          });
        });
    });
  };

  getColumns = () => {
    const { columns, toggleState } = this.state;
    let columnList = [...columns];
    if(toggleState === "0"){
      //active tab
      columnList.push({
        title: "Created Date",
        field: "created_date",
        editable: "never",
        render: (rowData) =>
          convertToFormat(rowData?.created_date, "MM/DD/YYYY"),
      },
      {
        title: "Modified Date",
        field: "modified_date",
        editable: "never",
        render: (rowData) =>
          convertToFormat(rowData?.modified_date, "MM/DD/YYYY"),
      })
    }
    else if (toggleState === "2") {
      //processed tab
      columnList.push({
        title: "EOSL Count",
        field: "eosl_record_counter",
        editable: "never",
      },
      {
        title: "Modified Date",
        field: "modified_date",
        editable: "never",
        render: (rowData) =>
          convertToFormat(rowData?.modified_date, "MM/DD/YYYY"),
      },
      {
        title: "EOSL Processed Date",
        field: "eosl_processed_date ",
        editable: "never",
        render: (rowData) =>
          convertToFormat(rowData?.eosl_processed_date, "MM/DD/YYYY"),
      });
    }
    else if(toggleState === "1"){
      //deleted tab
      columnList.push({
        title: "Created Date",
        field: "created_date",
        editable: "never",
        render: (rowData) =>
          convertToFormat(rowData?.created_date, "MM/DD/YYYY"),
      },
      {
        title: "Deleted Date",
        field: "modified_date",
        editable: "never",
        render: (rowData) =>
          convertToFormat(rowData?.modified_date, "MM/DD/YYYY"),
      })
    }
    else if (toggleState === "3") {
      //rollback tab
      columnList.push({
        title: "Rollback Count",
        field: "eosl_rollback_record_counter",
        editable: "never",
      },
      {
        title: "EOSL Processed Date",
        field: "eosl_processed_date ",
        editable: "never",
        render: (rowData) =>
          convertToFormat(rowData?.eosl_processed_date, "MM/DD/YYYY"),
      },
      {
        title: "EOSL Rollback Date",
        field: "eosl_rollback_date ",
        editable: "never",
        render: (rowData) =>
          convertToFormat(rowData?.eosl_rollback_date, "MM/DD/YYYY"),
      }
      );
    }
    return columnList;
  };

  render() {
    const { showModal, refreshToken, toggleState, actions } = this.state;
    const { masterTitle, eoslMasterProducts } = this.props;
    const columns = this.getColumns(); //according to tab we are modifying columns
    return (
      <>
        {
          <Button onClick={this.handleModalOpen} variant="link">
            <i className="fa fa-calendar" aria-hidden="true"></i> EOSL
          </Button>
        }
        <Modal
          show={showModal}
          scrollable={true}
          size={"xl"}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Manage {masterTitle + " EOSL"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 text-left mb-3">
                {eoslMasterProducts ? (
                  <EoslForm
                    additionalParams={this.props?.additionalParams}
                    onMasterSelect={this.onMasterSelect}
                    masterDetailId={this.props.masterDetailId}
                    masterKey={this.props.masterKey}
                    productInfo={
                      eoslMasterProducts?.find(
                        (r) => r.master_name === this.props.slug
                      )?.related_support_matrix
                    }
                    handleRefresh={this.handleRefresh}
                  />
                ) : null}
              </div>
            </div>
            <div className="row pb-1">
              <div className="col-md-12 text-left">
                <Toggle
                  variant="secondary"
                  triggerHandler={this.toggleChangehandler}
                  default={toggleState}
                  options={[
                    { name: "Active", value: "0" },
                    { name: "Processed", value: "2" },
                    { name: "Deleted", value: "1" },
                    { name: "Rollback", value: "3" },
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-left">
                <MaterialTable
                  columns={columns}
                  actions={actions}
                  components={{
                    OverlayLoading: (props) => <div></div>,
                  }}
                  options={{
                    actionsColumnIndex: -1,
                    debounceInterval: 1000,
                    toolbar: true,
                    filtering: false,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [10],
                    headerStyle: { backgroundColor: "#e9ecef" },
                    searchText:
                      this.state.currentSearchText !== ""
                        ? this.state.currentSearchText
                        : "",
                  }}
                  title={masterTitle + " EOSL"}
                  data={this.handleData}
                  key={refreshToken}
                  icons={{
                    Delete: () => (
                      <span className="material-icons MuiIcon-root">
                        delete
                      </span>
                    ),
                  }}
                  editable={
                    this.state.toggleState === "0"
                      ? {
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                //update code here
                                let datapath_eosl_date =
                                  newData.datapath_eosl_date;
                                let master_eosl_date = newData.master_eosl_date;
                                if (datapath_eosl_date?.getMonth) {
                                  datapath_eosl_date =
                                    datapath_eosl_date.toISOString();
                                }
                                if (master_eosl_date?.getMonth) {
                                  master_eosl_date =
                                    master_eosl_date.toISOString();
                                }
                                const payload = {
                                  datapath_eosl_date: convertToFormat(
                                    datapath_eosl_date,
                                    "YYYY-MM-DD"
                                  ),
                                  master_eosl_date: convertToFormat(
                                    master_eosl_date,
                                    "YYYY-MM-DD"
                                  ),
                                };
                                serviceObj
                                  .update(payload, newData.id)
                                  .then((response) => {
                                    toast.success(
                                      response.message || MSG_UPDATE_RECORD
                                    );
                                    resolve();
                                  })
                                  .catch(function (err) {
                                    for (const [field, msg] of Object.entries(
                                      err
                                    )) {
                                      toast.error(`${msg}`, { field });
                                    }
                                    resolve();
                                  });
                              }, 1000);
                            }),
                          onRowDelete: (rowData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                //delete code here
                                serviceObj
                                  .delete(rowData.id)
                                  .then((response) => {
                                    toast.success(
                                      response.message || MSG_DELETE_RECORD
                                    );
                                    resolve();
                                  });
                              }, 1000);
                            }),
                        }
                      : null
                  }
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.commonReducer,
});

const mapDispatchToProps = dispatch => ({
  getEoslMasterProducts: () => dispatch(getEoslMasterProducts()),
});

export default connect(mapStateToProps,mapDispatchToProps)(EoslMapping);
