import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//Ops Center Version methods
export const METHOD_OPS_CENTER_VERSION_LIST = 'opscenterversion/pageable/';
export const METHOD_OPS_CENTER_VERSION_CREATE = 'opscenterversion/';
export const METHOD_OPS_CENTER_VERSION_UPDATE = 'opscenterversion/{ID}/';
export const METHOD_OPS_CENTER_VERSION_DELETE = 'opscenterversion/{ID}/';
export const METHOD_OPS_CENTER_VERSION_DETAIL = 'opscenterversion/{ID}/';
export const METHOD_OPS_CENTER_VERSION_DROP = 'opscenterversion/drop/';

//protector version methods
export const METHOD_PROTECTOR_VERSION_LIST = 'protectorversion/pageable/';
export const METHOD_PROTECTOR_VERSION_CREATE = 'protectorversion/';
export const METHOD_PROTECTOR_VERSION_UPDATE = 'protectorversion/{ID}/';
export const METHOD_PROTECTOR_VERSION_DELETE = 'protectorversion/{ID}/';
export const METHOD_PROTECTOR_VERSION_DETAIL = 'protectorversion/{ID}/';
export const METHOD_PROTECTOR_VERSION_DROP = 'protectorversion/drop/';

//Adoptable Technologies methods
export const METHOD_ADOPTABLE_TECHNOLOGIES_LIST = 'adoptabletechnologies/pageable/';
export const METHOD_ADOPTABLE_TECHNOLOGIES_CREATE = 'adoptabletechnologies/';
export const METHOD_ADOPTABLE_TECHNOLOGIES_UPDATE = 'adoptabletechnologies/{ID}/';
export const METHOD_ADOPTABLE_TECHNOLOGIES_DELETE = 'adoptabletechnologies/{ID}/';
export const METHOD_ADOPTABLE_TECHNOLOGIES_DETAIL = 'adoptabletechnologies/{ID}/';
export const METHOD_ADOPTABLE_TECHNOLOGIES_DROP = 'adoptabletechnologies/drop/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;