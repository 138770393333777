import {
  MSG_DELETE_WARNING,
  MSG_UNDO_DELETE_WARNING,
  MSG_RECORD_CREATE,
  MSG_RECORD_UPDATE,
  MSG_RECORD_DELETE,
  MSG_RECORD_UNDO,
  MIN_TEXT_LENGTH,
  MAX_TEXT_LENGTH,
} from "constants/common";

//Hitachi Product Version
export const METHOD_HITACHI_PRODUCT_VERSION_LIST = 'hitachiproductversion/pageable/';
export const METHOD_HITACHI_PRODUCT_VERSION_CREATE = 'hitachiproductversion/';
export const METHOD_HITACHI_PRODUCT_VERSION_UPDATE = 'hitachiproductversion/{ID}/';
export const METHOD_HITACHI_PRODUCT_VERSION_DELETE = 'hitachiproductversion/{ID}/';
export const METHOD_HITACHI_PRODUCT_VERSION_DETAIL = 'hitachiproductversion/{ID}/';

// Integration Point(s)
export const METHOD_INTEGRATION_POINT_LIST = "integrationpoints/pageable/";
export const METHOD_INTEGRATION_POINT_CREATE = "integrationpoints/";
export const METHOD_INTEGRATION_POINT_UPDATE = "integrationpoints/{ID}/";
export const METHOD_INTEGRATION_POINT_DELETE = "integrationpoints/{ID}/";
export const METHOD_INTEGRATION_POINT_DETAIL = "integrationpoints/{ID}/";

// Hitachi Hardware Product

export const METHOD_HITACHI_HARDWARE_PRODUCT_LIST =
  "hitachihardwareproduct/pageable/";
export const METHOD_HITACHI_HARDWARE_PRODUCT_CREATE = "hitachihardwareproduct/";
export const METHOD_HITACHI_HARDWARE_PRODUCT_UPDATE =
  "hitachihardwareproduct/{ID}/";
export const METHOD_HITACHI_HARDWARE_PRODUCT_DELETE =
  "hitachihardwareproduct/{ID}/";
export const METHOD_HITACHI_HARDWARE_PRODUCT_DETAIL =
  "hitachihardwareproduct/{ID}/";

//text box length
export const TEXT_MIN_LENGTH = MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH = MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;
