import roles_list from "resources/json/roles.json";
//Roles list
export const ROLES = {};
roles_list.map((r) => (ROLES[r.slug] = r.id));

//menu prefrences
export const MENU_CATEGORIES = {
  PUBLIC_MENU: 0,
  USER_MENU: 1,
  ADMIN_MENU: 2,
};
export const DEFAULT_MENU_PREFRENCE = MENU_CATEGORIES.ADMIN_MENU;
export const SET_MENU_PREFRENCE = "MENU_PREFRENCE";
export const MENU_PREFRENCE_LOCAL_STORAGE = "OMNI-GUIDE-MENU-PREFRENCE";

//role based menu data
export const MENUS_DATA = [
  /*
  //public menus
  {
    title: "Home",
    menuCategory: MENU_CATEGORIES.PUBLIC_MENU,
    hasSubmenu: false,
    route: "/",
  },
  */
  //user menus
  {
    title: "Home",
    menuCategory: MENU_CATEGORIES.USER_MENU,
    hasSubmenu: false,
    restrictedUsers: [],
    route: "/",
  },
  //admin menus
  {
    title: "Home",
    menuCategory: MENU_CATEGORIES.ADMIN_MENU,
    hasSubmenu: false,
    restrictedUsers: [],
    route: "/",
  },
  {
    title: "Datapaths",
    menuCategory: MENU_CATEGORIES.ADMIN_MENU,
    requiredRoles: [ROLES.SYS_ADMIN, ROLES.PROD_ADMIN],
    hasSubmenu: true,
    restrictedUsers: [],
    childrens:[
      {
        title: "Bulk Upload",
        requiredRoles: [ROLES.SYS_ADMIN, ROLES.PROD_ADMIN],
        hasSubmenu: false,
        route: "/admin/bulk-upload"
      },
      {
        title: "Manage Datapaths",
        requiredRoles: [ROLES.SYS_ADMIN, ROLES.PROD_ADMIN],
        hasSubmenu: false,
        route: "/admin/datapath-export"
      }
    ]
  },
  {
    title: "Download Datapath",
    menuCategory: MENU_CATEGORIES.ADMIN_MENU,
    hasSubmenu: false,
    requiredRoles: [ROLES.SYS_ADMIN],
    restrictedUsers: [],
    route: "/admin/export-datapath",
  },
  {
    title: "Manage Users",
    menuCategory: MENU_CATEGORIES.ADMIN_MENU,
    hasSubmenu: false,
    requiredRoles: [ROLES.SYS_ADMIN],
    restrictedUsers: [],
    route: "/admin/users",
  },
  {
    title: "Settings",
    menuCategory: MENU_CATEGORIES.ADMIN_MENU,
    requiredRoles: [ROLES.SYS_ADMIN],
    hasSubmenu: true,
    restrictedUsers: [],
    childrens:[
      {
        title: "Admin Settings",
        menuCategory: MENU_CATEGORIES.ADMIN_MENU,
        hasSubmenu: false,
        requiredRoles: [ROLES.SYS_ADMIN],
        route: "/admin/settings",
      },
      {
        title: "Job Progress",
        menuCategory: MENU_CATEGORIES.ADMIN_MENU,
        hasSubmenu: false,
        requiredRoles: [ROLES.SYS_ADMIN],
        route: "/admin/master/job-progress",
      }
    ]
  } 
  //private menus
];