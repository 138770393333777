const FILTERS = {
    "ucp-vdi": {
        vdi_solution_type: {
            title: 'VDI Solution Type',
            icon: 'switch.png'
        },
        server_model: {
            title: 'Server Model',
            icon: 'server.png'
        },
        cpu_generation: {
            title: 'CPU Generation',
            icon: 'os.png'
        },
        operating_system: {
            title: 'Operating System',
            icon: 'os_kernel.png'
        },
        vdi_software_version: {
            title: 'VDI Software Version',
            iconClass: 'project-diagram'
        },
        fc_switch: {
            title: 'FC Switch',
            icon: 'switch.png'
        },
        block_storage: {
            title: 'Storage',
            icon: 'switch.png'
        },
        leaf_switch: {
            title: 'Leaf Switch',
            icon: 'firmware.png'
        },
        spine_switch: {
            title: 'Spine Switch',
            iconClass: 'network-wired'
        },
        management_switch: {
            title: 'Management Switch',
            icon: 'switch.png'
        },
    }
}
export default FILTERS;