import { networkService } from "services/networkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,
  METHOD_VENDOR_LIST,
  METHOD_VENDOR_DROPDOWN,
  METHOD_VENDOR_CREATE,
  METHOD_VENDOR_UPDATE,
  METHOD_VENDOR_DELETE,
  METHOD_VENDOR_DETAIL,
  METHOD_FIRMWARE_SERIES_LIST,
  METHOD_FIRMWARE_SERIES_CREATE,
  METHOD_FIRMWARE_SERIES_UPDATE,
  METHOD_FIRMWARE_SERIES_DELETE,
  METHOD_FIRMWARE_SERIES_DETAIL,
  METHOD_FIRMWARE_LIST,
  METHOD_FIRMWARE_CREATE,
  METHOD_FIRMWARE_UPDATE,
  METHOD_FIRMWARE_DELETE,
  METHOD_FIRMWARE_DETAIL,
  METHOD_FIRMWARE_DROPDOWN,
  METHOD_FIRMWARE_MAPPING_LIST,
  METHOD_FIRMWARE_MAPPING_CREATE,
  METHOD_FIRMWARE_MAPPING_UPDATE,
  METHOD_FIRMWARE_MAPPING_DELETE,
  METHOD_FIRMWARE_MAPPING_DETAIL,
} from "constants/admin/masters/switch";
class switchService {
  //switch methods
  getAll = (data) => {
    return networkService
      .post(METHOD_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (id) => {
    const updatedMethodName = METHOD_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, id) => {
    const updatedMethodName = METHOD_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (id, is_delete = false) => {
    const updatedMethodName = METHOD_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //switch vendor methods
  getAllVendors = (data) => {
    return networkService
      .post(METHOD_VENDOR_LIST, data)
      .then((response) => response.data);
  };
  getAllVendorDropdown= () => {
    return networkService
      .get(METHOD_VENDOR_DROPDOWN)
      .then((response) => response.data);
  };
  getVendorDetail = (id) => {
    const updatedMethodName = METHOD_VENDOR_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createVendor = (data) => {
    return networkService
      .post(METHOD_VENDOR_CREATE, data)
      .then((response) => response.data);
  };
  updateVendor = (data, id) => {
    const updatedMethodName = METHOD_VENDOR_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVendor = (id, is_delete = false) => {
    const updatedMethodName = METHOD_VENDOR_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //Switch Firmware Series methods
  getAllFirmwareSeries = (data) => {
    return networkService
      .post(METHOD_FIRMWARE_SERIES_LIST, data)
      .then((response) => response.data);
  };
  firmwareSeriesDetail = (id) => {
    const updatedMethodName = METHOD_FIRMWARE_SERIES_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createFirmwareSeries = (data) => {
    return networkService
      .post(METHOD_FIRMWARE_SERIES_CREATE, data)
      .then((response) => response.data);
  };
  updateFirmwareSeries = (data, id) => {
    const updatedMethodName = METHOD_FIRMWARE_SERIES_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteFirmwareSeries = (id, is_delete = false) => {
    const updatedMethodName = METHOD_FIRMWARE_SERIES_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //Switch Firmware methods
  getAllFirmware = (data) => {
    return networkService
      .post(METHOD_FIRMWARE_LIST, data)
      .then((response) => response.data);
  };
  firmwareDetail = (id) => {
    const updatedMethodName = METHOD_FIRMWARE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createFirmware = (data) => {
    return networkService
      .post(METHOD_FIRMWARE_CREATE, data)
      .then((response) => response.data);
  };
  updateFirmware = (data, id) => {
    const updatedMethodName = METHOD_FIRMWARE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteFirmware = (id, is_delete = false) => {
    const updatedMethodName = METHOD_FIRMWARE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  getAllFirmwareDropdown= () => {
    return networkService
      .get(METHOD_FIRMWARE_DROPDOWN)
      .then((response) => response.data);
  };

  //Switch Firmware mapping methods
  getAllFirmwareMapping = (data) => {
    return networkService
      .post(METHOD_FIRMWARE_MAPPING_LIST, data)
      .then((response) => response.data);
  };
  firmwareMappingDetail = (id) => {
    const updatedMethodName = METHOD_FIRMWARE_MAPPING_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createFirmwareMapping = (data) => {
    return networkService
      .post(METHOD_FIRMWARE_MAPPING_CREATE, data)
      .then((response) => response.data);
  };
  updateFirmwareMapping = (data, id) => {
    const updatedMethodName = METHOD_FIRMWARE_MAPPING_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteFirmwareMapping = (id, is_delete = false) => {
    const updatedMethodName = METHOD_FIRMWARE_MAPPING_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default switchService;
