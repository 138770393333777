const FILTERS = {
  "data-protection": {
    isv: {
      title: "ISV Name",
      icon: "server.png",
    },
    isv_application: {
      title: "ISV Application Name",
      icon: "server.png",
    },
    isv_application_version: {
      title: "ISV Application Version",
      icon: "server.png",
    },
    hitachi_hardware_product: {
      title: "Hitachi Hardware Product",
      icon: "os.png",
    },
    hitachi_product_version: {
      title: "Hitachi Product Version",
      icon: "os.png",
    },
    protocol: {
      title: "Protocol",
      iconClass: "network-wired",
    },
    integration_points: {
      title: "Integration Point(s)",
      icon: "os.png",
    },
    certification: {
      title: "Certification",
      icon: "os_kernel.png",
    },
  },
};
export default FILTERS;
