import { networkService } from "services/networkService";
import {
  METHOD_RAID_CARD_LIST,
  METHOD_RAID_CARD_CREATE,
  METHOD_RAID_CARD_UPDATE,
  METHOD_RAID_CARD_DELETE,
  METHOD_RAID_CARD_DETAIL,  
  METHOD_RAID_CARD_DRIVER_LIST,
  METHOD_RAID_CARD_DRIVER_CREATE,
  METHOD_RAID_CARD_DRIVER_UPDATE,
  METHOD_RAID_CARD_DRIVER_DELETE,
  METHOD_RAID_CARD_DRIVER_DETAIL,  
  METHOD_RAID_CARD_FIRMWARE_LIST,
  METHOD_RAID_CARD_FIRMWARE_CREATE,
  METHOD_RAID_CARD_FIRMWARE_UPDATE,
  METHOD_RAID_CARD_FIRMWARE_DELETE,
  METHOD_RAID_CARD_FIRMWARE_DETAIL,
  METHOD_ORACLE_SOLUTION_TYPE_LIST,
  METHOD_ORACLE_SOLUTION_TYPE_DETAIL,
  METHOD_ORACLE_SOLUTION_TYPE_CREATE,
  METHOD_ORACLE_SOLUTION_TYPE_UPDATE,
  METHOD_ORACLE_SOLUTION_TYPE_DELETE,
  METHOD_ORACLE_RELEASE_VERSION_LIST,
  METHOD_ORACLE_RELEASE_VERSION_DETAIL,
  METHOD_ORACLE_RELEASE_VERSION_CREATE,
  METHOD_ORACLE_RELEASE_VERSION_UPDATE,
  METHOD_ORACLE_RELEASE_VERSION_DELETE,
} from "constants/admin/masters/oracle";
class OracleService {

  //Raid Card methods
  getAllRaidCard = (data) => {
    return networkService
      .post(METHOD_RAID_CARD_LIST, data)
      .then((response) => response.data);
  };
  getRaidCardDetail = (id) => {
    const updatedMethodName = METHOD_RAID_CARD_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createRaidCard = (data) => {
    return networkService
      .post(METHOD_RAID_CARD_CREATE, data)
      .then((response) => response.data);
  };
  updateRaidCard = (data, id) => {
    const updatedMethodName = METHOD_RAID_CARD_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteRaidCard = (id, is_delete = false) => {
    const updatedMethodName = METHOD_RAID_CARD_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };   
  //Raid Card Driver methods
  getAllRaidCardDriver = (data) => {
    return networkService
      .post(METHOD_RAID_CARD_DRIVER_LIST, data)
      .then((response) => response.data);
  };
  getRaidCardDriverDetail = (id) => {
    const updatedMethodName = METHOD_RAID_CARD_DRIVER_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createRaidCardDriver = (data) => {
    return networkService
      .post(METHOD_RAID_CARD_DRIVER_CREATE, data)
      .then((response) => response.data);
  };
  updateRaidCardDriver = (data, id) => {
    const updatedMethodName = METHOD_RAID_CARD_DRIVER_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteRaidCardDriver = (id, is_delete = false) => {
    const updatedMethodName = METHOD_RAID_CARD_DRIVER_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
    //RaidCardFirmware methods
    getAllRaidCardFirmware = (data) => {
      return networkService
        .post(METHOD_RAID_CARD_FIRMWARE_LIST, data)
        .then((response) => response.data);
    };
    getRaidCardFirmwareDetail = (id) => {
      const updatedMethodName = METHOD_RAID_CARD_FIRMWARE_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createRaidCardFirmware = (data) => {
      return networkService
        .post(METHOD_RAID_CARD_FIRMWARE_CREATE, data)
        .then((response) => response.data);
    };
    updateRaidCardFirmware = (data, id) => {
      const updatedMethodName = METHOD_RAID_CARD_FIRMWARE_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteRaidCardFirmware = (id, is_delete = false) => {
      const updatedMethodName = METHOD_RAID_CARD_FIRMWARE_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName, { data: { is_delete } })
        .then((response) => response.data);
    };

  //Oracle solution type methods
  getAllOracleSolutionType = (data) => {
    return networkService
      .post(METHOD_ORACLE_SOLUTION_TYPE_LIST, data)
      .then((response) => response.data);
  };
  getOracleSolutionTypeDetail = (id) => {
    const updatedMethodName = METHOD_ORACLE_SOLUTION_TYPE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createOracleSolutionType = (data) => {
    return networkService
      .post(METHOD_ORACLE_SOLUTION_TYPE_CREATE, data)
      .then((response) => response.data);
  };
  updateOracleSolutionType = (data, id) => {
    const updatedMethodName = METHOD_ORACLE_SOLUTION_TYPE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteOracleSolutionType = (id, is_delete = false) => {
    const updatedMethodName = METHOD_ORACLE_SOLUTION_TYPE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //Oracle release version methods
  getAllOracleReleaseVersion = (data) => {
    return networkService
      .post(METHOD_ORACLE_RELEASE_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getOracleReleaseVersionDetail = (id) => {
    const updatedMethodName = METHOD_ORACLE_RELEASE_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createOracleReleaseVersion = (data) => {
    return networkService
      .post(METHOD_ORACLE_RELEASE_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateOracleReleaseVersion = (data, id) => {
    const updatedMethodName = METHOD_ORACLE_RELEASE_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteOracleReleaseVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_ORACLE_RELEASE_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default OracleService;