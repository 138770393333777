import { networkService } from "services/networkService";
import {
  METHOD_ANALYZERVERSION_LIST,
  METHOD_ANALYZERVERSION_CREATE,
  METHOD_ANALYZERVERSION_UPDATE,
  METHOD_ANALYZERVERSION_DELETE,
  METHOD_ANALYZERVERSION_DETAIL,
  METHOD_ANALYZERVERSION_DROPDOWN,  
  METHOD_VIRTUALIZATIONSOFTWARE_LIST,
  METHOD_VIRTUALIZATIONSOFTWARE_CREATE,
  METHOD_VIRTUALIZATIONSOFTWARE_UPDATE,
  METHOD_VIRTUALIZATIONSOFTWARE_DELETE,
  METHOD_VIRTUALIZATIONSOFTWARE_DETAIL,   
  METHOD_HITACHINAS_LIST,
  METHOD_HITACHINAS_CREATE,
  METHOD_HITACHINAS_UPDATE,
  METHOD_HITACHINAS_DELETE,
  METHOD_HITACHINAS_DETAIL,
  METHOD_FCSWITCH_LIST,
  METHOD_FCSWITCH_CREATE,
  METHOD_FCSWITCH_UPDATE,
  METHOD_FCSWITCH_DELETE,
  METHOD_FCSWITCH_DETAIL,
  METHOD_ANALYZERMAPPER_LIST,
  METHOD_ANALYZERMAPPER_DETAIL,
  METHOD_ANALYZERMAPPER_DETAIL_REACTIVATE,
  METHOD_ANALYZERMAPPER_DELETE,
  METHOD_ANALYZERMAPPER_ADD,
  METHOD_ANALYZERMAPPER_UPDATE
} from "constants/admin/masters/analyzer";
class analyzerService {

  masters = [
    { id: 1, name: 'Operating System' },
    { id: 9, name: 'Analyzer JDK' },
    { id: 10, name: 'Detail View JDK' },
    { id: 2, name: 'Hypervisor' },
    { id: 3, name: 'Virtualization Software' },
    { id: 4, name: 'Hosts' },
    { id: 5, name: 'Switch' },
    { id: 6, name: 'Storage Array' },
    { id: 7, name: 'Hitachi NAS' },
    { id: 8, name: '3rd Party Array' }
  ]
  //Analyzer methods
  getAllAnalyzer = (data) => {

    return new Promise((resolve, reject) => {
      resolve({
        total: 8,
        items: this.masters
      })
    })


    /*return networkService
      .post(METHOD_ANALYZER_LIST, data)
      .then((response) => response.data);*/
  };
  getEditDetail = (masterId,recordId) => {
  const methodName = METHOD_ANALYZERMAPPER_DETAIL.replace("{masterId}", masterId).replace("{recordId}",recordId);
    return networkService
      .get(methodName)
      .then((response) => response.data);
  };
  getEditDetailForReactivate = (masterId,recordId) => {
    const methodName = METHOD_ANALYZERMAPPER_DETAIL_REACTIVATE.replace("{masterId}", masterId).replace("{recordId}",recordId);
      return networkService
        .get(methodName)
        .then((response) => response.data);
    };
  getAllInitialData = (masterId) => {

   const methodName=METHOD_ANALYZERMAPPER_LIST.replace("{masterId}",masterId)
    return networkService
      .get(methodName)
      .then((response) => response.data);

  }
  deleteAnalyzerMapping = (masterId,recordId, deleteType = 0) => {
    const methodName = METHOD_ANALYZERMAPPER_DELETE.replace("{masterId}", masterId).replace("{recordId}",recordId).replace("{deleteType}",deleteType);
    return networkService
      .delete(methodName)
      .then((response) => response.data);
  } 
  createAnalyzerMapping = (data,masterId) => {
    const methodName = METHOD_ANALYZERMAPPER_ADD.replace("{masterId}", masterId);
    return networkService
      .post(methodName, data)
      .then((response) => response.data);
  };
  updateAnalyzerMapping = (data,masterId,recordId) => {
    const methodName = METHOD_ANALYZERMAPPER_UPDATE.replace("{masterId}", masterId).replace("{recordId}",recordId);
    return networkService
      .post(methodName, data)
      .then((response) => response.data);
  };


  //AnalyzerVersion methods
  getAllAnalyzerVersion = (data) => {
   return networkService
     .post(METHOD_ANALYZERVERSION_LIST, data)
     .then((response) => response.data);
 };
 getAnalyzerVersionDetail = (id) => {
   const updatedMethodName = METHOD_ANALYZERVERSION_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createAnalyzerVersion = (data) => {
    return networkService
      .post(METHOD_ANALYZERVERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateAnalyzerVersion = (data, id) => {
    const updatedMethodName = METHOD_ANALYZERVERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteAnalyzerVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_ANALYZERVERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  getAllAnalyzerVersionDropdown = () => {
    return networkService
      .get(METHOD_ANALYZERVERSION_DROPDOWN)
      .then((response) => response.data);
  };

  //VirtualizationSoftware methods
  getAllVirtualizationSoftware = (data) => {
   return networkService
     .post(METHOD_VIRTUALIZATIONSOFTWARE_LIST, data)
     .then((response) => response.data);
 };
 getVirtualizationSoftwareDetail = (id) => {
   const updatedMethodName = METHOD_VIRTUALIZATIONSOFTWARE_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createVirtualizationSoftware = (data) => {
    return networkService
      .post(METHOD_VIRTUALIZATIONSOFTWARE_CREATE, data)
      .then((response) => response.data);
  };
  updateVirtualizationSoftware = (data, id) => {
    const updatedMethodName = METHOD_VIRTUALIZATIONSOFTWARE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVirtualizationSoftware = (id, is_delete = false) => {
    const updatedMethodName = METHOD_VIRTUALIZATIONSOFTWARE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  //HitachiNas methods
  getAllHitachiNas = (data) => {
   return networkService
     .post(METHOD_HITACHINAS_LIST, data)
     .then((response) => response.data);
 };
 getHitachiNasDetail = (id) => {
   const updatedMethodName = METHOD_HITACHINAS_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createHitachiNas = (data) => {
    return networkService
      .post(METHOD_HITACHINAS_CREATE, data)
      .then((response) => response.data);
  };
  updateHitachiNas = (data, id) => {
    const updatedMethodName = METHOD_HITACHINAS_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHitachiNas = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HITACHINAS_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  
  //FcSwitch methods
  getAllFcSwitch = (data) => {
   return networkService
     .post(METHOD_FCSWITCH_LIST, data)
     .then((response) => response.data);
 };
 getFcSwitchDetail = (id) => {
   const updatedMethodName = METHOD_FCSWITCH_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createFcSwitch = (data) => {
    return networkService
      .post(METHOD_FCSWITCH_CREATE, data)
      .then((response) => response.data);
  };
  updateFcSwitch = (data, id) => {
    const updatedMethodName = METHOD_FCSWITCH_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteFcSwitch = (id, is_delete = false) => {
    const updatedMethodName = METHOD_FCSWITCH_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default analyzerService;