import React, { Component } from "react";
class Home extends Component {
  render() {
    return ( 
        <div className="card-body">
          <h4 className="card-title">Home</h4>
          <p className="card-text">Welcome to Home page</p>
      </div>
    );
  }
}
export default Home;
