const FILTERS = {
  "ucp-baremetal": {
    ucp_platform: {
      title: "UCP Platform",
      icon: "server.png",
    },
    server_model: {
      title: "Server Model",
      icon: "server.png",
    },
    cpu_generation: {
      title: "CPU Generation",
      iconClass: "project-diagram",
    },
    server_software_version: {
      title: "Server Software Version",
      icon: "server.png",
    },
    operating_system: {
      title: "Operating System",
      icon: "os.png",
    },
    controller: {
      title: "Controller",
      icon: "server.png",
      dynamic: true,
    },
    raid_controller: {
      title: "raid_controller",
      icon: "server.png",
    },
    sas_controller: {
      title: "sas_controller",
      icon: "server.png",
    },
    ucp_platform_ucp_rs: {
      title: "UCP Platform",
      icon: "server.png",
    },
    ucp_platform_ucp_hc: {
      title: "UCP Platform",
      icon: "server.png",
    },
    ucp_platform_ucp_ci: {
      title: "UCP Platform",
      icon: "server.png",
    },
    nic: {
      title: "NIC",
      icon: "hba_driver.png",
    },
    hba: {
      title: "HBA ",
      icon: "hba.png",
    },

    boot_options: {
      title: "Boot Options",
      icon: "server.png",
      singleCheck: true,
    },
  },
};
export default FILTERS;
