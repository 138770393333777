import {
  MSG_DELETE_WARNING,
  MSG_UNDO_DELETE_WARNING,
  MSG_RECORD_CREATE,
  MSG_RECORD_UPDATE,
  MSG_RECORD_DELETE,
  MSG_RECORD_UNDO,
  MIN_TEXT_LENGTH,
  MAX_TEXT_LENGTH,
} from "constants/common";

// Bare Metal Solution Type

export const METHOD_BAREMETAL_SOLUTION_TYPE_LIST =
  "baremetalsolutiontype/pageable/";
export const METHOD_BAREMETAL_SOLUTION_TYPE_CREATE = "baremetalsolutiontype/";
export const METHOD_BAREMETAL_SOLUTION_TYPE_UPDATE =
  "baremetalsolutiontype/{ID}/";
export const METHOD_BAREMETAL_SOLUTION_TYPE_DELETE =
  "baremetalsolutiontype/{ID}/";
export const METHOD_BAREMETAL_SOLUTION_TYPE_DETAIL =
  "baremetalsolutiontype/{ID}/";

//Bare Metal Version

export const METHOD_BAREMETAL_VERSION_LIST = "baremetalversion/pageable/";
export const METHOD_BAREMETAL_VERSION_CREATE = "baremetalversion/";
export const METHOD_BAREMETAL_VERSION_UPDATE = "baremetalversion/{ID}/";
export const METHOD_BAREMETAL_VERSION_DELETE = "baremetalversion/{ID}/";
export const METHOD_BAREMETAL_VERSION_DETAIL = "baremetalversion/{ID}/";

//text box length
export const TEXT_MIN_LENGTH = MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH = MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;
