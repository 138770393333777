import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH_200, MAX_TEXT_LENGTH } from "constants/common";

//vRO Release
export const METHOD_VRO_RELEASE_LIST = 'vrorelease/pageable/';
export const METHOD_VRO_RELEASE_CREATE = 'vrorelease/';
export const METHOD_VRO_RELEASE_UPDATE = 'vrorelease/{ID}/';
export const METHOD_VRO_RELEASE_DELETE = 'vrorelease/{ID}/';
export const METHOD_VRO_RELEASE_DETAIL = 'vrorelease/{ID}/';

//vRO Plugin
export const METHOD_VRO_PLUGIN_LIST = 'vroplugin/pageable/';
export const METHOD_VRO_PLUGIN_CREATE = 'vroplugin/';
export const METHOD_VRO_PLUGIN_UPDATE = 'vroplugin/{ID}/';
export const METHOD_VRO_PLUGIN_DELETE = 'vroplugin/{ID}/';
export const METHOD_VRO_PLUGIN_DETAIL = 'vroplugin/{ID}/';

//plugin version
export const METHOD_PLUGIN_VERSION_LIST = 'pluginversion/pageable/';
export const METHOD_PLUGIN_VERSION_CREATE = 'pluginversion/';
export const METHOD_PLUGIN_VERSION_UPDATE = 'pluginversion/{ID}/';
export const METHOD_PLUGIN_VERSION_DELETE = 'pluginversion/{ID}/';
export const METHOD_PLUGIN_VERSION_DETAIL = 'pluginversion/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;
export const TEXT_MAX_LENGTH_200=MAX_TEXT_LENGTH_200;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;