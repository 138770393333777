import React from "react";
import { Route, Redirect } from "react-router-dom";
import authService from "../services/authService";
import _ from "lodash";
import { parseQueryParams } from "utils/utils";
import history from "../history";
import ALLRoles from "resources/json/roles.json";
const PrivateRoute = ({ component: Component, ...rest }) => {

  const userData = authService.userData;
  const userRoles = userData.id ? userData.roles.map((role) => role.id) : [];
  const userMasters = userData.id ? userData.master_menu_array : [];
  const urlData = parseQueryParams((history.location.search || window.location.search));

   // add static permission for some masters at ui level for system admin
   const systemAdminRole = ALLRoles.find((role) => role.slug === 'SYS_ADMIN')
   if(!_.isEmpty(_.intersection(userRoles,[systemAdminRole.id]))){
     userMasters.push('legacy-assets','job-progress');
   }
   // add static permission for some masters at ui level for system admin
   
  return (
    <Route
      {...rest}
      render={(props) =>
        authService.isAuthenticated === true ? (
          (!_.isEmpty(_.intersection(userRoles, rest.requiredRoles))) ? (
            //user roles and required roles satisfy
            (rest.masterName) ? (
              (!_.isEmpty(_.intersection(userMasters, [rest.masterName]))) ? (
                <Component {...props} />
              ) : (
                  <Redirect
                    to={{
                      pathname: "/403",
                      state: { from: props.location },
                    }}
                  />
                )
            ) : (<Component {...props} />)
          ) : (
              <Redirect
                to={{
                  pathname: "/403",
                  state: { from: props.location },
                }}
              />
            )
        ) : (
            (!urlData.t) ?
              (<Redirect
                to={{
                  pathname: "/sso_login",
                  state: { from: props.location },
                }}
              />) : null
          )
      }
    />
  );
};
export default PrivateRoute;
