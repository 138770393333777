import React from "react";
import { Link } from "react-router-dom";
const Breadcrumb = (props) => {

    const styleObj = {
        flexWrap: 'wrap'
    }
    return (
        <>
            <nav style={styleObj} aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {
                        props.data.map((row, index) => (
                            (props.data.length === index + 1) ? (
                                <li key={index} className="breadcrumb-item active" aria-current="page">{(!props.disableEditTitle) ? (props.isEdit ? "Edit" : "Add") : null} {row.title}</li>
                            ) : (
                                    <li key={index} className='breadcrumb-item'>
                                        <Link className="text-breadcrumb-link" to={row.url}>
                                            {row.title}
                                        </Link>
                                    </li>

                                )

                        ))
                    }
                </ol>
            </nav>
        </>
    );
}
export default Breadcrumb;
