const FILTERS = {
    uvm: {
        local_storage: {
            title: 'Local Storage',
            icon: 'os.png'
        },
        path_mode: {
            title: 'Path Mode',
            icon: 'os_kernel.png'
        },
        protocol: {
            title: 'Protocol',
            iconClass: 'network-wired'
        },
        alua_support: {
            title: 'Alua Support',
            iconClass: 'network-wired'
        },
        switch: {
            title: 'Switch',
            icon: 'switch.png'
        },
        switch_firmware: {
            title: 'Switch Firmware',
            icon: 'firmware.png'
        },
        external_storage: {
            title: 'External Storage',
            icon: 'server.png'
        }
    }
}
export default FILTERS;