import React from "react";
import { Link } from "react-router-dom";
function PageNotFound(props) {
  return (
    <>
      <div >
        <div className="card-body">
          <h4 className="card-title">
            {props.title || "Error 404 : Page not Found."}
          </h4>
          <p className="card-text">
            {props.description ||
              "This page is not available in this application"}
          </p>
          <Link className="btn btn-secondary" to="/">
            {'Click here to go Home '}
            <i className="fa fa-chevron-circle-right"></i>
          </Link>
        </div>
      </div>
    </>
  );
}
export default PageNotFound;
