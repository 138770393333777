import { networkService } from "services/networkService";
import {
  METHOD_HITACHI_PRODUCT_VERSION_LIST,
  METHOD_HITACHI_PRODUCT_VERSION_CREATE,
  METHOD_HITACHI_PRODUCT_VERSION_UPDATE,
  METHOD_HITACHI_PRODUCT_VERSION_DELETE,
  METHOD_HITACHI_PRODUCT_VERSION_DETAIL,
  METHOD_INTEGRATION_POINT_LIST,
  METHOD_INTEGRATION_POINT_CREATE,
  METHOD_INTEGRATION_POINT_UPDATE,
  METHOD_INTEGRATION_POINT_DELETE,
  METHOD_INTEGRATION_POINT_DETAIL,
  METHOD_HITACHI_HARDWARE_PRODUCT_LIST,
  METHOD_HITACHI_HARDWARE_PRODUCT_CREATE,
  METHOD_HITACHI_HARDWARE_PRODUCT_UPDATE,
  METHOD_HITACHI_HARDWARE_PRODUCT_DELETE,
  METHOD_HITACHI_HARDWARE_PRODUCT_DETAIL,
} from "constants/admin/masters/dataProtection";
class DataProtectionService {
  //HITACHI PRODUCT VERSION METHODS
  getAllHitachiProductVersion = (data) => {
    return networkService
      .post(METHOD_HITACHI_PRODUCT_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getHitachiProductVersionDetail = (id) => {
    const updatedMethodName = METHOD_HITACHI_PRODUCT_VERSION_DETAIL.replace(
      "{ID}",
      id
    );
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHitachiProductVersion = (data) => {
    return networkService
      .post(METHOD_HITACHI_PRODUCT_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateHitachiProductVersion = (data, id) => {
    const updatedMethodName = METHOD_HITACHI_PRODUCT_VERSION_UPDATE.replace(
      "{ID}",
      id
    );
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHitachiProductVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HITACHI_PRODUCT_VERSION_DELETE.replace(
      "{ID}",
      id
    );
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //  INTEGRATION_POINT METHODS
  getAllIntegrationPoint = (data) => {
    return networkService
      .post(METHOD_INTEGRATION_POINT_LIST, data)
      .then((response) => response.data);
  };
  getIntegrationPointDetail = (id) => {
    const updatedMethodName = METHOD_INTEGRATION_POINT_DETAIL.replace(
      "{ID}",
      id
    );
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createIntegrationPoint = (data) => {
    return networkService
      .post(METHOD_INTEGRATION_POINT_CREATE, data)
      .then((response) => response.data);
  };
  updateIntegrationPoint = (data, id) => {
    const updatedMethodName = METHOD_INTEGRATION_POINT_UPDATE.replace(
      "{ID}",
      id
    );
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteIntegrationPoint = (id, is_delete = false) => {
    const updatedMethodName = METHOD_INTEGRATION_POINT_DELETE.replace(
      "{ID}",
      id
    );
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  // HITACHI HARDWARE PRODUCT METHODS
  getAllHitachiHardwareProduct = (data) => {
    return networkService
      .post(METHOD_HITACHI_HARDWARE_PRODUCT_LIST, data)
      .then((response) => response.data);
  };
  getHitachiHardwareProductDetail = (id) => {
    const updatedMethodName = METHOD_HITACHI_HARDWARE_PRODUCT_DETAIL.replace(
      "{ID}",
      id
    );
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHitachiHardwareProduct = (data) => {
    return networkService
      .post(METHOD_HITACHI_HARDWARE_PRODUCT_CREATE, data)
      .then((response) => response.data);
  };
  updateHitachiHardwareProduct = (data, id) => {
    const updatedMethodName = METHOD_HITACHI_HARDWARE_PRODUCT_UPDATE.replace(
      "{ID}",
      id
    );
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHitachiHardwareProduct = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HITACHI_HARDWARE_PRODUCT_DELETE.replace(
      "{ID}",
      id
    );
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default DataProtectionService;
