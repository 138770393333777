import {
  RELATED_MASTERS,
  DISCLAIMER_ACCEPTANCE,
  LOADING,
  SET_USER_ADDITIONAL_INFO,
  JOB_PROGRESS_PROCESS_CHECK,
  EOSL_MASTER_PRODUCTS,
  MEGA_MENU_OPTION,
  IS_REDIRECTED_TO_PRODUCT_TAB
} from "constants/common";
import commonService from "services/commonService";
import userService from "services/admin/userService";
import jobProgressService from "services/admin/masters/jobProgressService";

const commonServiceObj = new commonService();
const userServiceObj = new userService();
const jobProgressServiceObj = new jobProgressService();

export const getEoslMasterProducts = () => (dispatch) => {
  commonServiceObj.getAllEoslMasterProducts().then((response) => {
    dispatch({
      type: EOSL_MASTER_PRODUCTS,
      payload: response,
    });
  });
};

export const getAllRelatedMasters = () => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  commonServiceObj.getAllRelatedMasters().then(
    (response) => {
      dispatch({
        type: RELATED_MASTERS,
        payload: response,
      });
      dispatch({
        type: LOADING,
        payload: false,
      });
    },
    (err) => {
      dispatch({
        type: LOADING,
        payload: false,
      });
    }
  );
};

export const getJobProgressProcessCheck = () => (dispatch) => {
  jobProgressServiceObj.getJobProgressProcessCheck().then(
    (response) => {
      dispatch({
        type: JOB_PROGRESS_PROCESS_CHECK,
        payload: response,
      });
    },
    (err) => {}
  );
};

export const setDisclaimerAccept = () => (dispatch) => {
  localStorage.setItem(DISCLAIMER_ACCEPTANCE, true);
  dispatch({
    type: DISCLAIMER_ACCEPTANCE,
    payload: true,
  });
};
export const userAdditionalInformation = () => (dispatch) => {
  userServiceObj.getAllRelatedMasters().then(
    (response) => {
      dispatch({
        type: SET_USER_ADDITIONAL_INFO,
        payload: response,
      });
    },
    (err) => {
      console.log("Some error in getting user's additional infomation");
    }
  );
};

export const getMegaMenuOptions = (data) => {
  return {
    type: MEGA_MENU_OPTION,
    payload: data,
  };
};
export const linkedFromSolutiontoProduct = (data) => {
  return {
    type: IS_REDIRECTED_TO_PRODUCT_TAB,
    payload: data,
  };
};