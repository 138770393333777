import React, { Component } from "react";
import { STORAGE_FILTER_CONTROLS } from "constants/productMatrix";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import _ from "lodash";
class FilterableMultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      touched: 0,
      selectedOptions: [],
      options: this.props.options,
    };
  }

  componentDidMount() {
    this.setSelectedState();
  }

  setSelectedState = () => {
    const selectedOptions = this.props.options
      .filter((option) => option.isselected === true)
      .map((opt) => opt.id);
    this.setState({
      selectedOptions,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.touched !== prevState.touched) {
      if (this.state.touched > 0) {
        this.props.onChangeValue({
          field: this.props.controlName,
          type: "int",
          value: this.state.selectedOptions,
          operator: "eq",
        });
      }
    }
    if (prevProps.refreshToken !== this.props.refreshToken) {
      this.setState({
        selectedOptions: [],
        search:""
      });
    }
    if (prevProps.resetOptions !== this.props.resetOptions) {
      this.setSelectedState();
    }
  }
  setSearch = (e) => {
    this.setState({
      search: e.target.value,
    });
  };

  handleCheckbox = (e) => {
    const { selectedOptions } = this.state;
    this.setState({ touched: this.state.touched + 1 });
    if (e.target.checked) {
      this.setState({
        selectedOptions: [
          ...this.state.selectedOptions,
          parseInt(e.target.value),
        ],
      });
    } else {
      const selectedOpts = selectedOptions;
      const removalIndex = selectedOptions.indexOf(parseInt(e.target.value));
      selectedOpts.splice(removalIndex, 1);
      this.setState({ selectedOptions: [...selectedOpts] });
    }
  };

  render() {
    const { controlName,controlFor } = this.props;
    const { selectedOptions } = this.state;

    const options =
      this.state.search !== ""
        ? this.props.options.filter(
          (opt) =>
            opt.name.toLowerCase().indexOf(this.state.search.toLowerCase()) >
            -1
        )
        : this.props.options;
    return (options)?(
      <div className="boxshadow_filters">
        <div className="form-group searchbar_filters">
          {STORAGE_FILTER_CONTROLS[controlFor][controlName]?.icon ? (
            <img
              alt={STORAGE_FILTER_CONTROLS[controlFor][controlName]?.title || controlName}
              src={
                "/assets/images/" + STORAGE_FILTER_CONTROLS[controlFor][controlName]?.icon
              }
            />
          ) : (STORAGE_FILTER_CONTROLS[controlFor][controlName]?.iconClass) ?
          <FontAwesomeIcon style={{height:'23px'}} icon={["fas", STORAGE_FILTER_CONTROLS[controlFor][controlName]?.iconClass]} />: null}
          <input
            type="text"
            className="search_input_filters"
            onChange={this.setSearch}
            value={this.state.search}
          />
          <i className="fa fa-search search_icon_filters"></i>
          <span className="box-header">
            {STORAGE_FILTER_CONTROLS[controlFor][controlName]?.title || controlName}
          </span>
          <hr />
        </div>
        <div className="options_filters">
        <div style={{ height:'100%',overflowY:'scroll', overflowX: 'hidden' }} >
            {options &&
              options.map((option, index) => (
                <div className="form-group" key={index}>
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option.id)}
                    onChange={this.handleCheckbox}
                    id={controlName + "-" + option.id}
                    value={option.id}
                  />
                  <label
                    title={option.name}
                    htmlFor={controlName + "-" + option.id}
                  >
                    {option.name}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>
    ):null;
  }
}
export default FilterableMultiSelect;
