import React, { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
import DataGrid from "components/shared/DataGrid";
import _ from "lodash";
import DownloadaDatapathService from "services/admin/downloadDatapathService";
import Spinner from "components/shared/Spinner";
import {
  handleRefresh,
  toggleChangehandler,
  getCurrentSlug,
} from "utils/masterHelpers";
import { formatDate } from "utils/utils";
import JobProgressStatus from "containers/Admin/masters/job-progress/JobProgressStatus";
import { LOGGED_IN_USER_INFO } from "constants/common";
import { ALLOWED_USERS } from "constants/admin/downloadDatapath";
const serviceObj = new DownloadaDatapathService();
class List extends Component {
  constructor(props) {
    super(props);
    this.toggleChangehandler = toggleChangehandler.bind(this);
    this.handleRefresh = handleRefresh.bind(this);
    this.getCurrentSlug = getCurrentSlug.bind(this);
    this.formatDate = formatDate.bind(this);
    this.state = {
      loadingTable: false,
      refreshToken: Date.now(),
      downloading: false,
      columns: [
        { title: "Product", field: "product_name" },
        {
          title: "Non Eosl Records",
          field: "non_eosl_zip_file_name",
          render: (rowData) => (
            <span>
              <button
                className="btn p-0 branding-link"
                onClick={() => this.download("non-eosl" ,rowData.product_id, rowData.non_eosl_zip_file_name)}
              >
                <u>{rowData.non_eosl_zip_file_name}</u>
              </button>
            </span>
          ),
        },
        {
          title: "All Records",
          field: "zip_file_name",
          render: (rowData) => (
            <span>
              <button
                className="btn p-0 branding-link"
                onClick={() => this.download("eosl", rowData.product_id, rowData.zip_file_name)}
              >
                <u>{rowData.zip_file_name}</u>
              </button>
            </span>
          ),
        },
        {
          title: "Created Date",
          field: "created_date",
          render: (rowData) => this.formatDate(rowData.created_date),
        },
      ],
      pageSize: 20,
      recordsMainFilter: {
        field: "is_deleted",
        type: "bool",
        value: "False",
        operator: "eq",
      },
    };
  }

  componentDidMount = async () => {
    const userInfo = sessionStorage.getItem(LOGGED_IN_USER_INFO)
          ? JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO))
          : {};
    let user_type = userInfo.user_type;
    if(!ALLOWED_USERS.includes(user_type)){
      let columns = this.state.columns
      var filtered = columns.filter(function(value, index, arr){ 
        return value.field !== "zip_file_name";
      });
      this.setState({
        columns : filtered
      })
    }
  }

  async download(downloadType, product_id, fileName) {
    this.setState({
      downloading: true,
    });
    let reqParams = {
      product_id: product_id,
      include_eosl_records: downloadType === "non-eosl" ? "false" : "true",
    };
    await serviceObj.downloadFile(reqParams, fileName);
    this.setState({
      downloading: false,
    });
    this.setState({
      downloading: false,
    });
  }

  handlePagingData = (query) => {
    this.setState({
      loadingTable: true,
    });
    return new Promise((resolve, reject) => {
      let sortData = [{ field: "id", dir: "desc" }]; //default sorting
      if (!_.isEmpty(query.orderBy)) {
        sortData = [
          {
            field: query.orderBy.field,
            dir: query.orderDirection,
          },
        ];
      }
      this.setState({ pageSize: query.pageSize }, () => {
        let filters = [];
        if (!_.isEmpty(query.filters)) {
          query.filters.map((filter) =>
            filters.push({
              field: filter.column.field,
              type: "string",
              value: filter.value,
              operator: "eq",
            })
          );
        }
        if (!_.isEmpty(this.state.recordsMainFilter)) {
          filters.push(this.state.recordsMainFilter);
        }
        const reqParams = {
          take: query.pageSize,
          skip: query.pageSize * query.page,
          sort: sortData,
          filter: filters,
        };
        serviceObj
          .getAll(reqParams)
          .then((response) => {
            this.setState({
              loadingTable: false,
            });
            resolve({
              data: response,
              page: query.page,
              totalCount: response.length,
            });
          })
          .catch((e) => {
            this.setState({
              loadingTable: false,
            });
          });
      });
    });
  };
  render() {
    const { loadingTable, downloading } = this.state;
    return (
      <>
        {loadingTable || downloading ? <Spinner /> : null}
        <div className="form-wrap mt-3">
          <div className="form-group">
            <Form.Group as={Row}>
              <Col sm={6}>
                <JobProgressStatus />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col sm={12}>
                <DataGrid
                  columns={this.state.columns}
                  dataHandler={this.handlePagingData}
                  pageSize={this.state.pageSize}
                  refreshToken={this.state.refreshToken}
                ></DataGrid>
              </Col>
            </Form.Group>
          </div>
          <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12 mt-3">
            <h5>Disclaimer</h5>
            <p>
              <i>
                Datapath files are generated every mid-night based on the new updates
                done for respective products.
              </i>
            </p>
          </div>
        </div>
        </div>
      </>
    );
  }
}
export default List;
