import React, { Component } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ErrorMessage } from "components/shared/ErrorMessage";
import _ from "lodash";
import eoslService from "services/admin/masters/eoslService";
import Spinner from "components/shared/Spinner";
import Select from "react-select";
import DatePicker from "react-date-picker";
import {
  MSG_CREATE_RECORD,
  MSG_UPDATE_RECORD,
} from "constants/admin/masters/eosl";
import {
  handleChange,
  handleTouched,
  formFailure,
  getCurrentSlug,
  trimFields,
  handleSingleSelect,
} from "utils/masterHelpers";
import FilterableMultiSelect from "components/shared/FilterableMultiSelect";
import { convertToFormat } from "utils/utils";
import { PROD_ENVS } from "constants/common";
const serviceObj = new eoslService();
class EoslForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = handleChange.bind(this);
    this.handleSingleSelect = handleSingleSelect.bind(this);
    this.handleTouched = handleTouched.bind(this);
    this.formFailure = formFailure.bind(this);
    this.getCurrentSlug = getCurrentSlug.bind(this);
    this.trimFields = trimFields.bind(this);

    this.todayDT = new Date();
    this.tomorrowDT = new Date(this.todayDT);
    this.tomorrowDT.setDate(this.tomorrowDT.getDate() + 1);

    this.state = {
      refreshProductToken: Date.now(),
      isEditForm: false,
      id: 0,
      isSubmitting: false,
      errors: {},
      touched: {},
      form: {
        masterdetail_id: props.masterDetailId,
        master_id: 0,
        master_eosl_date: null,
        datapath_eosl_date: null,
        product_id: [],
      },
      master_names: [],
    };
  }

  componentDidMount = async () => {
    await this.intializedata();
  };
  transformData = (data) =>
    data.map((row) => {
      return {
        value: row.id,
        label: row.name,
      };
    });

  intializedata = async () => {
    if (_.isEmpty(this.state.master_names)) {
      serviceObj
        .getMasterDropdownItems(
          this.props.masterKey,
          this.props?.additionalParams
        )
        .then(async (response) => {
          await this.setState({
            master_names: this.transformData(response),
          });
        });
    }
  };

  submitForm = async (e) => {
    e.preventDefault();
    this.trimFields(this.state.form);
    this.setState({
      isSubmitting: true,
    });
    const errors = await this.validateForm();
    if (_.isEmpty(errors)) {
      //now proceed to save user
      const form = { ...this.state.form, ...this.props?.additionalParams };
      const master_eosl_date = form["master_eosl_date"].toISOString();
      form["master_eosl_date"] = convertToFormat(
        master_eosl_date,
        "YYYY-MM-DD"
      );
      const datapath_eosl_date = form["datapath_eosl_date"].toISOString();
      form["datapath_eosl_date"] = convertToFormat(
        datapath_eosl_date,
        "YYYY-MM-DD"
      );
      try {
        let response;
        if (this.state.id > 0 && this.state.isEditForm) {
          response = await serviceObj.update(form, this.state.id);
        } else {
          response = await serviceObj.create(form);
        }
        if (response) {
          toast.success(
            response.message || this.state.isEditForm
              ? MSG_UPDATE_RECORD
              : MSG_CREATE_RECORD
          );
          this.resetForm();
        }
      } catch (err) {
        //failure case
        this.formFailure();
        if (err) {
          const errors = this.state.errors;
          for (const [field, msg] of Object.entries(err)) {
            errors[field] = msg[0];
          }
          await this.setState({
            errors,
          });
        }
      }
    } else {
      //failure case
      this.formFailure();
    }
  };
  runBgJob = () => {
    serviceObj
      .runbackgroundJob()
      .then(async (response) => {
        toast.success(response?.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  resetForm = () => {
    this.setState({
      form: {
        masterdetail_id: this.props.masterDetailId,
        master_id: 0,
        master_eosl_date: null,
        datapath_eosl_date: null,
        product_id: [],
      },
      isSubmitting: false,
      errors: {},
      touched: {},
      id: 0,
      isEditForm: false,
      refreshProductToken: Date.now(),
    });
    this.props.handleRefresh(); //to invoke parents list refresh
  };

  validateForm = async () => {
    const errors = {};
    const schema = yup.object().shape({
      master_id: yup
        .number()
        .required(`Master Name is required field`)
        .positive(`Master Name is required field`),
      product_id: yup.array().nullable().required("Product is required field"),
      master_eosl_date: yup
        .date()
        .nullable()
        .required("Master EOSL Date is required field")
        .typeError("Please Input Date in correct format"),
      datapath_eosl_date: yup
        .date()
        .nullable()
        .required("Datapath EOSL Date is required field")
        .typeError("Please Input Date in correct format"),
    });
    // check validity
    await schema
      .validate(this.state.form, { abortEarly: false })
      .catch(function (err) {
        err.inner.map((error) =>
          !errors[error.path] ? (errors[error.path] = error.message) : null
        );
      });

    this.setState({
      errors,
    });
    return errors; //return basicerrors
  };

  handleDate = (f, v) => {
    const form = this.state.form;
    form[f] = v;
    this.setState({
      form,
    });
    //mark this input as touched
    this.handleTouched(f);
    //now call the validations to keep the errors up to date
    this.validateForm();
  };

  handleMultiSelect = (data) => {
    const form = this.state.form;
    form[data.field] = data.value;
    this.setState(
      {
        form,
      },
      async () => {
        //mark this input as touched
        this.handleTouched(data.field);
        //trigger the validation
        await this.validateForm();
      }
    );
  };

  render() {
    const {
      isSubmitting,
      form,
      touched,
      errors,
      isEditForm,
      master_names,
      refreshProductToken,
    } = this.state;
    const productList = this.props.productInfo || [];
    return (
      <>
        {isSubmitting ? <Spinner /> : null}
        <form onSubmit={this.submitForm}>
          <div className="row pl-2">
            <div className="col-md-8">
              <div className="col-md-12 pb-3">
                <label htmlFor="master_id" className="mr-sm-2">
                  <b>Name</b>
                </label>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  className={`basic-single ${
                    touched.master_id && errors.master_id ? "is-invalid" : ""
                  }`}
                  placeholder={"Select Master"}
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="master_id"
                  options={master_names}
                  value={
                    master_names.find(
                      (item) => item.value === form.master_id
                    ) || 0
                  }
                  onChange={(e) => {
                    this.handleSingleSelect("master_id", e);
                    this.props.onMasterSelect(e);
                  }}
                />

                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="master_id"
                  className="text-danger"
                />
              </div>
              <div className="col-md-12 pb-3">
                <label htmlFor="eosl_date" className="mr-sm-2">
                  <b>Datapath EOSL Date</b>
                </label>
                <DatePicker
                  className="form-control"
                  format={"MM/dd/y"}
                  name="datapath_eosl_date"
                  onChange={(e) => this.handleDate("datapath_eosl_date", e)}
                  minDate={
                    PROD_ENVS.includes(
                      process.env.REACT_APP_CUSTOM_NODE_ENV
                    )
                      ? this.tomorrowDT
                      : null
                  }
                  value={form.datapath_eosl_date}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="datapath_eosl_date"
                  className="text-danger"
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="eosl_date" className="mr-sm-2">
                  <b>Master EOSL Date</b>
                </label>
                <DatePicker
                  className="form-control"
                  format={"MM/dd/y"}
                  name="master_eosl_date"
                  onChange={(e) => this.handleDate("master_eosl_date", e)}
                  value={form.master_eosl_date}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="master_eosl_date"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="col-md-12">
                <FilterableMultiSelect
                  controlFor={"eoslForm"}
                  controlName={"product_id"}
                  resetOptions={refreshProductToken}
                  options={productList}
                  onChangeValue={this.handleMultiSelect}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="product_id"
                  className="text-danger"
                />
              </div>
              <div className="col-md-12 text-right">
                <button
                  type="reset"
                  onClick={this.resetForm}
                  className="btn btn-md btn-light btn-link submit-btn-inline mr-sm-2"
                >
                  <i className="fa fa-refresh"></i> Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-md btn-dark submit-btn-inline"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? "Please wait..."
                    : isEditForm
                    ? "Update"
                    : "Add"}
                </button>
                {!PROD_ENVS.includes(
                  process.env.REACT_APP_CUSTOM_NODE_ENV
                ) ? (
                  <button
                    type="button"
                    onClick={this.runBgJob}
                    className="btn btn-md btn-dark submit-btn-inline ml-sm-2"
                  >
                    RUN BACKGROUND JOB
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </form>
        <div className="clearfix"></div>
      </>
    );
  }
}

export default EoslForm;
