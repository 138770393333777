import React, { Component } from "react"; 
import backgroundImage from "../../../resources/images/Hitachi-Vantara.jpg";
import { connect } from "react-redux";
class AdminDashboard extends Component {
  styleObj = {
    mainDiv: {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: "repeat",
      height: "480px",
      marginTop: "25px",
    }
  };
 
  render() {
    return (
      <div style={this.styleObj.mainDiv}>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.loginReducer,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
