import { networkService } from "services/networkService";
import {
  METHOD_VRO_RELEASE_LIST,
  METHOD_VRO_RELEASE_CREATE,
  METHOD_VRO_RELEASE_UPDATE,
  METHOD_VRO_RELEASE_DELETE,
  METHOD_VRO_RELEASE_DETAIL,
} from "constants/admin/masters/vro";
class VROReleaseService {
  getAll = (data) => {
    return networkService
      .post(METHOD_VRO_RELEASE_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (id) => {
    const updatedMethodName = METHOD_VRO_RELEASE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_VRO_RELEASE_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, id) => {
    const updatedMethodName = METHOD_VRO_RELEASE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (id,is_delete=false) => {
    const updatedMethodName = METHOD_VRO_RELEASE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };
}
export default VROReleaseService;
