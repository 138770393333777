import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';

class ConfirmBox extends Component {

  constructor(props){
    super(props);
    this.state={
      confirmState:false,
      additionalCheck:false
    }
  }

  handleAdditionalCheck = async e => {
    await this.setState({
      additionalCheck:e.target.checked
    })
  }
  handleSubmit = async flag => {
    await this.setState({
      confirmState:flag
    })
    this.props.actionHandler(this.state);
  }

  render(){
    const {modalTitle,CancelButtonText,ConfirmButtonText,children}=this.props;
    return (
      <Modal
        show={true}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-center disclaimer-header">
          <Modal.Title className="w-100" >
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div>{children}</div>
          {
            (this.props?.additionalCheckText)?(
              <div className="form-check mt-3">
                <label className="form-check-label">
                 <input className="form-check-input" type="checkbox" onChange={this.handleAdditionalCheck} value={this.state.additionalCheck} />
                {this.props?.additionalCheckText}
                </label>
              </div> 
            ):null
          }          
        </Modal.Body>
        <Modal.Footer>
          <p className="text-center"> 
            <button className="btn btn-secondary mr-2" onClick={()=>this.handleSubmit(false)}>{CancelButtonText}</button>
            <button className="btn btn-branding" onClick={()=>this.handleSubmit(true)}>{ConfirmButtonText}</button>
          </p>
          </Modal.Footer>
      </Modal>
    );
  }
}
// Specifies the default values for props:
ConfirmBox.defaultProps = {
  modalTitle: 'Confirmation',
  CancelButtonText:'Cancel',
  ConfirmButtonText:'Confirm'
};
ConfirmBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element]).isRequired,
  actionHandler: PropTypes.func.isRequired
  
};
export default ConfirmBox;


  