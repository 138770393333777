import React from "react";
import {
  MODULE_INTEROP_MATRIX_SLUGS,
  MODULE_GAD_MATRIX_SLUGS,
  MODULE_TC_UR_MATRIX_SLUGS,
  MODULE_NDM_MATRIX_SLUGS,
  MODULE_UVM_MATRIX_SLUGS,
  MODULE_OPENSTACK_MATRIX_SLUGS,
  MODULE_OSCLUSTER_MATRIX_SLUGS,
  MODULE_REPLICATION_INTERMIX_MATRIX_SLUGS,
  MODULE_ENCRYPTION_KEY_MATRIX_SLUGS,
  MODULE_HSPC_MATRIX_SLUGS,
  MODULE_VIEWPOINT_MATRIX_SLUGS,
  MODULE_KIWI_INTEROP_MATRIX_SLUGS,
  MODULE_SOPHIA_INTEROP_MATRIX_SLUGS,
  MODULE_STORAGE_CERTIFICATION_SLUGS,
  MODULE_OPS_COMMON_SERVICES_SLUGS,
  MODULE_OPS_CENTER_ADMINISTRATOR_SLUGS,
  MODULE_HCP_SLUGS,
  MODULE_HCPCS_SLUGS,
  MODULE_HCP_ANYWHERE_SLUGS,
  MODULE_CITRIX_SLUGS,
  MODULE_SAP_HANA_SLUGS,
  MODULE_ORACLE_EDW_SLUGS,
  MODULE_ORACLE_V_NV_SLUGS,
  MODULE_OPS_CENTER_AUTOMATOR_SLUGS,
  MODULE_OPS_CENTER_API_CM_SLUGS,
  MODULE_PROTECTOR_SLUGS,
  MODULE_SVP_SLUGS,
  MODULE_HNAS_SLUGS,
  MODULE_MFHOST_SLUGS,
  MODULE_MFFICON_SLUGS,
  MODULE_MFFCP_SLUGS,
  MODULE_HDLM_SLUGS,
  MODULE_HDLMRAC_SLUGS,
  MODULE_HVSSB_CERTIFICATION_SLUGS,
  MODULE_HVSSB_HSPC_SLUGS,
  MODULE_VASA_SLUGS,
  MODULE_VCENTER_SLUGS,
  MODULE_VROPS_SLUGS,
  MODULE_VRO_SLUGS,
  MODULE_SRA_SLUGS,
  MODULE_ORACLE_SLUGS,
  MODULE_VMWARE_SLUGS,
  MODULE_BAREMETAL_SLUGS,
  MODULE_HC_INTEROPERABILITY_SLUGS,
  MODULE_HRPC_SLUGS,
  MODULE_HSPP_SLUGS,
  MODULE_VEEAM_SLUGS,
  MODULE_DATA_PROTECTION_SLUGS,
  MODULE_ANALYZER_SLUGS,
  MODULE_FLASH_ANSIBLE_SLUGS,
  MODULE_SDS_ANSIBLE_SLUGS
} from "constants/productMatrix";


export const Matrices = [
  {
    Slugs: MODULE_INTEROP_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/interop-matrix/InteropMatrix")
    ),
  },
  {
    Slugs: MODULE_GAD_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/gad/GadMatrix")
    ),
  },
  {
    Slugs: MODULE_TC_UR_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/tc-ur/TcUrMatrix")
    ),
  },
  {
    Slugs: MODULE_NDM_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/ndm/NdmMatrix")
    ),
  },
  {
    Slugs: MODULE_UVM_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/uvm/UvmMatrix")
    ),
  },
  {
    Slugs: MODULE_OPENSTACK_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/openstack/OpenStackMatrix")
    ),
  },
  {
    Slugs: MODULE_OSCLUSTER_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/oscluster/OsClusterMatrix")
    ),
  },
  {
    Slugs: MODULE_REPLICATION_INTERMIX_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/replication-intermix/ReplicationIntermixMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_ENCRYPTION_KEY_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/encryptionkey/EncryptionKeyMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_HSPC_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hspc/HspcMatrix")
    ),
  },
  {
    Slugs: MODULE_VIEWPOINT_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/viewpoint/ViewPointMatrix")
    ),
  },
  {
    Slugs: MODULE_KIWI_INTEROP_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/kiwi-interop/KiwiInteropMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_SOPHIA_INTEROP_MATRIX_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/sophia-interop/SophiaInteropMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_STORAGE_CERTIFICATION_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/storage-certification/StorageCertificationMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_OPS_COMMON_SERVICES_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/ops-center-common-services/OpsCenterCommonServicesMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_OPS_CENTER_ADMINISTRATOR_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/ops-center-administrator/OpsCenterAdministratorMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_HCP_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hcp/HCPMatrix")
    ),
  },
  {
    Slugs: MODULE_HCPCS_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hcp-cs/HCPCSMatrix")
    ),
  },
  {
    Slugs: MODULE_HCP_ANYWHERE_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/hcp-anywhere/HCPAnywhereMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_CITRIX_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/citrix/CitrixMatrix")
    ),
  },
  {
    Slugs: MODULE_SAP_HANA_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/sap-hana/SapHanaMatrix")
    ),
  },
  {
    Slugs: MODULE_ORACLE_EDW_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/oracle-edw/OracleEdwMatrix")
    ),
  },
  {
    Slugs: MODULE_ORACLE_V_NV_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/oracle-v-nv/OracleVnvMatrix")
    ),
  },
  {
    Slugs: MODULE_OPS_CENTER_AUTOMATOR_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/ops-center-automator/OpsCenterAutomatorMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_OPS_CENTER_API_CM_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/ops-center-api-cm/OpsCenterApiCMMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_PROTECTOR_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/protector/ProtectorMatrix")
    ),
  },
  {
    Slugs: MODULE_SVP_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/svp/SvpMatrix")
    ),
  },
  {
    Slugs: MODULE_HNAS_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hnas/HNASMatrix")
    ),
  },
  {
    Slugs: MODULE_MFHOST_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/mf-host-connectivity/MFHostMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_MFFICON_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/mf-ficon-connectivity/MFFiconMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_MFFCP_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/mf-fcp-connectivity/MFFcpMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_HDLM_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hdlm/HDLMMatrix")
    ),
  },
  {
    Slugs: MODULE_HDLMRAC_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hdlm-rac/HDLMRACMatrix")
    ),
  },
  {
    Slugs: MODULE_HVSSB_CERTIFICATION_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/hvssb-certification/HvssbCertificationMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_HVSSB_HSPC_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hvssb-hspc/HvssbHspcMatrix")
    ),
  },
  {
    Slugs: MODULE_VASA_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/plugins/vasa/VasaMatrix")
    ),
  },
  {
    Slugs: MODULE_VCENTER_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/plugins/vcenter/VCenterMatrix")
    ),
  },
  {
    Slugs: MODULE_VROPS_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/plugins/vrops/VROPSMatrix")
    ),
  },
  {
    Slugs: MODULE_VRO_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/plugins/vro/VROMatrix")
    ),
  },
  {
    Slugs: MODULE_SRA_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/plugins/sra/SraMatrix")
    ),
  },
  {
    Slugs: MODULE_ORACLE_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/oracle/OracleMatrix")
    ),
  },
  {
    Slugs: MODULE_HC_INTEROPERABILITY_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/hybrid-cloud/hcInteroperabilityMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_VMWARE_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/vmware/VmWareMatrix")
    ),
  },
  {
    Slugs: MODULE_BAREMETAL_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/baremetal/baremetalMatrix")
    ),
  },
  {
    Slugs: MODULE_HRPC_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hrpc/HRPCMatrix")
    ),
  },
  {
    Slugs: MODULE_HSPP_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/hspp/HsppMatrix")
    ),
  },
  {
    Slugs: MODULE_VEEAM_SLUGS,
    Component: React.lazy(() =>
      import("containers/SearchProducts/matrices/veeam/VeeamMatrix")
    ),
  },
  {
    Slugs: MODULE_DATA_PROTECTION_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/dataprotection/dataprotectionMatrix"
      )
    ),
  },
  {
    Slugs:  MODULE_FLASH_ANSIBLE_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchProducts/matrices/flashansible/FlashAnsibleMatrix"
      )
    ),
  },
  {
    Slugs: MODULE_SDS_ANSIBLE_SLUGS,
  Component: React.lazy(() =>
    import(
      "containers/SearchProducts/matrices/sdsansible/SdsAnsibleMatrix"
    )
  ),
  },

  {
    Slugs: MODULE_ANALYZER_SLUGS,
    Component: React.lazy(() =>
      import(
        "containers/SearchAnalyzer/matrices/analyzer/AnalyzerMatrix"
      )
    ),
  }
  
  
];


