import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";


//switch methods
export const METHOD_LIST = 'switch/pageable/';
export const METHOD_CREATE = 'switch/';
export const METHOD_UPDATE = 'switch/{ID}/';
export const METHOD_DELETE = 'switch/{ID}/';
export const METHOD_DETAIL = 'switch/{ID}/';
//switch vendor methods
export const METHOD_VENDOR_LIST = 'switchvendor/pageable/';
export const METHOD_VENDOR_CREATE = 'switchvendor/';
export const METHOD_VENDOR_UPDATE = 'switchvendor/{ID}/';
export const METHOD_VENDOR_DELETE = 'switchvendor/{ID}/';
export const METHOD_VENDOR_DETAIL = 'switchvendor/{ID}/';
export const METHOD_VENDOR_DROPDOWN = 'switchvendor/drop/';
//firmware series methods
export const METHOD_FIRMWARE_SERIES_LIST = 'firmwareseries/pageable/';
export const METHOD_FIRMWARE_SERIES_CREATE = 'firmwareseries/';
export const METHOD_FIRMWARE_SERIES_UPDATE = 'firmwareseries/{ID}/';
export const METHOD_FIRMWARE_SERIES_DELETE = 'firmwareseries/{ID}/';
export const METHOD_FIRMWARE_SERIES_DETAIL = 'firmwareseries/{ID}/';
//firmware methods
export const METHOD_FIRMWARE_LIST = 'switchfirmware/pageable/';
export const METHOD_FIRMWARE_CREATE = 'switchfirmware/';
export const METHOD_FIRMWARE_UPDATE = 'switchfirmware/{ID}/';
export const METHOD_FIRMWARE_DELETE = 'switchfirmware/{ID}/';
export const METHOD_FIRMWARE_DETAIL = 'switchfirmware/{ID}/';
export const METHOD_FIRMWARE_DROPDOWN = 'switchfirmware/drop/';
//firmware mapping methods
export const METHOD_FIRMWARE_MAPPING_LIST = 'switchfirmwaremapping/pageable/';
export const METHOD_FIRMWARE_MAPPING_CREATE = 'switchfirmwaremapping/';
export const METHOD_FIRMWARE_MAPPING_UPDATE = 'switchfirmwaremapping/{ID}/';
export const METHOD_FIRMWARE_MAPPING_DELETE = 'switchfirmwaremapping/{ID}/';
export const METHOD_FIRMWARE_MAPPING_DETAIL = 'switchfirmwaremapping/{ID}/';



//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;
export const MSG_SWITCH_FW_EXIST_POPUP_ERROR = 'Due to following switch firmware existence in exception,this switch firmware cant be deleted';