import { MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//legacyLinks methods
export const METHOD_LIST = '/legacyproducts/pageable/';
export const METHOD_CREATE = '/legacyproduct/';
export const METHOD_UPDATE = '/legacyproduct/{ID}/';
export const METHOD_DELETE = '/legacyproduct/{ID}/';
export const METHOD_DETAIL = '/legacyproduct/{ID}/';
export const METHOD_DROPDOWN = '/legacyproductdrop/';
export const METHOD_SAVE_ALL = '/managelinks/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = 'Are you sure you want to delete the assets of "ITEM_NAME"?';
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;

export const MSG_VALIDATION_LINKS_FORM = 'Please check all titles and links are correct!';