const FILTERS = {
    "hdlm-rac": {
        hdlm_version: {
            title: 'HDLM Version',
            iconClass: 'project-diagram'
        },
        os: {
            title: 'OS',
            icon: 'os.png'
        },
        os_kernel: {
            title: 'Kernel',
            icon: 'os_kernel.png'
        },
        oracle_version: {
            title: 'Oracle Version',
            icon: 'server.png'
        },
        cluster: {
            title: 'Cluster',
            iconClass: 'project-diagram'
        },
        volume_manager: {
            title: 'Volume Manager',
            iconClass: 'network-wired'
        }        
    }
}
export default FILTERS;