const FILTERS = {
    "ops-automator": {
        automator_version: {
            title: 'Automator Version',
            icon: 'server.png'
        },
        
        hypervisor: {
            title: 'Hypervisor',
            icon: 'array.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        },
        jdk: {
            title: 'JDK',
            iconClass: 'project-diagram'
        },
        browser: {
            title: 'Browser',
            icon: 'multipath.png'
        },
        virtualization_software: {
            title: 'Virtualization Software',
            icon: 'os_kernel.png'
        },
        fc_switch: {
            title: 'Switch',
            icon: 'switch.png'
        },
        array_customer_version: {
            title: 'Storage Array',
            icon: 'array_if.png'
        },
    }
}
export default FILTERS;