import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//hvssb model methods
export const METHOD_VSSBMODEL_LIST = 'vssbmodel/pageable/';
export const METHOD_VSSBMODEL_CREATE = 'vssbmodel/';
export const METHOD_VSSBMODEL_UPDATE = 'vssbmodel/{ID}/';
export const METHOD_VSSBMODEL_DELETE = 'vssbmodel/{ID}/';
export const METHOD_VSSBMODEL_DETAIL = 'vssbmodel/{ID}/';
export const METHOD_VSSBMODEL_DROP = 'vssbmodel/drop/';

//hvssbsoftwareversion methods
export const METHOD_HVSSBSOFTWAREVERSION_LIST = 'hvssbsoftwareversion/pageable/';
export const METHOD_HVSSBSOFTWAREVERSION_CREATE = 'hvssbsoftwareversion/';
export const METHOD_HVSSBSOFTWAREVERSION_UPDATE = 'hvssbsoftwareversion/{ID}/';
export const METHOD_HVSSBSOFTWAREVERSION_DELETE = 'hvssbsoftwareversion/{ID}/';
export const METHOD_HVSSBSOFTWAREVERSION_DETAIL = 'hvssbsoftwareversion/{ID}/';
export const METHOD_HVSSBSOFTWAREVERSION_DROP = 'hvssbsoftwareversion/drop/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;