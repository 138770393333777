import React, { useState } from "react";
import { Link } from "react-router-dom";
import authService from "services/authService";
import _ from "lodash";
import { connect } from "react-redux";
import { getLegacyProductMenu } from "actions/productSearchActions";
import { useDispatch } from "react-redux";
import { getMegaMenuOptions } from "actions/commonActions";
export const DropdownMenu = (props) => {
  const dispatch = useDispatch();
  const userData = authService.userData;
  const userProducts = userData.id
    ? userData.products.map((prd) => prd.id)
    : [];
  const [menuItems, setMenuItems] = useState(props.childrens);
  const [searchData, setSearchData] = useState("");
  const escapeRegExp = (str) => {
    if (!_.isString(str)) {
      return "";
    }
    // eslint-disable-next-line no-useless-escape
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  };

  const handleChange = (e) => {
    setSearchData(e.target.value);
    if (e.target.value === "") setMenuItems(props.childrens);
    else
      setMenuItems(
        props.childrens.filter((item) => {
          // added check to item.title is not null
          return (
            item.title !== null && item.title
              .toLowerCase() &&  item.title
              .toLowerCase()
              .search(escapeRegExp(e.target.value).toLowerCase()) !== -1
          );
        })
      );
  };

  const resetHandler = (e) => {
    setSearchData("");
    setMenuItems(props.childrens);
    handleClick(e);
  };

  const renderSubMenu = (childrens) => {
    if (childrens && childrens.length > 0) {
      return (
        <ul className="dropdown-menu dropdown-menu-right" >
          {renderMenuItems(childrens)}
        </ul>
      );
    }
  };
const individualmenuItemClick = () => {
  // dispatch action to update tab reducer with tab key "Products"
  dispatch(getMegaMenuOptions("Products"));
  props.getLegacyProductMenu([]);
}
  const getIndividualItem = (item, i) => {
    return (
      <li className="dropdown-item" key={i} onClick={individualmenuItemClick}>
        <Link className="individual-menu-item" to={item.route}>
          {item.title}
        </Link>
      </li>
    );
  };
  const handleClick = (e) => {
    e.stopPropagation();
  };
  const getDropdownMenu = (item, i) => {
    return (
      <li className="dropdown-submenu" key={i}>
        <div
          className="dropdown-item rotate_icon dropdown-toggle nav-item"
          onClick={handleClick}
        >
          {item.title}
        </div>
        {renderSubMenu(item.childrens)}
      </li>
    );
  };
  const renderMenuItems = (items) =>
    items.map((item, i) => {
      return item.hasSubmenu
        ? item.productId
          ? !_.isEmpty(_.intersection(userProducts, item.productId))
            ? getDropdownMenu(item, i)
            : null
          : getDropdownMenu(item, i)
        : item.productId
        ? !_.isEmpty(_.intersection(userProducts, item.productId))
          ? getIndividualItem(item, i)
          : null
        : getIndividualItem(item, i);
    });

  const styleObj = {
    marginRight: "inherit",
    marginTop: "5px",
  };

  return (
    <ul className="dropdown-menu dropdown-menu-right">
     
      {props.searchBar && (
        <div>
          <li className="search-field">
            <input
              className="search-criteria"
              type="text"
              placeholder="Search"
              value={searchData}
              onClick={handleClick}
              onChange={(event) => handleChange(event)}
            />
            {!_.isEmpty(searchData) ? (
              <i
                className="fa fa-close search_icon_form_control show_cursor"
                style={styleObj}
                onClick={resetHandler}
              ></i>
            ) : (
              <i
                className="fa fa-search search_icon_form_control"
                style={styleObj}
                onClick={handleClick}
              ></i>
            )}
          </li>
        </div>
      )}
      {renderMenuItems(menuItems)}
    </ul>
  );
};

const mapStateToProps = (state) => ({
  ...state.productSearchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLegacyProductMenu: (payload) => dispatch(getLegacyProductMenu(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(DropdownMenu);
