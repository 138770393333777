import { networkService } from "services/networkService";
import {

 METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_LIST,
 METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_CREATE,
 METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_UPDATE,
 METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_DELETE,
 METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_DETAIL, 

 METHOD_ANSIBLE_LIST,
 METHOD_ANSIBLE_CREATE,
 METHOD_ANSIBLE_UPDATE ,
 METHOD_ANSIBLE_DELETE,
 METHOD_ANSIBLE_DETAIL ,


 METHOD_PYTHON_LIST,
 METHOD_PYTHON_CREATE,
 METHOD_PYTHON_UPDATE ,
 METHOD_PYTHON_DELETE ,
 METHOD_PYTHON_DETAIL ,

 METHOD_UAI_GATEWAY_LIST,
 METHOD_UAI_GATEWAY_CREATE ,
 METHOD_UAI_GATEWAY_UPDATE,
 METHOD_UAI_GATEWAY_DELETE,
 METHOD_UAI_GATEWAY_DETAIL

} from "constants/admin/masters/flashAnsible";


class FlashAnsibleService {
  // Hitachi Storage Ansible Module
  getAllHitachiStorageAnsibleModule = (data) => {
    return networkService
      .post(METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_LIST, data)
      .then((response) => response.data);
  };
  getHitachiStorageAnsibleModuleDetail = (id) => {
    const updatedMethodName = METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHitachiStorageAnsibleModule = (data) => {
    return networkService
      .post(METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_CREATE, data)
      .then((response) => response.data);
  };
  updateHitachiStorageAnsibleModule = (data, id) => {
    const updatedMethodName = METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data).then((response) => response.data);
  };
  deleteHitachiStorageAnsibleModule = (id,is_delete=false) => {
    const updatedMethodName = METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_DELETE.replace("{ID}", id);
    return networkService
    .delete(updatedMethodName,{ data: { is_delete }})
    .then((response) => response.data);
  };

  // Ansible
  getAllAnsible = (data) => {
    return networkService
      .post(METHOD_ANSIBLE_LIST, data)
      .then((response) => response.data);
  };
  getAnsibleDetail = (id) => {
    const updatedMethodName = METHOD_ANSIBLE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createAnsible = (data) => {
    return networkService
    .post(METHOD_ANSIBLE_CREATE, data)
    .then((response) => response.data);
  };
  updateAnsible = (data, id) => {
    const updatedMethodName = METHOD_ANSIBLE_UPDATE.replace("{ID}", id);
    return networkService
    .post(updatedMethodName, data)
    .then((response) => response.data);
  };
  deleteAnsible = (id,is_delete=false) => {
    const updatedMethodName = METHOD_ANSIBLE_DELETE.replace("{ID}", id);
    return networkService
    .delete(updatedMethodName,{ data: { is_delete }})
    .then((response) => response.data);
  };
  // Python
  getAllPython = (data) => {
    return networkService
    .post(METHOD_PYTHON_LIST, data)
    .then((response) => response.data);
  };
  getPythonDetail = (id) => {
    const updatedMethodName = METHOD_PYTHON_DETAIL.replace("{ID}", id);     
    return networkService
    .get(updatedMethodName)
    .then((response) => response.data);
  };
  createPython = (data) => {    
    return networkService
    .post(METHOD_PYTHON_CREATE, data)
    .then((response) => response.data);
  };
  updatePython = (data, id) => {    
    const updatedMethodName = METHOD_PYTHON_UPDATE.replace("{ID}", id);
    return networkService
    .post(updatedMethodName, data)
    .then((response) => response.data);
  };
  deletePython = (id,is_delete=false) => {
    const updatedMethodName = METHOD_PYTHON_DELETE.replace("{ID}", id);
    return networkService
    .delete(updatedMethodName,{ data: { is_delete }})
    .then((response) => response.data);
  };
  // UAI Gateway
  getAllUaiGateway = (data) => {
    return networkService  
    .post(METHOD_UAI_GATEWAY_LIST, data)    
    .then((response) => response.data);
  };
  getUaiGatewayDetail = (id) => {
    const updatedMethodName = METHOD_UAI_GATEWAY_DETAIL.replace("{ID}", id);
    return networkService
    .get(updatedMethodName)
    .then((response) => response.data);
  };
  createUaiGateway = (data) => {
    return networkService
    .post(METHOD_UAI_GATEWAY_CREATE, data)
    .then((response) => response.data);
  };
  updateUaiGateway = (data, id) => {
    const updatedMethodName = METHOD_UAI_GATEWAY_UPDATE.replace("{ID}", id);
    return networkService
    .post(updatedMethodName, data)
    .then((response) => response.data);
  };
  deleteUaiGateway = (id,is_delete=false) => {
    const updatedMethodName = METHOD_UAI_GATEWAY_DELETE.replace("{ID}", id);
    return networkService
    .delete(updatedMethodName,{ data: { is_delete }})
    .then((response) => response.data);
  };        

  } 

  export default FlashAnsibleService;