import React, { Component } from "react";
import { handleChangeMenu } from "utils/matriceHelpers";
import products from "resources/json/static-menus.json";
import MegaMenu from "components/shared/MegaMenu";
import { API_URL, APP_NAME } from "constants/common";
import commonService from "services/commonService";
import { captureGAPageView } from "utils/utils";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LABEL_ALL_PRODUCT_CATEGORY,LABEL_ALL_SOLUTION_CATEGORY } from "constants/productMatrix";
import { getLegacyProductMenu } from "actions/productSearchActions";
import { connect } from "react-redux";
import { getMegaMenuOptions } from "actions/commonActions";
import TabWrapper from "containers/Pages/TabWrapper/TabWrapper";
const commonServiceObj = new commonService();
class LegacyProducts extends Component {

  constructor(props) {
    super(props);
    this.handleChangeMenu = handleChangeMenu.bind(this);
    this.state = {
      legacyLinks: [],
      loading: false,
      legacyFlag: true
    }
  }

  componentDidMount() {
    this.getProductLinks();
    if (!this.state.legacyFlag) this.setState({ legacyFlag: true })
      this.setDefaultMegaMenuLabel();
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.productSlug !== prevProps.match.params.productSlug) {
      this.getProductLinks();
    }
  }

  findSelectedMenu = (products, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(products, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  }

  getProductLinks = async () => {
    const { match: { params: { productSlug } } } = this.props;
    const selectedProduct = this.findSelectedMenu(products, 'slug', productSlug);
    this.setState({ loading: true });
    commonServiceObj.getLegacyLinks(selectedProduct?.id).then(data => {
      this.setState({
        loading: false,
        legacyLinks: (data.length) ? data[0] : []
      });
      if (data && data[0]?.links.length === 1) {
        window.open(this.getUri(data[0]?.links[0].link), "_blank") //to open new page
      }
      //condition to open the legacy link if there is only 1 link

    }).catch(err => {
      this.setState({ loading: false });
      console.log('some error in downloading legacy links');
    }
    )
  }
  getUri = (link) => {
    return link.replace('{API_URL}', API_URL);
  }

  clickHandler = async linkData => {
    const { legacyLinks } = this.state;
    const data = { page_title: `${legacyLinks?.title} - ${linkData?.title}`, page_location: linkData?.link, page_path: window.location.pathname }
    await captureGAPageView(data);
    window.open(this.getUri(linkData?.link), "_blank") //to open new page
  }

  handleLegacyFlag = () => {
    this.setState({ legacyFlag: false })
  }
 
// to set the default mega menu label of button based from redux state
setDefaultMegaMenuLabel = () => {
  const { megaMenuOption } = this.props;
  if (!megaMenuOption || megaMenuOption === "Products") {
    this.setState({ defaultMegaMenuLabel: LABEL_ALL_PRODUCT_CATEGORY });
  } else if (megaMenuOption === "Solutions") {
    this.setState({ defaultMegaMenuLabel: LABEL_ALL_SOLUTION_CATEGORY });
  }
};
  render() {
    const { legacyLinks, loading } = this.state;
    let selectedProduct
    if ((legacyLinks?.parent)) {
      selectedProduct = this.findSelectedMenu(products, 'parent', (legacyLinks?.parent).split(' > ')[0]);
      if (this.state.legacyFlag) {
        this.props.getLegacyProductMenu(selectedProduct)
      }
    }
    return (!loading) ? (
      <TabWrapper>
     
                {/* wrapping legacy product inside tab component */}
                <div className="form-wrap">
                  <div className="row mega-row p-1 mt-3">
                    <div className="form-group">
                      <MegaMenu
                        data={(legacyLinks?.parent) && this.state.legacyFlag ? selectedProduct?.nodes : products}
                        onChangeMenu={this.handleChangeMenu}
                        flagHomeIcon={true}
                        defaultLabel={(legacyLinks?.parent) || (legacyLinks?.title) || LABEL_ALL_PRODUCT_CATEGORY}
                        legacyProduct={(legacyLinks?.parent) ? true : false}
                        handleLegacyFlag={this.handleLegacyFlag}
                      />
                      {(legacyLinks?.title) ? (
                        <Helmet>
                          <title>{APP_NAME} :: {legacyLinks?.title}</title>
                        </Helmet>
                      ) : null}

                    </div>
                  </div>
                  <div className="row pl-4">
                    <div className="col-lg-12">

                      <h3 className="mt-1"> <i className="fa fa-book"></i>  {(legacyLinks?.title)}</h3>
                      {(legacyLinks?.description) ? (<p>{legacyLinks?.description}</p>) : null}
                    </div>
                  </div>
                  <div className="row pl-4">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      {legacyLinks?.links && legacyLinks?.links.map((row, index) => (
                        <p key={index}><span onClick={() => this.clickHandler(row)} className="branding-red" > <FontAwesomeIcon size="lg" icon={["far", "file-alt"]} /> {row.title}</span> {/*<br />{row.link_modified_date}*/}</p>
                      ))}
                    </div>
                  </div>
                </div>
               {/* end of wrapping */}
               
      </TabWrapper>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  ...state.productSearchReducer,
  ...state.commonReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLegacyProductMenu: (payload) => dispatch(getLegacyProductMenu(payload)),
  getMegaMenuOptions: (key) => dispatch(getMegaMenuOptions(key)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps)(LegacyProducts);
