import {
  MSG_DELETE_WARNING,
  MSG_UNDO_DELETE_WARNING,
  MSG_RECORD_CREATE,
  MSG_RECORD_UPDATE,
  MSG_RECORD_DELETE,
  MSG_RECORD_UNDO,
  MIN_TEXT_LENGTH,
  MAX_TEXT_LENGTH,
} from "constants/common";

// Veeam Plugin Version
export const METHOD_VEEAM_PLUGIN_VERSION_LIST = "veeampluginversion/pageable/";
export const METHOD_VEEAM_PLUGIN_VERSION_CREATE = "veeampluginversion/";
export const METHOD_VEEAM_PLUGIN_VERSION_UPDATE = "veeampluginversion/{ID}/";
export const METHOD_VEEAM_PLUGIN_VERSION_DELETE = "veeampluginversion/{ID}/";
export const METHOD_VEEAM_PLUGIN_VERSION_DETAIL = "veeampluginversion/{ID}/";

// Server Type
export const METHOD_SERVER_TYPE_LIST = "servertype/pageable/";
export const METHOD_SERVER_TYPE_CREATE = "servertype/";
export const METHOD_SERVER_TYPE_UPDATE = "servertype/{ID}/";
export const METHOD_SERVER_TYPE_DELETE = "servertype/{ID}/";
export const METHOD_SERVER_TYPE_DETAIL = "servertype/{ID}/";

// Server Configuration
export const METHOD_SERVER_CONFIGURATION_LIST = "serverconfiguration/pageable/";
export const METHOD_SERVER_CONFIGURATION_CREATE = "serverconfiguration/";
export const METHOD_SERVER_CONFIGURATION_UPDATE = "serverconfiguration/{ID}/";
export const METHOD_SERVER_CONFIGURATION_DELETE = "serverconfiguration/{ID}/";
export const METHOD_SERVER_CONFIGURATION_DETAIL = "serverconfiguration/{ID}/";

// Server Purpose

export const METHOD_SERVER_PURPOSE_LIST = "serverpurpose/pageable/";
export const METHOD_SERVER_PURPOSE_CREATE = "serverpurpose/";
export const METHOD_SERVER_PURPOSE_UPDATE = "serverpurpose/{ID}/";
export const METHOD_SERVER_PURPOSE_DELETE = "serverpurpose/{ID}/";
export const METHOD_SERVER_PURPOSE_DETAIL = "serverpurpose/{ID}/";

//text box length
export const TEXT_MIN_LENGTH = MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH = MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;
