import { networkService } from "./networkService";
import {
  METHOD_GET_STORAGE_FILTERS,
  METHOD_GET_STORAGE_EXPORT_FILTERS,
  METHOD_GET_STORAGE_PRODUCTS_CSV,
  METHOD_GET_STORAGE_PRODUCTS_EXPORT_CSV,
  METHOD_GET_STORAGE_PRODUCTS_PDF,
  METHOD_GET_STORAGE_PRODUCTS,
  METHOD_GET_STORAGE_EXPORT_PRODUCTS,
  ERROR_NO_RESULTS,
  METHOD_DONWLOAD_KEYWORDS,
  METHOD_DONWLOAD_ALL_KEYWORDS,
  METHOD_SEARCH_RESULTS,
  KEYWORD_SEARCH_FIELD,
  KEYWORD_SEARCH_CATEGORY,
  METHOD_POST_ACCESS_LOGS,
  METHOD_GET_ACCESS_LOGS,
  ZIP_ENABLED_PRODUCTS,
  ZIP_TRIGGER_COUNT,
  EXPORT_DATAPATH_CONFIRM_MSG,
  RESTRICTED_USERS_LIST,
} from "constants/productMatrix";
import { parseQueryParams } from "utils/utils";
import { toast } from "react-toastify";
import { store } from "index";
import _ from "lodash";
import history from "../history";
import { LOGGED_IN_USER_INFO } from "constants/common";

class productMatrixService {
  uniqid = null;

  getMatriceFilters = (payload, productId) => {
    const CONSTRUCTED_METHOD = METHOD_GET_STORAGE_FILTERS.replace(
      "{productId}",
      productId
    );
    return networkService
      .post(CONSTRUCTED_METHOD, payload)
      .then((response) => response.data);
  };
  getMatriceExportFilters = (payload, productId, downloadAction) => {
    const CONSTRUCTED_METHOD = METHOD_GET_STORAGE_EXPORT_FILTERS.replace(
      "{productId}",
      productId
    ).replace("{downloadAction}", downloadAction);
    return networkService
      .post(CONSTRUCTED_METHOD, payload)
      .then((response) => response.data);
  };
  getKeywordSuggestions = (productId) => {
    const CONSTRUCTED_METHOD = METHOD_DONWLOAD_KEYWORDS.replace(
      "{productId}",
      productId
    );
    return networkService
      .get(CONSTRUCTED_METHOD)
      .then((response) => response.data);
  };
  getKeywordAllSuggestions = () => {
    return networkService
      .get(METHOD_DONWLOAD_ALL_KEYWORDS)
      .then((response) => response.data);
  };
  downloadFile = (
    downloadType,
    requestedPagingParams,
    productId,
    totalRecords
  ) => {
    return new Promise((resolve, reject) => {
      if (!_.isEmpty(requestedPagingParams)) {
        const metaData = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const userInfo = sessionStorage.getItem(LOGGED_IN_USER_INFO)
          ? JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO))
          : {};
        let user_type = userInfo.user_type;
        if (
          user_type && 
          !RESTRICTED_USERS_LIST.includes(user_type) &&
          downloadType === "csv" &&
          ZIP_ENABLED_PRODUCTS.includes(productId) &&
          totalRecords >= ZIP_TRIGGER_COUNT
        ) {
          if (!window.confirm(EXPORT_DATAPATH_CONFIRM_MSG)) {
            history.push("/admin/export-datapath");
          }
          resolve();
        } else {
          let fileName = "SearchResult.csv";
          let methodName = METHOD_GET_STORAGE_PRODUCTS_CSV;
          if (downloadType === "pdf") {
            fileName = "SearchResult.pdf";
            methodName = METHOD_GET_STORAGE_PRODUCTS_PDF;
          }

          //zip archieve download stuff
          // if( (downloadType==="csv") && (ZIP_ENABLED_PRODUCTS.includes(productId) && totalRecords>=ZIP_TRIGGER_COUNT)){
          //   fileName = "SearchResult.zip";
          //   methodName = METHOD_GET_STORAGE_PRODUCTS_ZIP;
          //   metaData['responseType'] = 'arraybuffer';
          // }

          const CONSTRUCTED_METHOD = methodName.replace(
            "{productId}",
            productId
          );
          networkService
            .post(CONSTRUCTED_METHOD, requestedPagingParams, metaData)
            .then(
              (response) => {
                if (window.navigator && window.navigator.msSaveBlob) {
                  //for ie and edge browsers
                  window.navigator.msSaveBlob(
                    new Blob([response.data]),
                    fileName
                  );
                } else {
                  //for regular browsers
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  let a = document.createElement("a");
                  a.href = url;
                  a.setAttribute("download", fileName);
                  a.target = "_blank";
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                }
                resolve();
              },
              (err) => {
                resolve(); //resolve it from exception also as global extension handler will show the error and resolving this promise will hide the spinner
              }
            );
        }
      } else {
        toast.error(ERROR_NO_RESULTS);
        resolve();
      }
    });
  };
  downloadFileDatapathExport = (
    downloadType,
    requestedPagingParams,
    productId,
    downloadAction
  ) => {
    return new Promise((resolve, reject) => {
      if (!_.isEmpty(requestedPagingParams)) {
        const headers = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let fileName = `Datapath-${productId}-${downloadAction}.csv`;
        const CONSTRUCTED_METHOD =
          METHOD_GET_STORAGE_PRODUCTS_EXPORT_CSV.replace(
            "{productId}",
            productId
          ).replace("{downloadAction}", downloadAction);
        networkService
          .post(CONSTRUCTED_METHOD, requestedPagingParams, headers)
          .then(
            (response) => {
              if (window.navigator && window.navigator.msSaveBlob) {
                //for ie and edge browsers
                window.navigator.msSaveBlob(
                  new Blob([response.data]),
                  fileName
                );
              } else {
                //for regular browsers
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                let a = document.createElement("a");
                a.href = url;
                a.setAttribute("download", fileName);
                a.target = "_blank";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }
              resolve();
            },
            () => {
              resolve(); //resolve it from exception also as global extension handler will show the error and resolving this promise will hide the spinner
            }
          );
      } else {
        toast.error(ERROR_NO_RESULTS);
        resolve();
      }
    });
  };

  getLabelsArrayByIds = (field, ids) => {
    const matriceFilters = store.getState().productSearchReducer.matriceFilters;
    return ids
      .map(
        (id) =>
          matriceFilters[field]?.find(
            (option) => parseInt(option.id) === parseInt(id)
          )?.name
      )
      .filter((name) => name !== undefined);
  };

  writeAccessLog = () => {
    const url = window.location.href.split("?");
    if (this.uniqid === null) {
      this.uniqid = "req-" + Date.now(); //set current timestamp with prefix in case of uniqid is null at first time
    }

    if (!_.isEmpty(url[1])) {
      const payload = {
        product: window.location.pathname
          .replace("/products/", "")
          .replace("/search", "global-search"),
        url: window.location.pathname
          .replace("/products", "products")
          .replace("/search", "global-search"), //url[0],
        search_item: [],
        uniqid: this.uniqid,
      };
      const search_items = [];
      const urlData = parseQueryParams(window.location.search);
      Object.keys(urlData).map((field) => {
        if (field !== KEYWORD_SEARCH_CATEGORY) {
          search_items.push({
            name: field,
            value:
              field === KEYWORD_SEARCH_FIELD
                ? [urlData[field]]
                : this.getLabelsArrayByIds(field, urlData[field].split(",")),
          });
        }
        return true;
      });
      payload.search_item = search_items;
      return networkService
        .post(METHOD_POST_ACCESS_LOGS, payload)
        .then((response) => response.data)
        .catch((e) => console.log("Error in creating access logs"));
    }
  };
  downloadAccessLogs = () => {
    return new Promise((resolve, reject) => {
      const headers = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      //const currentTime = new Date();
      const fileName = "access_logs.csv"; //(currentTime.getMonth()+1)+'-'+ currentTime.getFullYear()+'-AccessLogs.csv';
      networkService.get(METHOD_GET_ACCESS_LOGS, headers).then(
        (response) => {
          if (window.navigator && window.navigator.msSaveBlob) {
            //for ie and edge browsers
            window.navigator.msSaveBlob(new Blob([response.data]), fileName);
          } else {
            //for regular browsers
            const url = window.URL.createObjectURL(new Blob([response.data]));
            let a = document.createElement("a");
            a.href = url;
            a.setAttribute("download", fileName);
            a.target = "_blank";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
          resolve();
        },
        () => {
          resolve(); //resolve it from exception also as global extension handler will show the error and resolving this promise will hide the spinner
        }
      );
    });
  };

  getPaginatedProducts = (payload, productId) => {
    const CONSTRUCTED_METHOD = METHOD_GET_STORAGE_PRODUCTS.replace(
      "{productId}",
      productId
    );
    //write access log
    this.writeAccessLog();
    //write access log
    return networkService
      .post(CONSTRUCTED_METHOD, payload)
      .then((response) => response.data);
  };
  getPaginatedExportProducts = (payload, productId, downloadAction) => {
    const CONSTRUCTED_METHOD = METHOD_GET_STORAGE_EXPORT_PRODUCTS.replace(
      "{productId}",
      productId
    ).replace("{downloadAction}", downloadAction);
    return networkService
      .post(CONSTRUCTED_METHOD, payload)
      .then((response) => response.data);
  };

  getPaginatedSearchResults = (payload) => {
    //write access log
    this.writeAccessLog();
    //write access log
    return networkService
      .post(METHOD_SEARCH_RESULTS, payload)
      .then((response) => response.data);
  };

  geMatriceFiltersMock = (payload, productId) => {
    return new Promise((resolve, reject) => {
      resolve(require(`../resources/json/product-${productId}.json`));
    });
  };
  getPaginatedProductsMock = (payload, productId) => {
    return new Promise((resolve, reject) => {
      resolve(require(`../resources/json/product-${productId}-results.json`));
    });
  };
}
export default productMatrixService;
