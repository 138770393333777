import { LOADING, AUTH_LOADING, LOGGED_IN_USER_INFO } from "constants/common";
import {
  SET_MENU_PREFRENCE,
  DEFAULT_MENU_PREFRENCE,
  MENU_PREFRENCE_LOCAL_STORAGE,
} from "constants/menus";
import { LOGIN_INFO } from "constants/login";
import authService from "services/authService";
import commonService from "services/commonService";

const commonServiceObj = new commonService();
export const verifyLogin = () => async (dispatch) => {
  const userInfo = sessionStorage.getItem(LOGGED_IN_USER_INFO)
    ? JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO))
    : {};

  if (userInfo && userInfo.id > 0) {
    dispatch({
      type: LOGIN_INFO,
      payload: userInfo,
    });

    //update the auth data if exists
    await authService.authenticate(userInfo);

    //set the default menu prefrence to redux
    const MENU_PREF = sessionStorage.getItem(MENU_PREFRENCE_LOCAL_STORAGE)
      ? parseInt(sessionStorage.getItem(MENU_PREFRENCE_LOCAL_STORAGE))
      : DEFAULT_MENU_PREFRENCE;

    await dispatch(updateMenuPrefrence(MENU_PREF));
    return true;
  }
};
export const doSsoLogin = (userInfo) => async (dispatch) => {
  if (userInfo && userInfo.id > 0) {
    dispatch({
      type: LOGIN_INFO,
      payload: userInfo,
    });
    //update the auth data if exists
    sessionStorage.setItem(LOGGED_IN_USER_INFO, JSON.stringify(userInfo));
    //set the default menu prefrence to redux
    const MENU_PREF = DEFAULT_MENU_PREFRENCE; //(sessionStorage.getItem(MENU_PREFRENCE_LOCAL_STORAGE))?parseInt(sessionStorage.getItem(MENU_PREFRENCE_LOCAL_STORAGE)):DEFAULT_MENU_PREFRENCE;
    await dispatch(updateMenuPrefrence(MENU_PREF));

    return true;
  }
};

export const updateMenuPrefrence = (data) => {
  sessionStorage.setItem(MENU_PREFRENCE_LOCAL_STORAGE, data);
  return {
    type: SET_MENU_PREFRENCE,
    payload: data,
  };
};

export const setMenuPrefrence = (data) => async (dispatch) => {
  await dispatch(updateMenuPrefrence(data));
};

export const logout = () => async (dispatch) => {
  const userInfo = sessionStorage.getItem(LOGGED_IN_USER_INFO)
    ? JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO))
    : {};
  if (userInfo?.type && userInfo?.type === "backdoor") {
    //this is case of backdoor user logout
    dispatch({
      type: LOGIN_INFO,
      payload: {},
    });
    dispatch({
      type: SET_MENU_PREFRENCE,
      payload: DEFAULT_MENU_PREFRENCE,
    });
    authService.logout();
  } else {
    //this is for sso logout (shall I add okta logout logic seperately or I can add here)
    // const response=await commonServiceObj.getSsoLogoutUrl();
    const response = await commonServiceObj.getOktaSsoLogoutUrl();
    if (response) {
      dispatch({
        type: LOGIN_INFO,
        payload: {},
      });
      dispatch({
        type: SET_MENU_PREFRENCE,
        payload: DEFAULT_MENU_PREFRENCE,
      });
      // authService.logout(response.logouturl);
    }
  }
};

export const doLogin = (payload) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  try {
    const userInfo = await commonServiceObj.doBackdoorLogin(payload);
    if (userInfo.access_token && userInfo?.id) {
      userInfo["type"] = "backdoor";
      dispatch({
        type: LOGIN_INFO,
        payload: userInfo,
      });
      authService.authenticate(userInfo);
      //set the default menu prefrence to redux
      const MENU_PREF = sessionStorage.getItem(MENU_PREFRENCE_LOCAL_STORAGE)
        ? parseInt(sessionStorage.getItem(MENU_PREFRENCE_LOCAL_STORAGE))
        : DEFAULT_MENU_PREFRENCE;
      await dispatch(updateMenuPrefrence(MENU_PREF));
    }
    dispatch({
      type: LOADING,
      payload: false,
    });
    return true;
  } catch (error) {
    dispatch({
      type: LOADING,
      payload: false,
    });
    return error;
  }
};
export const updateAuthLoaderState = (data) => {
  return {
    type: AUTH_LOADING,
    payload: data,
  };
};
