const FILTERS = {
    "ops-common-services":{
        common_services_version: {
            title: 'Common Services Version',
            icon: 'server.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        },
        jdk: {
            title: 'JDK',
            iconClass: 'project-diagram'
        },
        hypervisor: {
            title: 'Hypervisor',
            icon: 'array.png'
        },
        browser: {
            title: 'Browser',
            icon: 'multipath.png'
        },
    }
}
export default FILTERS;