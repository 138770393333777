import React, { Component } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ErrorMessage } from "components/shared/ErrorMessage";
import _ from "lodash";
import jobProgressService from "services/admin/masters/jobProgressService";
import Spinner from "components/shared/Spinner";
import Breadcrumb from "components/shared/Breadcrumb"; 
import { MSG_UPDATE_RECORD } from "constants/admin/masters/jobProgress";
import { handleChange, handleTouched,formFailure,getCurrentSlug,trimFields } from "utils/masterHelpers";
import { MAX_TEXT_LENGTH, MIN_TEXT_LENGTH } from "constants/common";
const serviceObj = new jobProgressService();
class Form extends Component {
  constructor(props) {
    super(props);
    this.handleChange=handleChange.bind(this);
    this.handleTouched=handleTouched.bind(this);
    this.formFailure=formFailure.bind(this);
    this.getCurrentSlug=getCurrentSlug.bind(this);
    this.trimFields=trimFields.bind(this);
    this.state = {
      isEditForm: false,
      Id: 0,
      formReady: false,
      isSubmitting: false,
      errors: {},
      touched: {},
      form: {
        name: "",
        is_job_running:"0"
      },
      breadcrumbs: [{ title: 'Job Progress List', url: '/admin/master/'+this.getCurrentSlug() },
      { title: 'Job Progress' }
      ]
    };
  }

  componentDidMount = async () => {
    const { Id } = this.props.match.params;
    if (Id > 0) {
      //repopulate the form
      this.setState({
        Id,
        isEditForm: true,
      });
      const Info = await serviceObj.getJobProgressDetail(Id);
      const form = this.state.form;
      form.name = Info.name;
      form.is_job_running = (Info.is_job_running)?"1":"0";
      this.setState(
        {
          form,
        },
        () => {
          this.setState({
            formReady: true,
          });
        }
      ); //populate in form
    } else { 
      //add now allowed on this screen
      toast.success("Action Not Allowed");
      this.props.history.push("/admin/master/"+this.getCurrentSlug());
      
    }
  };
   
  submitForm = async (e) => {
    e.preventDefault();
    this.trimFields(this.state.form);
    this.setState({
      isSubmitting: true,
    });
    const errors = await this.validateForm();
    
    if (_.isEmpty(errors)) {
      //now proceed to save user
      const form = this.state.form;
      try {
        const response = await serviceObj.updateJobProgress(form, this.state.Id);
        if (response) {
          toast.success(response.message ||  MSG_UPDATE_RECORD );
          this.props.history.push("/admin/master/"+this.getCurrentSlug());
        }
      } catch (err) {
        //failure case
        this.formFailure();
        if (err) {
          const errors = this.state.errors;
          for (const [field, msg] of Object.entries(err)) {
            errors[field] = msg[0];
          }
          await this.setState({
            errors,
          });
        }
      }
    } else {
      //failure case
      this.formFailure();
    }
  }; 
  validateForm = async () => {
    const errors = {};
    const schema = yup.object().shape({
      name: yup.string().required().min(MIN_TEXT_LENGTH).max(MAX_TEXT_LENGTH),
      is_job_running: yup.number().required(),
    });
    // check validity
    await schema
      .validate(this.state.form, { abortEarly: false })
      .catch(function (err) {
        err.inner.map((error) =>
          !errors[error.path] ? (errors[error.path] = error.message) : null
        );
      });

    this.setState({
      errors,
    });
    return errors; //return basicerrors
  };

  render() {
    const {
      isSubmitting,
      form,
      touched,
      errors,
      isEditForm,
      formReady,
      breadcrumbs
    } = this.state;
    return (
      <div className="form-wrap mt-3">
        {isSubmitting ? <Spinner /> : null}
        {formReady ? (
          <div className="form-group">
            <Breadcrumb data={breadcrumbs} isEdit={isEditForm} />
            <form onSubmit={this.submitForm}>
              <div className="form-group">
                <label htmlFor="name">Job Name</label>
                <input
                  type="text"
                  name="name"
                  readOnly
                  //onChange={this.handleChange}
                  defaultValue={form.name}
                  className={`form-control ${
                    touched.name && errors.name
                      ? "is-invalid"
                      : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="name"
                  className="text-danger"
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Is Job Running</label>
                  <div className="pl-1">
                      <div className="form-check form-check-inline">
                      <input checked={form.is_job_running==="1"} onChange={this.handleChange} className="form-check-input" type="radio" name="is_job_running" id="inlineRadio1" value="1" />
                      <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input checked={form.is_job_running==="0"} onChange={this.handleChange} className="form-check-input" type="radio" name="is_job_running" id="inlineRadio2" value="0" />
                      <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                    </div>
                  </div>
               

               
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="name"
                  className="text-danger"
                />
              </div>
              <button
                type="submit"
                className="btn btn-secondary btn-block"
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? "Please wait..."
                  : isEditForm
                    ? "Update"
                    : "Add"}
              </button>
            </form>
          </div>
        ) : (
            <Spinner />
          )}
      </div>
    );
  }
}

export default Form;
