import React, { Component } from "react";
import PropTypes from "prop-types";
import { spinnerService } from 'services/spinnerService';
import "resources/css/spinner.css"

export default class SpinnerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: this.props.hasOwnProperty('show') ? this.props.show : false
        };
        if (this.props.hasOwnProperty('spinnerService')) {
            this.spinnerService = this.props.spinnerService;
        } else {
            this.spinnerService = spinnerService;
        }
        this.spinnerService._register(this);
    }
    componentWillUnmount() {
        this.spinnerService._unregister(this);
    }

    get name() {
        return this.props.name;
    }

    get group() {
        return this.props.group;
    }

    get show() {
        return this.state.show;
    }

    set show(show) {
        this.setState({ show });
    }


    render = () => (
    (this.state.show)?(<div className="spinner">{(this.props.children)?this.props.children:null}</div>):null
    )
}
SpinnerComponent.propTypes = {
    name: PropTypes.string
};