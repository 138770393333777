import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { MENUS_DATA, MENU_CATEGORIES } from "constants/menus";
import DropdownMenu from "./DropdownMenu";
import _ from "lodash";
import authService from "services/authService";
import { connect, useSelector } from "react-redux";
import ALLRoles from "resources/json/roles.json";
import { transformMenuDropdownData } from "utils/utils";
import { getLegacyProductMenu } from "actions/productSearchActions";
import { LOGGED_IN_USER_INFO } from "constants/common";
import { useDispatch } from "react-redux";
import { getMegaMenuOptions } from "actions/commonActions";
export const Menus = (props) => {
  const dispatch = useDispatch();
  const ROLES = {};
  ALLRoles.map((r) => (ROLES[r.slug] = r.id));
  let userData = authService.userData;

  const sessionUserData = sessionStorage.getItem(LOGGED_IN_USER_INFO);

  if (JSON.parse(sessionUserData)?.id) {
    userData = JSON.parse(sessionUserData);
  }
  const publicRole = ALLRoles.find((role) => role.slug === "PUBLIC_USER");
  const userRoles = userData.id ? userData.roles.map((role) => role.id) : [];
  const checkRoles = [ROLES.SYS_ADMIN, ROLES.PROD_ADMIN].some((i) =>
    userRoles.includes(i)
  );
  const masterMenusData = userData.id ? userData.master_menu_json : [];
  const [dropdownToggle, setDropdownToggle] = useState({});
  //outside click handeling
  const itemsRef = useRef([]);
  const handleOutsideClick = useCallback(
    (event) => {
      let contains = 0;
      if (itemsRef.current) {
        itemsRef.current.map((item, index) => {
          if (item && item.contains(event.target)) {
            contains++;
          }
          return true;
        });
        if (contains === 0) {
          setDropdownToggle({}); //close the main dropdown
        }
      }
    },
    [itemsRef]
  );

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return function cleanup() {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  //outside click handeling

  const dropdownTogglehandler = (index) => {
    
    setDropdownToggle({
      [index]: dropdownToggle[index] ? false : true,
    });
  };
  const clickedNavMenu = (clickedOption) =>{
  
    if(clickedOption === "Home"){
      // dispatch action to update tab reducer with tab key "Products"
      dispatch(getMegaMenuOptions("Products"));
    }
    props.getLegacyProductMenu([]);
  }

  const getMenu = (menu, index) => {
    const activeClass = dropdownToggle[index] ? "active" : "inactive";
    return !menu.restrictedUsers.includes(userData.user_type) ? (
      menu.hasSubmenu ? (
        <li
          ref={(el) => (itemsRef.current[index] = el)}
          key={index}
          onClick={() => dropdownTogglehandler(index)}
          className={`nav-item dropdown nav-link dropdown-toggle ${activeClass}`}
        >
          {menu.title} 
          <DropdownMenu childrens={menu.childrens} />
        </li>
      ) : (
        <Nav.Link
          as={Link}
          key={index}
          to={menu.route}
          onClick={()=>clickedNavMenu(menu.title)}
        >
          {menu.title} 
        </Nav.Link>
      )
    ) : null;
  };

  const { menuPrefrence } = useSelector((state) => ({
    menuPrefrence: state.loginReducer.menuPrefrence,
  }));
  const isPublicRoleOnly = () =>
    userRoles.length === 1 && userRoles[0] === publicRole.id;

  const masterMenuIndex = MENUS_DATA.length + 1;
  const masterMenus = transformMenuDropdownData(masterMenusData);
  // add some static menus for some masters at ui level for system admin
  const systemAdminRole = ALLRoles.find((role) => role.slug === "SYS_ADMIN");
  if (!_.isEmpty(_.intersection(userRoles, [systemAdminRole.id]))) {
    masterMenus.push({
      title: "Legacy Product Assets",
      route: "/admin/master/legacy-assets/",
      hasSubmenu: false,
      childrens: [],
    });
  }
  // add some static menus for some masters at ui level for system admin

  return (
    <>
      {MENUS_DATA.map((menu, index) =>
        !userData.id || isPublicRoleOnly() //public menus
          ? MENU_CATEGORIES.PUBLIC_MENU === menu.menuCategory
            ? getMenu(menu, index)
            : null
          : //non public menus but according toselcted menu toggle(user/admin),user/admin role,product permission etc etc,
          menuPrefrence && menuPrefrence === menu.menuCategory
          ? menu.requiredRoles
            ? //if menu is having requiredRoles
              !_.isEmpty(_.intersection(userRoles, menu.requiredRoles))
              ? //show the menu if user having the required role
                getMenu(menu, index)
              : null
            : //public or sys admin case
              getMenu(menu, index)
          : null
      )}
      {
        /*Masters dropdown*/
        userData.id &&
        checkRoles &&
        menuPrefrence &&
        menuPrefrence === MENU_CATEGORIES.ADMIN_MENU ? (
          <li
            ref={(el) => (itemsRef.current[masterMenuIndex] = el)}
            key={masterMenuIndex}
            onClick={() => dropdownTogglehandler(masterMenuIndex)}
            className={`nav-item dropdown nav-link dropdown-toggle ${
              dropdownToggle[masterMenuIndex] ? "active" : "inactive"
            }`}
          >
            Masters
            <DropdownMenu childrens={masterMenus} searchBar={true} />
          </li>
        ) : null
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.productSearchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLegacyProductMenu: (payload) => dispatch(getLegacyProductMenu(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Menus);
