const FILTERS = {
    "ucp-vmware": { 
        vmware_solution_type: {
            title: 'VMware Solution Type',
            iconClass: 'network-wired'
        },

        ucp_platform : {
            title: 'UCP Platform',
            icon: 'server.png',
            singleSelection : true
        },
        server_model: {
            title: 'Server Model',
            icon: 'server.png'
        },
        cpu_generation: {
            title: 'CPU Generation',
            iconClass: 'project-diagram'
        },
        server_software_version: {
            title: 'Server Software Version',
            icon: 'server.png'
            
        },
        server_service_pack : {
            title : "server_service_pack",
            icon : "server.png"
        },
        bios_bmc: {
            title: 'BIOS/BMC',
            icon: 'firmware.png'
        },
        vmware_vcf_version : {
            title: 'VMware VCF Version',
            icon: 'server.png'
        },
        operating_system: {
            title: 'Operating System',
            icon: 'os.png'
        },
        controller:{
            title : 'Controller',
            icon : 'server.png',
            dynamic: true
        },
        raid_controller :{
            title : 'raid_controller',
            icon : 'server.png',
        },
        sas_controller : {
            title : 'sas_controller',
            icon : 'server.png',
        },
        nic: {
            title: 'NIC',
            icon: 'hba_driver.png'
        },
        hba: {
            title: 'HBA ',
            icon: 'hba.png'
        },
        gpu: {
            title: 'GPU',
            icon: 'hba.png'
        },
        drive: {
            title: 'Drive',
            icon : 'hba_driver.png'
        }
    }
}
export default FILTERS;