import {
  LOADING, ADMIN_SETTINGS, SERVER_ERRORS
} from "constants/common";

const initialState = {
  loading: false,
  serverErrors: [],
  settingsData: [],
};

const adminSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ADMIN_SETTINGS:
      return {
        ...state,
        settingsData: action.payload,
      };
    case SERVER_ERRORS:
      return {
        ...state,
        serverErrors: action.payload,
      };

    default:
      return state;
  }
};
export default adminSettingsReducer;
