import { networkService } from "services/networkService";
import {
  METHOD_CLOUD_PROVIDER_LIST,
  METHOD_CLOUD_PROVIDER_CREATE,
  METHOD_CLOUD_PROVIDER_UPDATE,
  METHOD_CLOUD_PROVIDER_DELETE,
  METHOD_CLOUD_PROVIDER_DETAIL,
} from "constants/admin/masters/hybridCloud";

class HybridCloudService {
  getAllHybridCloud = (data) => {
    return networkService
      .post(METHOD_CLOUD_PROVIDER_LIST, data)
      .then((response) => response.data);
  };
  getHybridCloudDetail = (id) => {
    const updatedMethodName = METHOD_CLOUD_PROVIDER_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHybridCloud = (data) => {
    return networkService
      .post(METHOD_CLOUD_PROVIDER_CREATE, data)
      .then((response) => response.data);
  };
  updateHybridCloud = (data, id) => {
    const updatedMethodName = METHOD_CLOUD_PROVIDER_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHybridCloud = (id, is_delete = false) => {
    const updatedMethodName = METHOD_CLOUD_PROVIDER_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default HybridCloudService;
