const FILTERS = {
    "sdsb-certification": {
        sc_product: {
            title: 'Client Platform',
            icon: 'server.png'
        },
        protocol: {
            title: 'Protocol',
            iconClass: 'network-wired'
        },        
        hvssb_software_version: {
            title: 'Software Version',
            icon: 'array.png'
        },        
        partner: {
            title: 'Partner',
            icon: 'os.png'
        },
        certification: {
            title: 'Certification',
            icon: 'os_kernel.png'
        },
        multipath_software: {
            title: 'Multipath Software',
            icon: 'multipath.png'
        }
    }
}
export default FILTERS;