import {
  LOADING,
  RELATED_MASTERS,
  DISCLAIMER_ACCEPTANCE,
  JOB_PROGRESS_PROCESS_CHECK,
  EOSL_MASTER_PRODUCTS,
  MEGA_MENU_OPTION,
  IS_REDIRECTED_TO_PRODUCT_TAB
} from "constants/common";

const initialState = {
  loading: false,
  eoslMasterProducts: [],
  relatedMasters: [],
  disclaimerAcceptance: false,
  jobProgressProcessCheck: {},
  megaMenuOption: "Products",
  linkedFromSolutionToProduct: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case RELATED_MASTERS:
      return {
        ...state,
        relatedMasters: action.payload,
      };
    case EOSL_MASTER_PRODUCTS:
      return {
        ...state,
        eoslMasterProducts: action.payload,
      };
    case DISCLAIMER_ACCEPTANCE:
      return {
        ...state,
        disclaimerAcceptance: action.payload,
      };
    case JOB_PROGRESS_PROCESS_CHECK:
      return {
        ...state,
        jobProgressProcessCheck: action.payload,
      };
    case MEGA_MENU_OPTION:
      return {
        ...state,
        megaMenuOption: action.payload,
      };
    case IS_REDIRECTED_TO_PRODUCT_TAB:
      return {
        ...state,
        linkedFromSolutionToProduct: action.payload,
      };
    default:
      return state;
  }
};
export default commonReducer;
