import { networkService } from "services/networkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,
  METHOD_ALL_FLAT_PRODUCTS,
} from "constants/admin/users";
class userService {
  getAll = (data) => {
    return networkService
      .post(METHOD_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (userId) => {
    
    const updatedMethodName = METHOD_DETAIL.replace("{USER_ID}", userId);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, userId) => {
    const updatedMethodName = METHOD_UPDATE.replace("{USER_ID}", userId);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (userId) => {
    const updatedMethodName = METHOD_DELETE.replace("{USER_ID}", userId);
    return networkService
      .delete(updatedMethodName)
      .then((response) => response.data);
  };
  getAllFlatProducts = () => {
    return networkService
      .get(METHOD_ALL_FLAT_PRODUCTS)
      .then((response) => response.data);
  }
}
export default userService;
