import { networkService } from "services/networkService";
import {
    METHOD_BAREMETAL_SOLUTION_TYPE_LIST,
    METHOD_BAREMETAL_SOLUTION_TYPE_CREATE,
    METHOD_BAREMETAL_SOLUTION_TYPE_UPDATE,
    METHOD_BAREMETAL_SOLUTION_TYPE_DELETE ,
    METHOD_BAREMETAL_SOLUTION_TYPE_DETAIL,
    

    METHOD_BAREMETAL_VERSION_LIST,
    METHOD_BAREMETAL_VERSION_CREATE,
    METHOD_BAREMETAL_VERSION_UPDATE,
    METHOD_BAREMETAL_VERSION_DELETE,
    METHOD_BAREMETAL_VERSION_DETAIL
} from "constants/admin/masters/baremetal";

class BaremetalService {

    // BAREMETAL SOLUTION TYPE METHODS
    getAllBaremetalSolutionType = (data) => {
      return networkService
        .post(METHOD_BAREMETAL_SOLUTION_TYPE_LIST, data)
        .then((response) => response.data);
    };   
    getBaremetalSolutionTypeDetail = (id) => {
      const updatedMethodName = METHOD_BAREMETAL_SOLUTION_TYPE_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createBaremetalSolutionType = (data) => {
      return networkService
        .post(METHOD_BAREMETAL_SOLUTION_TYPE_CREATE, data)
        .then((response) => response.data);
    };
    updateBaremetalSolutionType = (data, id) => {
      const updatedMethodName = METHOD_BAREMETAL_SOLUTION_TYPE_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteBaremetalSolutionType = (id,is_delete=false) => {
      const updatedMethodName = METHOD_BAREMETAL_SOLUTION_TYPE_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };


// Bare Metal Version 

getAllBaremetalVersion = (data) => {
  return networkService
    .post(METHOD_BAREMETAL_VERSION_LIST, data)
    .then((response) => response.data);
};   
getBaremetalVersionDetail = (id) => {
  const updatedMethodName = METHOD_BAREMETAL_VERSION_DETAIL.replace("{ID}", id);
  return networkService
    .get(updatedMethodName)
    .then((response) => response.data);
};
createBaremetalVersion= (data) => {
  return networkService
    .post(METHOD_BAREMETAL_VERSION_CREATE, data)
    .then((response) => response.data);
};
updateBaremetalVersion = (data, id) => {
  const updatedMethodName = METHOD_BAREMETAL_VERSION_UPDATE.replace("{ID}", id);
  return networkService
    .post(updatedMethodName, data)
    .then((response) => response.data);
};
deleteBaremetalVersion = (id,is_delete=false) => {
  const updatedMethodName = METHOD_BAREMETAL_VERSION_DELETE.replace("{ID}", id);
  return networkService
    .delete(updatedMethodName,{ data: { is_delete }})
    .then((response) => response.data);
};

}
export default BaremetalService;
