import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';

class ErrorDialog extends Component {

  constructor(props){
    super(props);
    this.state={
      confirmState:false
    }
  }

  handleSubmit = async flag => {
    await this.setState({
      confirmState:flag
    })
    this.props.actionHandler(this.state);
  }

  render(){
    const {modalTitle,ConfirmButtonText,children}=this.props; 
    return (
      <Modal
        show={true}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-center disclaimer-header">
          <Modal.Title className="w-100" >
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div>{children}</div>         
        </Modal.Body>
        <Modal.Footer>
          <p className="text-center"> 
            <button className="btn btn-branding" onClick={()=>this.handleSubmit(true)}>{ConfirmButtonText}</button>
          </p>
          </Modal.Footer>
      </Modal>
    );
  }
}
// Specifies the default values for props:
ErrorDialog.defaultProps = {
  modalTitle: 'Error',
  ConfirmButtonText:'Ok'
};
ErrorDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element]).isRequired,
  actionHandler: PropTypes.func.isRequired
  
};
export default ErrorDialog;


  