const FILTERS = {
    vropssdsb: {
        vrops_release: {
            title: 'vROps Release',
            icon: 'server.png'
        },
        managementpack_version: {
            title: 'Management Pack Version',
            icon: 'os_kernel.png'
        },
        hvssb_software_version: {
            title: 'Software Version',
            icon: 'switch.png'
        },
        protocol: {
            title: 'Protocol',
            icon: 'os_kernel.png'
        }
    }
}
export default FILTERS;