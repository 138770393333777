const FILTERS = {
    tc: {
        primary_array: {
            title: 'Primary Array',
            icon: 'array.png'
        },
        primary_switch: {
            title: 'Primary Switch',
            icon: 'switch.png'
        },
        primary_switch_firmware: {
            title: 'Primary Switch Firmware',
            icon: 'firmware.png'
        },
        extender_switch: {
            title: 'Extender',
            icon: 'server.png'
        },
        extender_firmware: {
            title: 'Extender Firmware',
            icon: 'os.png'
        },
        protocol: {
            title: 'Protocol',
            iconClass: 'network-wired'
        },
        link_speed: {
            title: 'Link Speed',
            icon: 'hba.png'
        },
        secondary_switch: {
            title: 'Secondary Switch',
            icon: 'multipath.png'
        },
        secondary_switch_firmware: {
            title: 'Secondary Switch Firmware',
            icon: 'hba_driver.png'
        },
        secondary_array: {
            title: 'Secondary Array',
            icon: 'array.png'
        },
        primary_array_if: {
            title: 'Primary Array Interface',
            icon: 'array_if.png'
        },
        secondary_array_if: {
            title: 'Secondary Array Interface',
            iconClass: 'project-diagram'
        }
    }
}
export default FILTERS;