import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//viewpoint-version methods
export const METHOD_VIEWPOINTVERSION_LIST = 'viewpointversion/pageable/';
export const METHOD_VIEWPOINTVERSION_CREATE = 'viewpointversion/';
export const METHOD_VIEWPOINTVERSION_UPDATE = 'viewpointversion/{ID}/';
export const METHOD_VIEWPOINTVERSION_DELETE = 'viewpointversion/{ID}/';
export const METHOD_VIEWPOINTVERSION_DETAIL = 'viewpointversion/{ID}/';
export const METHOD_VIEWPOINTVERSION_DROP = 'viewpointversion/drop/';
//javadevkit methods
export const METHOD_JAVADEVKIT_LIST = 'javadevkit/pageable/';
export const METHOD_JAVADEVKIT_CREATE = 'javadevkit/';
export const METHOD_JAVADEVKIT_UPDATE = 'javadevkit/{ID}/';
export const METHOD_JAVADEVKIT_DELETE = 'javadevkit/{ID}/';
export const METHOD_JAVADEVKIT_DETAIL = 'javadevkit/{ID}/';
export const METHOD_JAVADEVKIT_DROP = 'javadevkit/drop/';

//browser methods
export const METHOD_BROWSER_LIST = 'browser/pageable/';
export const METHOD_BROWSER_CREATE = 'browser/';
export const METHOD_BROWSER_UPDATE = 'browser/{ID}/';
export const METHOD_BROWSER_DELETE = 'browser/{ID}/';
export const METHOD_BROWSER_DETAIL = 'browser/{ID}/';
export const METHOD_BROWSER_DROP = 'browser/drop/';

//hypervisor methods
export const METHOD_HYPERVISOR_LIST = 'viewpointhypervisor/pageable/';
export const METHOD_HYPERVISOR_CREATE = 'viewpointhypervisor/';
export const METHOD_HYPERVISOR_UPDATE = 'viewpointhypervisor/{ID}/';
export const METHOD_HYPERVISOR_DELETE = 'viewpointhypervisor/{ID}/';
export const METHOD_HYPERVISOR_DETAIL = 'viewpointhypervisor/{ID}/';
export const METHOD_HYPERVISOR_DROP = 'viewpointhypervisor/drop/';



//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;