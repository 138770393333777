import {
  ADMIN_SETTINGS,
  LOADING,
  SERVER_ERRORS
} from "constants/common";
import { encodeBase64 } from "utils/utils";
import commonService from "services/commonService";
const commonServiceObj = new commonService();

export const getAdminSettings = () => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  commonServiceObj.getAdminSettings().then((response) => {
    dispatch({
      type: ADMIN_SETTINGS,
      payload: response,
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  }, (err) => {
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};


export const saveAdminSettings = (formData) => async (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  dispatch({
    type: SERVER_ERRORS,
    payload: [],
  });

  try {
    //found and encrypt urls in the form data
    let encryptedData = JSON.parse(JSON.stringify(formData));//deep copy
    encryptedData = encryptedData.map(group => {
      group.adminsettings.map(field => {
        field.value =  encodeBase64(field.value);
        return field;
      })
      return group;
    })
    //found and encrypt urls in the form data
    const response = await commonServiceObj.saveAdminSettings(encryptedData);
    if (response) {
      dispatch({
        type: ADMIN_SETTINGS,
        payload: response,
      });
    }
    dispatch({
      type: LOADING,
      payload: false,
    });
    return true;
  } catch (errors) {
    dispatch({
      type: LOADING,
      payload: false,
    });
    dispatch({
      type: SERVER_ERRORS,
      payload: errors,
    });

    return false;
  }
};