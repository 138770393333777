import { networkService } from "services/networkService";
import {
  METHOD_VMWARE_SOLUTION_TYPE_LIST,
  METHOD_VMWARE_SOLUTION_TYPE_CREATE,
  METHOD_VMWARE_SOLUTION_TYPE_UPDATE,
  METHOD_VMWARE_SOLUTION_TYPE_DELETE,
  METHOD_VMWARE_SOLUTION_TYPE_DETAIL,

  METHOD_UCP_RELEASE_VERSION_LIST,
  METHOD_UCP_RELEASE_VERSION_CREATE,
  METHOD_UCP_RELEASE_VERSION_DELETE,
  METHOD_UCP_RELEASE_VERSION_DETAIL,
  METHOD_UCP_RELEASE_VERSION_UPDATE,

  METHOD_VMWARE_VCF_VERSION_LIST,
  METHOD_VMWARE_VCF_VERSION_DETAIL,
  METHOD_VMWARE_VCF_VERSION_CREATE,
  METHOD_VMWARE_VCF_VERSION_UPDATE,
  METHOD_VMWARE_VCF_VERSION_DELETE,

  METHOD_MANAGEMENT_SOFTWARE_VERSION_LIST,
  METHOD_MANAGEMENT_SOFTWARE_VERSION_DETAIL,
  METHOD_MANAGEMENT_SOFTWARE_VERSION_CREATE,
  METHOD_MANAGEMENT_SOFTWARE_VERSION_UPDATE,
  METHOD_MANAGEMENT_SOFTWARE_VERSION_DELETE
  
} from "constants/admin/masters/vmware";
class VMwareService {

  //VMWARE SOLUTION TYPE METHODS
  getAllVmwareSolutionType = (data) => {
    return networkService
      .post(METHOD_VMWARE_SOLUTION_TYPE_LIST, data)
      .then((response) => response.data);
  };
  getVmwareSolutionTypeDetail = (id) => {
    const updatedMethodName = METHOD_VMWARE_SOLUTION_TYPE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createVmwareSolutionType = (data) => {
    return networkService
      .post(METHOD_VMWARE_SOLUTION_TYPE_CREATE, data)
      .then((response) => response.data);
  };
  updateVmwareSolutionType = (data, id) => {
    const updatedMethodName = METHOD_VMWARE_SOLUTION_TYPE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVmwareSolutionType = (id,is_delete=false) => {
    const updatedMethodName = METHOD_VMWARE_SOLUTION_TYPE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };

  //UCP Release Version
  getAllUCPReleaseVersion = (data) => {
    return networkService
      .post(METHOD_UCP_RELEASE_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getDetailUCPReleaseVersion = (id) => {
    const updatedMethodName = METHOD_UCP_RELEASE_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createUCPReleaseVersion = (data) => {
    return networkService
      .post(METHOD_UCP_RELEASE_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateUCPReleaseVersion = (data, id) => {
    const updatedMethodName = METHOD_UCP_RELEASE_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteUCPReleaseVersion = (id,is_delete=false) => {
    const updatedMethodName = METHOD_UCP_RELEASE_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };

  //VMWARE VCF Version
  getAllVmwareVcfVersion = (data) => {
    return networkService
      .post(METHOD_VMWARE_VCF_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getDetailVmwareVcfVersion = (id) => {
    const updatedMethodName = METHOD_VMWARE_VCF_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createVmwareVcfVersion = (data) => {
    return networkService
      .post(METHOD_VMWARE_VCF_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateVmwareVcfVersion = (data, id) => {
    const updatedMethodName = METHOD_VMWARE_VCF_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVmwareVcfVersion = (id,is_delete=false) => {
    const updatedMethodName = METHOD_VMWARE_VCF_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };

  //Management Software Version
  getAllVDIManagementSoftwareVersion = (data) => {
    return networkService
      .post(METHOD_MANAGEMENT_SOFTWARE_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getDetailVDIManagementSoftwareVersion = (id) => {
    const updatedMethodName = METHOD_MANAGEMENT_SOFTWARE_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createVDIManagementSoftwareVersion = (data) => {
    return networkService
      .post(METHOD_MANAGEMENT_SOFTWARE_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateVDIManagementSoftwareVersion = (data, id) => {
    const updatedMethodName = METHOD_MANAGEMENT_SOFTWARE_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVDIManagementSoftwareVersion = (id,is_delete=false) => {
    const updatedMethodName = METHOD_MANAGEMENT_SOFTWARE_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };

}
export default VMwareService;
