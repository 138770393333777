import React from "react";
export const API_URL = process.env.REACT_APP_API_URL;
export const GA_TAG_ID = process.env.REACT_APP_GA_TAG_ID;
export const NAVBAR_LOGO = "Hitachi Vantara";
export const APP_NAME = process.env.REACT_APP_TITLE;
export const ENV_HEAD_COLOR = process.env.REACT_APP_ENV_COLOR;
export const APP_REFERRER = process.env.REACT_APP_REFERRER;
export const APP_SITEAVAILABILITY_URL =
  process.env.REACT_APP_SITEAVAILABILITY_URL;
export const PROD_ENVS = ["production", "uat"];

//API Method Constants
export const METHOD_GET_ALL_PRODUCTS = "getAllProducts/";
export const METHOD_ADMIN_SETINGS = "adminsettings/";
export const METHOD_ALL_RELATED_MASTERS = "products/getrelatedmatrix/";
export const METHOD_ALL_EOSL_MASTER_PRODUCTS = "products/geteoslmatrix/";
export const METHOD_ENCRYPT_PASSWORD = "encrypt/";
export const METHOD_LEGACY_LINKS = "legacyproducts/{productId}/";
export const METHOD_SHOW_DOWNTIME_BANNER = "adminsettings/general/banner/";

//Action Constants
export const ALL_CATEGORY_PRODUCTS = "ALL_CATEGORY_PRODUCTS";
export const LOADING = "LOADING";
export const AUTH_LOADING = "AUTH_LOADING";
export const ADMIN_SETTINGS = "ADMIN_SETTINGS";
export const SERVER_ERRORS = "SERVER_ERRORS";
export const RELATED_MASTERS = "RELATED_MASTERS";
export const EOSL_MASTER_PRODUCTS = "EOSL_MASTER_PRODUCTS";
export const DISCLAIMER_ACCEPTANCE = "DISCLAIMER_ACCEPTANCE";
export const SET_USER_ADDITIONAL_INFO = "SET_USER_ADDITIONAL_INFO";
export const JOB_PROGRESS_PROCESS_CHECK = "JOB_PROGRESS_PROCESS_CHECK";
export const IS_REDIRECTED_TO_PRODUCT_TAB = "IS_REDIRECTED_TO_PRODUCT_TAB";

//other utility constants for SessionStorage
//export const BEARER_TOKEN_NAME = 'OMNI-GUIDE-BEARER-TOKEN';
export const LOGGED_IN_USER_INFO = "OMNI-GUIDE-USER-INFO";
export const OKTA_TOKEN_INFO = "okta-token-storage";
export const OKTA_CACHE_STORAGE = "okta-cache-storage";
//ROLE SLUG CONSTANTS
export const ROLE_SLUG_PROD_ADMIN = "PROD_ADMIN";
export const ROLE_SLUG_SYS_ADMIN = "SYS_ADMIN";
export const ROLE_SLUG_PUBLIC_USER = "PUBLIC_USER";
export const ROLE_SLUG_LAB_USER = "LAB_USER";

//messages
export const GENERAl_ERROR_ADMIN_SETTINGS_FORM =
  "There are some error(s) in form, please check all the fields.";
export const SUCCESS_MESSAGE_ADMIN_SETTINGS_FORM =
  "Admin Settings updated successfully.";
export const ERROR_MESSAGE_401 =
  "Your session has been expired, please login again.";
export const ERROR_MESSAGE_UNKNOWN =
  "Unknown error occured, please contact with administrator.";
export const NOTE_MAX_FILTER_SUGGESSTION =
  'Note: Please select the filters and click "View Results"';
export const ERROR_MESSAGE_MIN_FILTERS =
  "Please select minimum {COUNT} different filters to view the search result";

  // tab constants
export const MEGAMENU_TAB_OPTIONS = ["Products", "Solutions"];

//master form validation related constants
export const MIN_TEXT_LENGTH = 3;
export const MAX_TEXT_LENGTH = 98;
export const MAX_TEXT_LENGTH_200 = 198;

//masters common warnings and mesages
export const MSG_RECORD_CREATE = "Record created successfully.";
export const MSG_RECORD_UPDATE = "Record updated successfully.";
export const MSG_RECORD_DELETE = "Record deleted successfully.";
export const MSG_RECORD_UNDO = "Record has been marked active successfully.";
export const MSG_DELETE_WARNING =
  'Are you sure you want to delete "ITEM_NAME"? All datapaths associated with it will be deleted.';
export const MSG_UNDO_DELETE_WARNING =
  'Are you sure you want to undelete "ITEM_NAME"?.';

export const DISCLAIMER_TITLE = "Legal Disclaimer";
export const DISCLAIMER_TEXT =
  "Information contained on this site is provided by Hitachi Vantara for general information purposes only, is based on information available as of the date of preparation or last update and is subject to change without notice.  Hitachi Vantara accepts no obligation to update the information or keep it current. By making this information available, Hitachi Vantara makes no express or implied warranties of any kind whatsoever regarding the information or the performance or other aspects of the products referred to on this site.  Warranties, if any, for any product described on this site are provided in separate agreements under which such product was purchased.  Hitachi Vantara expressly disclaims, to the maximum extent permitted by law, all warranties related to information provided on this site including, without limitation, any implied warranties of satisfactory quality, merchantability or fitness for a particular purpose, non-infringement, or any warranty of title.  Hitachi, by making information available on this site, does not warrant that any product or service will operate uninterrupted, securely, or error free.  Hitachi Vantara will have no liability whatsoever for any direct loss or damage (but excluding any liability for death, personal injury or fraudulent misrepresentation) or for any indirect, punitive, special, incidental or consequential damages, including but not limited to loss or corruption of data or records, loss of actual or anticipated business, revenue or profits or other economic loss arising out of or in connection with the use of information on this site even if such loss was foreseeable or Hitachi Vantara had been advised of the possibility of such loss.";
export const LEGACY_SUPPORT_TEXT =
  "Looking for legacy information? Check out our ";
export const LEGACY_SUPPORT_LINK_TEXT = "Legacy Product Support";
export const LEGACY_SUPPORT_TEXT_SAP_HANA = "Additional information: ";
export const SUPPORT_TEXT_PROTECTOR = "Protector Application Support";
export const LEGACY_SUPPORT_LINK_TEXT_SAP_HANA =
  "Hitachi Solution for SAP HANA Platform Support Matrix";
export const SUPPORT_TEXT_VMWARE = "Looking for latest ";
export const SUPPORT_LINK_TEXT_VMWARE = "PDF version";
export const ASTERISK_NOTE_TEXT = (
  <>
    Items marked with <span className="asterisk-style">*</span> (asterisk) are
    EOSL components
  </>
);
export const ASTERISK_NOTE_TEXT_HVSSB = (
  <>
    Items marked with <span className="asterisk-style">*</span> (asterisk)
    requires PM approval to share with External Parties/Customers
  </>
);
export const ASTERISK_NOTE_TEXT_EOSL =(
<>Items marked with * (asterisk) are EOSL components</>);

export const DOUBLE_ASTERISK_NOTE_TEXT = (
  <>
    Vendor has currently withdrawn support for switch firmware marked with{" "}
    <span className="asterisk-style">**</span> for selected configuration
  </>
);
export const LEGACY_SUPPORT_TEXT_ONE_OPENSTACK = "About community driver: ";
export const LEGACY_SUPPORT_LINK_TEXT_ONE_OPENSTACK =
  "OpenStack community site";
export const LEGACY_SUPPORT_TEXT_TWO_OPENSTACK = "About RHOSP in-tree driver: ";
export const LEGACY_SUPPORT_LINK_TEXT_TWO_OPENSTACK =
  "Red Hat Ecosystem Catalog site";
//Date Format
export const DATE_FORMAT = "MM/DD/YYYY HH:mm:ss";

export const OS_FIELD_NAME = "Operating System";
export const VCF_VERSION_FIELD_NAME = "VMware VCF Version";

export const MEGA_MENU_OPTION = "Products";