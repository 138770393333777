const FILTERS = {
    hcsf: {
        hcsf_node_type: {
            title: 'HCSF Node Type',
            icon: 'server.png'
        },
        hcsf_software_version: {
            title: 'HCSF Software Version',
            iconClass: 'project-diagram'
        },
        node_os: {
            title: 'Node OS',
            icon: 'os.png'
        },
        hcsf_switch: {
            title: 'HCSF Switch Model',
            icon: 'switch.png'
        },
        cloud_scale_version: {
            title: 'Cloud Scale Version',
            iconClass: 'network-wired'
        },
        feature: {
            title: 'Feature',
            icon: 'hba.png'
        },
        client_os: {
            title: 'Client OS',
            icon: 'os.png'
        },
        client_os_kernel: {
            title: 'Client OS Kernel',
            icon: 'os_kernel.png'
        },
        client_nic_hba: {
            title: 'Client NIC',
            icon: 'hba.png'
        },
        client_nic_hba_driver: {
            title: 'Client NIC Driver',
            icon: 'hba_driver.png'
        },
        client_switch: {
            title: 'Client Switch',
            icon: 'switch.png'
        },
        client_switch_firmware: {
            title: 'Client Switch Firmware',
            icon: 'firmware.png'
        },        
        filesystem: {
            title: 'FileSystem',
            icon: 'multipath.png'
        },
        hcp_model: {
            title: 'HCP Model',
            icon: 'server.png'
        },
        hcp_version: {
            title: 'HCP Version',
            icon: 'hba_driver.png'
        }
    }
}
export default FILTERS;