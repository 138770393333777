import { networkService } from "services/networkService";
import {
  METHOD_VSSBMODEL_LIST,
  METHOD_VSSBMODEL_CREATE,
  METHOD_VSSBMODEL_UPDATE,
  METHOD_VSSBMODEL_DELETE,
  METHOD_VSSBMODEL_DETAIL,  
  METHOD_HVSSBSOFTWAREVERSION_LIST,
  METHOD_HVSSBSOFTWAREVERSION_CREATE,
  METHOD_HVSSBSOFTWAREVERSION_UPDATE,
  METHOD_HVSSBSOFTWAREVERSION_DELETE,
  METHOD_HVSSBSOFTWAREVERSION_DETAIL,
} from "constants/admin/masters/sophia";
class SophiaService {

  //VSSBModel methods
  getAllVSSBModel = (data) => {
    return networkService
      .post(METHOD_VSSBMODEL_LIST, data)
      .then((response) => response.data);
  };
  getVSSBModelDetail = (id) => {
    const updatedMethodName = METHOD_VSSBMODEL_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createVSSBModel = (data) => {
    return networkService
      .post(METHOD_VSSBMODEL_CREATE, data)
      .then((response) => response.data);
  };
  updateVSSBModel = (data, id) => {
    const updatedMethodName = METHOD_VSSBMODEL_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVSSBModel = (id, is_delete = false) => {
    const updatedMethodName = METHOD_VSSBMODEL_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };   
  //HVSSBSoftwareversion methods
  getAllHVSSBSoftwareVersion = (data) => {
    return networkService
      .post(METHOD_HVSSBSOFTWAREVERSION_LIST, data)
      .then((response) => response.data);
  };
  getHVSSBSoftwareVersionDetail = (id) => {
    const updatedMethodName = METHOD_HVSSBSOFTWAREVERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHVSSBSoftwareVersion = (data) => {
    return networkService
      .post(METHOD_HVSSBSOFTWAREVERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateHVSSBSoftwareVersion = (data, id) => {
    const updatedMethodName = METHOD_HVSSBSOFTWAREVERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHVSSBSoftwareVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HVSSBSOFTWAREVERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default SophiaService;