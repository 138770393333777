import { networkService } from "services/networkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,
  METHOD_VENDOR_LIST,
  METHOD_VENDOR_DROPDOWN,
  METHOD_VENDOR_CREATE,
  METHOD_VENDOR_UPDATE,
  METHOD_VENDOR_DELETE,
  METHOD_VENDOR_DETAIL,  
  METHOD_KERNEL_LIST,
  METHOD_KERNEL_CREATE,
  METHOD_KERNEL_UPDATE,
  METHOD_KERNEL_DELETE,
  METHOD_KERNEL_DETAIL
} from "constants/admin/masters/os";
class osService {
  //os methods
  getAll = (data) => {
    return networkService
      .post(METHOD_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (id) => {
    const updatedMethodName = METHOD_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, id) => {
    const updatedMethodName = METHOD_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (id, is_delete = false) => {
    const updatedMethodName = METHOD_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //os vendor methods
  getAllVendors = (data) => {
    return networkService
      .post(METHOD_VENDOR_LIST, data)
      .then((response) => response.data);
  };
  getAllVendorDropdown= () => {
    return networkService
      .get(METHOD_VENDOR_DROPDOWN)
      .then((response) => response.data);
  };
  getVendorDetail = (id) => {
    const updatedMethodName = METHOD_VENDOR_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createVendor = (data) => {
    return networkService
      .post(METHOD_VENDOR_CREATE, data)
      .then((response) => response.data);
  };
  updateVendor = (data, id) => {
    const updatedMethodName = METHOD_VENDOR_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVendor = (id, is_delete = false) => {
    const updatedMethodName = METHOD_VENDOR_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

   //os kernel methods
   getAllKernels = (data) => {
    return networkService
      .post(METHOD_KERNEL_LIST, data)
      .then((response) => response.data);
  };
  getKernelDetail = (id) => {
    const updatedMethodName = METHOD_KERNEL_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createKernel = (data) => {
    return networkService
      .post(METHOD_KERNEL_CREATE, data)
      .then((response) => response.data);
  };
  updateKernel = (data, id) => {
    const updatedMethodName = METHOD_KERNEL_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteKernel = (id, is_delete = false) => {
    const updatedMethodName = METHOD_KERNEL_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default osService;
