import { networkService } from "services/networkService";
import {
  METHOD_VDI_SOFTWARE_VERSION_LIST,
  METHOD_VDI_SOFTWARE_VERSION_CREATE,
  METHOD_VDI_SOFTWARE_VERSION_UPDATE,
  METHOD_VDI_SOFTWARE_VERSION_DELETE,
  METHOD_VDI_SOFTWARE_VERSION_DETAIL,
  METHOD_ETHERNET_SWITCH_CATEGORY_LIST,
  METHOD_ETHERNET_SWITCH_CATEGORY_CREATE,
  METHOD_ETHERNET_SWITCH_CATEGORY_UPDATE,
  METHOD_ETHERNET_SWITCH_CATEGORY_DELETE,
  METHOD_ETHERNET_SWITCH_CATEGORY_DETAIL,
  METHOD_HANA_SOLUTION_TYPE_LIST,
  METHOD_HANA_SOLUTION_TYPE_CREATE,
  METHOD_HANA_SOLUTION_TYPE_UPDATE,
  METHOD_HANA_SOLUTION_TYPE_DELETE,
  METHOD_HANA_SOLUTION_TYPE_DETAIL,
  METHOD_CPU_FAMILY_LIST,
  METHOD_CPU_FAMILY_CREATE,
  METHOD_CPU_FAMILY_UPDATE,
  METHOD_CPU_FAMILY_DELETE,
  METHOD_CPU_FAMILY_DETAIL,
  METHOD_BIOS_BMC_LIST,
  METHOD_BIOS_BMC_CREATE,
  METHOD_BIOS_BMC_UPDATE,
  METHOD_BIOS_BMC_DELETE,
  METHOD_BIOS_BMC_DETAIL,
  METHOD_HNAS_LIST,
  METHOD_HNAS_CREATE,
  METHOD_HNAS_UPDATE,
  METHOD_HNAS_DELETE,
  METHOD_HNAS_DETAIL,
  METHOD_VDI_SOLUTION_TYPE_LIST,
  METHOD_VDI_SOLUTION_TYPE_CREATE,
  METHOD_VDI_SOLUTION_TYPE_UPDATE,
  METHOD_VDI_SOLUTION_TYPE_DELETE,
  METHOD_VDI_SOLUTION_TYPE_DETAIL,
  METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_LIST,
  METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_CREATE,
  METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_UPDATE,
  METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_DELETE,
  METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_DETAIL,
  METHOD_SERVER_SERVICE_PACK_LIST,
  METHOD_SERVER_SERVICE_PACK_CREATE,
  METHOD_SERVER_SERVICE_PACK_UPDATE,
  METHOD_SERVER_SERVICE_PACK_DELETE,
  METHOD_SERVER_SERVICE_PACK_DETAIL,
  METHOD_GPU_LIST,
  METHOD_GPU_CREATE,
  METHOD_GPU_UPDATE,
  METHOD_GPU_DELETE,
  METHOD_GPU_DETAIL,
  METHOD_VDI_RELEASE_VERSION_LIST,
  METHOD_VDI_RELEASE_VERSION_DETAIL,
  METHOD_VDI_RELEASE_VERSION_DELETE,
  METHOD_VDI_RELEASE_VERSION_UPDATE,
  METHOD_VDI_RELEASE_VERSION_CREATE,
  METHOD_SAP_RELEASE_VERSION_LIST,
  METHOD_SAP_RELEASE_VERSION_DETAIL,
  METHOD_SAP_RELEASE_VERSION_CREATE,
  METHOD_SAP_RELEASE_VERSION_UPDATE,
  METHOD_SAP_RELEASE_VERSION_DELETE,
  METHOD_RAID_CONTROLLER_LIST,
  METHOD_RAID_CONTROLLER_DETAIL,
  METHOD_RAID_CONTROLLER_CREATE,
  METHOD_RAID_CONTROLLER_UPDATE,
  METHOD_RAID_CONTROLLER_DELETE,
  METHOD_DRIVE_LIST,
  METHOD_DRIVE_DETAIL,
  METHOD_DRIVE_CREATE,
  METHOD_DRIVE_UPDATE,
  METHOD_DRIVE_DELETE
} from "constants/admin/masters/ucp";
class UcpService {

  //vdi software version methods
  getAllVdiSoftwareVersion = (data) => {
    return networkService
      .post(METHOD_VDI_SOFTWARE_VERSION_LIST, data)
      .then(response => response.data);
  };
  getDetailVdiSoftwareVersion = (id) => {
    const updatedMethodName = METHOD_VDI_SOFTWARE_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then(response => response.data);
  };
  createVdiSoftwareVersion = (data) => {
    return networkService
      .post(METHOD_VDI_SOFTWARE_VERSION_CREATE, data)
      .then(response => response.data);
  };
  updateVdiSoftwareVersion = (data, id) => {
    const updatedMethodName = METHOD_VDI_SOFTWARE_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then(response => response.data);
  };
  deleteVdiSoftwareVersion = (id,is_delete=false) => {
    const updatedMethodName = METHOD_VDI_SOFTWARE_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then(response => response.data);
  };

    //ethernet switch category methods
    getAllEthernetSwitchCategory = (data) => {
      return networkService
        .post(METHOD_ETHERNET_SWITCH_CATEGORY_LIST, data)
        .then(response => response.data);
    };
    getDetailEthernetSwitchCategory = (id) => {
      const updatedMethodName = METHOD_ETHERNET_SWITCH_CATEGORY_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createEthernetSwitchCategory = (data) => {
      return networkService
        .post(METHOD_ETHERNET_SWITCH_CATEGORY_CREATE, data)
        .then(response => response.data);
    };
    updateEthernetSwitchCategory = (data, id) => {
      const updatedMethodName = METHOD_ETHERNET_SWITCH_CATEGORY_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteEthernetSwitchCategory = (id,is_delete=false) => {
      const updatedMethodName = METHOD_ETHERNET_SWITCH_CATEGORY_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };

    
    //hana solution type methods
    getAllHanaSolutionType = (data) => {
      return networkService
        .post(METHOD_HANA_SOLUTION_TYPE_LIST, data)
        .then(response => response.data);
    };
    getDetailHanaSolutionType = (id) => {
      const updatedMethodName = METHOD_HANA_SOLUTION_TYPE_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createHanaSolutionType = (data) => {
      return networkService
        .post(METHOD_HANA_SOLUTION_TYPE_CREATE, data)
        .then(response => response.data);
    };
    updateHanaSolutionType = (data, id) => {
      const updatedMethodName = METHOD_HANA_SOLUTION_TYPE_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteHanaSolutionType = (id,is_delete=false) => {
      const updatedMethodName = METHOD_HANA_SOLUTION_TYPE_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };

        
    //cpu family methods
    getAllCpuFamily = (data) => {
      return networkService
        .post(METHOD_CPU_FAMILY_LIST, data)
        .then(response => response.data);
    };
    getDetailCpuFamily = (id) => {
      const updatedMethodName = METHOD_CPU_FAMILY_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createCpuFamily = (data) => {
      return networkService
        .post(METHOD_CPU_FAMILY_CREATE, data)
        .then(response => response.data);
    };
    updateCpuFamily = (data, id) => {
      const updatedMethodName = METHOD_CPU_FAMILY_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteCpuFamily = (id,is_delete=false) => {
      const updatedMethodName = METHOD_CPU_FAMILY_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };
    
    //bios bmc methods
    getAllBiosBmc = (data) => {
      return networkService
        .post(METHOD_BIOS_BMC_LIST, data)
        .then(response => response.data);
    };
    getDetailBiosBmc = (id) => {
      const updatedMethodName = METHOD_BIOS_BMC_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createBiosBmc = (data) => {
      return networkService
        .post(METHOD_BIOS_BMC_CREATE, data)
        .then(response => response.data);
    };
    updateBiosBmc = (data, id) => {
      const updatedMethodName = METHOD_BIOS_BMC_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteBiosBmc = (id,is_delete=false) => {
      const updatedMethodName = METHOD_BIOS_BMC_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };

    
    //hnas methods
    getAllHnas = (data) => {
      return networkService
        .post(METHOD_HNAS_LIST, data)
        .then(response => response.data);
    };
    getDetailHnas = (id) => {
      const updatedMethodName = METHOD_HNAS_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createHnas = (data) => {
      return networkService
        .post(METHOD_HNAS_CREATE, data)
        .then(response => response.data);
    };
    updateHnas = (data, id) => {
      const updatedMethodName = METHOD_HNAS_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteHnas = (id,is_delete=false) => {
      const updatedMethodName = METHOD_HNAS_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };

    //VDI Solution Type
    getAllVDISolutionType = (data) => {
      return networkService
        .post(METHOD_VDI_SOLUTION_TYPE_LIST, data)
        .then((response) => response.data);
    };
    getDetailVDISolutionType = (id) => {
      const updatedMethodName = METHOD_VDI_SOLUTION_TYPE_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createVDISolutionType = (data) => {
      return networkService
        .post(METHOD_VDI_SOLUTION_TYPE_CREATE, data)
        .then((response) => response.data);
    };
    updateVDISolutionType = (data, id) => {
      const updatedMethodName = METHOD_VDI_SOLUTION_TYPE_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteVDISolutionType = (id,is_delete=false) => {
      const updatedMethodName = METHOD_VDI_SOLUTION_TYPE_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };

    //VDI Release Version
    getAllVDIReleaseVersion = (data) => {
      return networkService
        .post(METHOD_VDI_RELEASE_VERSION_LIST, data)
        .then((response) => response.data);
    };
    getDetailVDIReleaseVersion = (id) => {
      const updatedMethodName = METHOD_VDI_RELEASE_VERSION_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createVDIReleaseVersion = (data) => {
      return networkService
        .post(METHOD_VDI_RELEASE_VERSION_CREATE, data)
        .then((response) => response.data);
    };
    updateVDIReleaseVersion = (data, id) => {
      const updatedMethodName = METHOD_VDI_RELEASE_VERSION_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteVDIReleaseVersion = (id,is_delete=false) => {
      const updatedMethodName = METHOD_VDI_RELEASE_VERSION_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };

    //VDI Management Software Version
    getAllVDIManagementSoftwareVersion = (data) => {
      return networkService
        .post(METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_LIST, data)
        .then((response) => response.data);
    };
    getDetailVDIManagementSoftwareVersion = (id) => {
      const updatedMethodName = METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createVDIManagementSoftwareVersion = (data) => {
      return networkService
        .post(METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_CREATE, data)
        .then((response) => response.data);
    };
    updateVDIManagementSoftwareVersion = (data, id) => {
      const updatedMethodName = METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteVDIManagementSoftwareVersion = (id,is_delete=false) => {
      const updatedMethodName = METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };

    //Server Service Pack
    getAllServerServicePack = (data) => {
      return networkService
        .post(METHOD_SERVER_SERVICE_PACK_LIST, data)
        .then((response) => response.data);
    };
    getDetailServerServicePack = (id) => {
      const updatedMethodName = METHOD_SERVER_SERVICE_PACK_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createServerServicePack = (data) => {
      return networkService
        .post(METHOD_SERVER_SERVICE_PACK_CREATE, data)
        .then((response) => response.data);
    };
    updateServerServicePack = (data, id) => {
      const updatedMethodName = METHOD_SERVER_SERVICE_PACK_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteServerServicePack = (id,is_delete=false) => {
      const updatedMethodName = METHOD_SERVER_SERVICE_PACK_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };

    //GPU
    getAllGPU = (data) => {
      return networkService
        .post(METHOD_GPU_LIST, data)
        .then((response) => response.data);
    };
    getDetailGPU = (id) => {
      const updatedMethodName = METHOD_GPU_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createGPU = (data) => {
      return networkService
        .post(METHOD_GPU_CREATE, data)
        .then((response) => response.data);
    };
    updateGPU = (data, id) => {
      const updatedMethodName = METHOD_GPU_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteGPU = (id,is_delete=false) => {
      const updatedMethodName = METHOD_GPU_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };

    //SAP Release Version
    getAllSAPReleaseVersion = (data) => {
      return networkService
        .post(METHOD_SAP_RELEASE_VERSION_LIST, data)
        .then((response) => response.data);
    };
    getDetailSAPReleaseVersion = (id) => {
      const updatedMethodName = METHOD_SAP_RELEASE_VERSION_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createSAPReleaseVersion = (data) => {
      return networkService
        .post(METHOD_SAP_RELEASE_VERSION_CREATE, data)
        .then((response) => response.data);
    };
    updateSAPReleaseVersion = (data, id) => {
      const updatedMethodName = METHOD_SAP_RELEASE_VERSION_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteSAPReleaseVersion = (id,is_delete=false) => {
      const updatedMethodName = METHOD_SAP_RELEASE_VERSION_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };

    //RAID Controller
    getAllRaidController = (data) => {
      return networkService
        .post(METHOD_RAID_CONTROLLER_LIST, data)
        .then((response) => response.data);
    };
    getDetailRaidController = (id) => {
      const updatedMethodName = METHOD_RAID_CONTROLLER_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createRaidController = (data) => {
      return networkService
        .post(METHOD_RAID_CONTROLLER_CREATE, data)
        .then((response) => response.data);
    };
    updateRaidController = (data, id) => {
      const updatedMethodName = METHOD_RAID_CONTROLLER_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteRaidController = (id,is_delete=false) => {
      const updatedMethodName = METHOD_RAID_CONTROLLER_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };

    //Drive
    getAllDrive = (data) => {
      return networkService
        .post(METHOD_DRIVE_LIST, data)
        .then((response) => response.data);
    };
    getDetailDrive = (id) => {
      const updatedMethodName = METHOD_DRIVE_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createDrive = (data) => {
      return networkService
        .post(METHOD_DRIVE_CREATE, data)
        .then((response) => response.data);
    };
    updateDrive = (data, id) => {
      const updatedMethodName = METHOD_DRIVE_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteDrive = (id,is_delete=false) => {
      const updatedMethodName = METHOD_DRIVE_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };
}
export default UcpService;
