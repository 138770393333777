const FILTERS = {
    "ops-protector": {
        ops_center_version: {
            title: 'Ops Center Version',
            icon: 'server.png'
        },
        
        protector_version: {
            title: 'Protector Version',
            icon: 'server.png'
        },
        array_customer_version: {
            title: 'Storage Array',
            icon: 'array_if.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        },
        supported_file_system: {
            title: 'Supported File System',
            iconClass: 'project-diagram'
        },
        browser: {
            title: 'Browser',
            icon: 'multipath.png'
        },
        adoptable_technologies: {
            title: 'Adoptable Technologies',
            icon: 'os.png'
        },
        adr_support: {
            title: 'ADR Support',
            iconClass: 'project-diagram'
        }
    }
}
export default FILTERS;