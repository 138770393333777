import { networkService } from "services/networkService";
import {
  METHOD_EOSL_LIST,
  METHOD_EOSL_CREATE,
  METHOD_EOSL_UPDATE,
  METHOD_EOSL_DELETE,
  METHOD_EOSL_DETAIL,
  METHOD_MASTER_DROPDOWN,
  METHOD_EOSL_ROLLBACK,
  METHOD_EOSL_RUN_BG_JOBS
} from "constants/admin/masters/eosl";
import { _ } from "core-js";
class eoslService {

   //Eosl methods
   getAllEosl = (payload) => {
   return networkService
        .post(METHOD_EOSL_LIST,payload)
        .then((response) => response.data);
   }
   getDetail = (id) => {
      const updatedMethodName = METHOD_EOSL_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    create = (payload) => {
      return networkService
        .post(METHOD_EOSL_CREATE, payload)
        .then((response) => response.data);
    };
    update = (payload, id) => {
      const updatedMethodName = METHOD_EOSL_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, payload)
        .then((response) => response.data);
    };    
    rollback = (id,payload={}) => {
      const updatedMethodName = METHOD_EOSL_ROLLBACK.replace("{ID}", id);
      return networkService
        .post(updatedMethodName,payload)
        .then((response) => response.data);
    };
    delete = (id,is_delete=false) => {
      const updatedMethodName = METHOD_EOSL_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then((response) => response.data);
    };
  
   //dropdown methods
   getMasterDropdownItems = (masterKey,additionalParams={}) => {
      const methodName=METHOD_MASTER_DROPDOWN.replace("{masterKey}",masterKey);
      if(!_.isEmpty(additionalParams)){
        return networkService
        .post(methodName,additionalParams)
        .then((response) => response.data);
      }else{
        return networkService
        .get(methodName)
        .then((response) => response.data);
      } 
   }
   runbackgroundJob = () => {
    return networkService
    .post(METHOD_EOSL_RUN_BG_JOBS)
    .then((response) => response.data);
   }
}
export default eoslService;