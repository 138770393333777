const FILTERS = {
    "hdlm": {
        hdlm_version: {
            title: 'HDLM Version',
            iconClass: 'project-diagram'
        },
        os: {
            title: 'OS',
            icon: 'os.png'
        },
        os_kernel: {
            title: 'Kernel',
            icon: 'os_kernel.png'
        },
        hba: {
            title: 'HBA Driver Provider',
            icon: 'hba.png'
        },
        hba_driver: {
            title: 'HBA Driver',
            icon: 'hba_driver.png'
        },
        array_customer_version: {
            title: 'Array',
            icon: 'array.png'
        },
        protocol: {
            title: 'Protocol',
            iconClass: 'network-wired'
        }
        
    }
}
export default FILTERS;