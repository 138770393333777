const FILTERS = {
  hspc: {
    hspc_version: {
      title: "HSPC Version",
      icon: "os_kernel.png",
    },
    supported_os: {
      title: "Supported OS",
      icon: "os.png",
    },
    container_orchestrator: {
      title: "Container Orchestrator",
      icon: "os.png",
    },

    orchestrator_version: {
      title: "Orchestrator Version",
      icon: "os.png",
    },
    array_customer_version: {
      title: "Array",
      icon: "array.png",
    },
    protocol: {
      title: "Protocol",
      iconClass: "network-wired",
    },
    csi_version: {
      title: "CSI Version",
      icon: "array_if.png",
    },
  },
};
export default FILTERS;
