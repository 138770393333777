import React, { Component } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ErrorMessage } from "components/shared/ErrorMessage";
import _ from "lodash";
import analyzerService from "services/admin/masters/analyzerService";
import Spinner from "components/shared/Spinner"; 
import Select from 'react-select';
//import DatePicker from 'react-date-picker'
import { MSG_CREATE_RECORD, MSG_UPDATE_RECORD } from "constants/admin/masters/analyzer";
import { handleChange, handleTouched,formFailure,getCurrentSlug,trimFields,handleSingleSelect,handleMultiSelect } from "utils/masterHelpers";
import masterFields from "./masterFields"; 
import { highlightStringAsterisk } from "utils/matriceHelpers";
import DOMPurify from "dompurify";
//import { convertToFormat } from "utils/utils";
const serviceObj = new analyzerService();
class Form extends Component {
  constructor(props) {
    super(props);
    this.handleChange=handleChange.bind(this);
    this.handleSingleSelect=handleSingleSelect.bind(this);
    this.handleMultiSelect=handleMultiSelect.bind(this);
    this.handleTouched=handleTouched.bind(this);
    this.formFailure=formFailure.bind(this);
    this.getCurrentSlug=getCurrentSlug.bind(this);
    this.trimFields=trimFields.bind(this);
    const masterId= parseInt(props.masterId);
    const masterInfo = masterFields.find((r) => r.masterId === masterId);
    const formSetup ={
      [masterInfo?.masterField]:0,
      analyzer_versions: [],
      //eosl_date: null,
    };
    if(masterInfo?.additionalField){
      formSetup[masterInfo?.additionalField.field]='';
    }
    this.state = {
      isEditForm: false,
      id: 0,
      masterId:masterId,
      masterInfo:masterInfo,
      isSubmitting: false,
      errors: {},
      touched: {},
      form: formSetup,
      analyzer_versions_list:props?.analyzer_versions_list || [],
      master_drop_list:props?.master_drop_list || [],
      os_exceptions:props?.os_exceptions || []
    };
  }

  componentDidMount = async () => {
    await this.intializedata();
  };
  componentDidUpdate = async (prevProps) => {
    if (this.props.editId !== prevProps.editId) {
      await this.intializedata();
    }
    if(this.props.master_drop_list!==prevProps.master_drop_list){
      this.setState({ 
        master_drop_list:this.props?.master_drop_list,
      })
    }
     

  };

  intializedata = async () => {

    if (this.props.editId > 0) {
      //repopulate the form
      this.setState({
        id: this.props.editId,
        isEditForm: true,
      });
      const {form,masterId,masterInfo} = this.state;
      const Info = await serviceObj.getEditDetail(masterId,this.props.editId);
      const basicDetails = Info?.record_details;
      form[masterInfo?.masterField] = basicDetails[masterInfo?.masterField];
      form.analyzer_versions =  Info.analyzer_versions.map(r=>r.id);
      /*if(!_.isEmpty(basicDetails.eosl_date)){
        form.eosl_date = new Date(basicDetails.eosl_date);
      }*/
      if(masterInfo?.additionalField){
        form[masterInfo?.additionalField.field]=(masterInfo.additionalField.type==="multi-select")?this.transformData(Info[masterInfo?.additionalField.field]):basicDetails[masterInfo?.additionalField.field];
      } 
      this.setState(
        {
          form,
        },
        () => {
          this.setState({
            errors: {},
            touched: {}
          });
        }
      ); //populate in form
    } else {
      //do any preload any api call if needed in caseof add form then make the form ready to load
      this.setState({
        id: this.props.editId,
        isEditForm: false,
      });
    }
  }
  submitForm = async (e) => {
    e.preventDefault();
    this.trimFields(this.state.form);
    this.setState({
      isSubmitting: true,
    });
    const errors = await this.validateForm();
    
    if (_.isEmpty(errors)) {
      //now proceed to save user      
      const form = {...this.state.form};
      /*if(!_.isEmpty(form['eosl_date'])){
        const eoslDate=form['eosl_date'].toISOString();
        form['eosl_date']=convertToFormat(eoslDate,'YYYY-MM-DD');
      }*/
      if(this.state.masterInfo?.additionalField?.type==="multi-select"){
        form[this.state.masterInfo?.additionalField.field]=form[this.state.masterInfo?.additionalField.field] && form[this.state.masterInfo?.additionalField.field].length?form[this.state.masterInfo?.additionalField.field].map(r=>r.value) : [];
      }       
      try {
        let response;
        if (this.state.id > 0 && this.state.isEditForm) {
          response = await serviceObj.updateAnalyzerMapping(form, this.state.masterId ,this.state.id);
        } else {
          response = await serviceObj.createAnalyzerMapping(form,this.state.masterId);
        }
        if (response) {
          toast.success(response.message || (this.state.isEditForm) ? MSG_UPDATE_RECORD : MSG_CREATE_RECORD);
          this.resetForm();
        }
      } catch (err) {
        //failure case
        this.formFailure();
        if (err) {
          const errors = this.state.errors;
          for (const [field, msg] of Object.entries(err)) {
            errors[field] = msg[0];
          }
          await this.setState({
            errors,
          });
        }
      }
    } else {
      //failure case
      this.formFailure();
    }
  };
  resetForm = () => {
    const masterInfo = this.state.masterInfo;
    const formSetup ={
      [masterInfo?.masterField]:0,
      analyzer_versions: [],
      //eosl_date: null,
    };
    if(masterInfo?.additionalField){
      formSetup[masterInfo?.additionalField.field]='';
    }
    this.setState({
      form:formSetup,
      isSubmitting: false,
      errors: {},
      touched: {},
      id:0,
      isEditForm:false
    });
    this.props.triggerParentHandler();
  }
    
  validateForm = async () => {
    const errors = {};
    const { masterInfo }=this.state;
    const validationShape={
      [masterInfo?.masterField]: yup
      .number()
      .required(`${masterInfo?.masterName} is required field`)
      .positive(`${masterInfo?.masterName} is required field`),
      analyzer_versions: yup
        .array()
        .required('Analyzer is required field'),
        /*eosl_date: yup
        .date()
        .nullable()
        //.required('EOSL Date is required field')
        .typeError('Please Input Date in correct format')*/
    }
    if(masterInfo?.additionalField && masterInfo?.additionalField?.required){
      validationShape[masterInfo?.additionalField.field]= yup
      .string()
      .required(`${masterInfo?.additionalField.fieldTitle} is required field`)
    }
    const schema = yup.object().shape(validationShape);
    // check validity
    await schema
      .validate(this.state.form, { abortEarly: false })
      .catch(function (err) {
        err.inner.map((error) =>
          !errors[error.path] ? (errors[error.path] = error.message) : null
        );
      });

    this.setState({
      errors,
    });
    return errors; //return basicerrors
  };

  handleCheckBox = (e) => {
    const form = this.state.form;
    const analyzer_versions = form.analyzer_versions; 
    let analyzers=[];
    if(e.target.checked){
      analyzers= _.union(analyzer_versions,[parseInt(e.target.value)])
    }else{
      analyzers = analyzer_versions.filter(v=> v !== parseInt(e.target.value)); //remove using filter
    }
    form.analyzer_versions = analyzers;
    this.setState({
      form
    })
 }

 handleEoslDate=(v)=>{
  const form = this.state.form;   
  form['eosl_date'] =v;
   this.setState({
     form
   })
    //mark this input as touched
    this.handleTouched('eosl_date');
    //now call the validations to keep the errors up to date
    this.validateForm();
 }

 transformData = (data) => data.map(row=>{
  return {
    value:row.id,
    label:row.name,
  }
});

customFilter(option, searchText) {
  if (
    option.data.plainText.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
}

  render() {
    const {
      isSubmitting,
      form,
      touched,
      errors,
      isEditForm,
      masterInfo,
      analyzer_versions_list,
      master_drop_list
    } = this.state;
    return (
      <>
        {isSubmitting ? <Spinner /> : null}
        { 
          <form onSubmit={this.submitForm} >
            <div className="row pl-2 pb-1">

              <div className="col-sm-10 pb-3">
                <label htmlFor={masterInfo?.masterField} className="mr-sm-2"><b>{masterInfo?.masterName}</b></label>
                <Select 
                 isDisabled={isEditForm}
                 className={`basic-single mb-2 ${
                  touched[masterInfo?.masterField] && errors[masterInfo?.masterField]
                    ? "is-invalid"
                    : ""
                  }`}  
                 classNamePrefix="select"                          
                 isClearable={true}
                 isSearchable={true}
                 filterOption={this.customFilter}
                 name={masterInfo?.masterField}
                 options={master_drop_list}
                 value={master_drop_list.find(item => item.value === form[masterInfo?.masterField]) || null}
                 onChange={e=>this.handleSingleSelect(masterInfo?.masterField,e)}
                />
                  
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name={masterInfo?.masterField}
                  className="text-danger"
                />
              </div>
              <div className="col-sm-10 pb-3">
                <label htmlFor="analyzer_versions" className="mr-sm-2"><b>Analyzer Versions</b></label> 
                  <ul className="checkbox-group">
                    {
                      analyzer_versions_list.map( (r,index)=>(
                        <li key={index}>
                        <div className="form-check ml-1 pt-2">
                          <label className="form-check-label">
                          <input className="form-check-input"  type="checkbox"
                          onChange={this.handleCheckBox} 
                          checked={form?.analyzer_versions?.includes(r.id)} value={r.id} />
                          <span
                           dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(highlightStringAsterisk(r.name)),
                          }}
                           />
                          </label>
                        </div>                        
                      </li>
                      ))
                    } 
                </ul>                 
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="analyzer_versions"
                  className="text-danger"
                />
              </div>

              {
                (masterInfo?.additionalField && masterInfo?.additionalField.type==="text")?(
                  <div className="col-md-10 pb-2">
                  <label htmlFor={masterInfo?.additionalField.field} className="mr-sm-2"><b>{masterInfo?.additionalField.fieldTitle}</b></label>
                  <input
                    type="text"
                    className="form-control"
                    name={masterInfo?.additionalField.field}
                    onChange={this.handleChange}
                    value={form[masterInfo?.additionalField.field]}
                  />
                  <ErrorMessage
                    tag="div"
                    touched={touched}
                    errors={errors}
                    name={masterInfo?.additionalField.field}
                    className="text-danger"
                  />
                </div>
              
                ):null
              }
              {
                (masterInfo?.additionalField && masterInfo?.additionalField.type==="multi-select")?(
                  <div className="col-md-10 pb-2">
                  <label htmlFor={masterInfo?.additionalField.field} className="mr-sm-2"><b>{masterInfo?.additionalField.fieldTitle}</b></label>
                  <Select 
                   isMulti
                    className={`basic-single mb-2 ${
                      touched[masterInfo?.additionalField.field] && errors[masterInfo?.additionalField.field]
                        ? "is-invalid"
                        : ""
                      }`}  
                    classNamePrefix="select"                          
                    isClearable={true}
                    isSearchable={true} 
                    filterOption={this.customFilter}
                    name={masterInfo?.additionalField.field}
                    options={this.state[masterInfo?.additionalField.listingKey]}
                    value={form[masterInfo?.additionalField.field]}
                    onChange={e=>this.handleMultiSelect(masterInfo?.additionalField.field,e)}
      
                    />
                  <ErrorMessage
                    tag="div"
                    touched={touched}
                    errors={errors}
                    name={masterInfo?.additionalField.field}
                    className="text-danger"
                  />
                </div>
              
                ):null
              }
              {/*
              <div className="col-md-10 pb-2">
                <label htmlFor="eosl_date" className="mr-sm-2"><b>EOSL Date</b></label>
                 <DatePicker
                  className="form-control"
                  name="eosl_date"
                  onChange={this.handleEoslDate}
                  value={form.eosl_date}
                  minDate={new Date()}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="eosl_date"
                  className="text-danger"
                />
              </div>*/}
              
              <div className="col-sm-12">
                <button type="reset" onClick={this.resetForm} className="btn btn-md btn-light btn-link submit-btn-inline mr-sm-2"><i className="fa fa-refresh"></i> Reset</button>
                <button
                  type="submit"
                  className="btn btn-md btn-dark submit-btn-inline"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? "Please wait..."
                    : isEditForm
                      ? "Update"
                      : "Add"}
                </button></div>

            </div>
          </form>
          }
        <div className="clearfix"></div>
      </>
    );
  }
}

export default Form;
