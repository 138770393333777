import React from "react";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "components/shared/PageNotFound";
import AppLayout from "layouts/AppLayout";
import PrivateRoute from "components/PrivateRoute";
import { ROLES } from "constants/menus";
import {
  SearchProducts,
  Login,
  AdminSettings,
  AdminDashboard,
  Users,
  UserForm,
  BulkUpload,
  DatapathExport,
  LegacyProducts,
  GlobalSearchResults,
  LandingProducts,
} from "containers";
import SsoLogin from "components/SsoLogin";
import MasterRoutes from "MasterRoutes";
import { DownloadaDatapath } from "containers/Admin";
import BlankPage from "components/shared/BlankPage";
import { LoginCallback } from "@okta/okta-react";
const Routes = () => (
  <AppLayout>
    <Switch>
      <Route exact path="/" component={SearchProducts} />
      <Route exact path="/menus" component={BlankPage} />
      <Route exact path="/sso_login" component={SsoLogin} />
      <Route path="/login/callback" component={LoginCallback} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/search" component={GlobalSearchResults} />
      <Route exact path="/search/:keyword" component={GlobalSearchResults} />
      <Route exact path="/products/:productSlug" component={SearchProducts} />
      <Route exact path="/assets/:productSlug" component={LegacyProducts} />
      <Route
        exact
        path="/landing-page/:productSlug"
        component={LandingProducts}
      />
      <PrivateRoute
        exact
        path="/admin/dashboard"
        requiredRoles={[ROLES.LAB_USER, ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
        component={AdminDashboard}
      />
      <PrivateRoute
        exact
        path="/admin/export-datapath"
        requiredRoles={[
          ROLES.LAB_USER,
          ROLES.SYS_ADMIN,
          ROLES.PUBLIC_USER,
          ROLES.PROD_ADMIN,
        ]}
        component={DownloadaDatapath}
      />
      <PrivateRoute
        exact
        path="/admin/users"
        requiredRoles={[ROLES.SYS_ADMIN]}
        component={Users}
      />
      <PrivateRoute
        exact
        path="/admin/users/add"
        requiredRoles={[ROLES.SYS_ADMIN]}
        component={UserForm}
      />
      <PrivateRoute
        exact
        path="/admin/users/edit/:userId"
        requiredRoles={[ROLES.SYS_ADMIN]}
        component={UserForm}
      />
      <PrivateRoute
        exact
        path="/admin/settings"
        requiredRoles={[ROLES.SYS_ADMIN]}
        component={AdminSettings}
      />
      <PrivateRoute
        exact
        path="/admin/bulk-upload"
        requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
        component={BulkUpload}
      />
      <PrivateRoute
        exact
        path="/admin/datapath-export"
        requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
        component={DatapathExport}
      />
      <Route
        exact
        path="/403"
        component={() => (
          <PageNotFound
            title="Unauthenticated Error"
            description="You are not authorise to this module"
          />
        )}
      />
      <Route path="/admin/master" component={MasterRoutes} />
      <Route
        component={() => (
          <PageNotFound title="Error 404" description="Page not found error" />
        )}
      />
    </Switch>
  </AppLayout>
);
export default Routes;
