const FILTERS = {
  "mf-fcp": {
    os: {
      title: "OS",
      icon: "os.png",
    },
    array_customer_version: {
      title: "Array",
      icon: "array.png",
    },
    hba: {
      title: "z-Series CHL Type",
      icon: "hba.png",
    },
    switch: {
      title: "Switch",
      icon: "switch.png",
    },
    switch_firmware: {
      title: "Switch Firmware",
      icon: "firmware.png",
    },
    array_if: {
      title: "Array Interface",
      icon: "array_if.png",
    },
  },
};
export default FILTERS;
