import React, { Component } from "react";
import MaterialTable from "material-table";
import DataGridSubTable from "./DataGridSubTable";
class DataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.refreshToken !== prevProps.refreshToken) {
      this.setState({ selectedRows: [] });
    }
  }
  rowStyle = (rowData) => ({
    backgroundColor: this.props.enableSubTable
      ? this.state.selectedRows.find(
        (r) =>
          r[this.props.uniqueRowColumn] ===
          rowData[this.props.uniqueRowColumn]
      )?.expanded
        ? "#E4F9FA"
        : "#F4F3F3"
      : "#F4F3F3",
  })

  render() {
    return (
      <div className="tableFormating master-grids">
        <MaterialTable
          key={this.props.refreshToken || 0}
          actions={this.props.actions || null}
          components={{
            OverlayLoading: props => (<div></div>)
          }}
          title={this.props.tableTitle || ''}
          options={{
            actionsColumnIndex: -1,
            debounceInterval: this.props.debounceInterval || 1000,
            toolbar: this.props.showToolBar || false,
            pageSize: this.props.pageSize || 20,
            pageSizeOptions: this.props.sizeOptions || [10, 20, 50, 100],
            emptyRowsWhenPaging: false,
            filtering: this.props.filtering || false,
            paging: (this.props.paging === false) ? false : true,
            rowStyle: this.props.rowStyle || this.rowStyle,
            maxBodyHeight: this.props.maxBodyHeight || null,
            headerStyle: this.props.headerStyle || null,
            selection: this.props.selection || false,
            showSelectAllCheckbox: false,
            showTextRowsSelected: false,
            selectionProps: (this.props.selection) ? rowData => ({
              color: 'primary',
            }) : null
          }
          }
          onSelectionChange={(this.props.selection) ? this.props.selectionHandler : null}
          columns={this.props.columns}
          onRowClick={
            (this.props.enableSubTable || this.props.customDetailPanel)
              ? (event, rowData, togglePanel) => {
                const found = this.state.selectedRows.find(
                  (r) =>
                    r[this.props.uniqueRowColumn] ===
                    rowData[this.props.uniqueRowColumn]
                );
                if (found && found[this.props.uniqueRowColumn] > 0) {
                  //means already clicked once
                  found.expanded = !found.expanded;
                  const updateState = [...this.state.selectedRows];
                  this.setState({ selectedRows: updateState });
                } else {
                  const newRecord = {
                    [this.props.uniqueRowColumn]:
                      rowData[this.props.uniqueRowColumn],
                    expanded: true,
                  };
                  const updateState = [...this.state.selectedRows, newRecord];
                  this.setState({ selectedRows: updateState });
                }
                togglePanel();
              }
              : null
          }
          detailPanel={
            this.props.enableSubTable
              ? [
                {
                  disabled: true,
                  render: (row) => {
                    return (
                      <DataGridSubTable
                        columns={this.props.subTableColumns}
                        data={row[this.props.subTableDataCol]}

                      />
                    );
                  },
                },
              ]
              : this.props.customDetailPanel ? (rowdata) => {
                const Component=this.props.customDetailPanel;
                return (<Component refreshHandler={this.props.refreshHandler} data={rowdata} />)
              }: null
          }
          data={this.props.dataHandler}
        />
      </div>
    );
  }
}

export default DataGrid;
