import React, { useEffect } from "react";
import Spinner from "./shared/Spinner";
import { useOktaAuth } from "@okta/okta-react";

function SsoLogin() {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    oktaAuth.signInWithRedirect();
  }, []);

  if (!authState) {
    return null;
  }

  return (
    <Spinner />
  );
}

export default SsoLogin;
