import { networkService } from "services/networkService";
import {
  METHOD_VIEWPOINTVERSION_LIST,
  METHOD_VIEWPOINTVERSION_CREATE,
  METHOD_VIEWPOINTVERSION_UPDATE,
  METHOD_VIEWPOINTVERSION_DELETE,
  METHOD_VIEWPOINTVERSION_DETAIL,
  METHOD_BROWSER_CREATE,
  METHOD_BROWSER_DELETE,
  METHOD_BROWSER_DETAIL,
  METHOD_BROWSER_LIST,
  METHOD_BROWSER_UPDATE,
  METHOD_HYPERVISOR_CREATE,
  METHOD_HYPERVISOR_DELETE,
  METHOD_HYPERVISOR_DETAIL,
  METHOD_HYPERVISOR_LIST,
  METHOD_HYPERVISOR_UPDATE,
  METHOD_JAVADEVKIT_CREATE,
  METHOD_JAVADEVKIT_DELETE,
  METHOD_JAVADEVKIT_DETAIL,
  METHOD_JAVADEVKIT_LIST,
  METHOD_JAVADEVKIT_UPDATE,

} from "constants/admin/masters/viewpoint";
class viewPointService {
  //ViewPointVersion methods
  getAllViewPointVersion = (data) => {
    return networkService
      .post(METHOD_VIEWPOINTVERSION_LIST, data)
      .then((response) => response.data);
  };
  getViewPointVersionDetail = (id) => {
    const updatedMethodName = METHOD_VIEWPOINTVERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createViewPointVersion = (data) => {
    return networkService
      .post(METHOD_VIEWPOINTVERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateViewPointVersion = (data, id) => {
    const updatedMethodName = METHOD_VIEWPOINTVERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteViewPointVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_VIEWPOINTVERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };  
   //browser methods
   getAllBrowser = (data) => {
    return networkService
      .post(METHOD_BROWSER_LIST, data)
      .then((response) => response.data);
  };
  getBrowserDetail = (id) => {
    const updatedMethodName = METHOD_BROWSER_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createBrowser = (data) => {
    return networkService
      .post(METHOD_BROWSER_CREATE, data)
      .then((response) => response.data);
  };
  updateBrowser = (data, id) => {
    const updatedMethodName = METHOD_BROWSER_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteBrowser = (id, is_delete = false) => {
    const updatedMethodName = METHOD_BROWSER_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  
   //Hypervisor methods
   getAllHypervisor = (data) => {
    return networkService
      .post(METHOD_HYPERVISOR_LIST, data)
      .then((response) => response.data);
  };
  getHypervisorDetail = (id) => {
    const updatedMethodName = METHOD_HYPERVISOR_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHypervisor = (data) => {
    return networkService
      .post(METHOD_HYPERVISOR_CREATE, data)
      .then((response) => response.data);
  };
  updateHypervisor = (data, id) => {
    const updatedMethodName = METHOD_HYPERVISOR_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHypervisor = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HYPERVISOR_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
    //JavaDevKit methods
    getAllJavaDevKit = (data) => {
    return networkService
      .post(METHOD_JAVADEVKIT_LIST, data)
      .then((response) => response.data);
  };
  getJavaDevKitDetail = (id) => {
    const updatedMethodName = METHOD_JAVADEVKIT_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createJavaDevKit = (data) => {
    return networkService
      .post(METHOD_JAVADEVKIT_CREATE, data)
      .then((response) => response.data);
  };
  updateJavaDevKit = (data, id) => {
    const updatedMethodName = METHOD_JAVADEVKIT_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteJavaDevKit = (id, is_delete = false) => {
    const updatedMethodName = METHOD_JAVADEVKIT_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default viewPointService;