const FILTERS = {
    "hnas":{
        server: {
            title: 'HNAS Model',
            icon: 'server.png'
        },
        os: {
            title: 'NASOS',
            icon: 'os.png'
        },
        hdrs: {
            title: 'HDRS',
            icon: 'os.png'
        },
        cluster_interconnect: {
            title: 'Cluster Interconnect Switch',
            iconClass: 'project-diagram'
        },
        switch: {
            title: 'Switch',
            icon: 'switch.png'
        },
        switch_firmware: {
            title: 'Switch Firmware',
            icon: 'firmware.png'
        },
        array: {
            title: 'Array',
            icon: 'array.png'
        },
        array_if: {
            title: 'Array Interface',
            icon: 'array_if.png'
        },
    }
}
export default FILTERS;