//API Method Constants
export const METHOD_PRODUCTS = 'products/getproducttree/';
export const METHOD_UPLOAD_FILE = 'datapath/upload/{productId}/';
export const METHOD_UPLOAD_HISTORY = 'datapath/list/';
export const METHOD_DOWNLOAD_TEMPlATE = 'datapath/download_template/{productId}/';

//Action Constants 
export const LOADING = 'LOADING';
export const PRODUCTS = 'PRODUCTS';
export const UPLOADING = 'UPLOADING';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';


//Errors Constant
export const ERROR_SERVER_UPLOAD = 'Server Upload Error occured.';
export const ERROR_FORM_INCOMPLETE = 'Please select a file to upload.';

