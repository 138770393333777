import { networkService } from "services/networkService";
import {
  METHOD_CLUSTER_INTERCONNECT_LIST,
  METHOD_CLUSTER_INTERCONNECT_CREATE,
  METHOD_CLUSTER_INTERCONNECT_UPDATE,
  METHOD_CLUSTER_INTERCONNECT_DELETE,
  METHOD_CLUSTER_INTERCONNECT_DETAIL,  
} from "constants/admin/masters/hnas";
class HnasService {

  getAllClusterInterconnect = (data) => {
    return networkService
      .post(METHOD_CLUSTER_INTERCONNECT_LIST, data)
      .then((response) => response.data);
  };
  getClusterInterconnectDetail = (id) => {
    const updatedMethodName = METHOD_CLUSTER_INTERCONNECT_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createClusterInterconnect = (data) => {
    return networkService
      .post(METHOD_CLUSTER_INTERCONNECT_CREATE, data)
      .then((response) => response.data);
  };
  updateClusterInterconnect = (data, id) => {
    const updatedMethodName = METHOD_CLUSTER_INTERCONNECT_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteClusterInterconnect = (id, is_delete = false) => {
    const updatedMethodName = METHOD_CLUSTER_INTERCONNECT_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };  
} 
export default HnasService;