import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//SCPRODUCT
export const METHOD_SCPRODUCT_LIST = 'scproduct/pageable/';
export const METHOD_SCPRODUCT_CREATE = 'scproduct/';
export const METHOD_SCPRODUCT_UPDATE = 'scproduct/{ID}/';
export const METHOD_SCPRODUCT_DELETE = 'scproduct/{ID}/';
export const METHOD_SCPRODUCT_DETAIL = 'scproduct/{ID}/';

//PARTNER
export const METHOD_PARTNER_LIST = 'partner/pageable/';
export const METHOD_PARTNER_CREATE = 'partner/';
export const METHOD_PARTNER_UPDATE = 'partner/{ID}/';
export const METHOD_PARTNER_DELETE = 'partner/{ID}/';
export const METHOD_PARTNER_DETAIL = 'partner/{ID}/';

//CERTIFICATION
export const METHOD_CERTIFICATION_LIST = 'certification/pageable/';
export const METHOD_CERTIFICATION_CREATE = 'certification/';
export const METHOD_CERTIFICATION_UPDATE = 'certification/{ID}/';
export const METHOD_CERTIFICATION_DELETE = 'certification/{ID}/';
export const METHOD_CERTIFICATION_DETAIL = 'certification/{ID}/';

//MULTIPATHSOFTWARE
export const METHOD_MULTIPATHSOFTWARE_LIST = 'multipathsoftware/pageable/';
export const METHOD_MULTIPATHSOFTWARE_CREATE = 'multipathsoftware/';
export const METHOD_MULTIPATHSOFTWARE_UPDATE = 'multipathsoftware/{ID}/';
export const METHOD_MULTIPATHSOFTWARE_DELETE = 'multipathsoftware/{ID}/';
export const METHOD_MULTIPATHSOFTWARE_DETAIL = 'multipathsoftware/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;

//mapping warnings
export const MSG_WARNING_DELETE_MAPPING = 'Are you sure you want to remove mapping for "ITEM_NAME"?';
export const MSG_WARNING_UNDO_DELETE_MAPPING = 'Are you sure you want to undo the mapping for "ITEM_NAME"?.';
export const MSG_ADDITIONAL = '"ITEM_NAME" is also associated with analyzers "ANALYZER_NAMES". Do you want to remove mapping for same?';
export const MSG_ADDITIONAL_UNDO = '"ITEM_NAME" is also associated with analyzers "ANALYZER_NAMES". Do you want to undo mapping for same?';