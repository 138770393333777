const FILTERS = {
    sra: {
        srm_version: {
            title: 'SRM Version',
            icon: 'server.png'
        },
        sra: {
            title: 'SRA',
            icon: 'os.png'
        },
        sra_version: {
            title: 'SRA Version',
            icon: 'os_kernel.png'
        },
        os_type: {
            title: 'OS Type',
            icon: 'os_kernel.png'
        },
        array_customer_version: {
            title: 'Array',
            icon: 'switch.png'
        },
        protocol: {
            title: 'Protocol',
            icon: 'os_kernel.png'
        }
    }
}
export default FILTERS;