import React, { Component } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ErrorMessage } from "components/shared/ErrorMessage";
import _ from "lodash";
import switchService from "services/admin/masters/switchService";
import Spinner from "components/shared/Spinner"; 
import Select from 'react-select';
import { MSG_CREATE_RECORD, MSG_UPDATE_RECORD } from "constants/admin/masters/switch";
import { handleChange, handleTouched,formFailure,getCurrentSlug,trimFields,handleSingleSelect } from "utils/masterHelpers";
const serviceObj = new switchService();
class Form extends Component {
  constructor(props) {
    super(props);
    this.handleChange=handleChange.bind(this);
    this.handleTouched=handleTouched.bind(this);
    this.formFailure=formFailure.bind(this);
    this.getCurrentSlug=getCurrentSlug.bind(this);
    this.trimFields=trimFields.bind(this);
    this.handleSingleSelect=handleSingleSelect.bind(this);
    this.state = {
      isEditForm: false,
      id: 0,
      formReady: false,
      isSubmitting: false,
      errors: {},
      touched: {},
      form: {
        switch_firmware_id: 0,
        switch_vendor_position: 0,
        switch_vendor_id: props.vendor_id,
        firmware_series_id: props.series_id,
      },
      switchFirmwares:[]
    };
  }

  componentDidMount = async () => {
    await this.intializedata();
  };
  componentDidUpdate = async (prevProps) => {
    if (this.props.editId !== prevProps.editId) {
      await this.intializedata();
    }
  };
  transformData = (data) => data.map(row=>{
    return {
      value:row.id,
      label:row.name
    }
  });

  intializedata = async () => {
    if(_.isEmpty(this.state.switchFirmwares)){
      await serviceObj.getAllFirmwareDropdown().then((response) => {
        const transformeData=this.transformData(response);
        this.setState({
          switchFirmwares: transformeData,
        });
      });
    }
    if (this.props.editId > 0) {
      //repopulate the form
      this.setState({
        id: this.props.editId,
        isEditForm: true,
        formReady:false,
      });
      const Info = await serviceObj.firmwareMappingDetail(this.props.editId);
      const form = this.state.form;
      form.switch_firmware_id = Info.switch_firmware_id;
      form.switch_vendor_position = Info.switch_vendor_position;
      this.setState(
        {
          form,
        },
        () => {
          this.setState({
            formReady: true,
            errors: {},
            touched: {}
          });
        }
      ); //populate in form
    } else {
      //do any preload any api call if needed in caseof add form then make the form ready to load
      this.setState({
        formReady: true,
        id: this.props.editId,
        isEditForm: false,
      });
    }
  }
   

  submitForm = async (e) => {
    e.preventDefault();
    this.trimFields(this.state.form);
    this.setState({
      isSubmitting: true,
    });
    const errors = await this.validateForm();
    if (_.isEmpty(errors)) {
      //now proceed to save user
      const form = this.state.form;
      try {
        let response;
        if (this.state.id > 0 && this.state.isEditForm) {
          response = await serviceObj.updateFirmwareMapping(form, this.state.id);
        } else {
          response = await serviceObj.createFirmwareMapping(form);
        }
        if (response) {
          toast.success(response.message || (this.state.isEditForm) ? MSG_UPDATE_RECORD : MSG_CREATE_RECORD);
          this.resetForm();
        }
      } catch (err) {
        //failure case
        this.formFailure();
        if (err) {
          const errors = this.state.errors;
          for (const [field, msg] of Object.entries(err)) {
            errors[field] = msg[0];
          }
          await this.setState({
            errors,
          });
        }
      }
    } else {
      //failure case
      this.formFailure();
    }
  };
  resetForm = () => {
    const form = this.state.form;
    form.switch_firmware_id = 0;
    form.switch_vendor_position = 0;
    this.setState({
      form,
      isSubmitting: false,
      errors: {},
      touched: {},
      id:0,
      isEditForm:false,
    });
    this.props.triggerParentHandler();
  }
    
  validateForm = async () => {
    const errors = {};
    const schema = yup.object().shape({
      switch_firmware_id: yup
      .number()
      .required()
      .positive("please select switch firmware.")
      .typeError("switch firmware is a required field"),
      switch_vendor_position: yup
        .number()
        .required()
        .positive("please input a positive position.")
        .typeError("position is a required field"),
    });
    // check validity
    await schema
      .validate(this.state.form, { abortEarly: false })
      .catch(function (err) {
        err.inner.map((error) =>
          !errors[error.path] ? (errors[error.path] = error.message) : null
        );
      });

    this.setState({
      errors,
    });
    return errors; //return basicerrors
  };

  render() {
    const {
      isSubmitting,
      form,
      touched,
      errors,
      isEditForm,
      formReady,
      switchFirmwares
    } = this.state;
    return (
      <>
        {isSubmitting ? <Spinner /> : null}
        {!formReady ? <Spinner /> : null}
        { 
          <form onSubmit={this.submitForm} >
            <div className="row pl-2 pb-1">

              <div className="col-sm-4">
                <label htmlFor="switch_firmware_id" className="mr-sm-2">Switch Firmware</label>
                <Select 
                 className={`basic-single mb-2 mr-sm-2 ${
                  touched.switch_firmware_id && errors.switch_firmware_id
                    ? "is-invalid"
                    : ""
                  }`}
                 menuPortalTarget={document.body} 
                 styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                 classNamePrefix="select"                          
                 isClearable={true}
                 isSearchable={true}
                 name="switch_firmware_id"
                 options={switchFirmwares}
                 value={switchFirmwares.find(item => item.value === form.switch_firmware_id) || ''}
                 onChange={e=>this.handleSingleSelect('switch_firmware_id',e)}
  
                />
                  
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="switch_firmware_id"
                  className="text-danger"
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="switch_vendor_position" className="mr-sm-2">Position</label>
                <input
                  type="number"
                  name="switch_vendor_position"
                  placeholder="position"
                  onChange={this.handleChange}
                  value={form.switch_vendor_position}
                  className={`form-control mb-2 mr-sm-2 ${
                    touched.switch_vendor_position && errors.switch_vendor_position ? "is-invalid" : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="switch_vendor_position"
                  className="text-danger"
                />
              </div>
              <div className="col-sm-4">
                <button type="reset" onClick={this.resetForm} className="btn btn-md btn-light btn-link submit-btn-inline mr-sm-2"><i className="fa fa-refresh"></i> Reset</button>
                <button
                  type="submit"
                  className="btn btn-md btn-dark submit-btn-inline"
                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? "Please wait..."
                    : isEditForm
                      ? "Update"
                      : "Add"}
                </button></div>

            </div>
          </form>
          }
        <div className="clearfix"></div>
      </>
    );
  }
}

export default Form;
