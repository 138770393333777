const FILTERS = {
    hcp:{
        isv: {
            title: 'ISV Name',
            icon: 'server.png'
        },
        isv_application: {
            title: 'ISV Application Name',
            icon: 'os.png'
        },
        isv_application_version: {
            title: 'ISV Application Version',
            icon: 'os_kernel.png'
        },
        hcp_version: {
            title: 'HCP Version',
            icon: 'hba_driver.png'
        },
        interface: {
            title: 'Interface',
            icon: 'array_if.png'
        }
    }
}
export default FILTERS;