import { networkService } from "services/networkService";
import {
  METHOD_HCSFNODETYPE_LIST,
  METHOD_HCSFNODETYPE_CREATE,
  METHOD_HCSFNODETYPE_UPDATE,
  METHOD_HCSFNODETYPE_DELETE,
  METHOD_HCSFNODETYPE_DETAIL,  
  METHOD_HCSFSOFTWAREVERSION_LIST,
  METHOD_HCSFSOFTWAREVERSION_CREATE,
  METHOD_HCSFSOFTWAREVERSION_UPDATE,
  METHOD_HCSFSOFTWAREVERSION_DELETE,
  METHOD_HCSFSOFTWAREVERSION_DETAIL,  
  METHOD_HCPMODEL_LIST,
  METHOD_HCPMODEL_CREATE,
  METHOD_HCPMODEL_UPDATE,
  METHOD_HCPMODEL_DELETE,
  METHOD_HCPMODEL_DETAIL,
  METHOD_HCPVERSION_LIST,
  METHOD_HCPVERSION_CREATE,
  METHOD_HCPVERSION_UPDATE,
  METHOD_HCPVERSION_DELETE,
  METHOD_HCPVERSION_DETAIL,
  METHOD_FEATURE_LIST,
  METHOD_FEATURE_CREATE,
  METHOD_FEATURE_UPDATE,
  METHOD_FEATURE_DELETE,
  METHOD_FEATURE_DETAIL,
  METHOD_FILESYSTEM_LIST,
  METHOD_FILESYSTEM_CREATE,
  METHOD_FILESYSTEM_UPDATE,
  METHOD_FILESYSTEM_DELETE,
  METHOD_FILESYSTEM_DETAIL
} from "constants/admin/masters/kiwi";
class kiwiService {

  //HcsfNodeType methods
  getAllHcsfNodeType = (data) => {
    return networkService
      .post(METHOD_HCSFNODETYPE_LIST, data)
      .then((response) => response.data);
  };
  getHcsfNodeTypeDetail = (id) => {
    const updatedMethodName = METHOD_HCSFNODETYPE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHcsfNodeType = (data) => {
    return networkService
      .post(METHOD_HCSFNODETYPE_CREATE, data)
      .then((response) => response.data);
  };
  updateHcsfNodeType = (data, id) => {
    const updatedMethodName = METHOD_HCSFNODETYPE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHcsfNodeType = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HCSFNODETYPE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };   
  //HcsfSoftwareversion methods
  getAllHcsfSoftwareVersion = (data) => {
    return networkService
      .post(METHOD_HCSFSOFTWAREVERSION_LIST, data)
      .then((response) => response.data);
  };
  getHcsfSoftwareVersionDetail = (id) => {
    const updatedMethodName = METHOD_HCSFSOFTWAREVERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHcsfSoftwareVersion = (data) => {
    return networkService
      .post(METHOD_HCSFSOFTWAREVERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateHcsfSoftwareVersion = (data, id) => {
    const updatedMethodName = METHOD_HCSFSOFTWAREVERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHcsfSoftwareVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HCSFSOFTWAREVERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
    //HCPModel methods
    getAllHCPModel = (data) => {
      return networkService
        .post(METHOD_HCPMODEL_LIST, data)
        .then((response) => response.data);
    };
    getHCPModelDetail = (id) => {
      const updatedMethodName = METHOD_HCPMODEL_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createHCPModel = (data) => {
      return networkService
        .post(METHOD_HCPMODEL_CREATE, data)
        .then((response) => response.data);
    };
    updateHCPModel = (data, id) => {
      const updatedMethodName = METHOD_HCPMODEL_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteHCPModel = (id, is_delete = false) => {
      const updatedMethodName = METHOD_HCPMODEL_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName, { data: { is_delete } })
        .then((response) => response.data);
    };     
    //HCPVersion methods
    getAllHCPVersion = (data) => {
      return networkService
        .post(METHOD_HCPVERSION_LIST, data)
        .then((response) => response.data);
    };
    getHCPVersionDetail = (id) => {
      const updatedMethodName = METHOD_HCPVERSION_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createHCPVersion = (data) => {
      return networkService
        .post(METHOD_HCPVERSION_CREATE, data)
        .then((response) => response.data);
    };
    updateHCPVersion = (data, id) => {
      const updatedMethodName = METHOD_HCPVERSION_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteHCPVersion = (id, is_delete = false) => {
      const updatedMethodName = METHOD_HCPVERSION_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName, { data: { is_delete } })
        .then((response) => response.data);
    };
    //feature methods
    getAllFeature = (data) => {
      return networkService
        .post(METHOD_FEATURE_LIST, data)
        .then((response) => response.data);
    };
    getFeatureDetail = (id) => {
      const updatedMethodName = METHOD_FEATURE_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createFeature = (data) => {
      return networkService
        .post(METHOD_FEATURE_CREATE, data)
        .then((response) => response.data);
    };
    updateFeature = (data, id) => {
      const updatedMethodName = METHOD_FEATURE_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteFeature = (id, is_delete = false) => {
      const updatedMethodName = METHOD_FEATURE_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName, { data: { is_delete } })
        .then((response) => response.data);
    }; 
     //filesystem methods
     getAllFileSystem = (data) => {
      return networkService
        .post(METHOD_FILESYSTEM_LIST, data)
        .then((response) => response.data);
    };
    getFileSystemDetail = (id) => {
      const updatedMethodName = METHOD_FILESYSTEM_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then((response) => response.data);
    };
    createFileSystem = (data) => {
      return networkService
        .post(METHOD_FILESYSTEM_CREATE, data)
        .then((response) => response.data);
    };
    updateFileSystem = (data, id) => {
      const updatedMethodName = METHOD_FILESYSTEM_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then((response) => response.data);
    };
    deleteFileSystem = (id, is_delete = false) => {
      const updatedMethodName = METHOD_FILESYSTEM_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName, { data: { is_delete } })
        .then((response) => response.data);
    }; 
}
export default kiwiService;