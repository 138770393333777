import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//array methods
export const METHOD_LIST = 'array/pageable/';
export const METHOD_CREATE = 'array/';
export const METHOD_UPDATE = 'array/{ID}/';
export const METHOD_DELETE = 'array/{ID}/';
export const METHOD_DETAIL = 'array/{ID}/';
export const METHOD_DROPDOWN = 'array/drop/';
//array customer methods
export const METHOD_ARRAYCUSTOMER_LIST = 'arraycustomer/pageable/';
export const METHOD_ARRAYCUSTOMER_CREATE = 'arraycustomer/';
export const METHOD_ARRAYCUSTOMER_UPDATE = 'arraycustomer/{ID}/';
export const METHOD_ARRAYCUSTOMER_DELETE = 'arraycustomer/{ID}/';
export const METHOD_ARRAYCUSTOMER_DETAIL = 'arraycustomer/{ID}/';
export const METHOD_ARRAYCUSTOMER_DROP = 'arraycustomer/drop/';
//array if methods
export const METHOD_ARRAYIF_LIST = 'arrayif/pageable/';
export const METHOD_ARRAYIF_CREATE = 'arrayif/';
export const METHOD_ARRAYIF_UPDATE = 'arrayif/{ID}/';
export const METHOD_ARRAYIF_DELETE = 'arrayif/{ID}/';
export const METHOD_ARRAYIF_DETAIL = 'arrayif/{ID}/';

//array customer version mapping methods
export const METHOD_ARRAYCUSTOMERVERSIONMAPPING_LIST = 'arraycustomerversionmapping/pageable/';
export const METHOD_ARRAYCUSTOMERVERSIONMAPPING_CREATE = 'arraycustomerversionmapping/';
export const METHOD_ARRAYCUSTOMERVERSIONMAPPING_UPDATE = 'arraycustomerversionmapping/{ID}/';
export const METHOD_ARRAYCUSTOMERVERSIONMAPPING_DELETE = 'arraycustomerversionmapping/{ID}/';
export const METHOD_ARRAYCUSTOMERVERSIONMAPPING_DETAIL = 'arraycustomerversionmapping/{ID}/';



//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;
//array master specific messages
export const MSG_WARNING_DELETE_ARRAY='Are you sure you want to delete "ITEM_NAME"? All datapaths and array customer versions associated with it will be deleted.';
export const MSG_WARNING_UNDO_DELETE_ARRAY='Array Customer Version data need to undelete manually. Are you sure you want to undelete "ITEM_NAME"?';

 