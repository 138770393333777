import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import DataGrid from "components/shared/DataGrid";
import _ from "lodash";
import userService from "services/admin/userService";
import { MSG_WARNING_DELETE, MSG_DELETE_SUCCESS } from "constants/admin/users";
import Spinner from "components/shared/Spinner";
const serviceObj = new userService();
class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingTable:false,
      refreshToken: Date.now(),
      //datatable states
      filtering: true,
      columns: [       
        { title: "Full Name", field: "full_name" },
        { title: "Email", field: "email" },
        { title: "User Type", field: "user_type" }
      ],
      actions: [
        {
          icon: "edit",
          tooltip: "Edit Record",
          onClick: (event, rowData) => {
            this.props.history.push("/admin/users/edit/" + rowData.id);
          },
        },
        {
          icon: "delete",
          tooltip: "Delete Record",
          onClick: (event, rowData) => {
            if(window.confirm(MSG_WARNING_DELETE.replace("ITEM_NAME",rowData.name))){
              serviceObj.delete(rowData.id).then((response) => {
                if(response){
                  toast.success(response.message || MSG_DELETE_SUCCESS);
                  this.handleRefresh();
                }
              });
            }
            
          },
        },
      ],
      pageSize: 20,
    };
  }
  handleRefresh = (e) => {
    this.setState({
      refreshToken: Date.now(),
    });
  };

  handlePagingData = (query) =>{
    this.setState({
      loadingTable:true
    })
    return new Promise((resolve, reject) => {
      const sortData = [{ field: "id", dir: "desc" }]; //default sorting
      if (!_.isEmpty(query.orderBy)) {
        sortData.push({
          field: query.orderBy.field,
          dir: query.orderDirection,
        });
      }
      this.setState({ pageSize: query.pageSize }, () => {
        let filters = [];
        if (!_.isEmpty(query.filters)) {
          query.filters.map((filter) =>
            filters.push({
              field: filter.column.field,
              type: "string",
              value: filter.value,
              operator: "eq",
            })
          );
        }
        const reqParams = {
          take: query.pageSize,
          skip: query.pageSize * query.page,
          sort: sortData,
          filter: filters,
        };
        serviceObj.getAll(reqParams).then((response) => {
          this.setState({
            loadingTable:false
          })
          resolve({
            data: response.items,
            page: query.page,
            totalCount: response.total,
          });
        }).catch(error=>{
          this.setState({
            loadingTable:false
          })
        });
      });
    });
  }
  render() {
    const { loadingTable } = this.state;
    return (
      <>
      {loadingTable ? <Spinner /> : null}
      <div className="form-wrap mt-3">
        <div className="form-group">
          <Form.Group as={Row}>
            <Col sm={12}>
              <Link className="btn btn-secondary" to={"/admin/users/add"}>
                Add User
              </Link>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={12}>
              <DataGrid
                actions={this.state.actions}
                columns={this.state.columns}
                dataHandler={this.handlePagingData}
                pageSize={this.state.pageSize}
                filtering={this.state.filtering}
                refreshToken={this.state.refreshToken}
              ></DataGrid>
            </Col>
          </Form.Group>
        </div>
      </div>
    </>
    );
  }
}
export default UsersList;
