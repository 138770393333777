import React, { Component, createRef } from "react";
import {
  STORAGE_FILTER_CONTROLS,
  KEYWORD_SEARCH_FIELD
} from "constants/productMatrix";
import { parseUrlQuery } from "utils/utils";
import { APP_NAME } from "constants/common";
import {LABEL_ALL_PRODUCT_CATEGORY} from "constants/productMatrix";
import { Helmet } from "react-helmet";
import { handleChangeMenu } from "utils/matriceHelpers";
import { connect } from "react-redux";
import products from "resources/json/static-menus.json";
import _ from "lodash";
import {
  getAllKeywordSuggestions
} from "actions/productSearchActions";
import MegaMenu from "components/shared/MegaMenu";
import { Link } from "react-router-dom";
import history from "../../../history";
import productMatrixService from "services/productMatrixService";
import Pagination from "react-js-pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabWrapper from "containers/Pages/TabWrapper/TabWrapper";
import Spinner from "components/shared/Spinner"; 
const productMatrixServiceObj = new productMatrixService();
const elemRef = createRef();
class GlobalSearch extends Component {

  constructor(props) {
    super(props);
    this.handleChangeMenu = handleChangeMenu.bind(this);
    const uriParams = this.getUriParams();
    const defaultKeywordSearch = uriParams && uriParams.find(filter => filter.field === KEYWORD_SEARCH_FIELD);
    this.state = {
      defaultMegaMenuLabel: LABEL_ALL_PRODUCT_CATEGORY,
      keywordsearch: defaultKeywordSearch?.value || '',
      globalSearch: [],
      activePage: 1,
      searchResults: [],
      totalRecords: 0,
      perPage: 10,
      showLoader: false
    }
  }
  componentDidMount() {
    const tags = this.props.allKeywords;
    if (_.isEmpty(tags)) {
      this.props.getAllKeywordSuggestions();
    }
    if (this.state.keywordsearch) {
      this.getSearchResults(this.state.keywordsearch)
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.history.location.search) {
      this.getSearchResults(this.state.keywordsearch)
    }
  }
  handlePageChange = async (activePage) => {
    await this.setState({ activePage });
    const { keywordsearch } = this.state;
    this.getSearchResults(keywordsearch, activePage);
  }

  getSearchResults = async (search_text, page = 1) => {
    // show loader
    this.setState({
      showLoader: true
    })
    const fromPage = (page - 1) * this.state.perPage;
    let toPage = page * this.state.perPage;
    const payload = { search_text, take: this.state.perPage, skip: fromPage };
    productMatrixServiceObj.getPaginatedSearchResults(payload).then(response => {
      if (response.total < toPage) {
        toPage = response.total;
      }
      // hide loader
      this.setState({
        totalRecords: response.total,
        searchResults: response.items,
        showLoader: false,
        fromPage,
        toPage
      })
    }).catch(error=>{
      console.log('error in fetching search results');
      // hide loader
      this.setState({
        showLoader: false
      })
    }
      
    );
  }
  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value
    })
    this.suggestKeywords();
  }
  getUriParams = () => {
    const qs = history.location.search;
    return qs !== "" ? parseUrlQuery(qs) : [];
  };
  suggestKeywords = () => {
    const term = this.state.keywordsearch.toLowerCase();
    if (term !== '') {
      //search from all product keywords        
      const globalSearch = [];
      const allKeywords = this.props.allKeywords;
      allKeywords.map(product => {
        const mainRow = {
          productName: product.product_name,
          matrixName: product.slug
        }
        const searchedTags = {};
        Object.keys(product.items).map(category => {
          const foundRecords = product.items[category].filter(keyword => keyword.toLowerCase().indexOf(term) > -1);
          if (foundRecords.length > 0) {
            searchedTags[category] = foundRecords;
          }
          return true;
        });
        mainRow['searchedTags'] = searchedTags;
        if (Object.keys(searchedTags)?.length > 0) {
          globalSearch.push(mainRow);
        }
        return true;
      });
      this.setState({
        globalSearch
      })
    } else {
      this.setState({
        globalSearch: []
      })
    }
  }
  redirectToMatrice = async (slug, keywordsearch, category) => {
    this.props.history.push(`products/${slug}?keywordsearch=${encodeURIComponent(keywordsearch)}&_f=${category}`);
  }
  redirectToSearch = async (keywordsearch) => {
    this.setState({
      globalSearch: []
    })
    this.props.history.push(`search?keywordsearch=${encodeURIComponent(keywordsearch)}`);
  }
  renderSuggestions = () => {
    const { globalSearch } = this.state;
    if (!_.isEmpty(globalSearch)) {
      return (
        <div className="form-group suggestions_area" >
          { globalSearch.map((product, index) => (
            <div key={`prod-${index}`}><h5 className="bg-light text-dark">{product.productName}</h5>
              {
                Object.keys(product.searchedTags).map((category, index) => (
                  <div key={`caterory-${index}`}><h6 className="bg-light text-dark">{STORAGE_FILTER_CONTROLS[product.matrixName][category]?.title}</h6>
                    {
                      product.searchedTags[category].map((keyword, index) => (
                        <button key={index} className="btn btn-link branding-link-thin" as={Link} onClick={() => this.redirectToMatrice(product.matrixName, keyword, category)} >{keyword}</button>
                      ))
                    }</div>
                ))
              }
            </div>
          ))
          }
        </div>);
    }
  }
  scrollToResult = () => {
    elemRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  resetHandler = () => {
    this.setState({
      keywordsearch: '',
      globalSearch: [],
      searchResults: [],
      totalRecords: 0
    })
   history.push('/');
    
   
  }
  displayAnchorTags = (row) => {
   if(row.pdf_url!== null){
     return <a  className="branding-link-thin uc-first" target="_blank" rel="noreferrer" href={row.pdf_url}>
        <><FontAwesomeIcon size="lg" icon={["far", "file-alt"]} />{' '}</>
        {row.search_keyword} | {row.product_name}
      </a>
  }
  else if(row.pdf_url === null && row.url_params === null && row.slug){
   return <a  className="branding-link-thin uc-first" target="_blank" rel="noreferrer" href={`products/${row.slug}`}>
    {row.search_keyword} | {row.product_name}
   </a>
  }
  else{
    return <a  className="branding-link-thin uc-first" target="_blank" rel="noreferrer" href={`products/${row.slug}?keywordsearch=${encodeURIComponent(row.search_keyword)}`}>
      {row.search_keyword} | {row.product_name}
    </a>
  }
}
  render() {
    const { defaultMegaMenuLabel, keywordsearch, totalRecords, searchResults, activePage, perPage, fromPage, toPage , showLoader} = this.state;
    return (

      <>
       <TabWrapper>
        <Helmet>
          <title>{APP_NAME} :: Search Results - {keywordsearch || ''}</title>
        </Helmet>
        <div className="form-wrap">
          <div className="row mega-row p-1 mt-3">
            
            <div className="form-group">
              <MegaMenu
                defaultOpenState={false}
                data={products}
                flagHomeIcon={true}
                onChangeMenu={this.handleChangeMenu}
                defaultLabel={defaultMegaMenuLabel}
              />
            </div>
            <div className="search-container">
              <div className="form-group search-container-top">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control shadow-none global_search_textbox"
                  placeholder={`Search here`}
                  name="keywordsearch"
                  value={keywordsearch}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.redirectToSearch(e.target.value)
                    }
                  }
                  }
                  onChange={this.handleChange}
                />
                {(!_.isEmpty(keywordsearch)) ? <i className="fa fa-close search_icon_form_control show_cursor" onClick={this.resetHandler}></i> : <i className="fa fa-search search_icon_form_control"></i>}

              </div>
              {this.renderSuggestions()}
            </div>
            {totalRecords > 0 ? (<div className="pl-2 mb-3"><button className="btn btn-link branding-link" onClick={this.scrollToResult}>{totalRecords} Records Found <i className="fa fa-angle-double-down"></i></button></div>)
              : null}
          </div>
          
          {
            showLoader? <Spinner />:
            <div className="form-group">
            {totalRecords > 0  && !showLoader ? (
              <h4 className="pt-3">
                <span >Showing {fromPage + 1}-{toPage} of {totalRecords} result</span>
              </h4>
            ) : <h4 className="pt-3">
                <span >No results found.</span>
              </h4>}

           { !showLoader && <div ref={elemRef} className="row pt-3">
              {
                (searchResults || []).map((row, index) => (
                  <div key={index} className="card col-lg-12 card-body">
                    <h6>
                      {this.displayAnchorTags(row)}
                    </h6>
                    {row?.description?(<p>{row?.description}</p>):null}
                  </div>
                ))
              }
              {
                (totalRecords > perPage) ? (
                  <div className="row pt-2">
                    <div className="col text-center">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={perPage}
                        totalItemsCount={totalRecords}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        itemClass="page-item branding-link"
                        linkClass="page-link branding-link"
                      />
                    </div>
                  </div>
                ) : null
              }
            </div>}
          </div>
          }
        </div> 
        </TabWrapper>
      </>
    );
  }
}


const mapStateToProps = (state) => ({
  ...state.productSearchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllKeywordSuggestions: () => dispatch(getAllKeywordSuggestions())
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);