const FILTERS = {
    "replication-intermix": {
        replication_product: {
            title: 'Replication Product',
            icon: 'os.png'
        },
        replication_product_intermix: {
            title: 'Replication Product Intermix',
            iconClass: 'network-wired'
        },
        site_topology: {
            title: 'Site Topology',
            iconClass: 'project-diagram'
        },
        primary_site_array: {
            title: 'Primary Site Array',
            icon: 'array.png'
        },
        remote_site_array: {
            title: 'Remote Site Array',
            icon: 'array_if.png'
        }
    }
}
export default FILTERS;