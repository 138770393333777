import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH_200, MAX_TEXT_LENGTH } from "constants/common";

//SRM VERSION
export const METHOD_SRM_VERSION_LIST = 'srmversion/pageable/';
export const METHOD_SRM_VERSION_CREATE = 'srmversion/';
export const METHOD_SRM_VERSION_UPDATE = 'srmversion/{ID}/';
export const METHOD_SRM_VERSION_DELETE = 'srmversion/{ID}/';
export const METHOD_SRM_VERSION_DETAIL = 'srmversion/{ID}/';

//SRA
export const METHOD_SRA_LIST = 'sra/pageable/';
export const METHOD_SRA_CREATE = 'sra/';
export const METHOD_SRA_UPDATE = 'sra/{ID}/';
export const METHOD_SRA_DELETE = 'sra/{ID}/';
export const METHOD_SRA_DETAIL = 'sra/{ID}/';

//SRA VERSION
export const METHOD_SRA_VERSION_LIST = 'sraversion/pageable/';
export const METHOD_SRA_VERSION_CREATE = 'sraversion/';
export const METHOD_SRA_VERSION_UPDATE = 'sraversion/{ID}/';
export const METHOD_SRA_VERSION_DELETE = 'sraversion/{ID}/';
export const METHOD_SRA_VERSION_DETAIL = 'sraversion/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;
export const TEXT_MAX_LENGTH_200=MAX_TEXT_LENGTH_200;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;