import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//EOSL
export const METHOD_EOSL_LIST = 'eoslmapping/pageable/';
export const METHOD_EOSL_CREATE = 'eoslmapping/';
export const METHOD_EOSL_UPDATE = 'eoslmapping/{ID}/';
export const METHOD_EOSL_DELETE = 'eoslmapping/{ID}/';
export const METHOD_EOSL_DETAIL = 'eoslmapping/{ID}/';
export const METHOD_EOSL_ROLLBACK = 'eoslmapping/rollback/{ID}/';
export const METHOD_EOSL_RUN_BG_JOBS = 'eoslmapping/runbackgroundjob';
export const METHOD_MASTER_DROPDOWN = '{masterKey}/drop/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;

export const MSG_WARNING_ROLLBACK = 'Are you sure you want to rollback "ITEM_NAME"?.';
export const MSG_ROLLBACK_RECORD = "Record has been rollback successfully.";