const FILTERS = {
    userForm: {
        roles: {
            title: 'Roles',
            iconClass: 'user-tag'
        },
        products: {
            title: 'Products',
            iconClass: 'sitemap'
        }
    }
}
export default FILTERS;