import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

export const METHOD_CLUSTER_INTERCONNECT_LIST = 'clusterinterconnectswitch/pageable/';
export const METHOD_CLUSTER_INTERCONNECT_CREATE = 'clusterinterconnectswitch/';
export const METHOD_CLUSTER_INTERCONNECT_UPDATE = 'clusterinterconnectswitch/{ID}/';
export const METHOD_CLUSTER_INTERCONNECT_DELETE = 'clusterinterconnectswitch/{ID}/';
export const METHOD_CLUSTER_INTERCONNECT_DETAIL = 'clusterinterconnectswitch/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;