import { networkService } from "services/networkService";
import { 
  METHOD_VENDOR_LIST, 
  METHOD_VENDOR_CREATE,
  METHOD_VENDOR_UPDATE,
  METHOD_VENDOR_DELETE,
  METHOD_VENDOR_DETAIL,  
  METHOD_MODEL_LIST,
  METHOD_MODEL_CREATE,
  METHOD_MODEL_UPDATE,
  METHOD_MODEL_DELETE,
  METHOD_MODEL_DETAIL
} from "constants/admin/masters/kms";
class kmsService {

  //kms vendor methods
  getAllVendors = (data) => {
    return networkService
      .post(METHOD_VENDOR_LIST, data)
      .then((response) => response.data);
  };
  getVendorDetail = (id) => {
    const updatedMethodName = METHOD_VENDOR_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createVendor = (data) => {
    return networkService
      .post(METHOD_VENDOR_CREATE, data)
      .then((response) => response.data);
  };
  updateVendor = (data, id) => {
    const updatedMethodName = METHOD_VENDOR_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteVendor = (id, is_delete = false) => {
    const updatedMethodName = METHOD_VENDOR_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

   //kms model methods
   getAllModels = (data) => {
    return networkService
      .post(METHOD_MODEL_LIST, data)
      .then((response) => response.data);
  };
  getModelDetail = (id) => {
    const updatedMethodName = METHOD_MODEL_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createModel = (data) => {
    return networkService
      .post(METHOD_MODEL_CREATE, data)
      .then((response) => response.data);
  };
  updateModel = (data, id) => {
    const updatedMethodName = METHOD_MODEL_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteModel = (id, is_delete = false) => {
    const updatedMethodName = METHOD_MODEL_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}
export default kmsService;
