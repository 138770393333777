import React from "react";
import DOMPurify from "dompurify";
import "resources/css/expandable-data.css";
const FormatedData = (props) => {
  const fillRow = (row) => {
    const diffrenceCols = props.allowedCols - row.length;
    for (let i = 0; i < diffrenceCols; i++) {
      row.push("");
    }
    return row;
  };

  const { data, allowedCols } = props;
  const multiDimensionalArray = data.reduce(
    (rows, key, index) =>
      (index % allowedCols === 0
        ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows,
    []
  );
  const addSpaceIfNoAsterisk = (str) => {
    const countOccurence = (str.match(/asterisk-style/g) || []).length;
    if(!props?.skipSpaceIfNoAsterisk && countOccurence===0){
      return "<span class='asterisk-style'>&nbsp;</span>"+str.trim();
    }else{
      return str.trim();
    }
  }

  return multiDimensionalArray.length ? (
    <div className="table-responsive-lg">
      <table className={props?.noClass ? null : "table table-borderless"}>
        <tbody>
          {multiDimensionalArray.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {fillRow(row).map((col, colIndex) => (
                <td
                  className={props?.noClass ? "pr-2" : null}
                  key={"col" + colIndex + "Row" + colIndex}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(addSpaceIfNoAsterisk(col)),
                  }}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
};
export default FormatedData;
