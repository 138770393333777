const FILTERS = {
    ekms: {
        kms_vendor: {
            title: 'KMS Vendor',
            icon: 'os_kernel.png'
        },
        kms_model: {
            title: 'KMS Model',
            icon: 'os.png'
        },
        software_version: {
            title: 'EKMS Software Version',
            iconClass: 'project-diagram'
        },
        array: {
            title: 'Array',
            icon: 'array.png'
        }
    }
}
export default FILTERS;