const FILTERS = {
    hcs: {
        server: {
            title: 'Server',
            icon: 'server.png'
        },
        os: {
            title: 'OS',
            icon: 'os.png'
        },
        os_kernel: {
            title: 'OS Kernel',
            icon: 'os_kernel.png'
        },
        array: {
            title: 'Array',
            icon: 'array.png'
        },
        array_if: {
            title: 'Array IF',
            icon: 'array_if.png'
        },
        hba: {
            title: 'HBA',
            icon: 'hba.png'
        },
        hba_driver: {
            title: 'HBA Driver',
            icon: 'hba_driver.png'
        },
        switch: {
            title: 'Switch',
            icon: 'switch.png'
        },
        switchfirmware: {
            title: 'Switch Firmware',
            icon: 'firmware.png'
        }
    }
}
export default FILTERS;