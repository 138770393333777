const FILTERS = {
    "mf-host": {
        cpu: {
            title: 'CPU',
            icon: 'server.png'
        },
        os: {
            title: 'OS',
            icon: 'os.png'
        },
        zhpf_support: {
            title: 'zHPF Support',
            iconClass: 'project-diagram'
        },
        array_customer_version: {
            title: 'Array',
            icon: 'array.png'
        }
    }
}
export default FILTERS;