const FILTERS = {
    viewpoint: {        
        view_point_version: {
            title: 'Viewpoint Version',
            icon: 'hba.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        },
        java_dev_kit: {
            title: 'JDK',
            iconClass: 'project-diagram'
        },
        view_point_hypervisor: {
            title: 'Hypervisor',
            icon: 'array.png'
        },
        browser: {
            title: 'Browser',
            icon: 'multipath.png'
        },
        array_customer_version: {
            title: 'Storage Array',
            icon: 'array_if.png'
        }
    }
}
export default FILTERS;