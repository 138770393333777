const FILTERS = {
    'vcenter-sdsb': {
        os: {
            title: 'vSphere Release',
            icon: 'server.png'
        },
        clientplugin_version: {
            title: 'Client Plug-In Version',
            icon: 'os.png'
        },
        protocol: {
            title: 'Protocol',
            icon: 'os_kernel.png'
        },
        hvssb_software_version: {
            title: 'Software Version',
            icon: 'switch.png'
        },
    }
}
export default FILTERS;