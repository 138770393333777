import React from "react";
export const ErrorMessage = (props) => {
  const renderError = (tag, className, errorText) => {
    errorText=errorText.replace(/_/g," ");
    return React.createElement(tag,{className},errorText);
  };

  return props.touched[props.name] && props.errors[props.name] !== undefined
    ? renderError(props.tag, props.className, props.errors[props.name])
    : null;
};
