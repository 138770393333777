const FILTERS = {
    "sap-hana": { 
        sap_hana_solution_type: {
            title: 'SAP HANA Solution Type',
            iconClass: 'network-wired'
        },
        server_model: {
            title: 'Server Model',
            icon: 'server.png'
        },
        cpu_generation: {
            title: 'CPU Generation',
            iconClass: 'project-diagram'
        },
        operating_system: {
            title: 'Operating System',
            icon: 'os.png'
        },
        server_software_version: {
            title: 'Server Software Version',
            icon: 'server.png'
        },
        nic: {
            title: 'NIC',
            icon: 'hba_driver.png'
        },
        leaf_switch: {
            title: 'Leaf Switch',
            icon: 'hba.png'
        },
        hba: {
            title: 'HBA ',
            icon: 'hba.png'
        },
        fc_switch: {
            title: 'FC Switch',
            icon: 'switch.png'
        },
        storage: {
            title: 'Storage',
            icon: 'array_if.png',
            dynamic: true
        },
        file_storage: {
            title: 'File Storage',
            icon: 'array_if.png'
        },
        block_storage: {
            title: 'Block Storage',
            icon: 'array_if.png'
        }
    }
}
export default FILTERS;