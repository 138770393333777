const FILTERS = {
    "svp":{
        array_customer_version: {
            title: 'Array',
            icon: 'array.png'
        },
        svp_type: {
            title: 'SVP Type',
            icon: 'server.png'
        },
        hypervisor: {
            title: 'Hypervisor',
            icon: 'server.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        }
    }
}
export default FILTERS;