import _ from "lodash";
export async function toggleChangehandler(e) {
  if (e === '0') {
    this.setState({
      recordsMainFilter: null,
      refreshToken: Date.now(),
    });
  }
  else {
    this.setState({
      recordsMainFilter: { "field": "is_deleted", "type": "bool", "value": e, "operator": "eq" },
      refreshToken: Date.now(),
    });
  }
}
export async function toggleChangehandlerNew(e) {
  if (e === '') {
    this.setState({
      recordsMainFilter: null,
      refreshToken: Date.now(),
    });
  }
  else {
    this.setState({
      recordsMainFilter: { "field": "is_deleted", "type": "int", "value": e, "operator": "eq" },
      refreshToken: Date.now(),
    });
  }
}
export async function handleRefresh(e) {
  this.setState({
    refreshToken: Date.now(),
  });
}

export async function handleSingleSelect(field, e) {
  const form = this.state.form;
  form[field] = e?.value || 0;
  this.setState({
    form
  });
  //mark this input as touched
  this.handleTouched(field);
  //now call the validations to keep the errors up to date
  this.validateForm();
}

export async function handleMultiSelect(field, value) {
  const form = this.state.form;
  form[field] = value;
  this.setState({
    form
  });
  //mark this input as touched
  this.handleTouched(field);
  //now call the validations to keep the errors up to date
  this.validateForm();
}

export async function handleChange(e) {
  //update the form
  const form = this.state.form;
  form[e.target.name] = e.target.value;
  this.setState({
    form,
  });
  //mark this input as touched
  this.handleTouched(e.target.name);
  //now call the validations to keep the errors up to date
  this.validateForm();
};
export async function handleCheck(e) {
  const form = this.state.form;
  form[e.target.name] = e.target.checked;
  this.setState({
    form,
  });
  //mark this input as touched
  this.handleTouched(e.target.name);
  //now call the validations to keep the errors up to date
  this.validateForm();
};

export async function handleTouched(field) {
  //update the touched
  const touched = this.state.touched;
  touched[field] = true;
  this.setState({
    touched,
  });
};
export async function formFailure() {
  const form = this.state.form;
  //after having errors in submit case,set all fields touched
  const touched = this.state.touched;
  Object.keys(form).map((element) => (touched[element] = true));
  this.setState({
    isSubmitting: false,
    touched,
  });
};

export function getCurrentSlug() {
  const slugData = this.props.location.pathname.split('/');
  return (!_.isEmpty(slugData) && slugData[3]) ? slugData[3] : '404';
}

export async function trimFields(form = {}) {
  if (!_.isEmpty(form)) {
    Object.keys(form).map(field => {
      if (typeof form[field] === 'string') {
        form[field] = form[field].trim()
      }
      return true;
    });
    await this.setState({ form });
  }
}

export function transformStringtoMatrix(data, rowDelimeter = "^^", colDelimeter = "~~") {
  const tempArray = data.split(rowDelimeter);
  const finalArray = [];
  for (var i = 0; i < tempArray.length; i++) {
    finalArray[i] = tempArray[i].split(colDelimeter);
  }
  return finalArray;
}