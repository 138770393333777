const FILTERS = {
  hrpc: {
    hrpc_version: {
      title: "HRPC Version",
      icon: "server.png",
    },

    hspc_version: {
      title: "HSPC Version",
      icon: "server.png",
    },
    operating_system: {
      title: "Operating System",
      icon: "os.png",
    },
    container_orchestrator: {
      title: "Container Orchestrator",
      icon: "os.png",
    },

    orchestrator_version: {
      title: "Orchestrator Version",
      icon: "os.png",
    },
    array_customer_version: {
      title: "Array",
      icon: "array.png",
    },
    report_name: {
      title: "Report Name",
      icon: "multipath.png",
    },
    notes: {
      title: "Notes",
      icon: "array.png",
    },
  },
};

export default FILTERS;
