import interopMatrix from "./filters/interop-matrix";
import hcs from "./filters/hcs";
import gad from "./filters/gad";
import tc from "./filters/tc";
import hur from "./filters/hur";
import ndm from "./filters/ndm";
import uvm from "./filters/uvm";
import openstack from "./filters/openstack";
import osCluster from "./filters/os-cluster";
import replicationIntermix from "./filters/replication-intermix";
import ekms from "./filters/ekms";
import hspc from "./filters/hspc";
import viewpoint from "./filters/viewpoint";
import hcsf from "./filters/hcsf";
import sdsb from "./filters/hvssb";
import analyzer from "./filters/analyzer";
import storageCertification from "./filters/storage-certification";
import opsCenterAdministrator from "./filters/ops-center-administrator";
import opsCenterCommonServices from "./filters/ops-center-common-services";
import hcp from "./filters/hcp";
import hcpCloud from "./filters/hcp-cloud";
import hcpAnywhere from "./filters/hcp-anywhere";
import citrix from "./filters/citrix";
import sapHana from "./filters/sap-hana";
import oracleMatrices from "./filters/oracle-matrices";
import userForm from "./filters/userForm";
import eoslForm from "./filters/eoslForm";
import opsCenterAutomator from "./filters/ops-center-automator";
import opsCenterApiCM from "./filters/ops-center-api-cm";
import protector from "./filters/protector";
import svp from "./filters/svp";
import hnas from "./filters/hnas";
import mfHost from "./filters/mainframe-host-connectivity";
import mfFicon from "./filters/mainframe-ficon-connectivity";
import mfFcp from "./filters/mainframe-fcp-connectivity";
import hdlm from "./filters/hdlm";
import hdlmRac from "./filters/hdlm-rac";
import sdsbCertification from "./filters/hvssb-certification";
import sdsbHspc from "./filters/hvssb-hspc";
import vasa from "./filters/vasa";
import vcenter from "./filters/vcenter";
import vrops from "./filters/vrops";
import vro from "./filters/vro";
import sra from "./filters/sra";
import vropssdsb from "./filters/vropsvssb";
import vcentervssb from "./filters/vcentervssb";
import vmware from "./filters/vmware";
import baremetal from "./filters/baremetal";
import hybridCloudInteroperability from "./filters/hybridCloudInteroperability";
import hrpc from "./filters/hrpc";
import hspp from "./filters/hspp";
import veeam from "./filters/veeam";
import dataProtection from "./filters/dataProtection";
import flashAnsible from "./filters/flashAnsible";
import sdsAnsible from "./filters/sdsAnsible";
import ansibleFlash from "./filters/ansibleFlash";
import ansibleSds from "./filters/ansibleSds";

//mapping data for filter control icon and title
export const STORAGE_FILTER_CONTROLS = {
  ...interopMatrix,
  ...hcs,
  ...gad,
  ...tc,
  ...hur,
  ...ndm,
  ...uvm,
  ...openstack,
  ...osCluster,
  ...replicationIntermix,
  ...ekms,
  ...hspc,
  ...viewpoint,
  ...hcsf,
  ...sdsb,
  ...analyzer,
  ...storageCertification,
  ...opsCenterAdministrator,
  ...opsCenterCommonServices,
  ...hcp,
  ...hcpCloud,
  ...hcpAnywhere,
  ...citrix,
  ...sapHana,
  ...userForm,
  ...eoslForm,
  ...oracleMatrices,
  ...opsCenterAutomator,
  ...opsCenterApiCM,
  ...protector,
  ...svp,
  ...hnas,
  ...mfHost,
  ...mfFicon,
  ...mfFcp,
  ...hdlm,
  ...hdlmRac,
  ...sdsbCertification,
  ...sdsbHspc,
  ...vasa,
  ...vcenter,
  ...vrops,
  ...vro,
  ...sra,
  ...vropssdsb,
  ...vcentervssb,
  ...vmware,
  ...baremetal,
  ...hybridCloudInteroperability,
  ...hrpc,
  ...hspp,
  ...veeam,
  ...dataProtection,
  ...flashAnsible,
  ...sdsAnsible,
  ...ansibleFlash,
  ...ansibleSds
};

export const MODULE_INTEROP_MATRIX_SLUGS = ["interop-matrix", "hcs"];
export const MODULE_GAD_MATRIX_SLUGS = ["gad"];
export const MODULE_TC_UR_MATRIX_SLUGS = ["tc", "hur"];
export const MODULE_NDM_MATRIX_SLUGS = ["ndm"];
export const MODULE_UVM_MATRIX_SLUGS = ["uvm"];
export const MODULE_OPENSTACK_MATRIX_SLUGS = ["openstack"];
export const MODULE_OSCLUSTER_MATRIX_SLUGS = ["os-cluster"];
export const MODULE_REPLICATION_INTERMIX_MATRIX_SLUGS = [
  "replication-intermix",
];
export const MODULE_ENCRYPTION_KEY_MATRIX_SLUGS = ["ekms"];
export const MODULE_HSPC_MATRIX_SLUGS = ["hspc"];
export const MODULE_VIEWPOINT_MATRIX_SLUGS = ["viewpoint"];
export const MODULE_KIWI_INTEROP_MATRIX_SLUGS = ["hcsf"];
export const MODULE_SOPHIA_INTEROP_MATRIX_SLUGS = ["sdsb"];
export const MODULE_STORAGE_CERTIFICATION_SLUGS = ["storage-certification"];
export const MODULE_OPS_COMMON_SERVICES_SLUGS = ["ops-common-services"];
export const MODULE_OPS_CENTER_ADMINISTRATOR_SLUGS = [
  "ops-center-administrator",
];
export const MODULE_HCP_SLUGS = ["hcp"];
export const MODULE_HCPCS_SLUGS = ["hcp-cloud"];
export const MODULE_HCP_ANYWHERE_SLUGS = ["hcp-anywhere"];
export const MODULE_CITRIX_SLUGS = ["ucp-vdi"];
export const MODULE_SAP_HANA_SLUGS = ["sap-hana"];
export const MODULE_ORACLE_SLUGS = ["oracle"];
export const MODULE_ORACLE_EDW_SLUGS = ["oracle-edw"];
export const MODULE_ORACLE_V_NV_SLUGS = ["oracle-virt-non-virt"];
export const KEYWORD_SEARCH_FIELD = "keywordsearch";
export const KEYWORD_SEARCH_CATEGORY = "_f";
export const MODULE_OPS_CENTER_AUTOMATOR_SLUGS = ["ops-automator"];
export const MODULE_OPS_CENTER_API_CM_SLUGS = ["ops-api-cm"];
export const MODULE_PROTECTOR_SLUGS = ["ops-protector"];
export const MODULE_SVP_SLUGS = ["svp"];
export const MODULE_HNAS_SLUGS = ["hnas"];
export const MODULE_MFHOST_SLUGS = ["mf-host"];
export const MODULE_MFFICON_SLUGS = ["mf-ficon"];
export const MODULE_MFFCP_SLUGS = ["mf-fcp"];
export const MODULE_HDLM_SLUGS = ["hdlm"];
export const MODULE_HDLMRAC_SLUGS = ["hdlm-rac"];
export const MODULE_HVSSB_CERTIFICATION_SLUGS = ["sdsb-certification"];
export const MODULE_HVSSB_HSPC_SLUGS = ["sdsb-hspc"];
export const MODULE_VASA_SLUGS = ["vasa"];
export const MODULE_VCENTER_SLUGS = ["vcenter", "vcenter-sdsb"];
export const MODULE_VCENTER_FLASH_SLUGS = ["vcenter"];
export const MODULE_VCENTER_SDS_SLUGS = [ "vcenter-sdsb"];
export const MODULE_VROPS_SLUGS = ["vrops", "vropssdsb"];
export const MODULE_VROPS_FLASH_SLUGS = ["vrops"];
export const MODULE_VROPS_SDS_SLUGS = ["vropssdsb"];
export const MODULE_VRO_SLUGS = ["vro"];
export const MODULE_SRA_SLUGS = ["sra"];
export const MODULE_VMWARE_SLUGS = ["ucp-vmware"];
export const MODULE_BAREMETAL_SLUGS = ["ucp-baremetal"];
export const MODULE_UCP_PLATFORM = [73];
export const MODULE_HC_INTEROPERABILITY_SLUGS = [
  "hybrid-cloud-interoperability",
];
export const MODULE_HRPC_SLUGS = ["hrpc"];
export const MODULE_HSPP_SLUGS = ["hspp"];
export const MODULE_VEEAM_SLUGS = ["veeam"];
export const MODULE_DATA_PROTECTION_SLUGS = ["data-protection"];

export const MODULE_ANALYZER_SLUGS = ["analyzer"];
export const MODULE_FLASH_ANSIBLE_SLUGS = ["flash-ansible","ansible-flash"];
export const MODULE_SDS_ANSIBLE_SLUGS = ["sdsb-ansible", "ansible-sdsb"];
  
//ZIP Download related Info
export const ZIP_ENABLED_PRODUCTS = [3, 6, 7, 10, 13, 55];
export const ZIP_TRIGGER_COUNT = 100000;

//labels
export const LABEL_ALL_PRODUCT_CATEGORY = "All Products Category";
export const LABEL_ALL_SOLUTION_CATEGORY = "All Solutions Category";
export const LABEL_CHOOSE_PRODUCT_CATEGORY = "Choose Product Category";
export const LABEL_BULK_UPLOAD_FOR = "Bulk Upload For";

//API Method Constants
export const METHOD_GET_STORAGE_FILTERS =
  "productmetrices/getallmasters/{productId}/";
export const METHOD_GET_STORAGE_PRODUCTS =
  "productmetrices/pageable/{productId}/";
export const METHOD_GET_STORAGE_PRODUCTS_CSV =
  "productmetrices/downloadcsv/{productId}/";
export const METHOD_GET_STORAGE_PRODUCTS_ZIP =
  "productmetrices/downloadzip/{productId}/";
export const METHOD_GET_STORAGE_PRODUCTS_PDF =
  "productmetrices/downloadpdf/{productId}/";
export const METHOD_DOWNLOAD_LIMIT = "others/exportrecordcount/";
export const METHOD_DONWLOAD_KEYWORDS = "productmetrices/tags/{productId}/";
export const METHOD_DONWLOAD_ALL_KEYWORDS = "productmetrices/alltags/";

//API ADMIN DATAPATH EXPORTS
export const METHOD_GET_STORAGE_EXPORT_FILTERS =
  "managedatapaths/getallmasters/{productId}/{downloadAction}/";
export const METHOD_GET_STORAGE_EXPORT_PRODUCTS =
  "managedatapaths/pageable/{productId}/{downloadAction}/";
export const METHOD_GET_STORAGE_PRODUCTS_EXPORT_CSV =
  "managedatapaths/downloadcsv/{productId}/{downloadAction}/";
export const METHOD_SEARCH_RESULTS = "productmetrices/search/";

//api access logs
export const METHOD_POST_ACCESS_LOGS = "accesslog/";
export const METHOD_GET_ACCESS_LOGS = "getaccesslog/";

//Action Constants
export const STORAGE_FILTERS = "STORAGE_FILTERS";
export const STORAGE_PRODUCT_DATA = "STORAGE_PRODUCT_DATA";
export const LOADING_FILTERS = "LOADING_FILTERS";
export const DOWNLOAD_LIMIT = "DOWNLOAD_LIMIT";
export const FILTER_KEYWORDS = "FILTER_KEYWORDS";
export const FILTER_ALL_KEYWORDS = "FILTER_ALL_KEYWORDS";
export const TOTAL_RECORD_COUNT = "TOTAL_RECORD_COUNT";
export const LEGACY_PRODUCT_MENU = "LEGACY_PRODUCT_MENU";

//Errors Constant
export const ERROR_FORM_EMPTY =
  "What are you looking for? Please select from the provided search options or enter a Search Keyword.";
export const ERROR_NO_RESULTS =
  "There are no search results to download. Please refine your search.";
export const ERROR_DOWNLOAD_LIMIT =
  "Please refine your search. You will be able to download only {0} or less search results at a time.";
export const RESET_FORM_WARNING = "Are you sure?";
export const NO_DATA_DISPLAY = "No records to display";

//message
export const EXPORT_DATAPATH_CONFIRM_MSG =
  "You are trying to download larger set of records. For better readability you may refine your search criteria. Would you like to refine your search? If not you can download the entire Datapaths from Datapath Download module.";

//datapath actions
export const DATAPATH_ACTIONS = [
  { id: 1, title: "Add" },
  { id: 2, title: "Update" },
  { id: 3, title: "Delete" },
  { id: 4, title: "Re-Activate" },
];

//restricted users
export const RESTRICTED_USERS_LIST = [
  "Non-Entitled Customer",
  "Entitled Customer",
  "Registered User",
];

//legacy links in filters
export const LEGACY_FILTER_LINKS = {
  /*
    hcs: {
        array: {
            title: 'Click here for legacy Arrays',
            link: 'https://download.hitachivantara.com/download/epcra/rd801910.pdf',
            height:'15'
        }
    },
    */
};
