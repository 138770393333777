const FILTERS = {
  "hybrid-cloud-interoperability": {
    cloud_provider: {
      title: "Cloud Provider",
      icon: "server.png",
    },
    operating_system: {
      title: "OS",
      icon: "os.png",
    },

    nic: {
      title: "NIC",
      icon: "hba_driver.png",
    },
    nic_driver: {
      title: "NIC Driver",
      icon: "hba_driver.png",
    },
    multi_path: {
      title: "Multipath",
      icon: "multipath.png",
    },
    switch: {
      title: "Switch",
      icon: "switch.png",
    },
    switchfirmware: {
      title: "Switch Firmware",
      icon: "firmware.png",
    },
    array_customer_version: {
      title: "Array",
      icon: "array.png",
    },
    array_interface: {
      title: "Array Interface",
      icon: "array_if.png",
    },
    microcode: {
      title: "Microcode",
      icon: "array.png",
    },
    report_name: {
      title: "Report Name",
      icon: "multipath.png",
    },
    notes: {
      title: "Notes",
      icon: "array.png",
    },
  },
};
export default FILTERS;
