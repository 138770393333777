import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH_200 } from "constants/common";

//CLIENT PLUG IN VERSION
export const METHOD_CLIENT_PLUG_IN_VERSION_LIST = 'clientpluginversion/pageable/';
export const METHOD_CLIENT_PLUG_IN_VERSION_CREATE = 'clientpluginversion/';
export const METHOD_CLIENT_PLUG_IN_VERSION_UPDATE = 'clientpluginversion/{ID}/';
export const METHOD_CLIENT_PLUG_IN_VERSION_DELETE = 'clientpluginversion/{ID}/';
export const METHOD_CLIENT_PLUG_IN_VERSION_DETAIL = 'clientpluginversion/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH_200;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;