import React, { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import DataGrid from "components/shared/DataGrid";
import switchService from "services/admin/masters/switchService";
import { MSG_WARNING_DELETE, MSG_DELETE_RECORD, MSG_WARNING_UNDO_DELETE, MSG_UNDO_RECORD, MSG_SWITCH_FW_EXIST_POPUP_ERROR } from "constants/admin/masters/switch";
import FirmwareForm from "./Form";
import Spinner from "components/shared/Spinner";
import Breadcrumb from "components/shared/Breadcrumb";
import _ from "lodash";
import Toggle from "components/shared/Toggle";
import MasterRelation from "components/shared/MasterRelation";
import { toggleChangehandler,getCurrentSlug, transformStringtoMatrix } from "utils/masterHelpers";
import JobProgressStatus from "containers/Admin/masters/job-progress/JobProgressStatus";
import EoslMapping from "containers/Admin/masters/eosl-mapping/regular-masters/EoslMapping";
import ErrorDialog from "components/shared/ErrorDialog";
import SimpleDataTable from "components/shared/SimpleDataTable";
const serviceObj = new switchService();
class List extends Component { 
  constructor(props) {
    super(props);
    this.toggleChangehandler=toggleChangehandler.bind(this);
    this.getCurrentSlug=getCurrentSlug.bind(this);
    this.state = {
      deleteExceptionOccured:false,
      deleteExceptMessage:'',
      loadinTable: false,
      editId: 0,
      refreshToken: Date.now(),
      allFirmwares: [],
      allFirmwaresColumns: [
        { title: "Firmware Name", field: "switch_firmware_id.name", sorting: false },
        { title: "Position", field: "switch_vendor_position", sorting: true },
        { title: "Firmware Series", field: "firmware_series_id.name", sorting: false }
      ],
      currentSeriesdata: [],
      filtering: false,
      paging: false,
      vendorName:'',
      seriesName:'',
      firmwareColumns: [
        { title: "Firmware Name", field: "switch_firmware_id.name", sorting: false },
        { title: "Position", field: "switch_vendor_position", sorting: true},
      ],
      breadcrumbs: [{ title: 'Switch Firmware Series List', url: '/admin/master/switch-firmware-series/' },
      { title: 'Manage Firmwares' }
      ],
      actions: [(row) => this.decideAction(row, 'undo'), (row) => this.decideAction(row, 'edit'), (row) => this.decideAction(row, 'delete')],
      pageSize: 20,
      recordsMainFilter: { "field": "is_deleted", "type": "bool", "value": 'False', "operator": "eq" }
    };
  }
  decideAction = (rowData, actionType) => {
    if (actionType === 'undo') {
      return (rowData.is_deleted === true) ? (
        {
          icon: actionType,
          tooltip: "Undo Deleted Record",
          onClick: (event, rowData) => {
            if (window.confirm(MSG_WARNING_UNDO_DELETE.replace("ITEM_NAME", rowData.switch_firmware_id.name))) {
              serviceObj.deleteFirmwareMapping(rowData.id).then((response) => {
                toast.success(response.message || MSG_UNDO_RECORD);
                this.handleRefresh();
              });
            }

          },
        }

      ) : null;
    }
    else if (actionType === 'edit') {
      return (rowData.is_deleted === false) ? (
        {
          icon: actionType,
          tooltip: "Edit Record",
          onClick: (event, rowData) => {
            this.setState({
              editId: rowData.id,
            });
          }
        }
      ) : null;
    }
    else if (actionType === 'delete') {
      return (rowData.is_deleted === false) ? ({
          icon: actionType,
          tooltip: "Delete Record",
          onClick: (event, rowData) => {
            if (window.confirm(MSG_WARNING_DELETE.replace("ITEM_NAME", rowData.switch_firmware_id.name))) {
              serviceObj.deleteFirmwareMapping(rowData.id).then((response) => {
                toast.success(response.message || MSG_DELETE_RECORD);
                this.handleRefresh();
              }).catch(e=>{
                
                if(e.firmware_exception_exists){
                  this.setState({
                    deleteExceptionOccured:true,
                    deleteExceptMessage:e?.sf_delete_error || e?.message
                  })
                } 

              });
            }

          },
        }) : null;
    }
  }
  componentDidMount = async () => {
    this.getMetaInfo()
    await this.getData();
  }
  componentDidUpdate = async (prevProps,prevState) => {
   if(this.state.refreshToken!==prevState.refreshToken){
    await this.getData();
   }    
  }
  handleRefresh = async (e) => {
    //await this.getData();
    this.setState({
      editId: 0,
      refreshToken: Date.now(),
    });
  };
  getMetaInfo = async () => {
    const { series_id } = this.props.match.params;
    const Info = await serviceObj.firmwareSeriesDetail(series_id);
    this.setState({
      vendorName: Info.switch_vendor_name,
      seriesName:Info.name
    })
  }

  getData = async () => {
    const { vendor_id, series_id } = this.props.match.params;
    this.setState({
      loadingTable: true
    })
    const sortData = [{ field: "firmware_series_id.id", dir: "desc" }, { field: "switch_vendor_position", dir: "desc" }];
    let filters = [{
      field: 'switch_vendor_id',
      type: "int",
      value: vendor_id,
      operator: "eq"
    }];
    if(!_.isEmpty(this.state.recordsMainFilter)){
      filters.push(this.state.recordsMainFilter)
    }
    const reqParams = {
      take: -1,
      sort: sortData,
      filter: filters
    };
    
    serviceObj.getAllFirmwareMapping(reqParams).then(async (response) => {
      this.setState({
        loadingTable: false
      })
      const currentSeriesdata = response.items.filter((row) => row.firmware_series_id.id === parseInt(series_id));
      const allItems=this.addgroupColor(response.items);
      await this.setState({ currentSeriesdata, allFirmwares: allItems });
    }).catch(e => {
      this.setState({
        loadingTable: false
      })
    });
  }
  addgroupColor = items => {
    const uniqueSeries=[];
    let lastColor;
    const color1="#FFFFFF";
    const color2="#F4F3F3";
    items.map((item)=>{     
      const found = uniqueSeries.find(sId => sId === item.firmware_series_id.id);
      if(found){
        item.backgroundColor=lastColor;
      }else{  
        uniqueSeries.push(item.firmware_series_id.id);
        if(lastColor===undefined){
          lastColor=color1;
        }else{
          lastColor=(lastColor===color1)?color2:color1;
        }
        item.backgroundColor=lastColor;
      }
      return item;
    })
    return items;    
  }
  rowStyle = rowData => {   
      return {
        backgroundColor:rowData.backgroundColor
      };    
  }
  render() {
    const { loadingTable, breadcrumbs,vendorName,seriesName,deleteExceptionOccured,deleteExceptMessage } = this.state;
    const { vendor_id, series_id } = this.props.match.params;
    return (
      <div className="form-wrap mt-3">
        <Breadcrumb data={breadcrumbs} disableEditTitle={true} />
        {
          deleteExceptionOccured?
          <ErrorDialog
            actionHandler={e=>{
              //rest on clicked ok
              this.setState({
                deleteExceptionOccured:false,
                deleteExceptMessage:''
              })
            }}
          >
            <SimpleDataTable
              title={MSG_SWITCH_FW_EXIST_POPUP_ERROR} 
              style={{ textAlign:'center' }}
              header={['Switch Name','Firmware Name']}
              data={transformStringtoMatrix(deleteExceptMessage)}
            />
          </ErrorDialog>
          :null
        }
        {loadingTable ? <Spinner /> : null}
        <div className="form-group d-flex">
          <div className="col-sm-6">
          <Form.Group as={Row}> 
              <Col sm={6}>
                <JobProgressStatus />
              </Col>
            </Form.Group> 
            <Form.Group as={Row}>
              <Form.Label column >
                <b>Vendor :</b><span className={'labelText'}>{vendorName}</span>
                <b>Firmware Series :</b><span className={'labelText'}>{seriesName}</span>
              </Form.Label>
            
            </Form.Group>
            
            <FirmwareForm editId={this.state.editId} triggerParentHandler={this.handleRefresh} vendor_id={vendor_id} series_id={series_id} />

            <Form.Group as={Row}>
              <Col className="mt-3">
              <Toggle variant="secondary" triggerHandler={this.toggleChangehandler} default={'False'} options={[{name:'Active',value:'False'},{name:'Deleted',value:'True'},{name:'All',value:'0'}]} />
              <MasterRelation master={this.getCurrentSlug()} />
              <EoslMapping additionalParams={{switch_vendor_id:Number(vendor_id), firmware_series_id :Number(series_id)}} slug={this.getCurrentSlug()}  masterDetailId={25} masterTitle="Switch Firmware" masterKey="switchfirmwaremapping" />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Col>
                <DataGrid 
                  showToolBar={true}                 
                  tableTitle={seriesName?('Firmwares for '+seriesName):null}
                  actions={this.state.actions}
                  columns={this.state.firmwareColumns}
                  dataHandler={this.state.currentSeriesdata}
                  filtering={this.state.filtering}
                  paging={this.state.paging}
                  rowStyle={{backgroundColor:'#FFFFFF'}}
                  refreshToken={this.state.refreshToken}
                  headerStyle= {{backgroundColor: "#e9ecef"}}
                ></DataGrid>
              </Col>
            </Form.Group>

          </div>
          <div className="col-sm-6">
            
              <Form.Group as={Row}>
                <Col >
                  <DataGrid
                    showToolBar={true}
                    tableTitle={vendorName?('Firmwares for '+vendorName):null}
                    rowStyle={this.rowStyle}
                    columns={this.state.allFirmwaresColumns}
                    dataHandler={this.state.allFirmwares}
                    filtering={this.state.filtering}
                    paging={this.state.paging}
                    refreshToken={this.state.refreshToken}
                    maxBodyHeight={"500px"}
                    headerStyle= {{backgroundColor: "#e9ecef"}}
                  ></DataGrid>
                </Col>
              </Form.Group>
           
          </div>
        </div>
      </div>
    );
  }
}
export default List;
