import {
  //ALL_CATEGORY_PRODUCTS,
  LOADING,
} from "constants/common";
import { 
  STORAGE_PRODUCT_DATA,
  STORAGE_FILTERS,
  LOADING_FILTERS,
  DOWNLOAD_LIMIT,
  FILTER_KEYWORDS,
  FILTER_ALL_KEYWORDS,
  TOTAL_RECORD_COUNT,
  LEGACY_PRODUCT_MENU
} from "constants/productMatrix";
import productMatrixService from "services/productMatrixService";
import uploadService from "services/uploadService";
import commonService from "services/commonService";

const commonServiceObj = new commonService();
const productMatrixServiceObj = new productMatrixService();
const uploadServiceObj = new uploadService();

export const initialFetch = () => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  commonServiceObj.getProductSearchInitials().then((response) => {
    /*dispatch({
      type: ALL_CATEGORY_PRODUCTS,
      payload: response[0],
    });*/
    dispatch({
      type: DOWNLOAD_LIMIT,
      payload: response[0],//response[1]//previously it wascoming on index 1
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  },(err)=>{
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};
export const getKeywordSuggestions = (productId) => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  productMatrixServiceObj.getKeywordSuggestions(productId).then((response) => {
     dispatch({
      type: FILTER_KEYWORDS,
      payload: response[0],
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  },(err)=>{
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};
export const getAllKeywordSuggestions = () => (dispatch) => {
  productMatrixServiceObj.getKeywordAllSuggestions().then((response) => {
     dispatch({
      type: FILTER_ALL_KEYWORDS,
      payload: response,
    });
  },(err)=>{

  });
};
export const getMatriceFilters = (payload,productId) => async (dispatch) => {
  dispatch({
    type: LOADING_FILTERS,
    payload: true,
  });
  try{
    const response = await productMatrixServiceObj.getMatriceFilters(payload,productId);
    dispatch({
      type: STORAGE_FILTERS,
      payload: response,
    });
    dispatch({
      type: LOADING_FILTERS,
      payload: false,
    });
    return true;
  }catch(err){
    dispatch({
      type: LOADING_FILTERS,
      payload: false,
    });
    return false;
  }
};
export const getMatriceExportFilters = (payload,productId,downloadAction) => async (dispatch) => {
  dispatch({
    type: LOADING_FILTERS,
    payload: true,
  });
  try{
    const response = await productMatrixServiceObj.getMatriceExportFilters(payload,productId,downloadAction);
    dispatch({
      type: STORAGE_FILTERS,
      payload: response,
    });
    dispatch({
      type: LOADING_FILTERS,
      payload: false,
    });
    return true;
  }catch(err){
    dispatch({
      type: LOADING_FILTERS,
      payload: false,
    });
    return false;
  }
};

export const totalRecords = (count=0) => (dispatch) => {
  dispatch({
    type: TOTAL_RECORD_COUNT,
    payload: count,
  });
};

export const resetFilters = () => (dispatch) => {
  dispatch({
    type: STORAGE_FILTERS,
    payload: [],
  });
  dispatch({
    type: TOTAL_RECORD_COUNT,
    payload: 0,
  });
};
//local mock api
export const getMatriceFiltersMock = (payload,productId) => async (dispatch) => {
  dispatch({
    type: LOADING_FILTERS,
    payload: true,
  });
  productMatrixServiceObj.getMatriceFiltersMock(payload,productId).then((response) => {
     dispatch({
      type: STORAGE_FILTERS,
      payload: response,
    });
    dispatch({
      type: LOADING_FILTERS,
      payload: false,
    });    
    return true;
  },(err)=>{
    dispatch({
      type: LOADING_FILTERS,
      payload: false,
    });    
    return false;
  });
};

export const getUserWiseProducts = () => (dispatch) => {
  dispatch({
    type: LOADING,
    payload: true,
  });
  uploadServiceObj.getUserWiseProducts().then((response) => {
    dispatch({
      type:   STORAGE_PRODUCT_DATA,
      payload: response,
    });
    dispatch({
      type: LOADING,
      payload: false,
    });
  },(err)=>{
    dispatch({
      type: LOADING,
      payload: false,
    });
  });
};

export const getLegacyProductMenu = (payload) =>  (dispatch) => {
  dispatch({
    type: LEGACY_PRODUCT_MENU,
    payload: payload,
  });
}