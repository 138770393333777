import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRelatedMasters } from "actions/commonActions";
import { Button,  Modal } from "react-bootstrap";
import _ from "lodash";
import DataGrid from "./DataGrid";
import Spinner from "./Spinner";
import Toggle from "./Toggle";
const MasterRelation = (props) => {
    const data = useSelector(state => state.commonReducer);
    const [showModal, setModalVisibility] = useState(false);
    const defaultVisibleRelations = 'current';
    const [showAll, setShowAll] = useState(defaultVisibleRelations);
    let { loading, relatedMasters } = data;
    const columns = [
        { title: "Master Name", field: "master_title" },
        {
            title: "Related Support Matrix", field: "related_support_matrix",
            render: (rowData) => rowData.related_support_matrix.map(r=>r.name).join(", "),
            sorting: false
        },
    ]
    const dispatch = useDispatch()
    const fetchRelatedMasters = () => {
        if (_.isEmpty(relatedMasters)) {
            dispatch(getAllRelatedMasters());
        }
    }
    //eslint-disable-next-line
    useEffect(fetchRelatedMasters, []);

    if ((props.master && relatedMasters?.length > 0) && showAll === defaultVisibleRelations) {
        relatedMasters = relatedMasters.filter(row => row.master_name === props.master)
    }

  
    const handleModalClose = (e) => {
        setModalVisibility(false);
    };
    const handleModalOpen = (e) => {
        setModalVisibility(true);
    };
    const toggleChangehandler = (e) => {
        setShowAll(e);
    }

    return (
        <>
            {(loading) ? <Spinner /> : null}
            <Button onClick={handleModalOpen} variant="link"><i className="fa fa-info-circle" aria-hidden="true"></i> See related support matrix</Button>
            
            <Modal
                show={showModal}
                scrollable={true}
                size={"xl"}
                onHide={handleModalClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Master Relations</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 text-left mb-3">
                            <Toggle variant="secondary" triggerHandler={toggleChangehandler} default={defaultVisibleRelations} options={[{ name: 'Current Master', value: defaultVisibleRelations }, { name: 'All Masters', value: 'all' }]} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-left">
                            <DataGrid
                                columns={columns}
                                dataHandler={relatedMasters}
                                filtering={false}
                                paging={false}
                                maxBodyHeight={"500px"}
                            ></DataGrid>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default MasterRelation;