import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobProgressProcessCheck } from "actions/commonActions";
import { Button } from "react-bootstrap";
//import _ from "lodash";

const JobProcessStatus = (props) => {
    const { jobProgressProcessCheck } = useSelector(state => state.commonReducer);
    const dispatch = useDispatch()

    const fetchJobProgressProcessCheck = () => {
            dispatch(getJobProgressProcessCheck());
    }
    /*
    const getchProgress = () => {
        if (_.isEmpty(jobProgressProcessCheck)) {
            fetchJobProgressProcessCheck();
        }
    }*/
    //eslint-disable-next-line
    useEffect(fetchJobProgressProcessCheck, []);

    return (jobProgressProcessCheck?.message)?(
        <div className="branding-link">
        <Button onClick={fetchJobProgressProcessCheck} variant="link"><i className="fa fa-refresh branding-link" aria-hidden="true"></i></Button>
        {`${jobProgressProcessCheck.message}`}
        </div>):null
}
export default JobProcessStatus;
