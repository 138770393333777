import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//raid-card methods
export const METHOD_RAID_CARD_LIST = 'raidcard/pageable/';
export const METHOD_RAID_CARD_CREATE = 'raidcard/';
export const METHOD_RAID_CARD_UPDATE = 'raidcard/{ID}/';
export const METHOD_RAID_CARD_DELETE = 'raidcard/{ID}/';
export const METHOD_RAID_CARD_DETAIL = 'raidcard/{ID}/';
export const METHOD_RAID_CARD_DROP = 'raidcard/drop/';

//raid-card-driver methods
export const METHOD_RAID_CARD_DRIVER_LIST = 'raidcarddriver/pageable/';
export const METHOD_RAID_CARD_DRIVER_CREATE = 'raidcarddriver/';
export const METHOD_RAID_CARD_DRIVER_UPDATE = 'raidcarddriver/{ID}/';
export const METHOD_RAID_CARD_DRIVER_DELETE = 'raidcarddriver/{ID}/';
export const METHOD_RAID_CARD_DRIVER_DETAIL = 'raidcarddriver/{ID}/';
export const METHOD_RAID_CARD_DRIVER_DROP = 'raidcarddriver/drop/';

//raid-card-firmware methods
export const METHOD_RAID_CARD_FIRMWARE_LIST = 'raidcardfirmware/pageable/';
export const METHOD_RAID_CARD_FIRMWARE_CREATE = 'raidcardfirmware/';
export const METHOD_RAID_CARD_FIRMWARE_UPDATE = 'raidcardfirmware/{ID}/';
export const METHOD_RAID_CARD_FIRMWARE_DELETE = 'raidcardfirmware/{ID}/';
export const METHOD_RAID_CARD_FIRMWARE_DETAIL = 'raidcardfirmware/{ID}/';
export const METHOD_RAID_CARD_FIRMWARE_DROP = 'raidcardfirmware/drop/';

//oracle-solution-type methods
export const METHOD_ORACLE_SOLUTION_TYPE_LIST = 'oraclesolutiontype/pageable/';
export const METHOD_ORACLE_SOLUTION_TYPE_CREATE = 'oraclesolutiontype/';
export const METHOD_ORACLE_SOLUTION_TYPE_UPDATE = 'oraclesolutiontype/{ID}/';
export const METHOD_ORACLE_SOLUTION_TYPE_DELETE = 'oraclesolutiontype/{ID}/';
export const METHOD_ORACLE_SOLUTION_TYPE_DETAIL = 'oraclesolutiontype/{ID}/';
export const METHOD_ORACLE_SOLUTION_TYPE_DROP = 'oraclesolutiontype/drop/';

//oracle-release-version methods
export const METHOD_ORACLE_RELEASE_VERSION_LIST = 'oraclereleaseversion/pageable/';
export const METHOD_ORACLE_RELEASE_VERSION_CREATE = 'oraclereleaseversion/';
export const METHOD_ORACLE_RELEASE_VERSION_UPDATE = 'oraclereleaseversion/{ID}/';
export const METHOD_ORACLE_RELEASE_VERSION_DELETE = 'oraclereleaseversion/{ID}/';
export const METHOD_ORACLE_RELEASE_VERSION_DETAIL = 'oraclereleaseversion/{ID}/';
export const METHOD_ORACLE_RELEASE_VERSION_DROP = 'oraclereleaseversion/drop/';


//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;