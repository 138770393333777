import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//hcsfnodetype methods
export const METHOD_HCSFNODETYPE_LIST = 'hcsfnodetype/pageable/';
export const METHOD_HCSFNODETYPE_CREATE = 'hcsfnodetype/';
export const METHOD_HCSFNODETYPE_UPDATE = 'hcsfnodetype/{ID}/';
export const METHOD_HCSFNODETYPE_DELETE = 'hcsfnodetype/{ID}/';
export const METHOD_HCSFNODETYPE_DETAIL = 'hcsfnodetype/{ID}/';
export const METHOD_HCSFNODETYPE_DROP = 'hcsfnodetype/drop/';

//hcsfsoftwareversion methods
export const METHOD_HCSFSOFTWAREVERSION_LIST = 'hcsfsoftwareversion/pageable/';
export const METHOD_HCSFSOFTWAREVERSION_CREATE = 'hcsfsoftwareversion/';
export const METHOD_HCSFSOFTWAREVERSION_UPDATE = 'hcsfsoftwareversion/{ID}/';
export const METHOD_HCSFSOFTWAREVERSION_DELETE = 'hcsfsoftwareversion/{ID}/';
export const METHOD_HCSFSOFTWAREVERSION_DETAIL = 'hcsfsoftwareversion/{ID}/';
export const METHOD_HCSFSOFTWAREVERSION_DROP = 'hcsfsoftwareversion/drop/';

//hcpmodel methods
export const METHOD_HCPMODEL_LIST = 'hcpmodel/pageable/';
export const METHOD_HCPMODEL_CREATE = 'hcpmodel/';
export const METHOD_HCPMODEL_UPDATE = 'hcpmodel/{ID}/';
export const METHOD_HCPMODEL_DELETE = 'hcpmodel/{ID}/';
export const METHOD_HCPMODEL_DETAIL = 'hcpmodel/{ID}/';
export const METHOD_HCPMODEL_DROP = 'hcpmodel/drop/';

//hcpversion methods
export const METHOD_HCPVERSION_LIST = 'hcpversion/pageable/';
export const METHOD_HCPVERSION_CREATE = 'hcpversion/';
export const METHOD_HCPVERSION_UPDATE = 'hcpversion/{ID}/';
export const METHOD_HCPVERSION_DELETE = 'hcpversion/{ID}/';
export const METHOD_HCPVERSION_DETAIL = 'hcpversion/{ID}/';
export const METHOD_HCPVERSION_DROP = 'hcpversion/drop/';

//feature methods
export const METHOD_FEATURE_LIST = 'feature/pageable/';
export const METHOD_FEATURE_CREATE = 'feature/';
export const METHOD_FEATURE_UPDATE = 'feature/{ID}/';
export const METHOD_FEATURE_DELETE = 'feature/{ID}/';
export const METHOD_FEATURE_DETAIL = 'feature/{ID}/';
export const METHOD_FEATURE_DROP = 'feature/drop/';

//filesystem methods
export const METHOD_FILESYSTEM_LIST = 'filesystem/pageable/';
export const METHOD_FILESYSTEM_CREATE = 'filesystem/';
export const METHOD_FILESYSTEM_UPDATE = 'filesystem/{ID}/';
export const METHOD_FILESYSTEM_DELETE = 'filesystem/{ID}/';
export const METHOD_FILESYSTEM_DETAIL = 'filesystem/{ID}/';
export const METHOD_FILESYSTEM_DROP = 'filesystem/drop/';



//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;