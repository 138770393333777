import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";


// Hitachi Storage Ansible Module
export const METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_LIST = 'hitachistorageansiblemodule/pageable/';
export const METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_CREATE = 'hitachistorageansiblemodule/';
export const METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_UPDATE = 'hitachistorageansiblemodule/{ID}/';
export const METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_DELETE = 'hitachistorageansiblemodule/{ID}/';
export const METHOD_HITACHI_STORAGE_ANSIBLE_MODULE_DETAIL = 'hitachistorageansiblemodule/{ID}/';

// Ansible
export const METHOD_ANSIBLE_LIST = 'ansible/pageable/';
export const METHOD_ANSIBLE_CREATE = 'ansible/';
export const METHOD_ANSIBLE_UPDATE = 'ansible/{ID}/';
export const METHOD_ANSIBLE_DELETE = 'ansible/{ID}/';
export const METHOD_ANSIBLE_DETAIL = 'ansible/{ID}/';

// Python
export const METHOD_PYTHON_LIST = 'python/pageable/';
export const METHOD_PYTHON_CREATE = 'python/';
export const METHOD_PYTHON_UPDATE = 'python/{ID}/';
export const METHOD_PYTHON_DELETE = 'python/{ID}/';
export const METHOD_PYTHON_DETAIL = 'python/{ID}/';

// UAI Gateway
export const METHOD_UAI_GATEWAY_LIST = 'uaigateway/pageable/';
export const METHOD_UAI_GATEWAY_CREATE = 'uaigateway/';
export const METHOD_UAI_GATEWAY_UPDATE = 'uaigateway/{ID}/';
export const METHOD_UAI_GATEWAY_DELETE = 'uaigateway/{ID}/';
export const METHOD_UAI_GATEWAY_DETAIL = 'uaigateway/{ID}/';



//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;