import React,{useState,useEffect} from "react";
import { ButtonGroup,ToggleButton } from "react-bootstrap";
const Toggle = (props) => {
    const [selectedOption, setOption] = useState(props.default || null);
    useEffect(()=>{
        setOption(props.default)
    },[props.default])
    return ( 
            <ButtonGroup toggle>
                {props.options && props.options.map((option, idx) => (
                    <ToggleButton
                        key={idx}
                        type="radio"
                        variant={props.variant || 'primary'}
                        name={props.controlName || 'myToggle'}
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={(e) => {
                            setOption(e.currentTarget.value);
                            props.triggerHandler(e.currentTarget.value)
                        }}
                    >
                        {option.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
    );
}
export default Toggle;