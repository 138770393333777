import React, { Component, Suspense, createRef } from "react";
import {
  STORAGE_FILTER_CONTROLS,
  KEYWORD_SEARCH_FIELD,
  KEYWORD_SEARCH_CATEGORY,
  LABEL_ALL_PRODUCT_CATEGORY,
} from "constants/productMatrix";
import Spinner from "components/shared/Spinner";
import { parseUrlQuery } from "utils/utils";
import { handleChangeMenu } from "utils/matriceHelpers";
import { connect } from "react-redux";
import products from "resources/json/static-menus.json";
import _ from "lodash";
import {
  resetFilters, getKeywordSuggestions, getAllKeywordSuggestions
} from "actions/productSearchActions";
import MegaMenu from "components/shared/MegaMenu";
import { Link } from "react-router-dom";
import history from "../../history";
import { APP_NAME } from "constants/common";
import { Helmet } from "react-helmet";
const AnalyzerMatrix = React.lazy(() => import("containers/SearchAnalyzer/matrices/analyzer/AnalyzerMatrix"));
const elemRef = createRef();

class SearchAnalyzer extends Component {
  constructor(props) {
    super(props);
    this.handleChangeMenu = handleChangeMenu.bind(this);
    const uriParams = this.getUriParams();
    const defaultKeywordSearch = uriParams && uriParams.find(filter => filter.field === KEYWORD_SEARCH_FIELD);
    const defaultKeywordCategory = uriParams && uriParams.find(filter => filter.field === KEYWORD_SEARCH_CATEGORY);
    this.state = {
      productSlug:'analyzer',
      defaultMegaMenuLabel: LABEL_ALL_PRODUCT_CATEGORY,
      keywordsearch: defaultKeywordSearch?.value || "",//this is to use internal state
      keyword: defaultKeywordSearch?.value || "",//this is to pass to child compponent so that url can change
      category: defaultKeywordCategory?.value || "",
      suggestions: [],
      globalSearch: []
    }
  }
  componentDidMount = async () => {
    this.getDownloadProductTags();
  };
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      //reset the previous filters from redux
      this.props.resetFilters();
      this.resetHandler();
      this.getDownloadProductTags();
      this.updateInformationfromUrl();
    }
  }

  getUriParams = () => {
    const qs = history.location.search;
    return qs !== "" ? parseUrlQuery(qs) : [];
  };


  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value
    })
    this.suggestKeywords();
  }
  setKeyword = async (keyword, category) => {
    await this.setState({
      keywordsearch: keyword,
      keyword,
      category,
      suggestions: []
    })
  }
  updateInformationfromUrl = () => {
    const uriParams = this.getUriParams();
    const defaultKeywordSearch = uriParams && uriParams.find(filter => filter.field === KEYWORD_SEARCH_FIELD);
    const defaultKeywordCategory = uriParams && uriParams.find(filter => filter.field === KEYWORD_SEARCH_CATEGORY);
    this.setState({
      keywordsearch: defaultKeywordSearch?.value || "",//this is to use internal state
      keyword: defaultKeywordSearch?.value || "",//this is to pass to child compponent so that url can change
      category: defaultKeywordCategory?.value || ""
    });
  }

  redirectToMatrice = async (slug, keyword, category) => {
    this.props.history.push(`products/${slug}?keywordsearch=${keyword}&_f=${category}`);
  }
  redirectToSearch = async (keyword) => {
    this.props.history.push(`search?keywordsearch=${keyword}`);
  }

  suggestKeywords = () => {
    const term = this.state.keywordsearch.toLowerCase();
    if (term !== '') {
      const {productSlug} = this.state;
      const selectedProduct = this.findSelectedMenu(products, 'slug', productSlug);
      if (selectedProduct?.id > 0) {
        const suggestions = {};
        //search from specific product
        
        const productKeywords = this.props.filterKeywords.find(row => row?.product_id === selectedProduct?.id);
        productKeywords?.items && Object.keys(productKeywords?.items).map(category => {
          const foundRecords = productKeywords.items[category].filter(keyword => keyword.toLowerCase().indexOf(term) > -1);
          if (foundRecords.length > 0) {
            suggestions[category] = foundRecords;
          }
          return true;
        })
        this.setState({
          suggestions
        })
      } else {
        //search from all product keywords        
        const globalSearch = [];
        const allKeywords = this.props.allKeywords;
        allKeywords.map(product => {
          const mainRow = {
            productName: product.product_name,
            matrixName: product.slug
          }
          const searchedTags = {};
          Object.keys(product.items).map(category => {
            const foundRecords = product.items[category].filter(keyword => keyword.toLowerCase().indexOf(term) > -1);
            if (foundRecords.length > 0) {
              searchedTags[category] = foundRecords;
            }
            return true;
          });
          mainRow['searchedTags'] = searchedTags;
          if (Object.keys(searchedTags)?.length > 0) {
            globalSearch.push(mainRow);
          }
          return true;
        });
        this.setState({
          globalSearch
        })

      }
    } else {
      this.setState({
        suggestions: [],
        globalSearch: []
      })
    }
  }

  scrollToResult = () => {
    elemRef.current.scrollIntoView({ behavior: 'smooth' })
  }
  
  getDownloadProductTags = async () => {
    const { productSlug } = this.state;
    const selectedProduct = this.findSelectedMenu(products, 'slug', productSlug);
    if (selectedProduct?.id) {
      const tags = this.props.filterKeywords.find(row => row?.product_id === selectedProduct?.id);
      if (_.isEmpty(tags)) {
        await this.props.getKeywordSuggestions(selectedProduct.id);
      }
    } else {
      const tags = this.props.allKeywords;
      if (_.isEmpty(tags)) {
        await this.props.getAllKeywordSuggestions();
      }
    }
  }

  resetHandler = () => {
    this.setState({
      keywordsearch: '',
      keyword: '',
      category: '',
      suggestions: [],
      globalSearch: []
    })

  }

  findSelectedMenu = (products, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(products, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  }
  renderSuggestions = () => {
    const { suggestions, globalSearch,productSlug } = this.state;
    const selectedProduct = this.findSelectedMenu(products, 'slug', productSlug);
    const matrixName = selectedProduct?.slug || productSlug;
    if (!_.isEmpty(suggestions) && selectedProduct?.id > 0) {
      return (
        <div className="form-group suggestions_area" >
          {Object.keys(suggestions).map((category, index) => (
            <div key={`caterory-${index}`}><h6 className="bg-light text-dark">{STORAGE_FILTER_CONTROLS[matrixName][category]?.title}</h6>
              {
                suggestions[category].map((keyword, index) => (
                  <button key={index} className="btn btn-link branding-link-thin" as={Link} onClick={() => this.setKeyword(keyword, category)} >{keyword}</button>
                ))
              }</div>
          ))
          }
        </div>);
    } else if (!_.isEmpty(globalSearch)) {
      return (
        <div className="form-group suggestions_area" >
          { globalSearch.map((product, index) => (
            <div key={`prod-${index}`}><h5 className="bg-light text-dark">{product.productName}</h5>
              {
                Object.keys(product.searchedTags).map((category, index) => (
                  <div key={`caterory-${index}`}><h6 className="bg-light text-dark">{STORAGE_FILTER_CONTROLS[product.matrixName][category]?.title}</h6>
                    {
                      product.searchedTags[category].map((keyword, index) => (
                        <button key={index} className="btn btn-link branding-link-thin" as={Link} onClick={() => this.redirectToMatrice(product.matrixName, keyword, category)} >{keyword}</button>
                      ))
                    }</div>
                ))
              }
            </div>
          ))
          }
        </div>);
    }
  }

  render() {
    const {
      loading,
      loadingFilters,
    } = this.props;
    const { keywordsearch, defaultMegaMenuLabel,keyword,category,productSlug } = this.state;
    const selectedProduct = this.findSelectedMenu(products, 'slug', productSlug);
    return (
      <>
        <Helmet>
          <title>{APP_NAME} :: {selectedProduct?.name || 'Home'}</title>
        </Helmet>
        {loading ? <Spinner /> : null}
        {loadingFilters ? <Spinner /> : null}
        <div className="form-wrap">
          <div className="row mega-row p-1 mt-3">
            <div >
              <div className="form-group">
                <MegaMenu
                  defaultOpenState={(selectedProduct?.name !== undefined) ? false : true}
                  alwaysOpenStates={[defaultMegaMenuLabel]}
                  data={products}
                  flagHomeIcon={true}
                  onChangeMenu={this.handleChangeMenu}
                  defaultLabel={(selectedProduct?.name) || defaultMegaMenuLabel}
                />
              </div>
            </div>
            <div className="search-container">
              <div className="form-group search-container-top">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control shadow-none global_search_textbox"
                  placeholder={`Search ${selectedProduct?.name || ''}`}
                  name="keywordsearch"
                  value={keywordsearch}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if(selectedProduct?.id>0){
                        this.setKeyword(e.target.value);
                      }else{
                        this.redirectToSearch(e.target.value)
                      }
                      
                    }
                  }
                  }
                  onChange={this.handleChange}
                />
                {(!_.isEmpty(keywordsearch)) ? <i className="fa fa-close search_icon_form_control show_cursor" onClick={this.resetHandler}></i> : <i className="fa fa-search search_icon_form_control"></i>}

              </div>
              {this.renderSuggestions()}
            </div>

            {(!_.isEmpty(selectedProduct) && this.props.totalRecordCount) ? (<div className="pl-2 mb-3"><button className="btn btn-link branding-link" onClick={this.scrollToResult}>{this.props.totalRecordCount} Records Found <i className="fa fa-angle-double-down"></i></button></div>)
              : null}
          </div>
          <div className="form-group">
            <Suspense fallback={<Spinner />}>
              <AnalyzerMatrix resultElemRef={elemRef} productId={selectedProduct.id} keyword={keyword} category={category} resetGlobalSearch={this.resetHandler} productSlug={selectedProduct.slug} />
            </Suspense>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.productSearchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllKeywordSuggestions: () => dispatch(getAllKeywordSuggestions()),
  getKeywordSuggestions: (productId) => dispatch(getKeywordSuggestions(productId)),
  resetFilters: () => dispatch(resetFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchAnalyzer);
