import { combineReducers } from 'redux';
import productSearchReducer from './productSearchReducer';
import loginReducer from './loginReducer';
import adminSettingsReducer from './adminSettingsReducer';
import bulkUploadReducer from './bulkUploadReducer';
import commonReducer from './commonReducer';
const rootReducer = combineReducers({
    productSearchReducer,
    loginReducer,
    adminSettingsReducer,
    bulkUploadReducer,
    commonReducer
});
export default rootReducer;