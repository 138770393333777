import React, { Component } from "react";
import Routes from "Routes";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "components/ErrorBoundary";
import { connect } from "react-redux";
import { verifyLogin, doSsoLogin } from "actions/loginActions";
import { Router } from "react-router-dom";
import history from "./history";
import SpinnerComponent from "components/shared/SpinnerComponent";
import Disclaimer from "components/shared/Disclaimer";
import { Security } from "@okta/okta-react";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { config } from "config";
import Spinner from "components/shared/Spinner";
const oktaAuth = new OktaAuth(config.oidc);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderAppRoutes: false,
    };
    props.verifyLogin(); //this is for page load and reset the authentication from locastorage to redux and auth service
  }

  componentDidMount = async () => {
    this.setState({
      renderAppRoutes: true,
    });
  };

  customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await oktaAuth.signInWithRedirect();
    }
  };

  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  render() {
    const { disclaimerAcceptance, authInfo } = this.props;
    const { renderAppRoutes } = this.state;

    return (
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={this.customAuthHandler}
        restoreOriginalUri={this.restoreOriginalUri}
      >
        <Router history={history}>
          {authInfo && <Spinner />}
          <SpinnerComponent name="globalSpinner"></SpinnerComponent>

          <ErrorBoundary>
            <ToastContainer
              position="top-right"
              autoClose={false}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable={false}
              pauseOnHover
            />
            {renderAppRoutes ? <Routes /> : null}
            {!disclaimerAcceptance ? <Disclaimer /> : null}
          </ErrorBoundary>
        </Router>
      </Security>
    );
  }
}

function mapStateToProps(state) {
  const { loginReducer } = state;
  return { authInfo: loginReducer.authLoading };
}

const mapDispatchToProps = (dispatch) => ({
  verifyLogin: () => dispatch(verifyLogin()),
  doSsoLogin: (userInfo) => dispatch(doSsoLogin(userInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
export {oktaAuth}
