import { networkService } from "services/networkService";
import {
  METHOD_DATASTORE_TYPE_LIST,
  METHOD_DATASTORE_TYPE_CREATE,
  METHOD_DATASTORE_TYPE_UPDATE,
  METHOD_DATASTORE_TYPE_DELETE,
  METHOD_DATASTORE_TYPE_DETAIL,
} from "constants/admin/masters/datastoreType";
class DatastoreTypeService {
  getAll = (data) => {
    return networkService
      .post(METHOD_DATASTORE_TYPE_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (id) => {
    const updatedMethodName = METHOD_DATASTORE_TYPE_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_DATASTORE_TYPE_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, id) => {
    const updatedMethodName = METHOD_DATASTORE_TYPE_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (id,is_delete=false) => {
    const updatedMethodName = METHOD_DATASTORE_TYPE_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };
}
export default DatastoreTypeService;
