import { LOADING, AUTH_LOADING } from "constants/common";
import { LOGIN_INFO } from "constants/login";
import { SET_MENU_PREFRENCE } from "constants/menus";

const initialState = {
  loading: false,
  loginData: {},
  menuPrefrences: [],
  authLoading: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AUTH_LOADING:
      return {
        ...state,
        authLoading: action.payload,
      };
    case LOGIN_INFO:
      return {
        ...state,
        loginData: action.payload,
      };
    case SET_MENU_PREFRENCE:
      return {
        ...state,
        menuPrefrence: action.payload,
      };
    default:
      return state;
  }
};
export default loginReducer;
