import {
  LOGGED_IN_USER_INFO,
  OKTA_TOKEN_INFO,
  OKTA_CACHE_STORAGE,
} from "constants/common";
import history from "../history";
export const filterAuthdata = (data) => {
  const { email, ...userData } = data; //pop the email from userinfo as email we are not using anywhere so dont save in session
  return userData;
};

const authService = {
  isAuthenticated: false,
  userData: {},
  authenticate(data) {
    this.isAuthenticated = true;
    data = filterAuthdata(data);
    this.userData = data;
    sessionStorage.setItem(LOGGED_IN_USER_INFO, JSON.stringify(data));
  },
  logout(url) {
    sessionStorage.clear(); //clear the whole sessionstorage on logout
    localStorage.setItem(OKTA_TOKEN_INFO, "");
    localStorage.setItem(OKTA_CACHE_STORAGE, "");
    //clear the whole localstorage on logout
    this.isAuthenticated = false;
    this.userData = {};
    if (url !== undefined) {
      window.location.href = url;
    } else {
      this.redirect("/");
     
    }
  },
  ssoReLogin(currentPath) {
    sessionStorage.clear(); //clear the whole sessionstorage on logout
    localStorage.setItem(OKTA_TOKEN_INFO, ""); //clear the whole localstorage on logout
    this.isAuthenticated = false;
    this.userData = {};
    history.push({
      pathname: "/sso_login",
      state: { from: { pathname: currentPath } },
    });
  },
  redirect(path) {
    history.push(path);
  },
};
export default authService;
