import { LOADING, PRODUCTS,UPLOADING, UPLOAD_PROGRESS } from "constants/admin/bulkUpload";

const initialState = {
  loading: false,
  uploading: false,
  upload_progress: 0,
  products:[]
};

const bulkUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case PRODUCTS:
        return {
          ...state,
          products: action.payload,
        };
    case UPLOADING:
      return {
        ...state,
        uploading: action.payload,
      };
    
    case UPLOAD_PROGRESS:
      return {
        ...state,
        upload_progress: action.payload,
      };
    default:
      return state;
  }
};
export default bulkUploadReducer;
