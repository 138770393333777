import React, { useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import commonService from "services/commonService";
import * as yup from "yup";
import _ from "lodash";
import { ErrorMessage } from "./ErrorMessage";
import { MAX_TEXT_LENGTH, MIN_TEXT_LENGTH } from "constants/common";
const serviceObj = new commonService();
const EncryptionTool = (props) => {

    const [showModal, setModalVisibility] = useState(false);
    const [form, setForm] = useState({
        password: '',
        encryptResult: '',
    });
    const [touched, setTouched] = useState({});
    const [errors, setErrors] = useState({});
    const textAreaRef = useRef(null);
    const resetAll = () => {
        setTouched({})
        setErrors({});
        setForm({ ...form, password: '', encryptResult: '' });
    }
    const handleModalClose = (e) => {
        setModalVisibility(false);
        resetAll();
    };
    const handleModalOpen = (e) => {
        setModalVisibility(true);
    };
    const submitHandler = async e => {
        await setTouched({ password: true });
        const errors = await validateForm();
        if (_.isEmpty(errors)) {
            serviceObj.encryptPassword({ plain_text: form.password }).then((response) => {
                setForm({ ...form, encryptResult: response.result });
            }).catch(e => {
                setErrors(e);
            });
        }
    }
    const copyHandler = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        toast.success('Password copied to clipboard.');
        handleModalClose();
    }
    const validateForm = async () => {
        const errors = {};
        const schema = yup.object().shape({
            password: yup.string().required().min(MIN_TEXT_LENGTH).max(MAX_TEXT_LENGTH),
        });
        // check validity
        await schema
            .validate(form, { abortEarly: false })
            .catch(function (err) {
                err.inner.map((error) =>
                    !errors[error.path] ? (errors[error.path] = error.message) : null
                );
            });
        setErrors(errors);
        return errors; //return basicerrors
    };


    return (
        <>
            <Button onClick={handleModalOpen} variant="link"><i className="fa fa-key" aria-hidden="true"></i> Encryption Tool</Button>

            <Modal
                show={showModal}
                scrollable={true}
                size={"xl"}
                onHide={handleModalClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Encrypt Your Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 text-left">
                            {(form.encryptResult === '') ? (
                                <>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" >Input your password here</span>
                                        </div>
                                        <input type="text" name="password" value={form.password} onChange={async e => { await setForm({ ...form, [e.target.name]: e.target.value }); }} autoComplete="false" className={`form-control ${touched.password && errors.password
                                            ? "is-invalid"
                                            : ""
                                            }`} />

                                    </div>
                                    <ErrorMessage
                                        tag="div"
                                        touched={touched}
                                        errors={errors}
                                        name="password"
                                        className="text-danger"
                                    />
                                    <button onClick={submitHandler} className="btn btn-secondary mt-2">Encrypt This</button>
                                </>
                            ) : (
                                    <>
                                        <textarea className="form-control" rows="4" ref={textAreaRef} defaultValue={form.encryptResult} />
                                        <button onClick={copyHandler} className="btn btn-secondary mt-2">Copy to clipboard</button>
                                    </>
                                )
                            }


                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default EncryptionTool;