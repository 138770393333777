const FILTERS = {
    'oracle-edw': { 
        compute_node_server: {
            title: 'Compute Node',
            icon: 'server.png'
        },
        compute_node_os: {
            title: 'Compute Node OS',
            icon: 'os.png'
        },
        compute_node_os_kernel: {
            title: 'Compute Node OS Kernel',
            icon: 'os_kernel.png'
        },
        cpu_family: {
            title: 'CPU Family',
            iconClass: 'project-diagram'
        },
        bios_bmc: {
            title: 'BIOS/BMC',
            icon: 'firmware.png'
        },
        nic_1_hba: {
            title: 'NIC 1',
            icon: 'hba.png'
        },
        nic_2_hba: {
            title: 'NIC 2',
            icon: 'hba_driver.png'
        },
        compute_nic_switch: {
            title: 'Compute Switch',
            icon: 'switch.png'
        },
        management_nic_switch: {
            title: 'Management Switch',
            icon: 'array_if.png'
        }
    },
    "oracle-virt-non-virt": { 
        compute_node_server: {
            title: 'Compute Node',
            icon: 'server.png'
        },
        os: {
            title: 'Operating System',
            icon: 'os.png'
        },
        os_kernel: {
            title: 'OS Kernel',
            icon: 'os_kernel.png'
        },
        cpu_family: {
            title: 'CPU Family',
            iconClass: 'project-diagram'
        },
        bios_bmc: {
            title: 'BIOS/BMC',
            icon: 'firmware.png'
        },
        nic_1_hba: {
            title: 'NIC 1',
            icon: 'hba.png'
        },
        nic_2_hba: {
            title: 'NIC 2',
            icon: 'hba_driver.png'
        },
        nic_switch: {
            title: 'Ethernet Switch',
            icon: 'switch.png'
        }, 
        hba: {
            title: 'HBA',
            iconClass: 'project-diagram'
        },
        fc_switch: {
            title: 'FC Switch',
            icon: 'array_if.png'
        },
        array_customer_version: {
            title: 'Array',
            icon: 'array.png'
        }
    },
     "oracle":{
        oracle_solution_type:{
            title : 'Oracle Solution Type',
            iconClass: 'network-wired'
        },
        server_model : {
            title :'Server Model',
            icon : 'server.png',
        },
        cpu_generation : {
            title :'CPU Generation',
            icon : 'server.png',
        },
        server_software_version:{
            title : 'Server Software Version',
            icon :  'server.png'
        },
        operating_system: {
            title: 'Operating System',
            icon: 'os.png'
        },
        nic: {
            title: 'NIC',
            icon: 'hba.png'
        },
        leaf_switch: {
            title: 'Leaf Switch',
            icon: 'firmware.png'
        },
        hba:{
            title: 'HBA',
            icon: 'hba.png'
        },
        fc_switch: {
            title: 'FC Switch',
            icon: 'array_if.png'
        },
        storage: {
            title: 'Storage',
            icon: 'array_if.png',
            dynamic: true
        }
    }
}
export default FILTERS;