import React from "react";
import PrivateRoute from "components/PrivateRoute";
import { ROLES } from "constants/menus";
import {
  ServerListing,
  ServerForm,
  SwitchListing,
  SwitchForm,
  SwitchVendorListing,
  SwitchVendorForm,
  SwitchFirmwareSeriesListing,
  SwitchFirmwareSeriesForm,
  SwitchFirmwareListing,
  SwitchFirmwareForm,
  SwitchFirmwareMapping,
  HbaListing,
  HbaForm,
  HbaDriverListing,
  HbaDriverForm,
  OsVendorForm,
  OsVendorListing,
  OsForm,
  OsListing,
  OsKernelForm,
  OsKernelListing,
  ClusterForm,
  ClusterListing,
  MultipathForm,
  MultipathListing,
  ProtocolForm,
  ProtocolListing,
  ArrayForm,
  ArrayListing,
  ArrayCustomerForm,
  ArrayCustomerListing,
  ArrayIfForm,
  ArrayCustomerVersionMappingListing,
  ArrayCustomerVersionMappingForm,
  ArrayIfListing,
  LinkspeedForm,
  LinkspeedListing,
  VendorDistributionForm,
  VendorDistributionListing,
  HbsdVersionForm,
  HbsdVersionListing,
  ReleasesForm,
  ReleasesListing,
  ClusterVersionListing,
  ClusterVersionForm,
  ReplicationProductListing,
  ReplicationProductForm,
  ReplicationProductIntermixForm,
  ReplicationProductIntermixListing,
  SiteTopologyListing,
  SiteTopologyForm,
  KmsVendorForm,
  KmsVendorListing,
  KmsModelForm,
  KmsModelListing,
  SoftwareVersionForm,
  SoftwareVersionListing,
  HspcVersionForm,
  HspcVersionListing,
  DockerVersionForm,
  DockerVersionListing,
  KubernetesVersionForm,
  KubernetesVersionListing,
  RhocpVersionForm,
  RhocpVersionListing,
  LegacyProductListing,
  LegacyProductForm,
  ViewPointVersionListing,
  ViewPointVersionForm,
  HypervisorListing,
  HypervisorForm,
  JavaDevKitForm,
  JavaDevKitListing,
  BrowserForm,
  BrowserListing,
  HcsfNodeTypeForm,
  HcsfNodeTypeListing,
  HcsfSoftwareVersionListing,
  HcsfSoftwareVersionForm,
  HCPModelForm,
  HCPModelListing,
  HCPVersionForm,
  HCPVersionListing,
  FeatureForm,
  FeatureListing,
  FileSystemForm,
  FileSystemListing,
  VSSBModelForm,
  VSSBModelListing,
  HVSSBSoftwareVersionForm,
  HVSSBSoftwareVersionListing,
  CSIVersionForm,
  CSIVersionListing,
  AnalyzerManage,
  AnalyzerListing,
  AnalyzerVersionListing,
  AnalyzerVersionForm,
  VirtualizationSoftwareListing,
  VirtualizationSoftwareForm,
  HitachiNasForm,
  HitachiNasListing,
  FcSwitchForm,
  FcSwitchListing,
  ScProductForm,
  ScProductListing,
  ScCertificationForm,
  ScCertificationListing,
  ScPartnerForm,
  ScPartnerListing,
  ScMultiPathSoftwareForm,
  ScMultiPathSoftwareListing,
  AdVersionForm,
  AdVersionListing,
  AdDockerForm,
  AdDockerListing,
  AdSwitchForm,
  AdSwitchListing,
  JobProgressListing,
  JobProgressForm,
  ISVNameList,
  ISVNameForm,
  ISVApplicationNameList,
  ISVApplicationNameForm,
  ISVApplicationVersionList,
  ISVApplicationVersionForm,
  HCPInterfaceList,
  HCPInterfaceForm,
  HCPCloudScaleVersionList,
  HCPCloudScaleVersionForm,
  HCPAnywhereVersionList,
  HCPAnywhereVersionForm,
  VDISoftwareVersionForm,
  EthernetSwitchCategoryList,
  EthernetSwitchCategoryForm,
  VDISoftwareVersionList,
  HanaSolutionTypeForm,
  HanaSolutionTypeList,
  BiosBmcList,
  BiosBmcForm,
  CpuFamilyForm,
  CpuFamilyList,
  HnasForm,
  HnasList,
  RaidCardList,
  RaidCardForm,
  RaidCardDriverList,
  RaidCardDriverForm,
  RaidCardFirmwareList,
  RaidCardFirmwareForm,
  CommonServicesVersionListing,
  CommonServicesVersionForm,
  AutomatorVersionListing,
  AutomatorVersionForm,
  ApiConfigurationManagerListing,
  ApiConfigurationManagerForm,
  OpsCenterVersionListing,
  OpsCenterVersionForm,
  ProtectorVersionListing,
  ProtectorVersionForm,
  AdoptableTechnologiesListing,
  AdoptableTechnologiesForm,
  ClusterInterconnectListing,
  ClusterInterconnectForm,
  YesNoListing,
  YesNoForm,
  CpuListing,
  CpuForm,
  HdlmVersionListing,
  HdlmVersionForm,
  HdlmTagListing,
  HdlmTagForm,
  OracleVersionListing,
  OracleVersionForm,
  VolumeManagerListing,
  VolumeManagerForm,
  HDRSListing,
  HDRSForm,
  VasaProviderVersionForm,
  VasaProviderVersionListing,
  DatastoreTypeListing,
  DatastoreTypeForm,
  ClientPluginVersionListing,
  ClientPluginVersionForm,
  VROPSReleaseListing,
  VROPSReleaseForm,
  ManagementPackListing,
  ManagementPackForm,
  ManagementPackVersionListing,
  ManagementPackVersionForm,
  VROReleaseListing,
  VROReleaseForm,
  VROPluginForm,
  VROPluginListing,
  PluginVersionForm,
  PluginVersionListing,
  SRMVersionListing,
  SRMVersionForm,
  SRAForm,
  SRAListing,
  SRAVersionListing,
  SRAVersionForm,
  VDISolutionTypeListing,
  VDISolutionTypeForm,
  VDIReleaseVersionListing,
  VDIReleaseVersionForm,
  GPUListing,
  GPUForm,
  ServerServicePackForm,
  ServerServicePackListing,
  VDIManagementSoftwareVersionForm,
  VDIManagementSoftwareVersionListing,
  SAPReleaseVersionListing,
  SAPReleaseVersionForm,
  RaidControllerListing,
  RaidControllerForm,
  DriveListing,
  DriveForm,
  OracleReleaseVersionListing,
  OracleReleaseVersionForm,
  OracleSolutionTypeListing,
  OracleSolutionTypeForm,
  HybridCloudListing,
  HybridCloudForm,
  VmwareSolutionTypeListing,
  VmwareSolutionTypeForm,
  UCPReleaseVersionListing,
  UCPReleaseVersionForm,
  VmwareVCFVersionListing,
  VmwareVCFVersionForm,
  ManagementSoftwareVersionListing,
  ManagementSoftwareVersionForm,
  BaremetalSolutionTypeListing,
  BaremetalSolutionTypeForm,
  BaremetalVersionListing,
  BaremetalVersionForm,
  HrpcVersionListing,
  HrpcVersionForm,
  ContainerOrchestratorListing,
  ContainerOrchestratorForm,
  OrchestratorVersionListing,
  OrchestratorVersionForm,
  HsppVersionListing,
  HsppVersionForm,
  VeeamPluginVersionList,
  VeeamPluginVersionForm,
  ServerTypeList,
  ServerTypeForm,
  ServerConfigurationList,
  ServerConfigurationForm,
  ServerPurposeList,
  ServerPurposeForm,
  IntegrationPointList,
  IntegrationPointForm,
  HitachiHardwareProductList,
  HitachiHardwareProductForm,
  HitachiProductVersionList,
  HitachiProductVersionForm,
  HitachiStorageAnsibleModuleList,
  HitachiStorageAnsibleModuleForm,
  AnsibleList,
  AnsibleForm,
  PythonList,
  PythonForm,
  UaiGatewayForm,
  UaiGatewayList
} from "containers/Admin/masters";
import { Route, Switch } from "react-router-dom";
import PageNotFound from "components/shared/PageNotFound";
const MasterRoutes = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}`}
      exact
      component={() => (
        <PageNotFound
          title="Error : Invalid Master Url"
          description="Please select a valid master from top navigation menus"
        />
      )}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server"
      component={ServerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server"
      component={ServerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server"
      component={ServerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch"
      component={SwitchListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch"
      component={SwitchForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch"
      component={SwitchForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-vendor`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-vendor"
      component={SwitchVendorListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-vendor/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-vendor"
      component={SwitchVendorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-vendor/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-vendor"
      component={SwitchVendorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-firmware-series`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-firmware-series"
      component={SwitchFirmwareSeriesListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-firmware-series/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-firmware-series"
      component={SwitchFirmwareSeriesForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-firmware-series/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-firmware-series"
      component={SwitchFirmwareSeriesForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-firmware`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-firmware"
      component={SwitchFirmwareListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-firmware/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-firmware"
      component={SwitchFirmwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-firmware/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-firmware"
      component={SwitchFirmwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/switch-firmware-mapping/:vendor_id/:series_id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="switch-firmware-mapping"
      component={SwitchFirmwareMapping}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hba`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hba"
      component={HbaListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hba/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hba"
      component={HbaForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hba/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hba"
      component={HbaForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hbadriver`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hbadriver"
      component={HbaDriverListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hbadriver/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hbadriver"
      component={HbaDriverForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hbadriver/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hbadriver"
      component={HbaDriverForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/os-vendor`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="os-vendor"
      component={OsVendorListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/os-vendor/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="os-vendor"
      component={OsVendorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/os-vendor/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="os-vendor"
      component={OsVendorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/os`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="os"
      component={OsListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/os/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="os"
      component={OsForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/os/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="os"
      component={OsForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oskernel`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oskernel"
      component={OsKernelListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oskernel/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oskernel"
      component={OsKernelForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oskernel/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oskernel"
      component={OsKernelForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cluster`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cluster"
      component={ClusterListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cluster/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cluster"
      component={ClusterForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cluster/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cluster"
      component={ClusterForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/multipath`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="multipath"
      component={MultipathListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/multipath/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="multipath"
      component={MultipathForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/multipath/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="multipath"
      component={MultipathForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/protocol`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="protocol"
      component={ProtocolListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/protocol/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="protocol"
      component={ProtocolForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/protocol/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="protocol"
      component={ProtocolForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/array`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="array"
      component={ArrayListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/array/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="array"
      component={ArrayForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/array/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="array"
      component={ArrayForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/array-customer`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="array-customer"
      component={ArrayCustomerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/array-customer/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="array-customer"
      component={ArrayCustomerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/array-customer/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="array-customer"
      component={ArrayCustomerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/arrayif`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="arrayif"
      component={ArrayIfListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/arrayif/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="arrayif"
      component={ArrayIfForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/arrayif/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="arrayif"
      component={ArrayIfForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/array-customer-version-mapping`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="array-customer-version-mapping"
      component={ArrayCustomerVersionMappingListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/array-customer-version-mapping/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="array-customer-version-mapping"
      component={ArrayCustomerVersionMappingForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/linkspeed`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="linkspeed"
      component={LinkspeedListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/linkspeed/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="linkspeed"
      component={LinkspeedForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/linkspeed/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="linkspeed"
      component={LinkspeedForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vendordistribution`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vendordistribution"
      component={VendorDistributionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vendordistribution/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vendordistribution"
      component={VendorDistributionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vendordistribution/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vendordistribution"
      component={VendorDistributionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hbsdversion`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hbsdversion"
      component={HbsdVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hbsdversion/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hbsdversion"
      component={HbsdVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hbsdversion/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hbsdversion"
      component={HbsdVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/releases`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="releases"
      component={ReleasesListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/releases/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="releases"
      component={ReleasesForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/releases/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="releases"
      component={ReleasesForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/clusterversion`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="clusterversion"
      component={ClusterVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/clusterversion/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="clusterversion"
      component={ClusterVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/clusterversion/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="clusterversion"
      component={ClusterVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/replication-product`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="replication-product"
      component={ReplicationProductListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/replication-product/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="replication-product"
      component={ReplicationProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/replication-product/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="replication-product"
      component={ReplicationProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/replication-product-intermix`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="replication-product-intermix"
      component={ReplicationProductIntermixListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/replication-product-intermix/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="replication-product-intermix"
      component={ReplicationProductIntermixForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/replication-product-intermix/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="replication-product-intermix"
      component={ReplicationProductIntermixForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/site-topology`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="site-topology"
      component={SiteTopologyListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/site-topology/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="site-topology"
      component={SiteTopologyForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/site-topology/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="site-topology"
      component={SiteTopologyForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kms-vendor`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kms-vendor"
      component={KmsVendorListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kms-vendor/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kms-vendor"
      component={KmsVendorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kms-vendor/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kms-vendor"
      component={KmsVendorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kms-model`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kms-model"
      component={KmsModelListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kms-model/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kms-model"
      component={KmsModelForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kms-model/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kms-model"
      component={KmsModelForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/software-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="software-version"
      component={SoftwareVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/software-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="software-version"
      component={SoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/software-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="software-version"
      component={SoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hspc-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hspc-version"
      component={HspcVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hspc-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hspc-version"
      component={HspcVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hspc-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hspc-version"
      component={HspcVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/docker-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="docker-version"
      component={DockerVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/docker-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="docker-version"
      component={DockerVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/docker-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="docker-version"
      component={DockerVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kubernetes-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kubernetes-version"
      component={KubernetesVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kubernetes-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kubernetes-version"
      component={KubernetesVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/kubernetes-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="kubernetes-version"
      component={KubernetesVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/rhocp-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="rhocp-version"
      component={RhocpVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/rhocp-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="rhocp-version"
      component={RhocpVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/rhocp-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="rhocp-version"
      component={RhocpVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/view-point-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="view-point-version"
      component={ViewPointVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/view-point-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="view-point-version"
      component={ViewPointVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/view-point-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="view-point-version"
      component={ViewPointVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hypervisor`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hypervisor"
      component={HypervisorListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hypervisor/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hypervisor"
      component={HypervisorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hypervisor/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hypervisor"
      component={HypervisorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/browser`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="browser"
      component={BrowserListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/browser/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="browser"
      component={BrowserForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/browser/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="browser"
      component={BrowserForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/java-dev-kit`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="java-dev-kit"
      component={JavaDevKitListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/java-dev-kit/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="java-dev-kit"
      component={JavaDevKitForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/java-dev-kit/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="java-dev-kit"
      component={JavaDevKitForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcsf-node-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcsf-node-type"
      component={HcsfNodeTypeListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcsf-node-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcsf-node-type"
      component={HcsfNodeTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcsf-node-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcsf-node-type"
      component={HcsfNodeTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcsf-software-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcsf-software-version"
      component={HcsfSoftwareVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcsf-software-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcsf-software-version"
      component={HcsfSoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcsf-software-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcsf-software-version"
      component={HcsfSoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-model`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-model"
      component={HCPModelListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-model/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-model"
      component={HCPModelForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-model/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-model"
      component={HCPModelForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-version"
      component={HCPVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-version"
      component={HCPVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-version"
      component={HCPVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/feature`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="feature"
      component={FeatureListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/feature/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="feature"
      component={FeatureForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/feature/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="feature"
      component={FeatureForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/filesystem`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="filesystem"
      component={FileSystemListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/filesystem/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="filesystem"
      component={FileSystemForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/filesystem/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="filesystem"
      component={FileSystemForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vssb-model`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vssb-model"
      component={VSSBModelListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vssb-model/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vssb-model"
      component={VSSBModelForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vssb-model/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vssb-model"
      component={VSSBModelForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hvssb-software-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hvssb-software-version"
      component={HVSSBSoftwareVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hvssb-software-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hvssb-software-version"
      component={HVSSBSoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hvssb-software-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hvssb-software-version"
      component={HVSSBSoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/csiversion`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="csiversion"
      component={CSIVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/csiversion/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="csiversion"
      component={CSIVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/csiversion/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="csiversion"
      component={CSIVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-product`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-product"
      component={ScProductListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-product/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-product"
      component={ScProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-product/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-product"
      component={ScProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-certification`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-certification"
      component={ScCertificationListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-certification/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-certification"
      component={ScCertificationForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-certification/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-certification"
      component={ScCertificationForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-partner`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-partner"
      component={ScPartnerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-partner/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-partner"
      component={ScPartnerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-partner/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-partner"
      component={ScPartnerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-multipath-software`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-multipath-software"
      component={ScMultiPathSoftwareListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-multipath-software/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-multipath-software"
      component={ScMultiPathSoftwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-multipath-software/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-multipath-software"
      component={ScMultiPathSoftwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/analyzer`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="analyzer"
      component={AnalyzerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/analyzer/manage-mapping/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="analyzer"
      component={AnalyzerManage}
    />
    <PrivateRoute
      exact
      path={`${match.url}/analyzer-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="analyzer-version"
      component={AnalyzerVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/analyzer-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="analyzer-version"
      component={AnalyzerVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/analyzer-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="analyzer-version"
      component={AnalyzerVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/virtualization-software`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="virtualization-software"
      component={VirtualizationSoftwareListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/virtualization-software/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="virtualization-software"
      component={VirtualizationSoftwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/virtualization-software/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="virtualization-software"
      component={VirtualizationSoftwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-nas`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hitachi-nas"
      component={HitachiNasListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-nas/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hitachi-nas"
      component={HitachiNasForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-nas/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hitachi-nas"
      component={HitachiNasForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/fc-switch`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="fc-switch"
      component={FcSwitchListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/fc-switch/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="fc-switch"
      component={FcSwitchForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/fc-switch/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="fc-switch"
      component={FcSwitchForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-product`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-product"
      component={ScProductListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-product/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-product"
      component={ScProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-product/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-product"
      component={ScProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-certification`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-certification"
      component={ScCertificationListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-certification/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-certification"
      component={ScCertificationForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-certification/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-certification"
      component={ScCertificationForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-partner`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-partner"
      component={ScPartnerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-partner/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-partner"
      component={ScPartnerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-partner/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-partner"
      component={ScPartnerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-multipath-software`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-multipath-software"
      component={ScMultiPathSoftwareListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-multipath-software/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-multipath-software"
      component={ScMultiPathSoftwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sc-multipath-software/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sc-multipath-software"
      component={ScMultiPathSoftwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-administrator-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-administrator-version"
      component={AdVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-administrator-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-administrator-version"
      component={AdVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-administrator-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-administrator-version"
      component={AdVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-docker`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-docker"
      component={AdDockerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-docker/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-docker"
      component={AdDockerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-docker/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-docker"
      component={AdDockerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-switch`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-switch"
      component={AdSwitchListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-switch/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-switch"
      component={AdSwitchForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oc-switch/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oc-switch"
      component={AdSwitchForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-name`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-name"
      component={ISVNameList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-name/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-name"
      component={ISVNameForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-name/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-name"
      component={ISVNameForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-application-name`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-application-name"
      component={ISVApplicationNameList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-application-name/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-application-name"
      component={ISVApplicationNameForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-application-name/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-application-name"
      component={ISVApplicationNameForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-application-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-application-version"
      component={ISVApplicationVersionList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-application-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-application-version"
      component={ISVApplicationVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/isv-application-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="isv-application-version"
      component={ISVApplicationVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-interface`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-interface"
      component={HCPInterfaceList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-interface/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-interface"
      component={HCPInterfaceForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-interface/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-interface"
      component={HCPInterfaceForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-for-cloud-scale-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-for-cloud-scale-version"
      component={HCPCloudScaleVersionList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-for-cloud-scale-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-for-cloud-scale-version"
      component={HCPCloudScaleVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-for-cloud-scale-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-for-cloud-scale-version"
      component={HCPCloudScaleVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-anywhere-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-anywhere-version"
      component={HCPAnywhereVersionList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-anywhere-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-anywhere-version"
      component={HCPAnywhereVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hcp-anywhere-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hcp-anywhere-version"
      component={HCPAnywhereVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-software-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-software-version"
      component={VDISoftwareVersionList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-software-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-software-version"
      component={VDISoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-software-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-software-version"
      component={VDISoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ethernet-switch-category`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ethernet-switch-category"
      component={EthernetSwitchCategoryList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ethernet-switch-category/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ethernet-switch-category"
      component={EthernetSwitchCategoryForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ethernet-switch-category/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ethernet-switch-category"
      component={EthernetSwitchCategoryForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hana-solution-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hana-solution-type"
      component={HanaSolutionTypeList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hana-solution-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hana-solution-type"
      component={HanaSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hana-solution-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hana-solution-type"
      component={HanaSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/bios-bmc`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bios-bmc"
      component={BiosBmcList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/bios-bmc/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bios-bmc"
      component={BiosBmcForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/bios-bmc/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bios-bmc"
      component={BiosBmcForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cpu-family`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cpu-family"
      component={CpuFamilyList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cpu-family/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cpu-family"
      component={CpuFamilyForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cpu-family/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cpu-family"
      component={CpuFamilyForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hnas`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hnas"
      component={HnasList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hnas/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hnas"
      component={HnasForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hnas/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hnas"
      component={HnasForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-card`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card"
      component={RaidCardList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-card/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card"
      component={RaidCardForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-card/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card"
      component={RaidCardForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/raid-card-driver`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card-driver"
      component={RaidCardDriverList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-card-driver/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card-driver"
      component={RaidCardDriverForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-card-driver/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card-driver"
      component={RaidCardDriverForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-card-firmware`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card-firmware"
      component={RaidCardFirmwareList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-card-firmware/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card-firmware"
      component={RaidCardFirmwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-card-firmware/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-card-firmware"
      component={RaidCardFirmwareForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/legacy-assets`}
      requiredRoles={[ROLES.SYS_ADMIN]}
      masterName="legacy-assets"
      component={LegacyProductListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/legacy-assets/add`}
      requiredRoles={[ROLES.SYS_ADMIN]}
      masterName="legacy-assets"
      component={LegacyProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/legacy-assets/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN]}
      masterName="legacy-assets"
      component={LegacyProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/job-progress`}
      requiredRoles={[ROLES.SYS_ADMIN]}
      masterName="job-progress"
      component={JobProgressListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/job-progress/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN]}
      masterName="job-progress"
      component={JobProgressForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/common-services-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="common-services-version"
      component={CommonServicesVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/common-services-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="common-services-version"
      component={CommonServicesVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/common-services-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="common-services-version"
      component={CommonServicesVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/automator-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="automator-version"
      component={AutomatorVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/automator-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="automator-version"
      component={AutomatorVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/automator-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="automator-version"
      component={AutomatorVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cm-rest-api-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cm-rest-api-version"
      component={ApiConfigurationManagerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cm-rest-api-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cm-rest-api-version"
      component={ApiConfigurationManagerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cm-rest-api-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cm-rest-api-version"
      component={ApiConfigurationManagerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ops-center-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ops-center-version"
      component={OpsCenterVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ops-center-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ops-center-version"
      component={OpsCenterVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ops-center-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ops-center-version"
      component={OpsCenterVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/protector-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="protector-version"
      component={ProtectorVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/protector-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="protector-version"
      component={ProtectorVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/protector-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="protector-version"
      component={ProtectorVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/adoptable-technologies`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="adoptable-technologies"
      component={AdoptableTechnologiesListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/adoptable-technologies/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="adoptable-technologies"
      component={AdoptableTechnologiesForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/adoptable-technologies/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="adoptable-technologies"
      component={AdoptableTechnologiesForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cluster-interconnect-switch`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cluster-interconnect-switch"
      component={ClusterInterconnectListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cluster-interconnect-switch/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cluster-interconnect-switch"
      component={ClusterInterconnectForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cluster-interconnect-switch/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cluster-interconnect-switch"
      component={ClusterInterconnectForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/yes-no`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="yes-no"
      component={YesNoListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/yes-no/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="yes-no"
      component={YesNoForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/yes-no/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="yes-no"
      component={YesNoForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cpu`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cpu"
      component={CpuListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cpu/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cpu"
      component={CpuForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cpu/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cpu"
      component={CpuForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdlm-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdlm-version"
      component={HdlmVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdlm-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdlm-version"
      component={HdlmVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdlm-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdlm-version"
      component={HdlmVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdlm-tags`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdlm-tags"
      component={HdlmTagListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdlm-tags/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdlm-tags"
      component={HdlmTagForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdlm-tags/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdlm-tags"
      component={HdlmTagForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-version"
      component={OracleVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-version"
      component={OracleVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-version"
      component={OracleVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/volume-manager`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="volume-manager"
      component={VolumeManagerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/volume-manager/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="volume-manager"
      component={VolumeManagerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/volume-manager/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="volume-manager"
      component={VolumeManagerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdrs`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdrs"
      component={HDRSListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdrs/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdrs"
      component={HDRSForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hdrs/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hdrs"
      component={HDRSForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vasa-provider-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vasa-provider-version"
      component={VasaProviderVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vasa-provider-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vasa-provider-version"
      component={VasaProviderVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vasa-provider-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vasa-provider-version"
      component={VasaProviderVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/datastore-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="datastore-type"
      component={DatastoreTypeListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/datastore-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="datastore-type"
      component={DatastoreTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/datastore-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="datastore-type"
      component={DatastoreTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/client-plugin-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="client-plugin-version"
      component={ClientPluginVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/client-plugin-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="client-plugin-version"
      component={ClientPluginVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/client-plugin-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="client-plugin-version"
      component={ClientPluginVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vrops-release`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vrops-release"
      component={VROPSReleaseListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vrops-release/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vrops-release"
      component={VROPSReleaseForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vrops-release/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vrops-release"
      component={VROPSReleaseForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-pack`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-pack"
      component={ManagementPackListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-pack/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-pack"
      component={ManagementPackForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-pack/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-pack"
      component={ManagementPackForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-pack-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-pack-version"
      component={ManagementPackVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-pack-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-pack-version"
      component={ManagementPackVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-pack-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-pack-version"
      component={ManagementPackVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vro-release`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vro-release"
      component={VROReleaseListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vro-release/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vro-release"
      component={VROReleaseForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vro-release/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vro-release"
      component={VROReleaseForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vro-plugin`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vro-plugin"
      component={VROPluginListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vro-plugin/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vro-plugin"
      component={VROPluginForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vro-plugin/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vro-plugin"
      component={VROPluginForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/plugin-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="plugin-version"
      component={PluginVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/plugin-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="plugin-version"
      component={PluginVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/plugin-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="plugin-version"
      component={PluginVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/srm-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="srm-version"
      component={SRMVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/srm-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="srm-version"
      component={SRMVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/srm-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="srm-version"
      component={SRMVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sra`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sra"
      component={SRAListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sra/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sra"
      component={SRAForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sra/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sra"
      component={SRAForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sra-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sra-version"
      component={SRAVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sra-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sra-version"
      component={SRAVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sra-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sra-version"
      component={SRAVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-solution-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-solution-type"
      component={VDISolutionTypeListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-solution-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-solution-type"
      component={VDISolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-solution-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-solution-type"
      component={VDISolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-release-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-release-version"
      component={VDIReleaseVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-release-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-release-version"
      component={VDIReleaseVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-release-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-release-version"
      component={VDIReleaseVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-management-software-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-management-software-version"
      component={VDIManagementSoftwareVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-management-software-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-management-software-version"
      component={VDIManagementSoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vdi-management-software-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vdi-management-software-version"
      component={VDIManagementSoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-service-pack`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-service-pack"
      component={ServerServicePackListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-service-pack/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-service-pack"
      component={ServerServicePackForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-service-pack/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-service-pack"
      component={ServerServicePackForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/gpu`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="gpu"
      component={GPUListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/gpu/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="gpu"
      component={GPUForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/gpu/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="gpu"
      component={GPUForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sap-release-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sap-release-version"
      component={SAPReleaseVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sap-release-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sap-release-version"
      component={SAPReleaseVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/sap-release-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="sap-release-version"
      component={SAPReleaseVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-controller`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-controller"
      component={RaidControllerListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-controller/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-controller"
      component={RaidControllerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/raid-controller/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="raid-controller"
      component={RaidControllerForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/drive`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="drive"
      component={DriveListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/drive/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="drive"
      component={DriveForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/drive/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="drive"
      component={DriveForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-release-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-release-version"
      component={OracleReleaseVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-release-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-release-version"
      component={OracleReleaseVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-release-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-release-version"
      component={OracleReleaseVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-solution-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-solution-type"
      component={OracleSolutionTypeListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-solution-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-solution-type"
      component={OracleSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/oracle-solution-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-solution-type"
      component={OracleSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-solution-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-solution-type"
      component={VmwareSolutionTypeListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-solution-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-solution-type"
      component={VmwareSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-solution-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-solution-type"
      component={VmwareSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cloud-provider`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cloud-provider"
      component={HybridCloudListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cloud-provider/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cloud-provider"
      component={HybridCloudForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/cloud-provider/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="cloud-provider"
      component={HybridCloudForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ucp-release-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ucp-release-version"
      component={UCPReleaseVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ucp-release-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ucp-release-version"
      component={UCPReleaseVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ucp-release-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ucp-release-version"
      component={UCPReleaseVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-vcf-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-vcf-version"
      component={VmwareVCFVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-vcf-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-vcf-version"
      component={VmwareVCFVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-vcf-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-vcf-version"
      component={VmwareVCFVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-software-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-software-version"
      component={ManagementSoftwareVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-software-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-software-version"
      component={ManagementSoftwareVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/management-software-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="management-software-version"
      component={ManagementSoftwareVersionForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/bare-metal-solution-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bare-metal-solution-type"
      component={BaremetalSolutionTypeListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/bare-metal-solution-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bare-metal-solution-type"
      component={BaremetalSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/bare-metal-solution-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bare-metal-solution-type"
      component={BaremetalSolutionTypeForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/bare-metal-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bare-metal-version"
      component={BaremetalVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/bare-metal-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bare-metal-version"
      component={BaremetalVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/bare-metal-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="bare-metal-version"
      component={BaremetalVersionForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/oracle-solution-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="oracle-solution-type"
      component={OracleSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-solution-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-solution-type"
      component={VmwareSolutionTypeListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-solution-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-solution-type"
      component={VmwareSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/vmware-solution-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="vmware-solution-type"
      component={VmwareSolutionTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hrpc-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hrpc-version"
      component={HrpcVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hrpc-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hrpc-version"
      component={HrpcVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hrpc-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hrpc-version"
      component={HrpcVersionForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/container-orchestrator`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="container-orchestrator"
      component={ContainerOrchestratorListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/container-orchestrator/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="container-orchestrator"
      component={ContainerOrchestratorForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/container-orchestrator/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="container-orchestrator"
      component={ContainerOrchestratorForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/orchestrator-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="orchestrator-version"
      component={OrchestratorVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/orchestrator-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="orchestrator-version"
      component={OrchestratorVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/orchestrator-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="orchestrator-version"
      component={OrchestratorVersionForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/hspp-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hspp-version"
      component={HsppVersionListing}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hspp-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hspp-version"
      component={HsppVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hspp-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hspp-version"
      component={HsppVersionForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/veeam-plugin-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="veeam-plugin-version"
      component={VeeamPluginVersionList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/veeam-plugin-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="veeam-plugin-version"
      component={VeeamPluginVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/veeam-plugin-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="veeam-plugin-version"
      component={VeeamPluginVersionForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/server-type`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-type"
      component={ServerTypeList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-type/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-type"
      component={ServerTypeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-type/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-type"
      component={ServerTypeForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/server-configuration`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-type"
      component={ServerConfigurationList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-configuration/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-configuration"
      component={ServerConfigurationForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-configuration/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-configuration"
      component={ServerConfigurationForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/server-purpose`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-type"
      component={ServerPurposeList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-purpose/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-purpose"
      component={ServerPurposeForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/server-purpose/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-purpose"
      component={ServerPurposeForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/integration_points`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-type"
      component={IntegrationPointList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/integration_points/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-purpose"
      component={IntegrationPointForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/integration_points/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-purpose"
      component={IntegrationPointForm}
    />

    <PrivateRoute
      exact
      path={`${match.url}/hitachi-hardware-product`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-type"
      component={HitachiHardwareProductList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-hardware-product/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-purpose"
      component={HitachiHardwareProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-hardware-product/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-purpose"
      component={HitachiHardwareProductForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-product-version`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-type"
      component={HitachiProductVersionList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-product-version/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-purpose"
      component={HitachiProductVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-product-version/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="server-purpose"
      component={HitachiProductVersionForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-storage-ansible-module`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hitachi-storage-ansible-module"
      component={HitachiStorageAnsibleModuleList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-storage-ansible-module/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hitachi-storage-ansible-module"
      component={HitachiStorageAnsibleModuleForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/hitachi-storage-ansible-module/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="hitachi-storage-ansible-module"
      component={HitachiStorageAnsibleModuleForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ansible`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ansible"
      component={AnsibleList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ansible/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ansible"
      component={AnsibleForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/ansible/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="ansible"
      component={AnsibleForm}
    />
   
    <PrivateRoute
      exact
      path={`${match.url}/python`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="python"
      component={PythonList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/python/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="python"
      component={PythonForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/python/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="python"
      component={PythonForm}
    />
   
   <PrivateRoute
      exact
      path={`${match.url}/uai-gateway`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="uai-gateway"
      component={UaiGatewayList}
    />
    <PrivateRoute
      exact
      path={`${match.url}/uai-gateway/add`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="uai-gateway"
      component={UaiGatewayForm}
    />
    <PrivateRoute
      exact
      path={`${match.url}/uai-gateway/edit/:Id`}
      requiredRoles={[ROLES.SYS_ADMIN, ROLES.PROD_ADMIN]}
      masterName="uai-gateway"
      component={UaiGatewayForm}
    />
   
   
    <Route
      component={() => (
        <PageNotFound
          title="Error : Invalid Master Url"
          description="Please select a valid master from top navigation menus"
        />
      )}
    />
  </Switch>
);
export default MasterRoutes;
