const FILTERS = {
    ndm: {
        os: {
            title: 'OS',
            icon: 'os.png'
        },
        os_kernel: {
            title: 'OS Kernel',
            icon: 'os_kernel.png'
        },
        multi_path: {
            title: 'Multipath',
            icon: 'multipath.png'
        },
        cluster: {
            title: 'Cluster',
            iconClass: 'project-diagram'
        },
        source_array: {
            title: 'Source Array',
            icon: 'hba_driver.png'
        },
        target_array: {
            title: 'Target Array',
            icon: 'switch.png'
        },
        protocol: {
            title: 'Protocol',
            iconClass: 'network-wired'
        },
    }
}
export default FILTERS;