import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//ANALYZERVERSION
export const METHOD_ANALYZERVERSION_LIST = 'analyzerversion/pageable/';
export const METHOD_ANALYZERVERSION_CREATE = 'analyzerversion/';
export const METHOD_ANALYZERVERSION_UPDATE = 'analyzerversion/{ID}/';
export const METHOD_ANALYZERVERSION_DELETE = 'analyzerversion/{ID}/';
export const METHOD_ANALYZERVERSION_DETAIL = 'analyzerversion/{ID}/';
export const METHOD_ANALYZERVERSION_DROPDOWN = 'analyzerversion/drop/';

//VIRTUALIZATIONSOFTWARE
export const METHOD_VIRTUALIZATIONSOFTWARE_LIST = 'virtualizationsoftware/pageable/';
export const METHOD_VIRTUALIZATIONSOFTWARE_CREATE = 'virtualizationsoftware/';
export const METHOD_VIRTUALIZATIONSOFTWARE_UPDATE = 'virtualizationsoftware/{ID}/';
export const METHOD_VIRTUALIZATIONSOFTWARE_DELETE = 'virtualizationsoftware/{ID}/';
export const METHOD_VIRTUALIZATIONSOFTWARE_DETAIL = 'virtualizationsoftware/{ID}/';

//HITACHINAS
export const METHOD_HITACHINAS_LIST = 'hitachinas/pageable/';
export const METHOD_HITACHINAS_CREATE = 'hitachinas/';
export const METHOD_HITACHINAS_UPDATE = 'hitachinas/{ID}/';
export const METHOD_HITACHINAS_DELETE = 'hitachinas/{ID}/';
export const METHOD_HITACHINAS_DETAIL = 'hitachinas/{ID}/';

//FCSWITCH
export const METHOD_FCSWITCH_LIST = 'fcswitch/pageable/';
export const METHOD_FCSWITCH_CREATE = 'fcswitch/';
export const METHOD_FCSWITCH_UPDATE = 'fcswitch/{ID}/';
export const METHOD_FCSWITCH_DELETE = 'fcswitch/{ID}/';
export const METHOD_FCSWITCH_DETAIL = 'fcswitch/{ID}/';

//ANALYZERMAPPER
export const METHOD_ANALYZERMAPPER_LIST = 'analyzermapperlist/{masterId}/';
export const METHOD_ANALYZERMAPPER_DETAIL = 'analyzermapper/{masterId}/{recordId}/';
export const METHOD_ANALYZERMAPPER_DETAIL_REACTIVATE = 'analyzermapper/{masterId}/{recordId}/1/';
export const METHOD_ANALYZERMAPPER_DELETE = 'analyzermapper/{masterId}/{recordId}/{deleteType}/';
export const METHOD_ANALYZERMAPPER_ADD = 'analyzermapper/{masterId}/0/';
export const METHOD_ANALYZERMAPPER_UPDATE = 'analyzermapper/{masterId}/{recordId}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;

//mapping warnings
export const MSG_WARNING_DELETE_MAPPING = 'Are you sure you want to remove mapping for "ITEM_NAME"?';
export const MSG_WARNING_UNDO_DELETE_MAPPING = 'Are you sure you want to undo the mapping for "ITEM_NAME"?.';
export const MSG_ADDITIONAL = '"ITEM_NAME" is also associated with analyzers "ANALYZER_NAMES". Do you want to remove mapping for same?';
export const MSG_ADDITIONAL_UNDO = '"ITEM_NAME" is also associated with analyzers "ANALYZER_NAMES". Do you want to undo mapping for same?';