import { LOADING,PRODUCTS,UPLOADING } from "constants/admin/bulkUpload";
import uploadService from "services/uploadService";
  
  const uploadServiceObj = new uploadService();
  
  export const uploadFile = (productId,formData) => async (dispatch) => {
    dispatch({
      type: UPLOADING,
      payload: true,
    });

    try {
      const result=await uploadServiceObj.uploadFile(productId,formData,dispatch);
      dispatch({
        type: UPLOADING,
        payload: false,
      }); 
      return result;
    } catch (error) {
      dispatch({
        type: UPLOADING,
        payload: false,
      });
      return false;
    }
  };
  export const getUserWiseProducts = () => (dispatch) => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    uploadServiceObj.getUserWiseProducts().then((response) => {
      dispatch({
        type: PRODUCTS,
        payload: response,
      });
      dispatch({
        type: LOADING,
        payload: false,
      });
    },(err)=>{
      dispatch({
        type: LOADING,
        payload: false,
      });
    });
  };