import React from "react";
import DOMPurify from "dompurify";
import { checkExcept, replaceAll } from "utils/matriceHelpers";
const CustomStringRender = ({
  data,
  controlFor,
  controlName,
  WrapperTag = "div",
}) => {
  if (checkExcept(controlFor, controlName)) {
    return data;
  } else {
    const content =
        data !== null
          ?  replaceAll(data,"*", '<span class="asterisk-style">*</span>')
          : data;
    
    return (
      <WrapperTag
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content),
        }}
      />
    );
  }
};
export default CustomStringRender;
