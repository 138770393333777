const FILTERS = {
    vasa: {
        os: {
            title: 'vSphere Release',
            icon: 'server.png'
        },
        vasa_version: {
            title: 'VASA Provider Version',
            icon: 'os.png'
        },
        datastore_type: {
            title: 'Datastore Type',
            icon: 'os_kernel.png'
        },
        array_customer_version: {
            title: 'Array',
            icon: 'switch.png'
        },
        protocol: {
            title: 'Protocol',
            icon: 'os_kernel.png'
        }
    }
}
export default FILTERS;