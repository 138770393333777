const FILTERS = {
    "os-cluster": {
        os: {
            title: 'OS',
            icon: 'os.png'
        },
        array: {
            title: 'Array',
            icon: 'array.png'
        },
        cluster_software: {
            title: 'Cluster Software',
            iconClass: 'project-diagram'
        },
        cluster_version: {
            title: 'Cluster Version',
            iconClass: 'network-wired'
        }
    }
}
export default FILTERS;