import React, { Component, Suspense, createRef } from "react";
import Spinner from "components/shared/Spinner";
import { parseUrlQuery } from "utils/utils";
import { handleChangeMenu } from "utils/matriceHelpers";
import { connect } from "react-redux";
import products from "resources/json/static-menus.json";
import _ from "lodash";
import {
  resetFilters,
  getKeywordSuggestions,
  getAllKeywordSuggestions,
  getLegacyProductMenu,
} from "actions/productSearchActions";
import MegaMenu from "components/shared/MegaMenu";
import { Link } from "react-router-dom";
import history from "../../history";
import { APP_NAME } from "constants/common";
import { Helmet } from "react-helmet";
import authService from "services/authService";
import { Matrices } from "./SearchMatrices";
import TabWrapper from "containers/Pages/TabWrapper/TabWrapper";
import {
  KEYWORD_SEARCH_FIELD,
  LABEL_ALL_PRODUCT_CATEGORY,
  LABEL_ALL_SOLUTION_CATEGORY,
  STORAGE_FILTER_CONTROLS,
} from "constants/productMatrix";
import { KEYWORD_SEARCH_CATEGORY } from "constants/productMatrix";

const elemRef = createRef();

class SearchProducts extends Component {
  constructor(props) {
    super(props);
    this.handleChangeMenu = handleChangeMenu.bind(this);
    const uriParams = this.getUriParams();
    const defaultKeywordSearch =
      uriParams &&
      uriParams.find((filter) => filter.field === KEYWORD_SEARCH_FIELD);
    const defaultKeywordCategory =
      uriParams &&
      uriParams.find((filter) => filter.field === KEYWORD_SEARCH_CATEGORY);
    this.state = {
      defaultMegaMenuLabel: "Products",
      keywordsearch: defaultKeywordSearch?.value || "", //this is to use internal state
      keyword: defaultKeywordSearch?.value || "", //this is to pass to child compponent so that url can change
      category: defaultKeywordCategory?.value || "",
      suggestions: [],
      globalSearch: [],
    };
  }
  // to set the default mega menu label of button based from redux state
  setDefaultMegaMenuLabel = () => {
    const { megaMenuOption } = this.props;
    if (!megaMenuOption || megaMenuOption === "Products") {
      this.setState({ defaultMegaMenuLabel: LABEL_ALL_PRODUCT_CATEGORY });
    } else if (megaMenuOption === "Solutions") {
      this.setState({ defaultMegaMenuLabel: LABEL_ALL_SOLUTION_CATEGORY });
    }
  };

  getUriParams = () => {
    const qs = history.location.search;
    return qs !== "" ? parseUrlQuery(qs) : [];
  };

  componentDidMount = async () => {
    this.getDownloadProductTags();
    this.setDefaultMegaMenuLabel();
  };

  handleChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.suggestKeywords();
  };
  setKeyword = async (keyword, category = "") => {
    await this.setState({
      keywordsearch: keyword,
      keyword: encodeURIComponent(keyword),
      category,
      suggestions: [],
    });
  };
  updateInformationfromUrl = () => {
    const uriParams = this.getUriParams();
    const defaultKeywordSearch =
      uriParams &&
      uriParams.find((filter) => filter.field === KEYWORD_SEARCH_FIELD);
    const defaultKeywordCategory =
      uriParams &&
      uriParams.find((filter) => filter.field === KEYWORD_SEARCH_CATEGORY);
    this.setState({
      keywordsearch: defaultKeywordSearch?.value || "", //this is to use internal state
      keyword: defaultKeywordSearch?.value || "", //this is to pass to child compponent so that url can change
      category: defaultKeywordCategory?.value || "",
    });
  };

  redirectToMatrice = (slug, keyword, category) => {
    const redirectTo = `products/${slug}?keywordsearch=${encodeURIComponent(
      keyword
    )}&_f=${category}`;
    this.props.history.push(redirectTo);
  };
  redirectToSearch = (keyword) => {
    this.props.history.push(
      `search?keywordsearch=${encodeURIComponent(keyword)}`
    );
  };

  suggestKeywords = () => {
    const term = this.state.keywordsearch.toLowerCase();
    if (term !== "") {
      const params = this.props.match.params;
      const selectedProduct = this.findSelectedMenu(
        products,
        "slug",
        params.productSlug
      );
      if (selectedProduct?.id > 0) {
        const suggestions = {};
        //search from specific product
        const productKeywords = this.props.filterKeywords.find(
          (row) => row?.product_id === selectedProduct?.id
        );
        Object.keys(productKeywords?.items || []).map((category) => {
          const foundRecords = productKeywords.items[category].filter(
            (keyword) => keyword.toLowerCase().indexOf(term) > -1
          );
          if (foundRecords.length > 0) {
            suggestions[category] = foundRecords;
          }
          return true;
        });
        this.setState({
          suggestions,
        });
      } else {
        //search from all product keywords
        const globalSearch = [];
        const allKeywords = this.props.allKeywords;
        allKeywords.map((product) => {
          const mainRow = {
            productName: product.product_name,
            matrixName: product.slug,
          };
          const searchedTags = {};
          Object.keys(product.items).map((category) => {
            const foundRecords = product.items[category].filter(
              (keyword) => keyword.toLowerCase().indexOf(term) > -1
            );
            if (foundRecords.length > 0) {
              searchedTags[category] = foundRecords;
            }
            return true;
          });
          mainRow["searchedTags"] = searchedTags;
          if (Object.keys(searchedTags)?.length > 0) {
            globalSearch.push(mainRow);
          }
          return true;
        });
        this.setState({
          globalSearch,
        });
      }
    } else {
      this.setState({
        suggestions: [],
        globalSearch: [],
      });
    }
  };

  renderRelevantComponent = (selectedProduct) => {
    const userData = authService.userData;
    const { keyword, category } = this.state;
    let RelevantComponent = null;
    let userTypeAuthGuard = null;
    for (const matrice of Matrices) {
      if (matrice.Slugs.includes(selectedProduct.slug)) {
        if (!matrice?.allowedUserTypes) {
          //if allowed user types not exist on this matrix
          RelevantComponent = matrice.Component;
          break;
        } else if (
          matrice?.allowedUserTypes &&
          matrice?.allowedUserTypes?.includes(userData?.user_type)
        ) {
          //if allowed user types exist on this matrix and user have required permission
          RelevantComponent = matrice.Component;
          break;
        } else if (matrice?.allowedUserTypes) {
          userTypeAuthGuard = true;
          break;
        }
      }
    }
    if (RelevantComponent !== null) {
      return (
        <RelevantComponent
          resultElemRef={elemRef}
          productId={selectedProduct.id}
          keyword={keyword}
          category={category}
          resetGlobalSearch={this.resetHandler}
          productSlug={selectedProduct.slug}
        />
      );
    } else {
      return userTypeAuthGuard && userTypeAuthGuard !== null ? (
        <div className="row col-lg-12 alert alert-warning">
          Log in to Product Compatibility Guide to view the page (Hitachi
          Vantara Internal only)
        </div>
      ) : (
        <div className="row col-lg-12 alert alert-warning">Coming soon</div>
      );
    }
  };
  scrollToResult = () => {
    elemRef.current.scrollIntoView({ behavior: "smooth" });
  };
  
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      //reset the previous filters from redux
      this.props.resetFilters();
      this.resetHandler();
      this.getDownloadProductTags();
      this.updateInformationfromUrl();
    }
    if (prevProps.megaMenuOption !== this.props.megaMenuOption) {
      this.setDefaultMegaMenuLabel();
    }
  }
  getDownloadProductTags = async () => {
    const {
      match: { params },
    } = this.props;
    const selectedProduct = this.findSelectedMenu(
      products,
      "slug",
      params.productSlug
    );
    if (selectedProduct?.id) {
      const tags = this.props.filterKeywords.find(
        (row) => row?.product_id === selectedProduct?.id
      );
      if (_.isEmpty(tags)) {
        await this.props.getKeywordSuggestions(selectedProduct.id);
      }
    } else {
      const tags = this.props.allKeywords;
      if (_.isEmpty(tags)) {
        await this.props.getAllKeywordSuggestions();
      }
    }
  };

  resetHandler = () => {
    this.setState({
      keywordsearch: "",
      keyword: "",
      category: "",
      suggestions: [],
      globalSearch: [],
    });
  };

  findSelectedMenu = (products, keyToFind, valToFind) => {
   
    let foundObj;
    JSON.stringify(products, (_, nestedValue) => {
      if (nestedValue && nestedValue[keyToFind] === valToFind) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };
  renderSuggestions = () => {
    const { suggestions, globalSearch } = this.state;
    const {
      match: { params },
    } = this.props;
    const selectedProduct = this.findSelectedMenu(
      products,
      "slug",
      params?.productSlug
    );
    const matrixName = selectedProduct?.slug || params.productSlug;
    if (!_.isEmpty(suggestions) && selectedProduct?.id > 0) {
      return (
        <div className="form-group suggestions_area">
          {Object.keys(suggestions).map((category, index) => (
            <div key={`caterory-${index}`}>
              <h6 className="bg-light text-dark">
                {STORAGE_FILTER_CONTROLS[matrixName][category]?.title}
              </h6>
              {suggestions[category].map((data, index) => (
                <button
                  key={index}
                  className="btn btn-link branding-link-thin"
                  as={Link}
                  onClick={(e) => this.setKeyword(data, category)}
                >
                  {data}
                </button>
              ))}
            </div>
          ))}
        </div>
      );
    } else if (!_.isEmpty(globalSearch)) {
      return (
        <div className="form-group suggestions_area">
          {globalSearch.map((product, index) => (
            <div key={`prod-${index}`}>
              <h5 className="bg-light text-dark">{product.productName}</h5>
              {Object.keys(product.searchedTags).map((category, index) => (
                <div key={`caterory-${index}`}>
                  <h6 className="bg-light text-dark">
                    {
                      STORAGE_FILTER_CONTROLS[product.matrixName][category]
                        ?.title
                    }
                  </h6>
                  {product.searchedTags[category].map((data, index) => (
                    <button
                      key={index}
                      className="btn btn-link branding-link-thin"
                      as={Link}
                      onClick={(e) =>
                        this.redirectToMatrice(
                          product.matrixName,
                          data,
                          category
                        )
                      }
                    >
                      {data}
                    </button>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    }
  };

  findProducts = (selectedProduct) => {
 
    if (selectedProduct?.parent) {
      let foundObj;
      JSON.stringify(products, (_, nestedValue) => {
        if (
          nestedValue &&
          nestedValue["parent"] === (selectedProduct?.parent).split(" > ")[0]
        ) {
          foundObj = nestedValue;
        }
        return nestedValue;
      });

      return foundObj?.nodes;
    } else {
      return products;
    }
  };

  render() {
    const {
      loading,
      loadingFilters,
      match: { params },
    } = this.props;
    const { keywordsearch, defaultMegaMenuLabel } = this.state;
    const selectedProduct = this.findSelectedMenu(
      products,
      "slug",
      params?.productSlug
    );

   
    return (
      <>
        <Helmet>
          <title>
            {APP_NAME} :: {selectedProduct?.name || "Home"}
          </title>
        </Helmet>
        {loading ? <Spinner /> : null}
        {loadingFilters ? <Spinner /> : null}
        {/* include tabwrapper component */}
       <TabWrapper>
                  
                    <div className="form-wrap">
                      <div className="row mega-row mt-3">
                       
                          <div className="form-group">
                            <MegaMenu
                              defaultOpenState={
                                selectedProduct?.name !== undefined
                                  ? false
                                  : true
                              }
                              alwaysOpenStates={[defaultMegaMenuLabel]}
                              data={this.findProducts(selectedProduct)}
                              flagHomeIcon={true}
                              onChangeMenu={this.handleChangeMenu}
                              defaultLabel={
                                selectedProduct?.parent ||
                                selectedProduct?.name ||
                                defaultMegaMenuLabel
                              }
                              selectedProduct={selectedProduct?.parent}
                            />
                          </div>
                        <div className="search-container">
                          <div className="form-group search-container-top">
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control shadow-none global_search_textbox"
                              placeholder={`Search ${
                                selectedProduct?.name || ""
                              }`}
                              name="keywordsearch"
                              value={keywordsearch}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  if (selectedProduct?.id > 0) {
                                    this.setKeyword(e.target.value);
                                  } else {
                                    this.redirectToSearch(e.target.value);
                                  }
                                }
                              }}
                              onChange={this.handleChange}
                            />
                            {!_.isEmpty(keywordsearch) ? (
                              <i
                                className="fa fa-close search_icon_form_control show_cursor"
                                onClick={this.resetHandler}
                              ></i>
                            ) : (
                              <i className="fa fa-search search_icon_form_control"></i>
                            )}
                          </div>

                          {this.renderSuggestions()}
                        </div>

                        {!_.isEmpty(selectedProduct) &&
                        this.props.totalRecordCount ? (
                          <div className="pl-2 mb-3">
                            <button
                              className="btn btn-link branding-link"
                              onClick={this.scrollToResult}
                            >
                              {this.props.totalRecordCount} Records Found{" "}
                              <i className="fa fa-angle-double-down"></i>
                            </button>
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <Suspense fallback={<Spinner />}>
                          {!_.isEmpty(selectedProduct)
                            ? this.renderRelevantComponent(selectedProduct)
                            : null}
                        </Suspense>
                      </div>
                    </div>
                    </TabWrapper>
                 
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.productSearchReducer,
  ...state.commonReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAllKeywordSuggestions: () => dispatch(getAllKeywordSuggestions()),
  getKeywordSuggestions: (productId) =>
    dispatch(getKeywordSuggestions(productId)),
  resetFilters: () => dispatch(resetFilters()),
  getLegacyProductMenu: (payload) => dispatch(getLegacyProductMenu(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchProducts);
