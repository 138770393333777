import React, { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
//import { toast } from "react-toastify";
import DataGrid from "components/shared/DataGrid";
import _ from "lodash";
import analyzerService from "services/admin/masters/analyzerService";
//import { MSG_WARNING_DELETE, MSG_DELETE_RECORD,MSG_WARNING_UNDO_DELETE,MSG_UNDO_RECORD } from "constants/admin/masters/analyzer";
import Spinner from "components/shared/Spinner";
import { handleRefresh, toggleChangehandlerNew,getCurrentSlug } from "utils/masterHelpers";
const serviceObj = new analyzerService();
class List extends Component {
  constructor(props) {
    super(props);
    this.toggleChangehandler=toggleChangehandlerNew.bind(this);
    this.handleRefresh=handleRefresh.bind(this);
    this.getCurrentSlug=getCurrentSlug.bind(this);
    this.state = {
      loadingTable: false,
      refreshToken: Date.now(),
      //datatable states
      filtering: true,
      columns: [
        { title: "Analyzer Mapping Masters", field: "name" },
      ],
      actions: [{
        icon: 'settings',
        tooltip: "Manage Mapping",
        onClick: (event, rowData) => {
          this.props.history.push("/admin/master/"+this.getCurrentSlug()+"/manage-mapping/"+ rowData.id);
        },
      }],
      pageSize: 20,
      recordsMainFilter: { "field": "is_deleted", "type": "int", "value": 0, "operator": "eq" }
    };
  }
  


  handlePagingData = (query) => {
    this.setState({
      loadingTable: true
    })
    return new Promise((resolve, reject) => {
      let sortData = [{ "field": "id", "dir": "desc" }]; //default sorting
      if (!_.isEmpty(query.orderBy)) {
        sortData = [{
          field: query.orderBy.field,
          dir: query.orderDirection,
        }];
      }
      this.setState({ pageSize: query.pageSize }, () => {
        let filters = [];
        if (!_.isEmpty(query.filters)) {
          query.filters.map((filter) =>
            filters.push({
              field: filter.column.field,
              type: "string",
              value: filter.value,
              operator: "eq",
            })
          );
        }
        const reqParams = {
          take: query.pageSize,
          skip: query.pageSize * query.page,
          sort: sortData,
          filter: filters,
        };        
        if(!_.isEmpty(this.state.recordsMainFilter)){
          filters.push(this.state.recordsMainFilter)
        }
        serviceObj.getAllAnalyzer(reqParams).then((response) => {
          this.setState({
            loadingTable: false
          })
          resolve({
            data: response.items,
            page: query.page,
            totalCount: response.total,
          });
        }).catch(e => {
          this.setState({
            loadingTable: false
          })
        });
      });
    });
  }
  render() {
    const { loadingTable } = this.state;
    return (
      <>
        {loadingTable ? <Spinner /> : null}
        <div className="form-wrap mt-3">
          <div className="form-group">
            <Form.Group as={Row}>
              <Col sm={12}>
                <DataGrid
                  actions={this.state.actions}
                  columns={this.state.columns}
                  dataHandler={this.handlePagingData}
                  pageSize={this.state.pageSize}
                  filtering={this.state.filtering}
                  refreshToken={this.state.refreshToken}
                ></DataGrid>
              </Col>
            </Form.Group>
          </div>
        </div>
      </>
    );
  }
}
export default List;
