import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import Spinner from "components/shared/Spinner";
import {
  getAdminSettings,
  saveAdminSettings,
} from "actions/adminSettingsActions";
import productMatrixService from "services/productMatrixService";
import { SUCCESS_MESSAGE_ADMIN_SETTINGS_FORM, GENERAl_ERROR_ADMIN_SETTINGS_FORM } from "constants/common";
import EncryptionTool from "components/shared/EncryptionTool";
const productMatrixServiceObj= new productMatrixService();
class AdminSettings extends Component {
  constructor(props) {
    super(props);
    if (_.isEmpty(props.settingsData)) {
      this.props.getAdminSettings();
    }
    this.state = {
      isSubmitting: false,
      errors: [],
      settingsData: props.settingsData,
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.settingsData, prevProps.settingsData)) {
      this.setState({
        settingsData: this.props.settingsData,
      });
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      isSubmitting: true,
    });
    let response = await this.props.saveAdminSettings(this.state.settingsData);
    this.setState({
      isSubmitting: false,
    });
    if (response) {
      toast.success(SUCCESS_MESSAGE_ADMIN_SETTINGS_FORM);
    }
  };
  downloadAccessLogs = async (e) => {
    productMatrixServiceObj.downloadAccessLogs();
  }
  updateField = (updatedValue, groupIndex, index) => {
    const settingsData = [...this.state.settingsData];
    settingsData[groupIndex].adminsettings[index].value = updatedValue;
    this.setState({
      settingsData,
    });
  };
  getErrors = (serverErrors) => {
    const errors = {};
    if (!_.isEmpty(serverErrors)) {
      _.forOwn(serverErrors, function (group) {
        if (!_.isEmpty(group)) {
          (group?.adminsettings || []).map((field) => (
            (!_.isEmpty(field)) ? (
              errors[field.id[0]] = field.value[0]
            ) : null
          ))
        }
      })
    }
    return errors;
  }
  render() {
    const { loading } = this.props;
    const errors = this.getErrors(this.props.serverErrors);
    const { settingsData, isSubmitting } = this.state;
    return (
      <>
        {loading ? (
          <Spinner />
        ) : null}
        <div className="form-wrap">
          {settingsData ? (
            <div className="row">
              <div className="col-md-12 mt-1 text-right">
                <button onClick={this.downloadAccessLogs} className="btn btn-secondary"><i className="fa fa-download" aria-hidden="true"></i> Download Access Logs</button>
                <EncryptionTool />
              </div>
            </div>
          ) : null}
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-lg-12 customTab">
                <Tabs defaultActiveKey="tab-0" id="adminSettingsForm">
                  {settingsData &&
                    settingsData.map((group, groupIndex) => (
                      <Tab
                        key={groupIndex}
                        eventKey={"tab-" + groupIndex}
                        title={group.name}
                      >
                        {group.adminsettings.map((field, index) => (
                          <div key={index} className="form-group">
                            <label htmlFor={field.label}>{field.label}:</label>
                            <input
                              type="text"
                              name={field.label}
                              placeholder={"Enter " + field.label}
                              onChange={(e) =>
                                this.updateField(
                                  e.target.value,
                                  groupIndex,
                                  index
                                )
                              }
                              className={`form-control ${(errors[field.id] && errors[field.id] !== undefined) ? "is-invalid" : ""
                                }`}
                              value={
                                this.state.settingsData[groupIndex]
                                  .adminsettings[index].value
                              }
                            />
                            {(errors[field.id] && errors[field.id] !== undefined) ? (
                              <div className="text-danger">
                                {errors[field.id]}
                              </div>
                            ) : null}

                          </div>
                        ))}
                      </Tab>
                    ))}
                </Tabs>
              </div>
            </div>
            {!_.isEmpty(settingsData) ? (
              <>
                <div className="row mt-3">
                  {!_.isEmpty(errors) ? (
                    <div className="col-lg-12 alert alert-danger">
                      {GENERAl_ERROR_ADMIN_SETTINGS_FORM}
                    </div>
                  ) : null}
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <input
                      type="submit"
                      value={isSubmitting ? "Please wait..." : "Save"}
                      disabled={isSubmitting}
                      className="btn btn-secondary btn-block"
                    />
                  </div>
                </div>
              </>) : null}
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.adminSettingsReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getAdminSettings: () => dispatch(getAdminSettings()),
  saveAdminSettings: (formData) => dispatch(saveAdminSettings(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
