const FILTERS = {
    "hcp-anywhere":{
        isv: {
            title: 'ISV Name',
            icon: 'server.png'
        },
        isv_application: {
            title: 'ISV Application Name',
            icon: 'os.png'
        },
        isv_application_version: {
            title: 'ISV Application Version',
            icon: 'os_kernel.png'
        },
        hcp_anywhere_client_os: {
            title: 'HCP Anywhere Client OS',
            icon: 'os.png'
        },
        hcp_anywhere_version: {
            title: 'HCP Anywhere Version',
            icon: 'hba_driver.png'
        },
        interface: {
            title: 'Interface',
            icon: 'array_if.png'
        }
    }
}
export default FILTERS;