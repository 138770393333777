import { networkService } from "services/networkService";
import {
  METHOD_JOBPROGRESS_LIST,
  METHOD_JOBPROGRESS_UPDATE,
  METHOD_JOBPROGRESS_DETAIL, 
  METHOD_JOBPROGRESS_PROCESSCHECK
} from "constants/admin/masters/jobProgress";
class jobProgressService {
  //JobProgress methods
  getAllJobProgress = (data) => {
    return networkService
      .post(METHOD_JOBPROGRESS_LIST, data)
      .then((response) => response.data);
  };
  getJobProgressDetail = (id) => {
    const updatedMethodName = METHOD_JOBPROGRESS_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  updateJobProgress = (data, id) => {
    const updatedMethodName = METHOD_JOBPROGRESS_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  getJobProgressProcessCheck = (id) => {
    return networkService
      .get(METHOD_JOBPROGRESS_PROCESSCHECK)
      .then((response) => response.data);
  };
}
export default jobProgressService;
