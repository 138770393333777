import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//hdlm version
export const METHOD_LIST = 'hdlmversion/pageable/';
export const METHOD_CREATE = 'hdlmversion/';
export const METHOD_UPDATE = 'hdlmversion/{ID}/';
export const METHOD_DELETE = 'hdlmversion/{ID}/';
export const METHOD_DETAIL = 'hdlmversion/{ID}/';

//hdlm tag methods
export const METHOD_HDLMTAG_LIST = 'hdlmtag/pageable/';
export const METHOD_HDLMTAG_CREATE = 'hdlmtag/';
export const METHOD_HDLMTAG_UPDATE = 'hdlmtag/{ID}/';
export const METHOD_HDLMTAG_DELETE = 'hdlmtag/{ID}/';
export const METHOD_HDLMTAG_DETAIL = 'hdlmtag/{ID}/';
export const METHOD_HDLMTAG_DROP = 'hdlmtag/drop/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;