import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout, updateAuthLoaderState } from "actions/loginActions";
import { Link, useHistory } from "react-router-dom";
import userService from "services/admin/userService";
import commonService from "services/commonService";
import BrandSiteSvg from "./BrandSiteSvg";
import "resources/css/clientlib-base.min.scss";
import "resources/css/mobile-menu.scss";
import bsmenus from "resources/json/support-menus.json";
import links from "resources/json/links.json";
import {
  ENV_HEAD_COLOR,
  LOGGED_IN_USER_INFO,
  OKTA_TOKEN_INFO,
  OKTA_CACHE_STORAGE,
} from "constants/common";
import { captureGADimension } from "utils/utils";
import { useOktaAuth } from "@okta/okta-react";
import { store } from "index";
import { LOGIN_INFO } from "constants/login";
import authService from "services/authService";

const userServiceObj = new userService();
const commonServiceObj = new commonService();

const HeaderBrandSite = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [brandsitemenus, setBrandsitemenus] = useState(bsmenus);
  const [userType, setUserType] = useState("Public");
  const { oktaAuth } = useOktaAuth();
  const userData = JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO));

  const hideNavItems = async (user_type) => {
    const hideByTitles = ["My Sites/Products", "License Management"];
    const menus = (brandsitemenus[user_type] || []).map((row) => {
      row.childrens = row?.childrens?.filter(
        (item) => !hideByTitles.includes(item)
      );
      return row;
    });
    brandsitemenus[user_type] = menus;
    setBrandsitemenus(brandsitemenus);
  };
  const checkSites = async (user_type, hdsusername) => {
    const servletURLParams =
      "?userId=" + hdsusername + "&userType=" + getUserCategory(user_type);
    commonServiceObj
      .checkSitesAvailablityForSupportHeader(servletURLParams)
      .then((data) => {
        let hideNavs = false;
        if (data.status === "Success") {
          if (data.hasSites !== null) {
            if (!data.hasSites) {
              hideNavs = true;
            }
          } else {
            hideNavs = true;
          }
        } else {
          hideNavs = true;
        }
        if (hideNavs) {
          hideNavItems(user_type);
        }
      })
      .catch((err) => {
        hideNavItems(user_type); 
        //in case of api failure or any cors issue, hide the site availabilities
        console.log("Error in Site availability xhr! " + JSON.stringify(err));
      });
  };
  const insertQuaralooUserLog = (response) => {
    if (userData?.id) {
      const s = window.document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.innerHTML = `_kiq.push(['identity', '${
        response.hdsusername || ""
      }']);      
                        _kiq.push(['set', {
                            'userType': '${response.user_type || ""}',
                            'company': '${response.company_name || ""}',
                            'country': '${response.country || ""}'
                            }]);`;
      window.document.body.appendChild(s);
    }
    //}
  };
  const getUserCategory = (userType) => {
    if (userType.indexOf("Partner") !== -1) {
      return "Partner";
    } else if (userType.indexOf("Customer") !== -1) {
      return "Customer";
    } else {
      return userType;
    }
  };
  const getUserMenus = (userType) => {
    const userSpecificmenus = {
      INTUSER: ["Manage Subscriptions"],
      "Service Partner": ["My Profile", "Manage Subscriptions"],
      "Sales Partner": ["My Profile", "Manage Subscriptions"],
      "Restricted Partner": ["My Profile"],
      "Entitled Customer": [
        "My Profile",
        "View Site Users",
        "Manage Subscriptions",
      ],
      "Non-Entitled Customer": ["My Profile", "Manage Subscriptions"],
      "Registered User": ["My Profile"],
      OEM: [],
      Public: [],
    };
    return userSpecificmenus[userType];
  };
  const getUserType = (userType) => {
    let user_type = userType; //in non partner then keep the same
    const partnerTypes = [
      {
        title: "Service Partner",
        slugs: ["VAR-ASP", "SP-ISP", "SP-SUB", "VAR-GEN(w.Certs)"],
      },
      {
        title: "Sales Partner",
        slugs: [
          "DIST-GEN",
          "SYS-ITG",
          "VAR-VC",
          "SW-PTR",
          "VAR-GEN",
          "SUP LEV1",
          "TAP",
          "DIST-VC",
        ],
      },
      {
        title: "Restricted Partner",
        slugs: ["PTR-ADV", "MISC", "GSA", "GLOB-SOL"],
      },
    ];
    partnerTypes.map((row) => {
      if (row.slugs.includes(userType)) {
        user_type = row.title;
      }
      return true;
    });
    return user_type;
  };
  const getUserDetails = async () => {
    userServiceObj.getDetail(userData.id).then(async (response) => {
      dispatch(updateAuthLoaderState(false));
      //quaraloo user log
      insertQuaralooUserLog(response);
      //quaraloo user log
      //support header userType based menus hide/show

      const user_type = getUserType(response.user_type);

      let checkSitesFlag = false;
      await setUserType(user_type);
      if (
        user_type &&
        user_type !== "INTUSER" &&
        user_type !== "Non-Entitled Customer" &&
        user_type !== "Registered User"
      ) {
        checkSitesFlag = true;
      } else {
        hideNavItems(user_type);
      }
      if (checkSitesFlag) {
        checkSites(user_type, response.hdsusername);
      }
      //support header userType based menus hide/show
    });
  };
  useEffect(() => {
    if (userData?.id > 0) {
      getUserDetails();
    }
    captureGADimension(userData); //capture logged in dimension in GA
    //eslint-disable-next-line
  }, [userData?.id]);

  const signout = () => {
    dispatch(logout());
    if (store.getState(LOGIN_INFO)) {
      oktaAuth.signOut();
      authService.logout();
    }
    history.push("/");
  };

  const [mobileSearchFormVisibility, setMobileSearchFormVisibility] =
    useState(false);
  const [leftLocation, setLeftLocation] = useState({});
  const [currentMegaMenu, setMegaMenu] = useState();
  const setCurrentMegaMenu = (activeMenu, event = null) => {
    setMegaMenu(activeMenu);
    if (event !== null) {
      setLeftLocation({
        ...leftLocation,
        [activeMenu]: event.currentTarget.offsetLeft + "px",
      });
    }
  };
  const [searchBox, setSearchBox] = useState();
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchBox("");
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });
  const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
  const updateMobileMenuStyle = () => {
    return mobileMenuToggle
      ? {
          width: "250px",
          zIndex: "99999",
        }
      : {
          width: 0,
          zIndex: "0",
        };
  };
  const [currentMobileMenu, setActiveMobileMenu] = useState();
  const setCurrentMobileMenu = (activemenu) => {
    if (activemenu !== currentMobileMenu) {
      setActiveMobileMenu(activemenu);
    } else {
      setActiveMobileMenu("");
    }
  };
  const createMultidimentional = (data, breakAt = 3) => {
    return data.reduce(
      (rows, key, index) =>
        (index % breakAt === 0
          ? rows.push([key])
          : rows[rows.length - 1].push(key)) && rows,
      []
    );
  };
  const renderChilds = (items, breakAt) => {
    const data = createMultidimentional(items, breakAt);
    return data?.map((section, index) => (
      <ul key={index} className="col-md-6">
        {section.map((item, index) => (
          <li key={index}>
            <a
              href={links[item]}
              className="animateLink"
              rel="noopener noreferrer"
              target="_blank"
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
    ));
  };
  const getSupportTitle = () => {
    if (
      userType &&
      (userType === "Registered User" ||
        userType === "Non-Entitled Customer" ||
        userType === "Customer")
    ) {
      return "Hitachi Vantara Customer Support";
    } else {
      return "Hitachi Vantara Support";
    }
  };
  const getCategoryClass = (category, currentMegaMenu) => {
    return (
      category?.categoryLiClass ||
      `drop-down hds-default-nav ${
        currentMegaMenu === category.title ? "open" : ""
      }`
    );
  };

  const getCategory = (category, index, mobile = false) => {
    if (mobile === false) {
      return (
        <li
          key={index}
          onMouseLeave={() => setCurrentMegaMenu("")}
          onMouseOver={(e) => setCurrentMegaMenu(category.title, e)}
          className={getCategoryClass(category, currentMegaMenu)}
        >
          <a
            className={category?.categoryAnchorClass}
            rel="noopener noreferrer"
            target="_blank"
            href={links[category.title]}
          >
            <span itemProp="name">{category.title}</span>
          </a>
          {category?.childrens && category?.childrens.length ? (
            <div
              style={{
                display: currentMegaMenu === category.title ? "block" : "none",
              }}
              className="hds-megaMenuWrapper full-nav-solution"
            >
              <div className="hds-megaMenu content-container clearfix">
                <div
                  style={{
                    maxWidth: "450px",
                    zIndex: 99999,
                    left: leftLocation[category.title] || 0,
                  }}
                  className="mega-menu-box col-xs-12 col-md-5"
                >
                  <div className="row">
                    {renderChilds(category?.childrens, category?.breakAt)}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </li>
      );
    } else {
      return (
        <li
          key={index}
          className={`drop-down hds-default-nav ${
            currentMobileMenu === category.title ? "open" : ""
          }`}
        >
          {category?.childrens?.length > 0 ? (
            <Link
              to={"#"}
              onClick={() => setCurrentMobileMenu(category.title)}
              className="hds-default-nav-anchor"
            >
              <span itemProp="name">{category.title}</span>
              <span className="icon-accordion-closed"></span>
              <span className="icon-accordion-opened"></span>
            </Link>
          ) : (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={links[category.title]}
              className={`hds-default-nav-anchor`}
            >
              <span itemProp="name">{category.title}</span>
            </a>
          )}

          {category?.childrens?.length > 0 ? (
            <div
              style={{
                display:
                  currentMobileMenu === category.title ? "block" : "none",
              }}
            >
              <div className="hds-megaMenu content-container clearfix">
                <div className="mega-menu-box col-xs-12 col-md-5">
                  <ul className="col-md-12">
                    {category?.childrens?.map((item, index) => (
                      <li key={index}>
                        <a
                          href={links[item]}
                          className="animateLink"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {" "}
                          <div
                            className="true"
                            style={{ display: "inline-block" }}
                          >
                            {item}
                          </div>{" "}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
        </li>
      );
    }
  };

  return (
    <section className="brandSiteWrapper">
      <BrandSiteSvg />
      <div className="header aem-GridColumn aem-GridColumn--default--12">
        <div
          style={updateMobileMenuStyle()}
          className="sidebar hds-mobile-navigation"
        >
          <Link
            to={"#"}
            className="closebtn"
            onClick={() => setMobileMenuToggle(false)}
          >
            ×
          </Link>
          <ul className="col-md-9 col-xs-12 removePosRelative globalNavWrapper">
            {brandsitemenus[userType]?.map((category, index) =>
              getCategory(category, index, true)
            )}

            {userData?.id > 0 ? (
              <li
                className={`drop-down hds-default-nav ${
                  currentMobileMenu === "account" ? "open" : ""
                }`}
              >
                <Link
                  to={"#"}
                  onClick={() => setCurrentMobileMenu("account")}
                  className="hds-default-nav-anchor"
                >
                  <span itemProp="name">Account Settings</span>
                  <span className="icon-accordion-closed"></span>
                  <span className="icon-accordion-opened"></span>
                </Link>
                <div
                  style={{
                    display: currentMobileMenu === "account" ? "block" : "none",
                  }}
                >
                  <div className="hds-megaMenu content-container clearfix">
                    <div className="mega-menu-box col-xs-12 col-md-5">
                      <ul className="col-md-12">
                        {getUserMenus(userType)?.map((menu, index) => (
                          <li key={index}>
                            <a
                              href={links[menu]}
                              className="animateLink"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {" "}
                              <div
                                className="true"
                                style={{ display: "inline-block" }}
                              >
                                {menu}
                              </div>{" "}
                            </a>
                          </li>
                        ))}
                        <li>
                          <Link
                            className="logout-link"
                            to={"#"}
                            onClick={signout}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ) : (
              <li className="drop-down hds-default-nav">
                <Link className="hds-default-nav-anchor" to={"/sso_login"}>
                  <span itemProp="name">Login</span>
                </Link>
              </li>
            )}

            <li className="quickNav">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.hitachivantara.com/en-us/home.html"
              >
                <svg className="icon quick-svg">
                  <use xlinkHref="/content/dam/support/images/header-footer.svg#globe"></use>
                </svg>
                <span className="labelText hidden-lg hidden-md hidden">
                  Global
                </span>{" "}
                <span id="localeName">Hitachi Vantara</span>
                <span className="glyphicon glyphicon-new-window hidden-md hidden-lg"></span>
                <span className="caret-arrow hidden-sm hidden-xs hidden">
                  <svg className="icon-geo-overlay">
                    <use
                      className="default"
                      xlinkHref="/content/dam/support/images/header-footer.svg#down-arrow"
                    ></use>
                    <use
                      className="active"
                      xlinkHref="/content/dam/support/images/header-footer.svg#up-arrow"
                    ></use>
                  </svg>
                </span>
              </a>
            </li>
            <li className="quickNav">
              <a
                rel="noopener noreferrer"
                href="https://partnerportal.hitachivantara.com/"
                target="_blank"
              >
                <svg className="icon quick-svg">
                  <use xlinkHref="/content/dam/support/images/header-footer.svg#partner-portal"></use>
                </svg>
                Partner Portal
                <span
                  className="glyphicon glyphicon-new-window hidden-md hidden-lg"
                  aria-hidden="true"
                ></span>
              </a>
            </li>
          </ul>
        </div>
        <div className="one-column">
          <div
            style={{ backgroundColor: ENV_HEAD_COLOR }}
            className="hds-global-header clearfix"
          >
            <div className="header-container content-container">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.hitachivantara.com/en-us/home.html"
              >
                <svg className="hitachi-logo hidden-xs hidden-sm">
                  <use xlinkHref="#hitachi-inspire-the-next-logo"></use>
                </svg>
              </a>
              <a
                href="https://www.hitachivantara.com/en-us/home.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                <svg
                  className="hitachi-logo hidden-md hidden-lg"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <use xlinkHref="#hitachi-inspire-the-next-logo"></use>
                </svg>
              </a>

              <div
                className="hds-quick-navigation hidden-xs hidden-sm"
                id="top-nav-bar"
              >
                <ul>
                  <li className="quickNav">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.hitachivantara.com/en-us/home.html"
                    >
                      <svg
                        className="icon quick-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <use xlinkHref="#globe"></use>
                      </svg>
                      <span className="labelText hidden-lg hidden-md hidden">
                        Global
                      </span>{" "}
                      <span id="localeName">Hitachi Vantara</span>
                      <span className="glyphicon glyphicon-new-window hidden-md hidden-lg"></span>
                      <span className="caret-arrow hidden-sm hidden-xs hidden">
                        <svg
                          className="icon-geo-overlay"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                        >
                          <use
                            className="default"
                            xlinkHref="#down-arrow"
                          ></use>
                          <use className="active" xlinkHref="#up-arrow"></use>
                        </svg>
                      </span>
                    </a>
                  </li>

                  <li className="quickNav">
                    <a
                      href="https://partnerportal.hitachivantara.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <svg
                        className="icon quick-svg"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <use xlinkHref="#partner-portal"></use>
                      </svg>{" "}
                      Partner Portal{" "}
                      <span
                        className="glyphicon glyphicon-new-window hidden-md hidden-lg"
                        aria-hidden="true"
                      ></span>
                    </a>
                  </li>
                </ul>
              </div>

              <button
                className="navbar-toggle collapsed nav-button"
                type="button"
                onClick={() => setMobileMenuToggle(!mobileMenuToggle)}
              >
                <span className="sr-only">Toggle navigation</span>{" "}
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>{" "}
                <span className="icon-bar"></span>
              </button>

              <li className="search-mobile hidden-md hidden-lg">
                <div
                  className="hds-default-nav-anchor"
                  onClick={() =>
                    setMobileSearchFormVisibility(!mobileSearchFormVisibility)
                  }
                >
                  <svg
                    className="search-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <use xlinkHref="#search-mobile"></use>
                  </svg>
                </div>
              </li>
              <div
                style={{
                  display: mobileSearchFormVisibility ? "block" : "none",
                }}
                className="search-mobile-container hidden-md hidden-lg"
              >
                <div className="search-section">
                  <div className="col-sm-12 col-no-pad">
                    <form
                      target="_blank"
                      action="https://support.hitachivantara.com/en/search-results-page.html"
                    >
                      <input
                        type="text"
                        size="20"
                        maxLength="40"
                        name="q"
                        className="search-txt cxglobalsearch"
                      />
                      <input
                        type="submit"
                        value="Search"
                        className="btn-search"
                        id="cxp-gsearch-btn"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{ zIndex: 1050 }}
              className="hds-main-navigation-container "
            >
              <div className="hds-main-navigation">
                <div className="col-md-4">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://support.hitachivantara.com"
                  >
                    <span className="hv-txt-header-logo">
                      {getSupportTitle()}
                    </span>
                  </a>
                </div>
                <ul className="col-xs-12 removePosRelative globalNavWrapper hidden-xs hidden-sm">
                  {brandsitemenus[userType]?.map((category, index) =>
                    getCategory(category, index)
                  )}

                  <li
                    ref={ref}
                    onClick={() => setSearchBox("search")}
                    className="search"
                  >
                    <Link
                      to={"#"}
                      className={searchBox === "search" ? "active" : ""}
                    >
                      <svg
                        className="search-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      >
                        <use xlinkHref="#search"></use>
                      </svg>
                    </Link>

                    <div
                      style={{
                        display: searchBox === "search" ? "block" : "none",
                      }}
                      className="header-search-popup"
                    >
                      <div className="cxp-suggestion">
                        <div className="input-box">
                          <form
                            target="_blank"
                            action="https://support.hitachivantara.com/en/search-results-page.html"
                          >
                            <input
                              autoComplete="off"
                              type="text"
                              placeholder="Search Support"
                              name="q"
                            />
                          </form>
                        </div>
                        <div className="cxp-custom-suggestion cxp-suggest">
                          <h3 className="cxp-suggested-cases-heading">
                            Quick Links
                          </h3>
                          <ul className="cxp-suggested-cases">
                            <li>
                              <a
                                href="https://knowledge.hitachivantara.com/Support_Information/Support_Website/Support_Connect_Overview"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Support Website Overview
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://compatibility.hitachivantara.com/"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Product Compatibility
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://knowledge.hitachivantara.com/Support_Information/More_Info/Product_Life-Cycle_Matrix"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Product Life-Cycle Matrix
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://knowledge.hitachivantara.com/Support_Information/Hitachi_Vantara_Security_Advisories"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Security Advisories
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li
                    onMouseLeave={() => setCurrentMegaMenu("")}
                    onMouseOver={() => setCurrentMegaMenu("user")}
                    className="user-profile"
                  >
                    {userData?.id ? (
                      <>
                        <Link
                          title={userData.full_name}
                          to={"#"}
                          className="hds-default-nav-anchor"
                        >
                          <b>
                            {userData.full_name.replace(/(.{7})..+/, "$1…") +
                              " "}
                          </b>
                          <img
                            alt="user"
                            className="hidden-xs hidden-sm"
                            src="/assets/images/cxp-generic-user-icon.png"
                          />
                        </Link>

                        <div
                          style={{
                            display:
                              currentMegaMenu === "user" ? "block" : "none",
                          }}
                          className="hds-megaMenuWrapper user-menu-box"
                        >
                          <ul>
                            {getUserMenus(userType)?.map((menu, index) => (
                              <li key={index}>
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={links[menu]}
                                >
                                  {menu}
                                </a>
                              </li>
                            ))}
                            <li>
                              <Link
                                className="logout-link"
                                to={"#"}
                                onClick={signout}
                              >
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <Link to={"/sso_login"}>
                        <b>{"Login "}</b>
                        <img
                          alt="user login"
                          className="hidden-xs hidden-sm"
                          src="/assets/images/cxp-generic-user-icon.png"
                        />
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HeaderBrandSite;
