import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import * as serviceWorker from "serviceWorker";
import { Provider } from "react-redux";
import configureStore from "store";
import BrowserCompatible from "components/shared/BrowserCompatible";
import { getBrowser, isSupported } from "utils/detectBrowser";
import "bootstrap/dist/css/bootstrap.min.css";
import "index.scss";
import "App.scss";
import "react-toastify/dist/ReactToastify.css";
//font awsome 4.7
import 'font-awesome/css/font-awesome.min.css';
//font-awesome 5.1
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
// Add all icons to the library so you can use it in your page
library.add(fas, far);
//font awsome 5.1
const browser=getBrowser();
const supported=isSupported(browser);
export const store=configureStore();
ReactDOM.render(
  (supported)?(
    <Provider store={store}>
      <App />
    </Provider>
  ):<BrowserCompatible browserInfo={browser} />
 ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
