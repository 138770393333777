import React from "react";
import { useLocation, withRouter } from "react-router-dom";
import Menus from "./Menus";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import {
  APP_NAME,
  OKTA_TOKEN_INFO,
  LOGGED_IN_USER_INFO,
} from "constants/common";
import authService from "services/authService";
import { getLegacyProductMenu } from "actions/productSearchActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import commonService from "services/commonService";
import history from "../../history";
import {
  doSsoLogin,
  verifyLogin,
  updateAuthLoaderState,
} from "actions/loginActions";
import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";
const commonServiceObj = new commonService();

const Header = (props) => {
  const userData =
    authService && authService.userData
      ? authService.userData
      : JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO));
  const { oktaAuth } = useOktaAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => {
    return state.loginReducer.loginData;
  });
  const [isStateToggled, setStateToggled] = useState(false);

  useEffect(() => {
    if (loginData?.full_name) {
      setStateToggled(true);
    }
  }, [loginData]);

  useEffect(() => {
    if (isStateToggled) {
      dispatch(verifyLogin());
    }
  }, [isStateToggled]);

  useEffect(() => {
    verifyAccessToken();
  }, [location?.pathname]);

  const verifyAccessToken = () => {
    const data = localStorage.getItem(OKTA_TOKEN_INFO);
    const sessionData = JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO));

    const token = data ? JSON.parse(data) : {};
    if (token?.accessToken && token?.accessToken?.accessToken && !sessionData) {
      dispatch(updateAuthLoaderState(true));
      const payload = { encrypted_token: token.accessToken.accessToken };

      commonServiceObj
        .getVerifySsoToken(payload)
        .then(async (result) => {
          if (result.access_token && result.id) {
            result["type"] = "sso";

            dispatch(doSsoLogin(result));
            history.push(history.location.pathname);
            // history.push(history.location.pathname + window.location.search);
          } else {
            oktaAuth.signOut();
            authService.logout();
          }
        })
        .catch((e) => {
          console.log("Some error ocurred at token verification process!");
          dispatch(updateAuthLoaderState(false));
        });
    }
  };

  const handleClick = (e) => {
    props.getLegacyProductMenu([]);
    props.history.push("/menus");
    setTimeout(() => {
      props.history.replace(`/`);
    }, 0);
  };

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand
        as={Link}
        onClick={(event) => handleClick(event)}
        className="navbar-header2 mt-1"
        to=""
      >
        {APP_NAME}
      </Navbar.Brand>
      {userData && userData.id ? (
        <Navbar.Toggle aria-controls="header-navbar-nav" />
      ) : null}
      <Navbar.Collapse className="justify-content-end" id="header-navbar-nav">
        <Nav>
          {" "}
          <Menus />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  ...state.productSearchReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getLegacyProductMenu: (payload) => dispatch(getLegacyProductMenu(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
