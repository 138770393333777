import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//oracle version
export const METHOD_ORACLE_VERSION_LIST = 'oracleversion/pageable/';
export const METHOD_ORACLE_VERSION_CREATE = 'oracleversion/';
export const METHOD_ORACLE_VERSION_UPDATE = 'oracleversion/{ID}/';
export const METHOD_ORACLE_VERSION_DELETE = 'oracleversion/{ID}/';
export const METHOD_ORACLE_VERSION_DETAIL = 'oracleversion/{ID}/';

//volume manager
export const METHOD_VOLUME_MANAGER_LIST = 'volumemanager/pageable/';
export const METHOD_VOLUME_MANAGER_CREATE = 'volumemanager/';
export const METHOD_VOLUME_MANAGER_UPDATE = 'volumemanager/{ID}/';
export const METHOD_VOLUME_MANAGER_DELETE = 'volumemanager/{ID}/';
export const METHOD_VOLUME_MANAGER_DETAIL = 'volumemanager/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;