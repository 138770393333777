import React from "react";
import FormatedData from "components/shared/FormatedData";
import { highlightStringAsterisk } from "utils/matriceHelpers";

const masterFields = [
    {
      masterId: 1,
      masterName: "Operating System",
      masterField:"os_id",
      columns: [
        { title: "Operating System", field: "os_name", sorting: false },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false }
      ]
    },
    {
      masterId: 9,
      masterName: "Analyzer JDK",
      masterField:"jdk_id",
      columns: [
        { title: "Analyzer JDK", field: "jdk_name", sorting: false },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false }
      ]
    },
    {
      masterId: 10,
      masterName: "Detail View JDK",
      masterField:"detail_view_jdk_id",
      columns: [
        { title: "Detail View JDK", field: "detail_view_jdk_name", sorting: false },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false }
      ]
    },
    {
      masterId: 2,
      masterName: "Hypervisor",
      masterField:"hypervisor_id",
      additionalField:{
        type:'multi-select',
        field:'os_exceptions',
        fieldTitle:'Operating System Exceptions',
        listingKey:'os_exceptions'
      },
      columns: [
        { title: "Hypervisor", field: "hypervisor_name", sorting: false,
        width: 140,       
        },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false,
        width: 100,        
        },
        { title: "OS Exceptions" , field:"os_exceptions", sorting: false,            
          render:rowData=>(<FormatedData skipSpaceIfNoAsterisk={true} data={rowData.os_exceptions?highlightStringAsterisk(rowData.os_exceptions,'os_exceptions').split(","):[]} allowedCols="3" />)
        }
      ],      
      editAllowedAllEligibleRows:true
    },
    {
      masterId: 3,
      masterName: "Virtualization Software",
      masterField:"virtualization_software_id",
      columns: [
        {
          title: "Virtualization Software",
          field: "virtualization_software_name",
          sorting: false,
        },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false }
      ],
    },
    {
      masterId: 4,
      masterName: "Hosts",
      masterField:"host_id",
      columns: [
        {
          title: "Hosts",
          field: "host_name",
          sorting: false,
        },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false }
      ],
    },
    {
      masterId: 5,
      masterName: "Switch",
      masterField:"fc_switch_id",
      columns: [
        {
          title: "Switch",
          field: "fc_switch_name",
          sorting: false,
        },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false }
      ],
    },
    {
      masterId: 6,
      masterName: "Storage Array", 
      masterField:"array_customer_version_id",
      additionalField:{
        type:'text',
        field:'microcode',
        fieldTitle:'Microcode',
        required:true,
      },
      columns: [ 
        {
          title: "Storage Array",
          field: "array_customer_version_name",
          sorting: false,
        },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false },
        {
          title: "Microcode",
          field: "microcode",
          sorting: false,
        }
      ],
      editAllowedAllEligibleRows:true
    },
    {
      masterId: 7,
      masterName: "Hitachi NAS",
      masterField:"hnas_id",
      additionalField:{
        type:'text',
        field:'hnas_notes',
        fieldTitle:'Notes'
      },
      columns: [
        { title: "Hitachi NAS", field: "hnas_name", sorting: false },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false },
        { title: "Notes", field: "hnas_notes", sorting: false }
      ],
      editAllowedAllEligibleRows:true
    },
    {
      masterId: 8,
      masterName: "3rd Party Array",
      masterField:"array_customer_version_id",
      columns: [
        { title: "3rd Party Array", field: "array_customer_version_name", sorting: false },
        { title: "Analyzer Version", field: "analyzer_version_name", sorting: false }
      ],
    }
  ];
export default masterFields;