import React, { Component } from "react";
class FileUploadControl extends Component {



  handleChange = (event) => {
    const { minSize, maxsize, allowedTypes } = this.props;//minsize and maxsize comes in kb
    const files = event.target.files;
    const errors = [];
    // loop access files array
    for (var x = 0; x < files.length; x++) {
      const file = files[x];
      const fileSize = (file.size / 1000);//convert bytes into kbs
      if (fileSize >= minSize && fileSize <= maxsize) {
        const fileName = file.name;
        const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
        if (!allowedTypes.includes(ext)) {
          errors.push(`${file.name} should have any of ${allowedTypes} extensions!`);
        }
      } else {
        errors.push(`${file.name} should be between ${minSize}-${maxsize} Kb!`);
      }
    }

    if (errors.length>0) {
      event.target.value = null;
      //event.target.files = null; //commneted as it was breaking the flow in Ie
      this.props.onError(errors);
    }else{
      this.props.onFileChange(files);
    }
  }

  render() {
    
    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const { isMultiple, allowedTypes, refreshToken } = this.props;
    return (
      <div className={(!isIE)?"bulkUpload":null}>
        <input
          type="file"
          key={refreshToken}
          accept={allowedTypes.map((type) => "." + type).join(',')}
          multiple={isMultiple}
          onChange={this.handleChange}
          className="form-control"
        />
      </div>
    );
  }
}
export default FileUploadControl;
