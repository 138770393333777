import { networkService } from "services/networkService";
import {
  METHOD_ADMINISTRATORVERSION_LIST,
  METHOD_ADMINISTRATORVERSION_CREATE,
  METHOD_ADMINISTRATORVERSION_UPDATE,
  METHOD_ADMINISTRATORVERSION_DELETE,
  METHOD_ADMINISTRATORVERSION_DETAIL,  
  METHOD_DOCKER_LIST,
  METHOD_DOCKER_CREATE,
  METHOD_DOCKER_UPDATE,
  METHOD_DOCKER_DELETE,
  METHOD_DOCKER_DETAIL,   
  METHOD_SWITCH_LIST,
  METHOD_SWITCH_CREATE,
  METHOD_SWITCH_UPDATE,
  METHOD_SWITCH_DELETE,
  METHOD_SWITCH_DETAIL
} from "constants/admin/masters/opsAdminsitrator";
class opsAdminsitratorService {

  //AdministratorVersion methods
  getAllAdministratorVersion = (data) => {
   return networkService
     .post(METHOD_ADMINISTRATORVERSION_LIST, data)
     .then((response) => response.data);
 };
 getAdministratorVersionDetail = (id) => {
   const updatedMethodName = METHOD_ADMINISTRATORVERSION_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createAdministratorVersion = (data) => {
    return networkService
      .post(METHOD_ADMINISTRATORVERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateAdministratorVersion = (data, id) => {
    const updatedMethodName = METHOD_ADMINISTRATORVERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteAdministratorVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_ADMINISTRATORVERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  //Docker methods
  getAllDocker = (data) => {
   return networkService
     .post(METHOD_DOCKER_LIST, data)
     .then((response) => response.data);
 };
 getDockerDetail = (id) => {
   const updatedMethodName = METHOD_DOCKER_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createDocker = (data) => {
    return networkService
      .post(METHOD_DOCKER_CREATE, data)
      .then((response) => response.data);
  };
  updateDocker = (data, id) => {
    const updatedMethodName = METHOD_DOCKER_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteDocker = (id, is_delete = false) => {
    const updatedMethodName = METHOD_DOCKER_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  //Switch methods
  getAllSwitch = (data) => {
   return networkService
     .post(METHOD_SWITCH_LIST, data)
     .then((response) => response.data);
 };
 getSwitchDetail = (id) => {
   const updatedMethodName = METHOD_SWITCH_DETAIL.replace("{ID}", id);
   return networkService
     .get(updatedMethodName)
     .then((response) => response.data);
 };
  createSwitch = (data) => {
    return networkService
      .post(METHOD_SWITCH_CREATE, data)
      .then((response) => response.data);
  };
  updateSwitch = (data, id) => {
    const updatedMethodName = METHOD_SWITCH_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteSwitch = (id, is_delete = false) => {
    const updatedMethodName = METHOD_SWITCH_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  
}
export default opsAdminsitratorService;