import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH, MAX_TEXT_LENGTH_200 } from "constants/common";

//vdi software version methods
export const METHOD_VDI_SOFTWARE_VERSION_LIST = 'vdisoftwareversion/pageable/';
export const METHOD_VDI_SOFTWARE_VERSION_CREATE = 'vdisoftwareversion/';
export const METHOD_VDI_SOFTWARE_VERSION_UPDATE = 'vdisoftwareversion/{ID}/';
export const METHOD_VDI_SOFTWARE_VERSION_DELETE = 'vdisoftwareversion/{ID}/';
export const METHOD_VDI_SOFTWARE_VERSION_DETAIL = 'vdisoftwareversion/{ID}/';
export const METHOD_VDI_SOFTWARE_VERSION_DROP = 'vdisoftwareversion/drop/';

//ethernet switch category methods
export const METHOD_ETHERNET_SWITCH_CATEGORY_LIST = 'ethernetswitchcategory/pageable/';
export const METHOD_ETHERNET_SWITCH_CATEGORY_CREATE = 'ethernetswitchcategory/';
export const METHOD_ETHERNET_SWITCH_CATEGORY_UPDATE = 'ethernetswitchcategory/{ID}/';
export const METHOD_ETHERNET_SWITCH_CATEGORY_DELETE = 'ethernetswitchcategory/{ID}/';
export const METHOD_ETHERNET_SWITCH_CATEGORY_DETAIL = 'ethernetswitchcategory/{ID}/';
export const METHOD_ETHERNET_SWITCH_CATEGORY_DROP = 'ethernetswitchcategory/drop/';

//hana solution type methods
export const METHOD_HANA_SOLUTION_TYPE_LIST = 'hanasolutiontype/pageable/';
export const METHOD_HANA_SOLUTION_TYPE_CREATE = 'hanasolutiontype/';
export const METHOD_HANA_SOLUTION_TYPE_UPDATE = 'hanasolutiontype/{ID}/';
export const METHOD_HANA_SOLUTION_TYPE_DELETE = 'hanasolutiontype/{ID}/';
export const METHOD_HANA_SOLUTION_TYPE_DETAIL = 'hanasolutiontype/{ID}/';
export const METHOD_HANA_SOLUTION_TYPE_DROP = 'hanasolutiontype/drop/';

//cpu family methods
export const METHOD_CPU_FAMILY_LIST = 'cpufamily/pageable/';
export const METHOD_CPU_FAMILY_CREATE = 'cpufamily/';
export const METHOD_CPU_FAMILY_UPDATE = 'cpufamily/{ID}/';
export const METHOD_CPU_FAMILY_DELETE = 'cpufamily/{ID}/';
export const METHOD_CPU_FAMILY_DETAIL = 'cpufamily/{ID}/';
export const METHOD_CPU_FAMILY_DROP = 'cpufamily/drop/';

//bios bmc methods
export const METHOD_BIOS_BMC_LIST = 'biosbmc/pageable/';
export const METHOD_BIOS_BMC_CREATE = 'biosbmc/';
export const METHOD_BIOS_BMC_UPDATE = 'biosbmc/{ID}/';
export const METHOD_BIOS_BMC_DELETE = 'biosbmc/{ID}/';
export const METHOD_BIOS_BMC_DETAIL = 'biosbmc/{ID}/';
export const METHOD_BIOS_BMC_DROP = 'biosbmc/drop/';

//hnas methods
export const METHOD_HNAS_LIST = 'hnas/pageable/';
export const METHOD_HNAS_CREATE = 'hnas/';
export const METHOD_HNAS_UPDATE = 'hnas/{ID}/';
export const METHOD_HNAS_DELETE = 'hnas/{ID}/';
export const METHOD_HNAS_DETAIL = 'hnas/{ID}/';
export const METHOD_HNAS_DROP = 'hnas/drop/';

//VDI Solution Type
export const METHOD_VDI_SOLUTION_TYPE_LIST = 'vdisolutiontype/pageable/';
export const METHOD_VDI_SOLUTION_TYPE_CREATE = 'vdisolutiontype/';
export const METHOD_VDI_SOLUTION_TYPE_UPDATE = 'vdisolutiontype/{ID}/';
export const METHOD_VDI_SOLUTION_TYPE_DELETE = 'vdisolutiontype/{ID}/';
export const METHOD_VDI_SOLUTION_TYPE_DETAIL = 'vdisolutiontype/{ID}/';

//VDI Release Version
export const METHOD_VDI_RELEASE_VERSION_LIST = 'vdireleaseversion/pageable/';
export const METHOD_VDI_RELEASE_VERSION_CREATE = 'vdireleaseversion/';
export const METHOD_VDI_RELEASE_VERSION_UPDATE = 'vdireleaseversion/{ID}/';
export const METHOD_VDI_RELEASE_VERSION_DELETE = 'vdireleaseversion/{ID}/';
export const METHOD_VDI_RELEASE_VERSION_DETAIL = 'vdireleaseversion/{ID}/';

//VDI Management Software Version
export const METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_LIST = 'vdimanagementsoftwareversion/pageable/';
export const METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_CREATE = 'vdimanagementsoftwareversion/';
export const METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_UPDATE = 'vdimanagementsoftwareversion/{ID}/';
export const METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_DELETE = 'vdimanagementsoftwareversion/{ID}/';
export const METHOD_VDI_MANAGEMENT_SOFTWARE_VERSION_DETAIL = 'vdimanagementsoftwareversion/{ID}/';

//Server Service Pack
export const METHOD_SERVER_SERVICE_PACK_LIST = 'serverservicepack/pageable/';
export const METHOD_SERVER_SERVICE_PACK_CREATE = 'serverservicepack/';
export const METHOD_SERVER_SERVICE_PACK_UPDATE = 'serverservicepack/{ID}/';
export const METHOD_SERVER_SERVICE_PACK_DELETE = 'serverservicepack/{ID}/';
export const METHOD_SERVER_SERVICE_PACK_DETAIL = 'serverservicepack/{ID}/';

//GPU
export const METHOD_GPU_LIST = 'gpu/pageable/';
export const METHOD_GPU_CREATE = 'gpu/';
export const METHOD_GPU_UPDATE = 'gpu/{ID}/';
export const METHOD_GPU_DELETE = 'gpu/{ID}/';
export const METHOD_GPU_DETAIL = 'gpu/{ID}/';

//SAP RELEASE VERSION
export const METHOD_SAP_RELEASE_VERSION_LIST = 'sapreleaseversion/pageable/';
export const METHOD_SAP_RELEASE_VERSION_CREATE = 'sapreleaseversion/';
export const METHOD_SAP_RELEASE_VERSION_UPDATE = 'sapreleaseversion/{ID}/';
export const METHOD_SAP_RELEASE_VERSION_DELETE = 'sapreleaseversion/{ID}/';
export const METHOD_SAP_RELEASE_VERSION_DETAIL = 'sapreleaseversion/{ID}/';

//RAID Controller
export const METHOD_RAID_CONTROLLER_LIST = 'raidcontroller/pageable/';
export const METHOD_RAID_CONTROLLER_CREATE = 'raidcontroller/';
export const METHOD_RAID_CONTROLLER_UPDATE = 'raidcontroller/{ID}/';
export const METHOD_RAID_CONTROLLER_DELETE = 'raidcontroller/{ID}/';
export const METHOD_RAID_CONTROLLER_DETAIL = 'raidcontroller/{ID}/';

//DRIVE
export const METHOD_DRIVE_LIST = 'drive/pageable/';
export const METHOD_DRIVE_CREATE = 'drive/';
export const METHOD_DRIVE_UPDATE = 'drive/{ID}/';
export const METHOD_DRIVE_DELETE = 'drive/{ID}/';
export const METHOD_DRIVE_DETAIL = 'drive/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;
export const TEXT_MAX_LENGTH_200=MAX_TEXT_LENGTH_200;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;
