import { networkService } from "services/networkService";
import {
  METHOD_ISV_NAME_LIST,
  METHOD_ISV_NAME_CREATE,
  METHOD_ISV_NAME_UPDATE,
  METHOD_ISV_NAME_DELETE,
  METHOD_ISV_NAME_DETAIL,
  METHOD_ISV_APPLICATION_NAME_LIST,
  METHOD_ISV_APPLICATION_NAME_CREATE,
  METHOD_ISV_APPLICATION_NAME_UPDATE,
  METHOD_ISV_APPLICATION_NAME_DELETE,
  METHOD_ISV_APPLICATION_NAME_DETAIL,
  METHOD_ISV_APPLICATION_VERSION_LIST,
  METHOD_ISV_APPLICATION_VERSION_CREATE,
  METHOD_ISV_APPLICATION_VERSION_UPDATE,
  METHOD_ISV_APPLICATION_VERSION_DELETE,
  METHOD_ISV_APPLICATION_VERSION_DETAIL,
  METHOD_HCP_INTERFACE_LIST,
  METHOD_HCP_INTERFACE_CREATE,
  METHOD_HCP_INTERFACE_UPDATE,
  METHOD_HCP_INTERFACE_DELETE,
  METHOD_HCP_INTERFACE_DETAIL,
  METHOD_HCP_CLOUD_SCALE_VERSION_LIST,
  METHOD_HCP_CLOUD_SCALE_VERSION_CREATE,
  METHOD_HCP_CLOUD_SCALE_VERSION_UPDATE,
  METHOD_HCP_CLOUD_SCALE_VERSION_DELETE,
  METHOD_HCP_CLOUD_SCALE_VERSION_DETAIL,  
  METHOD_HCP_ANYWHERE_VERSION_LIST,
  METHOD_HCP_ANYWHERE_VERSION_CREATE,
  METHOD_HCP_ANYWHERE_VERSION_UPDATE,
  METHOD_HCP_ANYWHERE_VERSION_DELETE,
  METHOD_HCP_ANYWHERE_VERSION_DETAIL
} from "constants/admin/masters/hcp";
class HcpService {

  //Isv Name methods
  getAllIsvName = (data) => {
    return networkService
      .post(METHOD_ISV_NAME_LIST, data)
      .then(response => response.data);
  };
  getDetailIsvName = (id) => {
    const updatedMethodName = METHOD_ISV_NAME_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then(response => response.data);
  };
  createIsvName = (data) => {
    return networkService
      .post(METHOD_ISV_NAME_CREATE, data)
      .then(response => response.data);
  };
  updateIsvName = (data, id) => {
    const updatedMethodName = METHOD_ISV_NAME_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then(response => response.data);
  };
  deleteIsvName = (id,is_delete=false) => {
    const updatedMethodName = METHOD_ISV_NAME_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then(response => response.data);
  };

    //Isv Application Name methods
    getAllIsvApplicationName = (data) => {
      return networkService
        .post(METHOD_ISV_APPLICATION_NAME_LIST, data)
        .then(response => response.data);
    };
    getDetailIsvApplicationName = (id) => {
      const updatedMethodName = METHOD_ISV_APPLICATION_NAME_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createIsvApplicationName = (data) => {
      return networkService
        .post(METHOD_ISV_APPLICATION_NAME_CREATE, data)
        .then(response => response.data);
    };
    updateIsvApplicationName = (data, id) => {
      const updatedMethodName = METHOD_ISV_APPLICATION_NAME_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteIsvApplicationName = (id,is_delete=false) => {
      const updatedMethodName = METHOD_ISV_APPLICATION_NAME_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };


    //Isv Application Version methods
    getAllIsvApplicationVersion = (data) => {
      return networkService
        .post(METHOD_ISV_APPLICATION_VERSION_LIST, data)
        .then(response => response.data);
    };
    getDetailIsvApplicationVersion = (id) => {
      const updatedMethodName = METHOD_ISV_APPLICATION_VERSION_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createIsvApplicationVersion = (data) => {
      return networkService
        .post(METHOD_ISV_APPLICATION_VERSION_CREATE, data)
        .then(response => response.data);
    };
    updateIsvApplicationVersion = (data, id) => {
      const updatedMethodName = METHOD_ISV_APPLICATION_VERSION_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteIsvApplicationVersion = (id,is_delete=false) => {
      const updatedMethodName = METHOD_ISV_APPLICATION_VERSION_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };

    
    //HCP Interface methods
    getAllHCPInterface = (data) => {
      return networkService
        .post(METHOD_HCP_INTERFACE_LIST, data)
        .then(response => response.data);
    };
    getDetailHCPInterface = (id) => {
      const updatedMethodName = METHOD_HCP_INTERFACE_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createHCPInterface = (data) => {
      return networkService
        .post(METHOD_HCP_INTERFACE_CREATE, data)
        .then(response => response.data);
    };
    updateHCPInterface = (data, id) => {
      const updatedMethodName = METHOD_HCP_INTERFACE_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteHCPInterface = (id,is_delete=false) => {
      const updatedMethodName = METHOD_HCP_INTERFACE_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };
    
    //HCP for cloud scale version methods
    getAllHCPCloudScaleVersion = (data) => {
      return networkService
        .post(METHOD_HCP_CLOUD_SCALE_VERSION_LIST, data)
        .then(response => response.data);
    };
    getDetailHCPCloudScaleVersion = (id) => {
      const updatedMethodName = METHOD_HCP_CLOUD_SCALE_VERSION_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createHCPCloudScaleVersion = (data) => {
      return networkService
        .post(METHOD_HCP_CLOUD_SCALE_VERSION_CREATE, data)
        .then(response => response.data);
    };
    updateHCPCloudScaleVersion = (data, id) => {
      const updatedMethodName = METHOD_HCP_CLOUD_SCALE_VERSION_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteHCPCloudScaleVersion = (id,is_delete=false) => {
      const updatedMethodName = METHOD_HCP_CLOUD_SCALE_VERSION_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };
    
    //HCP anywhere version methods
    getAllHCPAnywhereVersion = (data) => {
      return networkService
        .post(METHOD_HCP_ANYWHERE_VERSION_LIST, data)
        .then(response => response.data);
    };
    getDetailHCPAnywhereVersion = (id) => {
      const updatedMethodName = METHOD_HCP_ANYWHERE_VERSION_DETAIL.replace("{ID}", id);
      return networkService
        .get(updatedMethodName)
        .then(response => response.data);
    };
    createHCPAnywhereVersion = (data) => {
      return networkService
        .post(METHOD_HCP_ANYWHERE_VERSION_CREATE, data)
        .then(response => response.data);
    };
    updateHCPAnywhereVersion = (data, id) => {
      const updatedMethodName = METHOD_HCP_ANYWHERE_VERSION_UPDATE.replace("{ID}", id);
      return networkService
        .post(updatedMethodName, data)
        .then(response => response.data);
    };
    deleteHCPAnywhereVersion = (id,is_delete=false) => {
      const updatedMethodName = METHOD_HCP_ANYWHERE_VERSION_DELETE.replace("{ID}", id);
      return networkService
        .delete(updatedMethodName,{ data: { is_delete }})
        .then(response => response.data);
    };


}
export default HcpService;
