import { networkService } from "services/networkService";
import {
  METHOD_MANAGEMENT_PACK_VERSION_LIST,
  METHOD_MANAGEMENT_PACK_VERSION_CREATE,
  METHOD_MANAGEMENT_PACK_VERSION_UPDATE,
  METHOD_MANAGEMENT_PACK_VERSION_DELETE,
  METHOD_MANAGEMENT_PACK_VERSION_DETAIL,
} from "constants/admin/masters/vrops";
class ManagementPackVersionService {
  getAll = (data) => {
    return networkService
      .post(METHOD_MANAGEMENT_PACK_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (id) => {
    const updatedMethodName = METHOD_MANAGEMENT_PACK_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_MANAGEMENT_PACK_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, id) => {
    const updatedMethodName = METHOD_MANAGEMENT_PACK_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (id,is_delete=false) => {
    const updatedMethodName = METHOD_MANAGEMENT_PACK_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };
}
export default ManagementPackVersionService;
