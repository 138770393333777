import { networkService } from "services/networkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,
} from "constants/admin/masters/hspcVersion";
class hspcVersionService {
  getAll = (data) => {
    return networkService
      .post(METHOD_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (id) => {
    const updatedMethodName = METHOD_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return networkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, id) => {
    const updatedMethodName = METHOD_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (id,is_delete=false) => {
    const updatedMethodName = METHOD_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName,{ data: { is_delete }})
      .then((response) => response.data);
  };
}
export default hspcVersionService;
