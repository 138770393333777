import React, { Component } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ErrorMessage } from "components/shared/ErrorMessage";
import _ from "lodash";
import replicationService from "services/admin/masters/replicationService";
import Spinner from "components/shared/Spinner";
import Breadcrumb from "components/shared/Breadcrumb";
import { MSG_CREATE_RECORD, MSG_UPDATE_RECORD, TEXT_MIN_LENGTH, TEXT_MAX_LENGTH } from "constants/admin/masters/replication";
import { handleChange, handleTouched,formFailure,getCurrentSlug,trimFields } from "utils/masterHelpers";
const serviceObj = new replicationService();
class Form extends Component {
  constructor(props) {
    super(props);
    this.handleChange=handleChange.bind(this);
    this.handleTouched=handleTouched.bind(this);
    this.formFailure=formFailure.bind(this);
    this.getCurrentSlug=getCurrentSlug.bind(this);
    this.trimFields=trimFields.bind(this);
    this.state = {
      isEditForm: false,
      Id: 0,
      formReady: false,
      isSubmitting: false,
      errors: {},
      touched: {},
      form: {
        name: "",
      }, 
      breadcrumbs:[{ title: 'Replication Product Intermix List', url: '/admin/master/'+this.getCurrentSlug() },
      { title: 'Replication Product Intermix' }
     ]
    };
  }

  componentDidMount = async () => {
    const { Id } = this.props.match.params;
    if (Id > 0) {
      //repopulate the form
      this.setState({
        Id,
        isEditForm: true,
      });
      const Info = await serviceObj.getProductIntermixDetail(Id);
      const form = this.state.form;
      form.name = Info.name;
      this.setState(
        {
          form,
        },
        () => {
          this.setState({
            formReady: true,
          });
        }
      ); //populate in form
    } else {
      //do any preload any api call if needed in caseof add form then make the form ready to load
      this.setState({
        formReady: true,
      });
    }
  };
  

  submitForm = async (e) => {
    e.preventDefault();
    this.trimFields(this.state.form);
    this.setState({
      isSubmitting: true,
    });
    const errors = await this.validateForm();
    if (_.isEmpty(errors)) {
      //now proceed to save user
      const form = this.state.form;
      try {
        let response;
        if (this.state.Id > 0 && this.state.isEditForm) {
          response = await serviceObj.updateProductIntermix(form, this.state.Id);
        } else {
          response = await serviceObj.createProductIntermix(form);
        }
        if (response) {
          toast.success(response.message || (this.state.isEditForm)?MSG_UPDATE_RECORD:MSG_CREATE_RECORD);
          this.props.history.push("/admin/master/"+this.getCurrentSlug());
        }
      } catch (err) {        
           //failure case
           this.formFailure();
           if(err){
             const errors = this.state.errors;
             for (const [field, msg] of Object.entries(err)) {
               errors[field]=msg[0];
             }
             await this.setState({
               errors,
             });
           }
      }
    } else {
      //failure case
      this.formFailure();
    }
  }; 

  validateForm = async () => {
    const errors = {};
    const schema = yup.object().shape({
      name: yup.string().required().min(TEXT_MIN_LENGTH).max(TEXT_MAX_LENGTH),
    });
    // check validity
    await schema
      .validate(this.state.form, { abortEarly: false })
      .catch(function (err) {
        err.inner.map((error) =>
          !errors[error.path] ? (errors[error.path] = error.message) : null
        );
      });

    this.setState({
      errors,
    });
    return errors; //return basicerrors
  };

  render() {
    const {
      isSubmitting,
      form,
      touched,
      errors,
      isEditForm,
      formReady,
      breadcrumbs
    } = this.state;
    return (
      <div className="form-wrap mt-3">
        {isSubmitting ? <Spinner /> : null}
        {formReady ? (
          <div className="form-group">
            <Breadcrumb data={breadcrumbs} isEdit={isEditForm} />
            <form onSubmit={this.submitForm}>
              <div className="form-group">
                <label htmlFor="name">Replication Product Intermix Name</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter replication product intermix name"
                  onChange={this.handleChange}
                  value={form.name}
                  className={`form-control ${
                    touched.name && errors.name
                      ? "is-invalid"
                      : ""
                    }`}
                />
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="name"
                  className="text-danger"
                />
              </div>
              <button
                type="submit"
                className="btn btn-secondary btn-block"
                disabled={isSubmitting}
              >
                {isSubmitting
                  ? "Please wait..."
                  : isEditForm
                    ? "Update"
                    : "Add"}
              </button>
            </form>
          </div>
        ) : (
            <Spinner />
          )}
      </div>
    );
  }
}

export default Form;
