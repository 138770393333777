import React, { Component } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ErrorMessage } from "components/shared/ErrorMessage";
import _ from "lodash";
import switchService from "services/admin/masters/switchService";
import Spinner from "components/shared/Spinner";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  MSG_CREATE_RECORD,
  MSG_UPDATE_RECORD,
  TEXT_MIN_LENGTH,
  TEXT_MAX_LENGTH
} from "constants/admin/masters/switch";
import Breadcrumb from "components/shared/Breadcrumb";
import DataGrid from "components/shared/DataGrid";
import {
  handleChange,
  handleTouched,
  formFailure,
  getCurrentSlug,
  trimFields,
} from "utils/masterHelpers";
const serviceObj = new switchService();
class Form extends Component {
  constructor(props) {
    super(props);
    this.handleChange = handleChange.bind(this);
    this.handleTouched = handleTouched.bind(this);
    this.formFailure = formFailure.bind(this);
    this.getCurrentSlug = getCurrentSlug.bind(this);
    this.trimFields = trimFields.bind(this);
    this.commonFWCols = [
      { title: "Name", field: "switch_firmware_id.name", sorting: false },
      { title: "Position", field: "switch_vendor_position", sorting: false },
      {
        title: "Firmware Series",
        field: "firmware_series_id.name",
        sorting: false,
      },
    ];
    this.state = {
      showPositionModal: false,
      showSupportedModal: false,
      showExceptionModal: false,
      showEosModal: false,
      isEditForm: false,
      Id: 0,
      formReady: false,
      isSubmitting: false,
      errors: {},
      touched: {},
      selectionTitles: {
        firmware_name: "",
        switch_firmware_restriction_name: "",
        exception_firmware_names: [],
        exclusion_firmware_names: [],
      },
      form: {
        switch_vendor_id: 0,
        name: "",
        switch_firmware_start_id: 0,
        switch_firmware_restriction_start_id: 0,
        exception_firmware_ids: [],
        exclusion_firmware_ids: [],
        switch_firmware_restriction_changed:0
      },
      switchDetails:null,//only set in case of edit screen, for watching switch fmwr restriction flag
      switchVendors: [],
      allFirmwaresColumns: [
        {
          title: "Choose",
          field: "id",
          sorting: false,
          render: (rowData) => (
            <input
              checked={
                this.state.form.switch_firmware_start_id ===
                rowData.switch_firmware_id.id
              }
              onChange={(e) => this.handleSelection(e.target.value)}
              type="radio"
              name="switch_firmware_start_id"
              value={JSON.stringify(rowData)}
            />
          ),
        },
        ...this.commonFWCols,
      ],
      supportedSFColumns: [
        {
          title: "Choose",
          field: "id",
          sorting: false,
          render: (rowData) => (
            <input
              checked={
                this.state.form.switch_firmware_restriction_start_id ===
                rowData.switch_firmware_id.id
              }
              onChange={(e) => this.handleSelectionSF(e.target.value)}
              type="radio"
              name="switch_firmware_restriction_start_id"
              value={JSON.stringify(rowData)}
            />
          ),
        },
        ...this.commonFWCols,
      ],
      allFirmwaresPositions: [],
      allFirmwaresExceptionColumns: [
        { title: "Name", field: "switch_firmware_id.name", sorting: false },
        { title: "Position", field: "switch_vendor_position", sorting: false },
        {
          title: "Firmware Series",
          field: "firmware_series_id.name",
          sorting: false,
        },
      ],
      allFirmwaresExceptions: [],
      breadcrumbs: [
        { title: "Switch List", url: "/admin/master/" + this.getCurrentSlug() },
        { title: "Switch" },
      ],
    };
  }

  componentDidMount = async () => {
    const { Id } = this.props.match.params;
    await serviceObj.getAllVendorDropdown().then((response) => {
      this.setState({
        switchVendors: response,
      });
    });
    if (Id > 0) {
      //repopulate the form
      this.setState({
        Id,
        isEditForm: true,
        formReady: false,
      });
      const Info = await serviceObj.getDetail(Id);
      await this.downloadFirmwares(Info.switch_vendor_id);
      const form = this.state.form;
      const selectionTitles = this.state.selectionTitles;
      form.name = Info.name;
      form.switch_vendor_id = Info.switch_vendor_id;
      form.switch_firmware_start_id = Info.switch_firmware_start_id;
      form.switch_firmware_restriction_start_id = Info.switch_firmware_restriction_start_id;
      selectionTitles.firmware_name = this.state.allFirmwaresPositions?.find(
        (row) =>
          parseInt(row.switch_firmware_id.id) ===
          parseInt(Info.switch_firmware_start_id)
      )?.switch_firmware_id?.name;
      selectionTitles.switch_firmware_restriction_name = this.state.allFirmwaresPositions?.find(
        (row) =>
          parseInt(row.switch_firmware_id.id) ===
          parseInt(Info.switch_firmware_restriction_start_id)
      )?.switch_firmware_id?.name;

      form.exception_firmware_ids =
        Info?.exception_firmware_ids?.map((row) => row?.id) || [];
      selectionTitles.exception_firmware_names =
        Info?.exception_firmware_ids?.map((row) => row?.name) || []; //this.state.allFirmwaresExceptions?.filter(row=>Info.exception_firmware_ids?.includes(row.id))?.map(r=>r.name) || [];

      form.exclusion_firmware_ids =
        Info?.exclusion_firmware_ids?.map((row) => row?.id) || [];
      selectionTitles.exclusion_firmware_names =
        Info?.exclusion_firmware_ids?.map((row) => row?.name) || []; 

      await this.setState({
        form,
        selectionTitles,
        formReady: true,
        switchDetails:Info
      }); //populate in form
      await this.prepareException_EosGridData();
    } else {
      //do any preload any api call if needed in caseof add form then make the form ready to load
      this.setState({
        formReady: true,
      });
    }
  };

  prepareException_EosGridData = async () => {
    const {form}=this.state;
    let {allFirmwaresPositions}=this.state;
    //for Exceptions
    let allFirmwaresExceptions = allFirmwaresPositions.filter(
      row =>
        row.switch_firmware_id.id !== form.switch_firmware_start_id
    );
    const selectedExceptions = [...form.exception_firmware_ids];
    allFirmwaresExceptions = allFirmwaresExceptions.map(row =>
      selectedExceptions.includes(row.switch_firmware_id.id)
        ? { ...row, tableData: { checked: true } }
        : { ...row, tableData: { checked: false } }
    );
    //for Exceptions

    //for EOS firmwares
    const selectedEosFW = [...form.exclusion_firmware_ids];
    allFirmwaresPositions = allFirmwaresPositions.map(row =>
      selectedEosFW.includes(row.switch_firmware_id.id)
        ? { ...row, tableData: { checked: true } }
        : { ...row, tableData: { checked: false } }
    );
    //for EOS firmwares

    await this.setState({
      allFirmwaresExceptions,
      allFirmwaresPositions
    });
  };

  filterData = (allData, searchTerm) => {
    searchTerm = searchTerm.toLowerCase();
    const columns = ["switch_firmware_id","switch_vendor_position","firmware_series_id"];
      return allData.filter( row  => {
        let match = false;
        for (let column of columns) {
          if (row[column]) {
            let colValue=row[column];
            if(column==="switch_firmware_id" || column==="firmware_series_id"){
              colValue=row[column].name;
            } 
            if (
              colValue.toString().toLowerCase().indexOf(searchTerm) > -1
            ) {
              match = true;
              break;
            }
          }
        }
        return match;
      });
    
  };

  getSupportedSFList = async query => {
    const { form, allFirmwaresPositions } = this.state;
    return new Promise(async (resolve, reject) => {
      const originalMinimumPosition = allFirmwaresPositions.find(
        (r) => r.switch_firmware_id.id === form.switch_firmware_start_id
      );

      let allData=allFirmwaresPositions.filter(r =>
          r.switch_vendor_position >=
          originalMinimumPosition.switch_vendor_position
      )
      if(query.search) {
          allData = this.filterData(allData, query.search);
      }
      resolve({
        data: allData,
      });
    });
  };

  getFirmwareList = async (field,query) => {
    let excludeData=[];
    let baseData=[];
    if(field==='exception_firmware_ids'){
      excludeData=this.state.form.exclusion_firmware_ids;
      baseData=this.state.allFirmwaresExceptions;
    }else{
      excludeData=this.state.form.exception_firmware_ids;
      baseData=this.state.allFirmwaresPositions;
    }
    let allData=baseData.filter(
      r => !excludeData.includes(r.switch_firmware_id.id)
    )
    if(query.search) {
      allData = this.filterData(allData, query.search);
    }
    return new Promise(async (resolve, reject) => {
      resolve({
        data: allData
      });
    });
  };
  getSFRestrictionChanged = () => {
    const {switchDetails,form}=this.state;
    if(switchDetails){
      if(form.name !== switchDetails.name){
        return 0;
      }
      else if(parseInt(form.switch_vendor_id) !== parseInt(switchDetails.switch_vendor_id)){
        return 0;
      }
      else if(parseInt(form.switch_firmware_start_id) !== parseInt(switchDetails.switch_firmware_start_id)){
        return 0;
      }
      else if(!_.isEqual(form.exception_firmware_ids.sort(),(switchDetails?.exception_firmware_ids?.map((row) => row?.id) || []).sort())){
        return 0;
      }
      else if(!_.isEqual(form.exclusion_firmware_ids.sort(),(switchDetails?.exclusion_firmware_ids?.map((row) => row?.id) || []).sort())){
        return 0;
      }
      else {
        if(parseInt(form.switch_firmware_restriction_start_id) !== parseInt(switchDetails.switch_firmware_restriction_start_id)){
         //it means all fields are same only switch_firmware_restriction_start_id is changes 
         return 1;
        }else{
         return 0;
        }
      }
    }
  }

  submitForm = async e => {
    e.preventDefault();
    this.trimFields(this.state.form);
    this.setState({
      isSubmitting: true,
    });
    const errors = await this.validateForm();
    if (_.isEmpty(errors)) {
      //now proceed to save user
      const {form} = this.state;
      try {
        let response;
        if (this.state.Id > 0 && this.state.isEditForm) {
          form.switch_firmware_restriction_changed=this.getSFRestrictionChanged();//get the flag
          response = await serviceObj.update(form, this.state.Id);
        } else {
          response = await serviceObj.create(form);
        }
        if (response) {
          toast.success(
            response.message || (this.state.isEditForm
              ? MSG_UPDATE_RECORD
              : MSG_CREATE_RECORD)
          );
          this.props.history.push("/admin/master/" + this.getCurrentSlug());
        }
      } catch (err) {
        //failure case
        this.formFailure();
        if (err) {
          const errors = this.state.errors;
          for (const [field, msg] of Object.entries(err)) {
            errors[field] = msg[0];
          }
          await this.setState({
            errors,
          });
        }
      }
    } else {
      //failure case
      this.formFailure();
    }
  };
  handleVendorChange = (e) => {
    //update the form
    const { form, selectionTitles } = this.state;
    form[e.target.name] = e.target.value;
    form.switch_firmware_start_id = 0;
    form.exception_firmware_ids = [];
    form.switch_firmware_restriction_start_id = 0;
    form.exclusion_firmware_ids = [];
    selectionTitles.firmware_name = "";
    selectionTitles.exception_firmware_names = [];
    selectionTitles.switch_firmware_restriction_name = "";
    selectionTitles.exclusion_firmware_names = [];
    this.setState({
      form,
      allFirmwaresPositions: [],
      allFirmwaresExceptions: [],
      selectionTitles,
    });
    if (e.target.value > 0) {
      //get the selected vendors data to populate for postion and exception selection
      this.setState({
        formReady: false,
      });
      this.downloadFirmwares(e.target.value);
    }
    //mark this input as touched
    this.handleTouched(e.target.name);
    //now call the validations to keep the errors up to date
    this.validateForm();
  };
  downloadFirmwares = async vendor_id => {
    const sortData = [
      { field: "firmware_series_id.id", dir: "desc" },
      { field: "switch_vendor_position", dir: "desc" },
    ];
    const reqParams = {
      take: -1,
      sort: sortData,
      filter: [
        {
          field: "switch_vendor_id",
          type: "int",
          value: vendor_id,
          operator: "eq",
        },
        {
          field: "is_deleted",
          type: "bool",
          value: "False",
          operator: "eq",
        },
      ],
    };
    await serviceObj.getAllFirmwareMapping(reqParams).then(async response => {
      this.setState({
        formReady: true,
      });
      const allItems = this.addgroupColor(response.items);
      await this.setState({ allFirmwaresPositions: allItems });
    });
  };
  addgroupColor = (items) => {
    const uniqueSeries = [];
    let lastColor;
    const color1 = "#FFFFFF";
    const color2 = "#F4F3F3";
    items.map((item) => {
      const found = uniqueSeries.find(
        (sId) => sId === item.firmware_series_id.id
      );
      if (found) {
        item.backgroundColor = lastColor;
      } else {
        uniqueSeries.push(item.firmware_series_id.id);
        if (lastColor === undefined) {
          lastColor = color1;
        } else {
          lastColor = lastColor === color1 ? color2 : color1;
        }
        item.backgroundColor = lastColor;
      }
      return item;
    });
    return items;
  };
  rowStyle = (rowData) => {
    return {
      backgroundColor: rowData.backgroundColor,
    };
  };

  handleModalClose = (e) => {
    this.setState({
      showPositionModal: false,
      showSupportedModal: false,
      showExceptionModal: false,
      showEosModal: false,
    });
  };

  openModal = async (key) => {
    //const form = this.state.form;
    //await this.downloadFirmwares(form.switch_vendor_id); //get all the firmware on popup open
    this.setState({
      [key]: true,
    });
  };

  checkFirmwareExistAsException = async (switch_firmware) => {
    const form = this.state.form;
    const selectionTitles = this.state.selectionTitles;
    if (form.exception_firmware_ids.includes(switch_firmware.id)) {
      if (
        window.confirm(
          switch_firmware.name +
            " exists in exceptions,click ok to exclude that from exception(s) and continue."
        )
      ) {
        const idIndex = form.exception_firmware_ids.indexOf(switch_firmware.id);
        if (idIndex !== -1) {
          form.exception_firmware_ids.splice(idIndex, 1);
        }
        const nameIndex = selectionTitles.exception_firmware_names.indexOf(
          switch_firmware.name
        );
        if (nameIndex !== -1) {
          selectionTitles.exception_firmware_names.splice(nameIndex, 1);
        }
        await this.setState({
          form,
          selectionTitles,
        });
        return true;
      } else {
        return false;
      }
    }
    return true;
  };
  handleSelection = async e => {
    e = JSON.parse(e);
    //cross check the start position already exists in exceptions list
    const proceed = await this.checkFirmwareExistAsException(
      e.switch_firmware_id
    );
    if (proceed) {
      const { form, selectionTitles, allFirmwaresPositions,isEditForm } = this.state;
      form.switch_firmware_start_id = e.switch_firmware_id.id;
      selectionTitles.firmware_name = e.switch_firmware_id.name;
      if (!isEditForm) {
        //add case autopopulate the supported sf start id
        form.switch_firmware_restriction_start_id = e.switch_firmware_id.id;
        selectionTitles.switch_firmware_restriction_name =
          e.switch_firmware_id.name;
      } else {
        if (form.switch_firmware_restriction_start_id > 0) {
          //specially for edit case if user
          const originalMinimumPosition = allFirmwaresPositions.find(
            (r) => r.switch_firmware_id.id === form.switch_firmware_start_id
          );
          const supportedPosition = allFirmwaresPositions.find(
            (r) =>
              r.switch_firmware_id.id ===
              form.switch_firmware_restriction_start_id
          );
          if (
            supportedPosition.switch_vendor_position <
            originalMinimumPosition.switch_vendor_position
          ) {
            //reset
            form.switch_firmware_restriction_start_id = 0;
            selectionTitles.switch_firmware_restriction_name = "";
          }
        }
      }
      this.setState({
        form,
        selectionTitles,
      });
      this.prepareException_EosGridData(); //re-prepare the exceptions data, to exclude the start position
    }
    this.handleModalClose();
    this.validateForm();
  };
  handleSelectionSF = async e => {
    e = JSON.parse(e);
    const { form, selectionTitles } = this.state;
    form.switch_firmware_restriction_start_id = e.switch_firmware_id.id;
    selectionTitles.switch_firmware_restriction_name =
      e.switch_firmware_id.name;
    this.setState({
      form,
      selectionTitles,
    });
    this.handleModalClose();
    this.validateForm();
  };
  handleExceptionSelection = e => {
    const form = this.state.form;
    const selectionTitles = this.state.selectionTitles;
    const exception_firmware_ids = [];
    const exception_firmware_names = [];
    e.map( row  => {
      exception_firmware_ids.push(row.switch_firmware_id.id);
      exception_firmware_names.push(row.switch_firmware_id.name);
      return true;
    });
    form.exception_firmware_ids = exception_firmware_ids;
    selectionTitles.exception_firmware_names = exception_firmware_names;
    this.setState({
      form,
      selectionTitles,
    });
    this.validateForm();
  };
  handleEosSelection = e => {
    const form = this.state.form;
    const selectionTitles = this.state.selectionTitles;
    const exclusion_firmware_ids = [];
    const exclusion_firmware_names = [];
    e.map( row  => {
      exclusion_firmware_ids.push(row.switch_firmware_id.id);
      exclusion_firmware_names.push(row.switch_firmware_id.name);
      return true;
    });
    form.exclusion_firmware_ids = exclusion_firmware_ids;
    selectionTitles.exclusion_firmware_names = exclusion_firmware_names;
    this.setState({
      form,
      selectionTitles,
    });
    this.validateForm();
  };

  validateForm = async () => {
    const errors = {};
    const schema = yup.object().shape({
      switch_vendor_id: yup
        .number()
        .required()
        .positive("please select the vendor."),
      name: yup
        .string()
        .required("switch name is a required field.")
        .min(TEXT_MIN_LENGTH)
        .max(TEXT_MAX_LENGTH),
      switch_firmware_start_id: yup
        .number()
        .required()
        .positive("please select original minimum supported firmware."),
      switch_firmware_restriction_start_id: yup
        .number()
        .required()
        .positive(
          "please select the supported switch firmware start position."
        ),
      //exception_firmware_ids: yup.array().required('please select atleast 1 exception.'),
    });
    // check validity
    await schema
      .validate(this.state.form, { abortEarly: false })
      .catch(function (err) {
        err.inner.map((error) =>
          !errors[error.path] ? (errors[error.path] = error.message) : null
        );
      });

    this.setState({
      errors,
    });
    return errors; //return basicerrors
  };

  render() {
    const {
      showPositionModal,
      showSupportedModal,
      showExceptionModal,
      showEosModal,
      isSubmitting,
      form,
      touched,
      errors,
      isEditForm,
      formReady,
      switchVendors,
      selectionTitles,
      breadcrumbs,
    } = this.state;
    return (
      <div className="form-wrap mt-3">
        {isSubmitting ? <Spinner /> : null}
        {!formReady ? <Spinner /> : null}
        <div className="form-group">
          <Breadcrumb data={breadcrumbs} isEdit={isEditForm} />
          <form onSubmit={this.submitForm}>
            <div className="form-group">
              <label htmlFor="switch_vendor_id">Switch Vendor</label>
              <select
                name="switch_vendor_id"
                onChange={this.handleVendorChange}
                value={form.switch_vendor_id}
                disabled={isEditForm}
                className={`form-control ${
                  touched.switch_vendor_id && errors.switch_vendor_id
                    ? "is-invalid"
                    : ""
                }`}
              >
                <option key="-1" value="0">
                  --SELECT--
                </option>
                {switchVendors.length
                  ? switchVendors.map((vendor, index) => (
                      <option key={index} value={vendor.id}>
                        {vendor.name}
                      </option>
                    ))
                  : null}
              </select>
              <ErrorMessage
                tag="div"
                touched={touched}
                errors={errors}
                name="switch_vendor_id"
                className="text-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">Switch Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter switch name"
                onChange={this.handleChange}
                value={form.name}
                className={`form-control ${
                  touched.name && errors.name ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                tag="div"
                touched={touched}
                errors={errors}
                name="name"
                className="text-danger"
              />
            </div>
            {this.state.form.switch_vendor_id > 0 ? (
              <div className="form-group">
                <label className="form-label col-form-label">
                  Original Minimum Supported Firmware :
                  <span className="labelText">
                    {selectionTitles.firmware_name
                      ? selectionTitles.firmware_name
                      : "Not Selected"}
                  </span>
                </label>
                <Link
                  to={"#"}
                  onClick={(e) => this.openModal("showPositionModal")}
                >
                  Change Option
                </Link>
                <ErrorMessage
                  tag="div"
                  touched={touched}
                  errors={errors}
                  name="switch_firmware_start_id"
                  className="text-danger"
                />
              </div>
            ) : null}
            {this.state.form.switch_firmware_start_id > 0 ? (
              <>
                <div className="form-group">
                  <label className="form-label col-form-label">
                    Supported Switch Firmware – Start Position :
                    <span className="labelText">
                      {selectionTitles.switch_firmware_restriction_name
                        ? selectionTitles.switch_firmware_restriction_name
                        : "Not Selected"}
                    </span>
                  </label>
                  <Link
                    to={"#"}
                    onClick={(e) => this.openModal("showSupportedModal")}
                  >
                    Change Option
                  </Link>
                  <ErrorMessage
                    tag="div"
                    touched={touched}
                    errors={errors}
                    name="switch_firmware_restriction_start_id"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label col-form-label">
                    Exceptions :
                    <span className="labelText">
                      {selectionTitles.exception_firmware_names.length > 0
                        ? selectionTitles.exception_firmware_names.join(", ")
                        : "Not Selected"}
                    </span>
                  </label>
                  <Link
                    to={"#"}
                    onClick={(e) => this.openModal("showExceptionModal")}
                  >
                    Change Option
                  </Link>
                  <ErrorMessage
                    tag="div"
                    touched={touched}
                    errors={errors}
                    name="exception_firmware_ids"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label col-form-label">
                    EOS Firmwares :
                    <span className="labelText">
                      {selectionTitles.exclusion_firmware_names.length > 0
                        ? selectionTitles.exclusion_firmware_names.join(", ")
                        : "Not Selected"}
                    </span>
                  </label>
                  <Link
                    to={"#"}
                    onClick={(e) => this.openModal("showEosModal")}
                  >
                    Change Option
                  </Link>
                  <ErrorMessage
                    tag="div"
                    touched={touched}
                    errors={errors}
                    name="exclusion_firmware_ids"
                    className="text-danger"
                  />
                </div>
              </>
            ) : null}

            <button
              type="submit"
              className="btn btn-secondary btn-block"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Please wait..." : isEditForm ? "Update" : "Add"}
            </button>
          </form>
        </div>

        <Modal
          show={showPositionModal}
          scrollable={true}
          size={"lg"}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Choose Original Minimum Supported Firmware
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DataGrid
              showToolBar={true}
              rowStyle={this.rowStyle}
              columns={this.state.allFirmwaresColumns}
              dataHandler={this.state.allFirmwaresPositions}
              filtering={false}
              paging={false}
              maxBodyHeight={"300px"}
              headerStyle={{ backgroundColor: "#e9ecef" }}
            ></DataGrid>
          </Modal.Body>
        </Modal>

        <Modal
          show={showSupportedModal}
          scrollable={true}
          size={"lg"}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Choose Supported Switch Firmware – Start Position
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DataGrid
              showToolBar={true}
              rowStyle={this.rowStyle}
              columns={this.state.supportedSFColumns}
              dataHandler={this.getSupportedSFList}
              filtering={false}
              paging={false}
              maxBodyHeight={"300px"}
              headerStyle={{ backgroundColor: "#e9ecef" }}
            ></DataGrid>
          </Modal.Body>
        </Modal>

        <Modal
          show={showExceptionModal}
          scrollable={true}
          size={"lg"}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Exceptions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DataGrid
              showToolBar={true}
              rowStyle={this.rowStyle}
              columns={this.state.allFirmwaresExceptionColumns}
              dataHandler={query=>this.getFirmwareList('exception_firmware_ids',query)}
              filtering={false}
              paging={false}
              maxBodyHeight={"280px"}
              headerStyle={{ backgroundColor: "#e9ecef" }}
              selection={true}
              selectionHandler={this.handleExceptionSelection}
            ></DataGrid>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={this.handleModalClose}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showEosModal}
          scrollable={true}
          size={"lg"}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Select EOS Firmwares</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DataGrid
              showToolBar={true}
              rowStyle={this.rowStyle}
              columns={this.state.allFirmwaresExceptionColumns}
              dataHandler={query=>this.getFirmwareList('exclusion_firmware_ids',query)}
              filtering={false}
              paging={false}
              maxBodyHeight={"280px"}
              headerStyle={{ backgroundColor: "#e9ecef" }}
              selection={true}
              selectionHandler={this.handleEosSelection}
            ></DataGrid>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={this.handleModalClose}
            >
              Ok
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Form;
