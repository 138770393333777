const FILTERS = {
    "mf-ficon": {
        hba: {
            title: 'z-Series CHL Type',
            icon: 'hba.png'
        },
        hba_driver: {
            title: 'Driver Level',
            icon: 'hba_driver.png'
        },
        switch: {
            title: 'Switch',
            icon: 'switch.png'
        },
        switch_sfp_array_if: {
            title: 'Switch SFP Speed',
            icon: 'array.png'
        },
        switch_firmware: {
            title: 'Switch Firmware',
            icon: 'firmware.png'
        },
        array_customer_version: {
            title: 'Array',
            icon: 'array.png'
        },
        array_if: {
            title: 'Array Interface',
            icon: 'array_if.png'
        },
        fec: {
            title: 'FEC',
            icon: 'hba.png'
        },
        edif_fces_enabled: {
            title: 'EDiF/FCES Enabled',
            iconClass: 'network-wired'
        }
    }
}
export default FILTERS;