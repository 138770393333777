import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";

//administratorversion
export const METHOD_ADMINISTRATORVERSION_LIST = 'ocadministratorversion/pageable/';
export const METHOD_ADMINISTRATORVERSION_CREATE = 'ocadministratorversion/';
export const METHOD_ADMINISTRATORVERSION_UPDATE = 'ocadministratorversion/{ID}/';
export const METHOD_ADMINISTRATORVERSION_DELETE = 'ocadministratorversion/{ID}/';
export const METHOD_ADMINISTRATORVERSION_DETAIL = 'ocadministratorversion/{ID}/';

//docker
export const METHOD_DOCKER_LIST = 'ocdocker/pageable/';
export const METHOD_DOCKER_CREATE = 'ocdocker/';
export const METHOD_DOCKER_UPDATE = 'ocdocker/{ID}/';
export const METHOD_DOCKER_DELETE = 'ocdocker/{ID}/';
export const METHOD_DOCKER_DETAIL = 'ocdocker/{ID}/';


//switch
export const METHOD_SWITCH_LIST = 'ocswitch/pageable/';
export const METHOD_SWITCH_CREATE = 'ocswitch/';
export const METHOD_SWITCH_UPDATE = 'ocswitch/{ID}/';
export const METHOD_SWITCH_DELETE = 'ocswitch/{ID}/';
export const METHOD_SWITCH_DETAIL = 'ocswitch/{ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;