import React from 'react'
import { Tabs, Tab } from "react-bootstrap";
import { MEGAMENU_TAB_OPTIONS } from "constants/common";
import { getMegaMenuOptions } from "actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useHistory } from "react-router-dom";



const TabWrapper =({ children }) =>{
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    
    const megaMenuOption = useSelector((state) => {
        return state.commonReducer.megaMenuOption;
      });
 
    const setTabKeyFunction = (key) => {
    // dispatch action to update reducer with tab key
    dispatch(getMegaMenuOptions(key));
   if(location.pathname === "/admin/datapath-export"){
      history.push("/admin/datapath-export");
    }
    else if(location.pathname === "/admin/bulk-upload"){
      history.push("/admin/bulk-upload");
    }
    else {
      history.push("/");
    }
  };
  return (
   
    <div className="row">
        <div className="col-lg-12 customTab">
          <Tabs
            defaultActiveKey={megaMenuOption}
            onSelect={(k) => setTabKeyFunction(k)}
            mountOnEnter
            unmountOnExit
          >

            {MEGAMENU_TAB_OPTIONS.map((tab) => (
              <Tab key={tab} eventKey={tab} title={tab} >
                <div id="page-content" className="container-block">
                    {children}
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
   
  )
}

export default TabWrapper;