import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import _ from "lodash";
import Spinner from "components/shared/Spinner";
import { uploadFile, getUserWiseProducts } from "actions/bulkUploadActions";
import {
  ERROR_FORM_INCOMPLETE,
} from "constants/admin/bulkUpload";
import FileUploadControl from "components/shared/FileUploadControl";
import DataGrid from "components/shared/DataGrid";
import uploadService from "services/uploadService";
import MegaMenu from "components/shared/ManageDatapathMegaMenu";
import { DATAPATH_ACTIONS } from "constants/productMatrix";
import { formatDate } from "utils/utils";
import JobProgressStatus from "containers/Admin/masters/job-progress/JobProgressStatus";
import TabWrapper from "containers/Pages/TabWrapper/TabWrapper";
const uploadServiceObj = new uploadService();
class BulkUpload extends Component {
  constructor(props) {
    super(props);
    this.formatDate=formatDate.bind(this);
    if (_.isEmpty(props.products)) {
      props.getUserWiseProducts();
    }
    this.state = {
      defaultMegaMenuLabel:'Bulk Upload For',
      loadingTable: false,
      downloading: false,
      isSubmitting: false,
      productId: 0,
      actionId: 1,//default value
      selectedFile: null,
      errors: [],
      refreshToken: Date.now(),
      //datatable states
      filtering: true,
      columns: [
        { title: "Id", field: "id", filtering: false },
        {
          title: "Uploaded File",
          field: "file_name",
          render: (rowData) => <button className="btn btn-sm p-0 btn-link" onClick={(e) => { e.stopPropagation(); this.downloadFile(rowData.file_url, rowData.file_name) }}>{rowData.file_name}</button>,
        },
        {
          title: "Result File",
          field: "result_file_name",
          filtering: false,
          render: (rowData) => <button className="btn btn-sm p-0 btn-link" onClick={(e) => { e.stopPropagation(); this.downloadFile(rowData.result_file_url, rowData.result_file_name) }}>{rowData.result_file_name}</button>,
        },
        { title: "Uploaded Records", field: "total_no_of_uploaded_records", filtering: false },
        {
          title: "Success Records",
          field: "total_no_of_successfull_records",
          filtering: false
        },
        { title: "Failed Records", field: "total_no_of_failed_records", filtering: false },
        { title: "Status", field: "status", filtering: false },
        { title: "Uploaded By", field: "created_by.full_name", filtering: false },
        {
          title: "Uploaded On",
          field: "started_on",
          filtering: false,
          render: (rowData) => this.formatDate(rowData.started_on),
        },

      ],
      pageSize: 20,
      uniqueRowColumn: "id",
      enableSubTable: true,
      subTableColumns: [
        { title: "Status", field: "status", width: "47%" },
        { title: "Updated Date",
          field: "created_date",
          width: "50%",
          render: (rowData) => this.formatDate(rowData.created_date),
        },
      ],
      subTableDataCol: "logs",
    };
  }
 

  handleChangeFile = (files) => {
    this.setState({
      selectedFile: files[0] || null,
      errors: []
    });
  };
  handleChangeMenu = (selectedProduct) => {
    let productID = null;
    if(selectedProduct.category === "Products"){
      productID = selectedProduct.id;
    } else if(selectedProduct.category === "Solutions" && selectedProduct.solution_id){
      productID = selectedProduct.solution_id;
      
    } else {
      productID = selectedProduct.id;
    }
    if (selectedProduct.id) {
      this.setState({ productId:productID, refreshToken: Date.now(),actionId:1 });
    }
  };
  handleChange = (e) => {
      this.setState({ 
        [e.target.name]:e.target.value,
        refreshToken: Date.now()
      });
  };
  handleRefresh = (e) => {
    this.setState({
      refreshToken: Date.now(),
    });
  }
  handleErrors = (data) => {
    this.setState({ errors: data });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.state.productId > 0 && (this.state.selectedFile !== null && this.state.selectedFile !== undefined)) {
      this.setState({ errors: [] });
      this.setState({
        isSubmitting: true,
      });
      const data = new FormData();
      data.append("action_id",this.state.actionId);
      data.append("file", this.state.selectedFile);
      const result = await this.props.uploadFile(this.state.productId,data);
      if (result) {
        this.setState({
          isSubmitting: false,
          selectedFile: null,
          refreshToken: Date.now(),
        });
        toast.success(result.message);
      } else {
        this.setState({
          isSubmitting: false,
          selectedFile: null,
          refreshToken: Date.now(),
        });
      }
    } else {
      this.setState({ errors: [ERROR_FORM_INCOMPLETE] });
    }
  };

  round = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  downloadFile = async (url, filename) => {
    this.setState({
      downloading: true
    });
    await uploadServiceObj.downloadFile(url, filename);
    this.setState({
      downloading: false
    });
  };
  downloadTemplate = async () => {
    this.setState({
      downloading: true
    });
    const payload = new FormData();
    payload.append("action_id",this.state.actionId);
    await uploadServiceObj.downloadTemplate(this.state.productId,payload);
    this.setState({
      downloading: false
    });
  };


  handlePagingData = (query) => {
    this.setState({
      loadingTable: true
    })
    return new Promise((resolve, reject) => {
      let sortData = [{ "field": "id", "dir": "desc" }]; //default sorting
      if (!_.isEmpty(query.orderBy)) {
        sortData = [{
          field: query.orderBy.field,
          dir: query.orderDirection,
        }];
      }
      this.setState({ pageSize: query.pageSize }, () => {
        let filters = [{
          field: "product_id",
          type: "int",
          value: this.state.productId,
          operator: "eq",
        },
        {
          field: "action_id",
          type: "int",
          value: this.state.actionId,
          operator: "eq",
        }];
        if (!_.isEmpty(query.filters)) {
          query.filters.map((filter) =>
            filters.push({
              field: filter.column.field,
              type: "string",
              value: filter.value,
              operator: "eq",
            })
          );
        }
        const reqParams = {
          take: query.pageSize,
          skip: query.pageSize * query.page,
          sort: sortData,
          filter: filters,
        };
        uploadServiceObj.getUploadHistory(reqParams).then((response) => {
          this.setState({
            loadingTable: false
          })
          if(!_.isEmpty(response) && response?.total){
            resolve({
              data: response.items,
              page: query.page,
              totalCount: response.total,
            });
          }else{
            resolve({
              data: [],
              page: query.page,
              totalCount: 0,
            });
          }
         
        })
      });
    });
  }
  render() {
    const { loading, products, uploading, upload_progress } = this.props;
    const { isSubmitting, errors, downloading, loadingTable, productId, actionId, 
            columns, refreshToken, uniqueRowColumn, pageSize, filtering, enableSubTable,
            subTableColumns, subTableDataCol, defaultMegaMenuLabel } = this.state;
    return (
      <>
        {loading ? <Spinner /> : null}
        {uploading ? <Spinner /> : null}
        {downloading ? <Spinner /> : null}
        {loadingTable ? <Spinner /> : null}
        <TabWrapper>
        <div className="form-wrap mt-3">
        <div className="form-group">
                <JobProgressStatus />
            </div>
          <div className="row mega-row p-1 mt-3">
            <div > 
              <div className="form-group">
                <MegaMenu
                defaultOpenState={(productId>0)?false:true}
                alwaysOpenStates={[defaultMegaMenuLabel]}
                  data={products}
                  onChangeMenu={this.handleChangeMenu}
                  defaultLabel={defaultMegaMenuLabel}
                />
              </div>
            </div>



            {
              productId > 0 ? (
                <div >
                  <div className="input-group col-sm-12 mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">Upload Action</span>
                    </div>
                    <select name="actionId" value={actionId} onChange={this.handleChange} className="form-control" placeholder="Choose Action">
                      { DATAPATH_ACTIONS.map((action,index)=>(
                        <option key={index} value={action.id}>{action.title}</option>
                      ))
                      }
                    </select>
                  </div>
                </div>
              ) : null
            }
          </div>
          {productId > 0 ? (
            <>
              <Form.Group as={Row} controlId="fileUpload">
                <Col sm={12}>
                  <Form.Label>File (.csv) <button onClick={this.downloadTemplate} className="btn btn-sm p-0 btn-link">Download Template</button></Form.Label>
                  <FileUploadControl
                    isMultiple={false}
                    minSize={0.1}
                    maxsize={100000}
                    refreshToken={refreshToken}
                    allowedTypes={["csv"]}
                    onError={this.handleErrors}
                    onFileChange={this.handleChangeFile}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm={12}>
                  {(errors && errors.length > 0)
                    ? errors.map((err, index) => (
                      <div key={index} className="text-danger">
                        {err}
                      </div>
                    ))
                    : null}
                  {isSubmitting && upload_progress < 100 ? (
                    <div
                      className="progress-bar bg-dark progress-bar-striped progress-bar-animated"
                      style={{ width: upload_progress + "%" }}
                    >
                      {this.round(upload_progress) + "%"}
                    </div>
                  ) : (
                      null
                    )}
                </Col>
              </Form.Group>
              <Form.Group as={Row}>

                <Col sm={1}>
                  <Link to="#" onClick={this.handleRefresh} className="btnReset">
                    <i className="fa fa-refresh"></i> Refresh
                  </Link>
                </Col>

                <Col sm={11}>
                  <input
                    onClick={this.handleSubmit}
                    type="button"
                    value={isSubmitting ? "Uploading..." : "Upload"}
                    disabled={isSubmitting || (errors && errors.length > 0)}
                    className="btn btn-secondary btn-block"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm={12}>
                  <DataGrid
                    columns={columns}
                    uniqueRowColumn={uniqueRowColumn}
                    dataHandler={this.handlePagingData}
                    pageSize={pageSize}
                    filtering={filtering}
                    enableSubTable={enableSubTable}
                    subTableColumns={subTableColumns}
                    subTableDataCol={subTableDataCol}
                    refreshToken={refreshToken}
                  ></DataGrid>
                </Col>
              </Form.Group>
            </>
          ) : null}
        </div>
        </TabWrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.bulkUploadReducer,
  ...state.commonReducer,
});


const mapDispatchToProps = (dispatch) => ({
  getUserWiseProducts: () => dispatch(getUserWiseProducts()),
  uploadFile: (formData, productId) =>
    dispatch(uploadFile(formData, productId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload);
