import { networkService } from "services/networkService";
import {
  METHOD_HRPC_VERSION_LIST,
  METHOD_HRPC_VERSION_CREATE,
  METHOD_HRPC_VERSION_UPDATE,
  METHOD_HRPC_VERSION_DELETE,
  METHOD_HRPC_VERSION_DETAIL,
  METHOD_CONTAINER_ORCHESTRATOR_LIST,
  METHOD_CONTAINER_ORCHESTRATOR_CREATE,
  METHOD_CONTAINER_ORCHESTRATOR_UPDATE,
  METHOD_CONTAINER_ORCHESTRATOR_DELETE,
  METHOD_CONTAINER_ORCHESTRATOR_DETAIL,
  METHOD_ORCHESTRATOR_VERSION_LIST,
  METHOD_ORCHESTRATOR_VERSION_CREATE,
  METHOD_ORCHESTRATOR_VERSION_UPDATE,
  METHOD_ORCHESTRATOR_VERSION_DELETE,
  METHOD_ORCHESTRATOR_VERSION_DETAIL,
} from "constants/admin/masters/hrpc";

class HrpcService {
  // Hrpc version
  getAllHrpcVersion = (data) => {
    return networkService
      .post(METHOD_HRPC_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getHrpcVersionDetail = (id) => {
    const updatedMethodName = METHOD_HRPC_VERSION_DETAIL.replace("{ID}", id);
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createHrpcVersion = (data) => {
    return networkService
      .post(METHOD_HRPC_VERSION_CREATE, data)
      .then((response) => response.data);
  };
  updateHrpcVersion = (data, id) => {
    const updatedMethodName = METHOD_HRPC_VERSION_UPDATE.replace("{ID}", id);
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteHrpcVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_HRPC_VERSION_DELETE.replace("{ID}", id);
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };

  //  Container Orchestrator
  getAllContainerOrchestrator = (data) => {
    return networkService
      .post(METHOD_CONTAINER_ORCHESTRATOR_LIST, data)
      .then((response) => response.data);
  };
  getContainerOrchestratorDetail = (id) => {
    const updatedMethodName = METHOD_CONTAINER_ORCHESTRATOR_DETAIL.replace(
      "{ID}",
      id
    );
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createContainerOrchestrator = (data) => {
    return networkService
      .post(METHOD_CONTAINER_ORCHESTRATOR_CREATE, data)
      .then((response) => response.data);
  };
  updateContainerOrchestrator = (data, id) => {
    const updatedMethodName = METHOD_CONTAINER_ORCHESTRATOR_UPDATE.replace(
      "{ID}",
      id
    );
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteContainerOrchestrator = (id, is_delete = false) => {
    const updatedMethodName = METHOD_CONTAINER_ORCHESTRATOR_DELETE.replace(
      "{ID}",
      id
    );
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
  //   Orchestrator Version

  getAllOrchestratorVersion = (data) => {
    return networkService
      .post(METHOD_ORCHESTRATOR_VERSION_LIST, data)
      .then((response) => response.data);
  };
  getOrchestratorVersion = (id) => {
    const updatedMethodName = METHOD_ORCHESTRATOR_VERSION_DETAIL.replace(
      "{ID}",
      id
    );
    return networkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  createOrchestratorVersion = (data) => {
    return networkService
      .post(METHOD_ORCHESTRATOR_VERSION_CREATE, data)
      .then((response) => response.data);
  };

  updateOrchestratorVersion = (data, id) => {
    const updatedMethodName = METHOD_ORCHESTRATOR_VERSION_UPDATE.replace(
      "{ID}",
      id
    );
    return networkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  deleteOrchestratorVersion = (id, is_delete = false) => {
    const updatedMethodName = METHOD_ORCHESTRATOR_VERSION_DELETE.replace(
      "{ID}",
      id
    );
    return networkService
      .delete(updatedMethodName, { data: { is_delete } })
      .then((response) => response.data);
  };
}

export default HrpcService;
