import { MSG_DELETE_WARNING, MSG_UNDO_DELETE_WARNING, MSG_RECORD_CREATE, MSG_RECORD_UPDATE, MSG_RECORD_DELETE, MSG_RECORD_UNDO, MIN_TEXT_LENGTH, MAX_TEXT_LENGTH } from "constants/common";


export const METHOD_LIST = 'os/pageable/';
export const METHOD_CREATE = 'os/';
export const METHOD_UPDATE = 'os/{ID}/';
export const METHOD_DELETE = 'os/{ID}/';
export const METHOD_DETAIL = 'os/{ID}/';
export const METHOD_VENDOR_LIST = 'osvendor/pageable/';
export const METHOD_VENDOR_CREATE = 'osvendor/';
export const METHOD_VENDOR_UPDATE = 'osvendor/{ID}/';
export const METHOD_VENDOR_DELETE = 'osvendor/{ID}/';
export const METHOD_VENDOR_DETAIL = 'osvendor/{ID}/';
export const METHOD_VENDOR_DROPDOWN = 'osvendor/drop/';
export const METHOD_KERNEL_LIST = 'oskernel/pageable/';
export const METHOD_KERNEL_CREATE = 'oskernel/';
export const METHOD_KERNEL_UPDATE = 'oskernel/{ID}/';
export const METHOD_KERNEL_DELETE = 'oskernel/{ID}/';
export const METHOD_KERNEL_DETAIL = 'oskernel/{ID}/';


//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = MSG_DELETE_WARNING;
export const MSG_WARNING_UNDO_DELETE = MSG_UNDO_DELETE_WARNING;
export const MSG_CREATE_RECORD = MSG_RECORD_CREATE;
export const MSG_UPDATE_RECORD = MSG_RECORD_UPDATE;
export const MSG_DELETE_RECORD = MSG_RECORD_DELETE;
export const MSG_UNDO_RECORD = MSG_RECORD_UNDO;